<!-- eslint-disable no-useless-catch -->
<template>
  <div class="container">
    <div class="row flex-wrap align-items-center justify-content-center">
      <div class="col-12  d-flex align-items-center justify-content-center">
        <h1>Cupom</h1>
      </div>
      <div class="col-12  d-flex align-items-center justify-content-center">
        <h2>Gerencie seus cupons</h2>
      </div>
    </div>

    <nav id="nav-cupom-rules" class="row align-items-center justify-content-start">
      <ul id="nav-ul-cupom" class="d-flex flex-row align-items-center justify-content-center">
        <li @click.prevent="selectPageView = 1" class="row align-items-center justify-content-center" :class="{
          'option-selected': selectPageView === 1
        }" title="Cadastrar Cupom">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
            <path
              d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z" />
          </svg>
        </li>

        <li @click.prevent="selectPageView = 2" class="row align-items-center justify-content-center" :class="{
          'option-selected': selectPageView === 2
        }" title="Ver Cupons">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-card-checklist" viewBox="0 0 16 16">
            <path
              d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
            <path
              d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
          </svg>
        </li>
      </ul>
    </nav>

    <div id="container-pageview">
      <div class="row align-items-start flex-wrap mt-5 pageview" :class="{ 'pageview-active': selectPageView === 1 }"
        style="gap: 0.5rem">

        <div class="cupom-card-rules general-rules col-4 m-0 d-flex flex-column ">
          <div class="row align-items-center m-0 mb-2">
            <span class="strong"> Regras de aplicação </span>
          </div>

          <div class="row align-items-center m-0 mt-3">
            <div class="col-12 p-0 m-0 cupom-rules-container">
              <label>Regras</label>
              <select class="input-general" @change="setRules">
                <option value="0">Selecione uma regra</option>
                <option value="1" :selected="selectRulesType === 1">Valor do Carrinho</option>
                <!-- <option value="2" :selected="selectRulesType === 2">Valor do produto</option> -->
                <option value="3" :selected="selectRulesType === 3">Quantidades de itens no carrinho</option>
                <option value="4" :selected="selectRulesType === 4">Qtde. de produtos</option>
                <option value="5" :selected="selectRulesType === 5">Categoria do produto</option>
                <!-- <option value="6" :selected="selectRulesType === 6">Produto</option> -->
                <option value="7" :selected="selectRulesType === 7">Pagamento á vista</option>
              </select>
            </div>
          </div>

          <div class="row align-items-center m-0 mt-3">
            <div v-if="selectRulesType === 1 || selectEnhancedRulesType.includes(1)"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label class="row align-items-center justify-content-between m-0">
                  Valor do Carrinho
                  <span class="strong">
                    ({{ operatorTranslate[cupom.amountCart.when || ''] }})
                  </span>
                </label>

                <!-- {{ cupom.amountCart.value }}
                {{ CUPOM.amountCart.value }} -->

                <input type="text" name="cart-value" id="cart-value" class="input-general" placeholder="R$1,00"
                  v-model="cupom.amountCart.value" v-money="formatString" @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column"
                  @click.prevent="(event) => event.target.classList.remove('options-available')">
                  <li title="Quando for Maior que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('amountCart', 'than', 'when')">Maior</li>
                  <li title="Quando for Menor que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('amountCart', 'less', 'when')">Menor</li>
                  <li title="Quando for Igual á" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('amountCart', 'equal', 'when')">Igual</li>
                  <li title="Quando for Diferente de" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('amountCart', 'difer', 'when')">Diferente</li>
                  <li title="Quando for Maior ou Igual" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('amountCart', 'equalthen', 'when')">Maior ou Igual</li>
                </ul>
              </div>
            </div>

            <!-- <div v-if="(selectRulesType === 2 || selectRulesType === 6) || (selectEnhancedRulesType.includes(2) || selectEnhancedRulesType.includes(6))"  class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Valor do Produto ({{ cupom.amountProduct.when || '' }})</label>
                <input
                  type="text"
                  name=""
                  id=""
                  class="input-general"
                  placeholder="Para valores acima de R$1,00"
                  v-model="cupom.amountProduct.value"
                  @click.prevent="handlerSetRulesOptions"
                >
                <ul class="cupom-rules-options d-flex flex-column" @click.prevent="(event) => event.target.classList.remove('options-available')">
                  <li title="Quando for Maior que" class="d-flex align-items-center" @click.prevent="() => setCupom('amountProduct', 'than', 'when')">Maior</li>
                  <li title="Quando for Menor que" class="d-flex align-items-center" @click.prevent="() => setCupom('amountProduct', 'less', 'when')">Menor</li>
                  <li title="Quando for Igual á" class="d-flex align-items-center" @click.prevent="() => setCupom('amountProduct', 'equal', 'when')">Igual</li>
                  <li title="Quando for Diferente de" class="d-flex align-items-center" @click.prevent="() => setCupom('amountProduct', 'difer', 'when')">Diferente</li>
                </ul>
              </div>
            </div> -->

            <div v-if="selectRulesType === 3 || selectEnhancedRulesType.includes(3)"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label class="row align-items-center justify-content-between m-0">
                  Qtde. de itens no carrinho
                  <span class="strong">
                    ({{ operatorTranslate[cupom.quantityCart.when || ''] }})
                  </span>
                </label>
                <input type="text" name="" id="" class="input-general" placeholder="Quantidade de items para o desconto"
                  v-model="cupom.quantityCart.value" @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column"
                  @click.prevent="(event) => event.target.classList.remove('options-available')">
                  <li title="Quando for Maior que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityCart', 'than', 'when')">Maior</li>
                  <li title="Quando for Menor que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityCart', 'less', 'when')">Menor</li>
                  <li title="Quando for Igual á" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityCart', 'equal', 'when')">Igual</li>
                  <li title="Quando for Diferente de" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityCart', 'difer', 'when')">Diferente</li>
                  <li title="Quando for Maior ou Igual" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityCart', 'equalthen', 'when')">Maior ou Igual</li>
                </ul>
              </div>
            </div>

            <div v-if="selectRulesType === 4 || selectEnhancedRulesType.includes(4)"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label class="row align-items-center justify-content-between m-0">
                  Por Produto
                  <span class="strong">
                    ({{ operatorTranslate[cupom.quantityProduct.when || ''] }})
                  </span>
                </label>
                <input type="text" name="" id="" class="input-general" placeholder="Para quantidades abaixo de 5 itens"
                  v-model="cupom.quantityProduct.value" @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column">
                  <li title="Quando for Maior que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityProduct', 'than', 'when')">Maior</li>
                  <li title="Quando for Menor que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityProduct', 'less', 'when')">Menor</li>
                  <li title="Quando for Igual á" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityProduct', 'equal', 'when')">Igual</li>
                  <li title="Quando for Diferente de" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityProduct', 'difer', 'when')">Diferente</li>
                  <li title="Quando for Maior ou Igual" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('quantityProduct', 'equalthen', 'when')">Maior ou Igual</li>
                </ul>
              </div>
            </div>

            <div v-if="selectRulesType === 5 || (selectEnhancedRulesType.includes(5))"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Categoria produto</label>
                <input type="text" name="" id="" class="input-general" readonly
                  placeholder="Clique aqui para selecionar um categoria" :value="cupom.categoryProduct.value.name"
                  @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column">
                  <li :title="category.description" class="d-flex align-items-center" @click.prevent="() =>
                    setCupom(
                      'categoryProduct',
                      { name: category.name, id: category.id },
                      'value'
                    )
                  " v-for="(category, index) in categories" :key="index">
                    {{ category.name }}
                  </li>
                </ul>
              </div>
            </div>

            <div
              v-if="(selectRulesType === 6 || selectEnhancedRulesType.includes(6)) || (selectRulesType === 4 || selectEnhancedRulesType.includes(4))"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Produtos</label>
                <input type="text" name="" id="" class="input-general" readonly
                  placeholder="Clique aqui para selecionar um produto"
                  :value="`Você selecionou ${cupom.product.value.length} produtos`"
                  @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column">
                  <li :title="product.description" class="d-flex align-items-center" :class="{
                    'rule-active': cupom.product.value.includes(product.id)
                  }" @click.prevent="() => setProducts(product.id)" v-for="(product, index) in productsNoPaginate"
                    :key="index">
                    {{ product.name }}
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="selectRulesType === 7 || selectEnhancedRulesType.includes(7)"
              class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label class="row align-items-center justify-content-between m-0">
                  Pagamento a vista
                  <span class="strong">
                    ({{ operatorTranslate[cupom.installments.when || ''] }})
                  </span>
                </label>
                <input type="text" name="" id="" class="input-general"
                  placeholder="Informe o número da parcelas para o pagamento a vista" v-model="cupom.installments.value"
                  @click.prevent="handlerSetRulesOptions">
                <ul class="cupom-rules-options d-flex flex-column">
                  <li title="Quando for Maior que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('installments', 'than', 'when')">Maior</li>
                  <li title="Quando for Menor que" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('installments', 'less', 'when')">Menor</li>
                  <li title="Quando for Igual á" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('installments', 'equal', 'when')">Igual</li>
                  <li title="Quando for Diferente de" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('installments', 'difer', 'when')">Diferente</li>
                  <li title="Quando for Maior ou Igual" class="d-flex align-items-center"
                    @click.prevent="() => setCupom('installments', 'equalthen', 'when')">Maior ou Igual</li>
                </ul>
              </div>
            </div>
          </div>
          <!---
            ADICIONAR OUTRO REGRA -> BUTTON
            <div class="row align-items-center justify-content-between m-0 mt-3">
              <div title="Adicionar mais uma regra"
                class="col-12 d-flex align-items-center justify-content-center cupom-rules-container button-rule">
                <span @click.prevent="handlerSetRulesOptions"> ADICIONAR OUTRA REGRA </span>
                <ul class="cupom-rules-options d-flex flex-column">
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(1) }"
                    @click.prevent="() => setEnhancedRules(1)">
                    Valor do Carrinho
                  </li>
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(3) }"
                    @click.prevent="() => setEnhancedRules(3)">
                    Quantidades de itens no carrinho
                  </li>
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(4) }"
                    @click.prevent="() => setEnhancedRules(4)">
                    Qtde. de produtos
                  </li>
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(5) }"
                    @click.prevent="() => setEnhancedRules(5)">
                    Categoria do produto
                  </li>
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(6) }"
                    @click.prevent="() => setEnhancedRules(6)">
                    Produto
                  </li>
                  <li class="d-flex align-items-center" :class="{ 'rule-active': selectEnhancedRulesType.includes(7) }"
                    @click.prevent="() => setEnhancedRules(7)">
                    Parcela
                  </li>
                </ul>
              </div>
            </div>
          -->
        </div>

        <div class="cupom-card-rules general-rules col-4 m-0 d-flex flex-column">
          <div class="row align-items-center m-0 mb-2">
            <span class="strong"> Regras Gerais </span>
          </div>

          <div class="row align-items-center m-0 mt-3">
            <div class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Validade</label>
                <div class="row p-0 m-0 mb-2">
                  <label for="cupom-date-de">De</label>
                  <input type="date" name="cupom-date-de" id="cupom-date-de" class="input-general"
                    v-model="cupom.date.start">
                </div>
                <div class="row p-0 m-0">
                  <label for="cupom-date-ate">Até</label>
                  <input type="date" name="cupom-date-ate" id="cupom-date-ate" class="input-general mt-2"
                    v-model="cupom.date.end">
                </div>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Nome do cupom</label>
                <input type="text" name="" id="" class="input-general" placeholder="Nome do cupom" v-model="cupom.name">
              </div>

              <div class="col-12 p-0 m-0 cupom-rules-container mt-3">
                <label>Descrição</label>
                <input type="text" name="" id="" class="input-general" placeholder="Uma descrição curta sobre o cupom"
                  v-model="cupom.description">
              </div>

              <div class="col-12 p-0 m-0 align-items-center justify-content-center" style="margin-top: 1rem !important">
                <label> Tipo de cupom </label>
                <div class="row align-items-center justify-content-center m-0">
                  <select class="col-12 input-general" id="select-type-cupom"
                    @change="(event) => cupom.type = event.target.selectedOptions[0].value || ''">
                    <option value="direct" :selected="cupom.type === 'direct' && true">Aplicação direta</option>
                    <option value="searchable" :selected="cupom.type === 'searchable' && true">Buscável</option>
                  </select>
                </div>
              </div>

              <div class="col-12 p-0 m-0 mt-3 cupom-rules-container">
                <label>Código do cupom</label>
                <input type="text" name="" id="" class="input-general" placeholder="Código de identificação do cupom"
                  :value="cupom.code"
                  @change.prevent="(event) => cupom.code = String(event.target.value.replace(/[^\w\s]+/gi, '').replace(/\s+/, '').trim()).toUpperCase()">
              </div>

              <div class="row align-items-center  justify-content-between m-0 mt-3" style="width: 100%;">
                <div class="col-4 p-0 m-0 cupom-rules-container mt-3">
                  <label>Qtde. Disponível</label>
                  <input type="text" name="" id="" class="input-general" placeholder="Quantidades disponíveis"
                    v-model="cupom.quantity">
                </div>

                <div class="col-4 p-0 m-0 cupom-rules-container mt-3">
                  <label>Valor Desconto</label>
                  <input type="text" name="" id="" v-if="!cupom.isPercent" class="input-general"
                    placeholder="Valor do desconto" v-model="cupom.discount" v-money="formatString">
                  <input type="number" name="" id="" v-else class="input-general" placeholder="Valor do desconto"
                    v-model="cupom.discount">
                </div>

                <div class="col-3 p-0 m-0 align-items-center justify-content-center"
                  style="margin-top: 1rem !important">
                  <label>Tipo de Desconto</label>
                  <div class="row align-items-center justify-content-center ml-1"
                    style="flex: 1 !important; width: inherit; height: 2.2rem; position: relative;  border-radius: 1rem; border:0.1rem solid #d5d5d5; background-color:#fff">
                    <button @click.prevent="() => cupom.isPercent = !cupom.isPercent"
                      class="d-flex align-items-center justify-content-center"
                      :style="`border: none; outline: none;  cursor: pointer; width: 2rem; height: 2rem; border-radius: 50%; background-color: #458ee8; position: absolute; ${!cupom.isPercent ? 'left: 0rem;' : 'right: 0rem;'} top: 0rem; color: #fff; font-size: 0.8rem; transition: 0.2s ease-in; user-select: none`">
                      {{
                          cupom.isPercent ? '%' : 'R$'
                      }}
                    </button>
                  </div>
                </div>
              </div>


            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 align-items-center justify-content-center" style="margin-top: 1rem !important">
                <label>
                  Cupom
                  {{
                      cupom.active ? 'Ativado' : 'Desativado'
                  }}
                </label>
                <div class="row align-items-center justify-content-center ml-1"
                  style="flex: 1 !important; width: inherit; height: 2.2rem; position: relative;  border-radius: 1rem; border:0.1rem solid #d5d5d5; background-color:#fff">
                  <button @click.prevent="() => cupom.active = !cupom.active"
                    class="d-flex align-items-center justify-content-center"
                    :style="`border: none; outline: none;  cursor: pointer; width: 2rem; height: 2rem; border-radius: 50%; ${cupom.active ? 'background-color: #458ee8;' : 'background-color: #e84545;'}  position: absolute; ${cupom.active ? 'left: 0rem;' : 'right: 0rem;'} top: 0rem; color: #fff; font-size: 0.8rem; transition: 0.2s ease-in; user-select: none`">
                    {{
                        cupom.active ? 'A' : 'D'
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cupom-card-rules general-rules col-4 m-0 d-flex flex-column">
          <div class="row align-items-center m-0 mb-2">
            <span class="strong"> Resumo </span>
          </div>

          <div class="row align-items-center m-0 mt-3">
            <div class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Nome: </label>
                <span class="strong">
                  {{ cupom.name }}
                </span>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Descrição: </label>
                <span class="strong">
                  {{ cupom.description }}
                </span>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.installments.value > 0">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Pagamento a vista, quando </label>
                <span class="strong">
                  a parcela em
                  {{ cupom.installments.value }}x
                  for
                  {{ operatorTranslate[cupom.installments.when] }}
                </span>

              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.amountCart.value > 0">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Carrinho: {{ operatorTranslate[cupom.amountCart.when] }} de </label>
                <span class="strong">
                  {{
                      Number(cupom.amountCart.value).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })
                  }}
                </span>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.quantityProduct.value > 0">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Qtde de Produtos: {{ operatorTranslate[cupom.quantityProduct.when] }} de </label>
                <span class="strong">
                  {{ cupom.quantityProduct.value }}x
                </span>
              </div>

              <div class="col-12 p-0 m-0 cupom-rules-container" v-if="cupom.product.value.length > 0">
                <label>Para
                  <span class="strong">
                    {{ cupom.product.value.length }}
                  </span>
                  produto(s)
                </label>
              </div>
            </div>


            <!-- {{ cupom.date.start }}
            {{ cupom.date.end }} -->
            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.date.start && cupom.date.end">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Validade: </label>
                de
                <span class="strong">
                  {{ cupom.date.start.split("-").reverse().join("/") }}
                  até
                  {{ cupom.date.end.split("-").reverse().join("/") }}
                </span>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.quantityCart.value > 0">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label title="Para quantidades de">Para Qtde: </label>
                <strong class="stong">
                  {{ cupom.quantityCart.value }}x
                </strong>
              </div>

              <div class="col-12 p-0 m-0 cupom-rules-container" v-if="cupom.quantityCart.price > 0">
                <label title="Para quantidades de">Com valores {{ operatorTranslate[cupom.quantityCart.when] }}
                  de:</label>
                <strong class="stong">
                  {{
                      Number(cupom.quantityCart.price).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })
                  }}
                </strong>
              </div>
            </div>

            <div class="row align-items-center m-0 mt-3" style="width: 100%" v-if="cupom.categoryProduct.value.id > 0">
              <div class="col-12 p-0 m-0 cupom-rules-container">
                <label>Categoria selecionada: </label>
                <span class="strong">
                  {{ cupom.categoryProduct.value.name }}
                </span>
              </div>
            </div>

          </div>

          <div @click.prevent="setCreateCupom" class="row align-items-center justify-content-between m-0 mt-3">
            <div title="Finalizar e criar cupom de desconto"
              class="col-12 d-flex align-items-center justify-content-center cupom-rules-container button-rule button-rule-finished btn btn-primary">
              <span> CRIAR CUPOM DE DESCONTO </span>
            </div>
          </div>
          <div @click.prevent="setUpdateCupom" v-if="CUPOM"
            class="row align-items-center justify-content-between m-0 mt-3">
            <div title="Atualizar cupom de desconto"
              class="col-12 d-flex align-items-center justify-content-center cupom-rules-container button-rule button-rule-updated">
              <span> ATUALIZAR CUPOM DE DESCONTO </span>
            </div>
          </div>
          <div @click.prevent="setResetCupom" class="row align-items-center justify-content-between m-0 mt-3">
            <div title="Resetar e Começar Novamente"
              class="col-12 d-flex align-items-center justify-content-center cupom-rules-container button-rule button-rule-prev">
              <span> RESETAR </span>
            </div>
          </div>
        </div>

      </div>

      <div class="col-12 align-items-start flex-wrap mt-5 pageview" :class="{ 'pageview-active': selectPageView === 2 }"
        style="gap: 0.5rem">
        <nav id="div-pagination" class="row align-items-center justify-content-center" style="margin: 0 auto">
          <button @click="() => getPage(1, true, 1, Math.round(CUPONS.length / 2))" title="Ir para primeira">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
            </svg>
          </button>

          <button v-for="(paginated, index) in navigationPaginate" :key="index" :id="`perPageID_${paginated.id}`"
            class="perPage row align-items-center justify-content-center" :perPage="`${paginated.perPage}`"
            :pageStart="`${paginated.pageStart}`" :pageEnd="`${paginated.pageEnd}`"
            @click="() => setPaginator(paginated.perPage, paginated.pageStart, paginated.pageEnd, paginated.id)">
            {{ paginated.page }}
          </button>

          <button @click="() => getPage(1, true, Math.round(CUPONS.length / 2), CUPONS.length + 1)"
            title="Ir para última">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-right-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </button>
        </nav>

        <div class="row flex-wrap mt-5" style="width: 100%; gap:0.5rem;">
          <div :class="`card-cupom ${cupom.active ? 'cupom-active' : ''}`" v-for="(cupom, index) in cupomFromPaginate"
            :key="index">
            <div class="card-cupom-header">
              {{ cupom.name || '' }}
            </div>
            <div class="card-cupom-content">
              {{ cupom.description || '' }}
            </div>
            <div class="card-cupom-footer">
              <button @click.prevent="() => setCupomDeleteByID(cupom.id)" title="Excluir cupom"
                class="col-5 d-flex align-items-center justify-content-center cupom-rules-container button-rule button-rule-prev">
                <span> EXCLUIR </span>
              </button>
              <button @click.prevent="() => setSelectCupom(cupom)" title="Selecionar para atualizar"
                class="col-6 d-flex align-items-center justify-content-center cupom-rules-container button-rule button-rule-finished">
                <span> SELECIONAR </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import { VMoney } from "v-money";

// Styles
import "./styles.scss"

export default {
  name: "CupomPage",
  computed: {
    ...mapGetters([
      'categories',
      'products',
      'CUPONS',
      'CUPOM',
      'productsNoPaginate'
    ])
  },
  directives: { money: VMoney },
  data: () => ({
    formatString: {
      decimal: ".",
      thousands: "",
      prefix: "",
      suffix: "",
      precision: 1,
      masked: false,
    },
    cupom: {
      amountCart: {
        value: 0.0,
        when: ''
      },
      amountProduct: {
        value: 0.0,
        when: ''
      },
      quantityCart: {
        value: 0,
        price: 0.0,
        when: ''
      },
      quantityProduct: {
        value: 0,
        when: ''
      },
      categoryProduct: {
        value: {
          name: '',
          id: 0
        }
      },
      product: {
        value: []
      },
      installments: {
        value: 0,
        when: ''
      },
      date: {
        start: null,
        end: null,
      },
      id: -1,
      uuid: '',
      name: '',
      code: '',
      quantity: 0,
      discount: 0.0,
      isPercent: false,
      description: '',
      active: '',
      type: 'direct'
    },
    cupomCached: {
      amountCart: {
        value: 0.0,
        when: ''
      },
      amountProduct: {
        value: 0.0,
        when: ''
      },
      quantityCart: {
        value: 0,
        price: 0.0,
        when: ''
      },
      quantityProduct: {
        value: 0,
        when: ''
      },
      categoryProduct: {
        value: {
          name: '',
          id: 0
        }
      },
      product: {
        value: []
      },
      installments: {
        value: 0,
        when: ''
      },
      date: {
        start: '2022/07/02',
        end: '2022/07/02'
      },
      id: -1,
      uuid: '',
      name: '',
      code: '',
      quantity: 0,
      discount: 0.0,
      isPercent: false,
      description: '',
      active: '',
      type: 'direct'
    },
    cupomBase: {
      amountCart: {
        value: 0.0,
        when: ''
      },
      amountProduct: {
        value: 0.0,
        when: ''
      },
      quantityCart: {
        value: 0,
        price: 0.0,
        when: ''
      },
      quantityProduct: {
        value: 0,
        when: ''
      },
      categoryProduct: {
        value: {
          name: '',
          id: 0
        }
      },
      product: {
        value: []
      },
      installments: {
        value: 0,
        when: ''
      },
      date: {
        start: '',
        end: ''
      },
      id: -1,
      uuid: '',
      name: '',
      code: '',
      quantity: 0,
      discount: 0.0,
      isPercent: false,
      description: '',
      active: '',
      type: 'direct'
    },
    cupomFromPaginate: [],
    navigationPaginate: [],
    operatorTranslate: {
      less: 'menor',
      than: 'maior',
      difer: 'diferente',
      equal: 'igual',
      equalthen: 'maior ou igual'
    },
    selectRulesType: 0,
    selectPageView: 1,
    selectEnhancedRulesType: [],
  }),
  mounted() {
    try {
      this.getCupons()
      if (this.CUPONS.length <= 0) {
        // this.$toast.warning("Nenhum cupom disponivel")
        this.selectPageView = 1
      }

      if (this.productsNoPaginate.length <= 0) this.LOAD_ALL_PRODUCTS()
    } catch (error) {
      console.log('')
      throw error
    }
  },
  watch: {
    selectPageView: {
      deep: true,
      immediate: false,
      handler(pageView) {
        try {
          if (this.CUPONS.length <= 0) {
            this.selectPageView = 1
            return
          }

          if (pageView === 2) {
            this.callbackStartPaginator()
          }
        } catch (error) {
          console.log('')
          throw error
        }
      }
    },
    CUPONS: {
      deep: true,
      immediate: false,
      handler(cupons) {
        try {
          if (cupons.length <= 0) {
            this.selectPageView = 1
            return
          }

          // console.log('watch ~ CUPONS: ',cupons)

          this.callbackStartPaginator()
        } catch (error) {
          console.log('')
          throw error
        }
      }
    },
    cupon: {
      deep: true,
      immediate: false,
      handler(cupom) {
        try {
          if (cupom.code.trim() !== '') {
            cupom.code = String(cupom.code?.replace(/[^a-zA-Z0-9],;-.!? ]/g, '')?.replace(/\s+/, '')?.trim()).toUpperCase()
            // console.log('watch@cupon', cupom.code)
            this.cupom.code = cupom.code
          }
        } catch (error) {
          console.log('')
          throw error;
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'CREATE_CUPOM',
      'INDEX_CUPOM',
      'UPDATE_CUPOM',
      'DESTROY_CUPOM',
      'SHOW_CUPOM',
      'LOAD_ALL_PRODUCTS'
    ]),
    ...mapMutations([
      'SET_CUPOM'
    ]),

    /**
     * Show the UI model of options
     */
    handlerSetRulesOptions(event = null, callback = null, ...rest) {
      try {
        if (!event) return

        this.setClearCupomRulesOptions()

        const targetCalled = event?.target

        if (!targetCalled) return

        if (!targetCalled?.nextSibling) return

        const target = targetCalled.nextSibling

        if (!target.classList.contains("options-available")) {
          target.classList.add('options-available')
          return
        }

        target.classList.remove('options-available')

        if (callback) callback(...rest)
      } catch (error) {
        console.log(error)
        // throw error
      }
    },

    /**
     * Set on the cupom object on key 'name' the 'value'
     *
     * @param {*} name The key on object Cupom
     * @param {*} value The value to key
     * @param {*} nestedName The key nested on key of cupom
     */
    setCupom(name = '', value = '', nestedName = "") {
      try {
        console.log('setCupom', name, nestedName, value)
        if (name.trim() === '' || !value) return

        this.setClearCupomRulesOptions()

        if (nestedName.trim() !== '') {
          if (!this.cupom[name]) this.cupom[name] = {}

          this.cupom[name][nestedName] = value

          return
        }

        this.cupom[name] = value

        console.log('setCupom', this.cupom)

      } catch (error) {
        console.log(error)
      }
    },

    /**
     * Remove the UI model of options that are showed
     *
     */
    setClearCupomRulesOptions() {
      try {
        const elementsCupomOptions = document.querySelectorAll('.cupom-rules-options')

        if (!elementsCupomOptions || elementsCupomOptions.length <= 0) return

        const elementsCupomOptionsParsed = Array.from(elementsCupomOptions)

        if (!elementsCupomOptionsParsed || elementsCupomOptionsParsed.length <= 0) return

        elementsCupomOptionsParsed?.forEach(elementCupom => {
          if (elementCupom?.classList?.contains('options-available')) elementCupom.classList.remove('options-available')
        })
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * Set a value on enhaced key of Cupom object
     * @param {*} whichRule The name of nested key
     */
    setEnhancedRules(whichRule = 1) {
      try {
        this.setClearCupomRulesOptions()

        if (whichRule <= 0 || whichRule > 7) {
          this.selectEnhancedRulesType = [1]
          return
        }

        let enhacedRulesTypes = this.selectEnhancedRulesType

        if (enhacedRulesTypes.includes(whichRule)) {
          enhacedRulesTypes = this.selectEnhancedRulesType.filter(rules => rules !== whichRule)
        } else {
          enhacedRulesTypes.push(whichRule)
        }


        this.selectEnhancedRulesType = enhacedRulesTypes
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Set the ID of product on array products of Cupom object
     * @param {*} productIDSelected The ID from product target
     */
    setProducts(productIDSelected = 1) {
      try {
        this.setClearCupomRulesOptions()

        let productsList = this.cupom.product.value

        if (productsList.includes(productIDSelected)) {
          productsList = this.cupom.product.value.filter(rules => rules !== productIDSelected)
        } else {
          productsList.push(productIDSelected)
        }

        this.cupom.product.value = productsList
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Set the rules target to be showed
     * @param {*} event A event with ID of target rule
     */
    setRules(event = null) {
      try {

        this.setClearCupomRulesOptions()

        if (!event) return

        const optionSelected = parseInt(event.target.selectedOptions?.[0]?.value || -1)

        if (optionSelected === 0) {
          this.selectRulesType = 0
          return
        }

        if (optionSelected < 0 || optionSelected > 7) {
          this.selectRulesType = 1
          return
        }

        this.selectRulesType = optionSelected
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Send the Cupom object to store engine for be sent to server
     */
    async setCreateCupom() {
      try {
        if (this.cupom.name.trim() === '' || this.cupom.description.trim() === '') {
          this.$toast.warning('Verifique o nome/descrição do seu cupom')
          return
        }

        if (this.cupom.quantity <= 0) {
          this.$toast.warning('Verifique a quantidade do seu cupom')
          return
        }

        if (this.cupom.discount <= 0) {
          this.$toast.warning('Verifique o disconto do seu cupom')
          return
        }

        if (!this.cupom.date.start || !this.cupom.date.end) {
          this.$toast.warning('Verifique a válidade do seu cupom')
          return
        }

        const response = await this.CREATE_CUPOM(this.cupom)

        const message = response.data?.message || response.data?.data?.message

        if (response.data?.code !== 201) {
          const messageRaw = response.data?.messageRaw?.message?.split(',') || 'Houve um erro'

          if (messageRaw) {
            messageRaw.forEach(message => this.$toast.error(message || ''))
            return
          }

          this.$toast.error(message)
          return
        }

        this.$toast.success(message)

        this.getParseCupomRaw()

        this.setResetCupom()
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Update the cupom target
     */
    async setUpdateCupom() {
      try {
        const response = await this.UPDATE_CUPOM(this.cupom)

        const message = response.data?.message || response.data?.data?.message

        if (response.data?.code !== 201) {
          const messageRaw = response.data?.messageRaw?.message?.split(',') || 'Houve um erro'

          if (messageRaw) {
            messageRaw.forEach(message => this.$toast.error(message || ''))
            return
          }

          this.$toast.error(message)
          return
        }

        this.$toast.success(message)

        this.getParseCupomRaw()
        this.SET_CUPOM(null)
        this.setResetCupom()
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Get the cupons from server
     */
    async getCupons() {
      try {
        const response = await this.INDEX_CUPOM()

        const message = response.data?.message || response.data?.data?.message

        if (response.data?.code !== 200) {
          const messageRaw = response.data?.messageRaw?.message?.split(',') || 'Houve um erro'

          if (typeof messageRaw !== 'string') {
            messageRaw?.forEach(message => this.$toast.error(message))
            return
          }

          this.$toast.error(message)
          return
        }

        this.$toast.success(message)
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Get a cupom from server
     *
     * @param {*} cupomID The id of cupom target
     */
    async getCupomByID(cupomID = -1) {
      try {

        if (cupomID <= -1) {
          this.$toast.warning('Selecione um cupom')
          return
        }

        const response = await this.SHOW_CUPOM(cupomID)

        const message = response.data?.message || response.data?.data?.message

        if (response.data?.code !== 200) {
          const messageRaw = response.data?.messageRaw?.message?.split(',') || 'Houve um erro'

          if (messageRaw) {
            messageRaw.forEach(message => this.$toast.error(message))
            return
          }

          this.$toast.error(message)
          return
        }

        this.$toast.success(message)

        this.getParseCupomRaw()
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Delet a cupom from server
     *
     * @param {*} cupomID The id of cupom target
     */
    async setCupomDeleteByID(cupomID = -1) {
      try {

        if (!cupomID || cupomID <= -1) {
          this.$toast.warning('Selecione um cupom')
          return
        }

        const response = await this.DESTROY_CUPOM(cupomID)

        const message = response.data?.message || response.data?.data?.message

        if (response.data?.code !== 201) {
          const messageRaw = response.data?.messageRaw?.message?.split(',') || 'Houve um erro'

          if (messageRaw) {
            messageRaw.forEach(message => this.$toast.error(message))
            return
          }

          this.$toast.error(message)
          return
        }

        this.$toast.success(message)
      } catch (error) {
        this.$toast.error('Houve um erro critico, Tente Novamente')
        console.log(error)
        throw error
      }
    },

    /**
     * Parse the structure raw from database and convert to an clear structure
     *
     * Also set the local CUPOM object
     */
    getParseCupomRaw() {
      try {
        if (!this.CUPOM) {
          this.cupom = this.cupomCached
          return
        }

        const clearStructure = this.cupomCached

        // CONDITIONS
        clearStructure.date.start = this.CUPOM.started?.split(" ")[0] ?? this.CUPOM.started
        clearStructure.date.end = this.CUPOM.ended?.split(" ")[0] ?? this.CUPOM.ended
        clearStructure.amountCart = this.CUPOM.condition?.amountCart || this.cupomCached.amountCart
        clearStructure.amountProduct = this.CUPOM.condition?.amountProduct || this.cupomCached.amountProduct
        clearStructure.quantityCart = this.CUPOM.condition?.quantityCart || this.cupomCached.quantityCart
        clearStructure.quantityProduct = this.CUPOM.condition?.quantityProduct || this.cupomCached.quantityProduct
        clearStructure.categoryProduct = this.CUPOM.condition?.categoryProduct || this.cupomCached.categoryProduct
        clearStructure.product = this.CUPOM.condition?.product || this.cupomCached.product

        // GENERAL
        clearStructure.id = this.CUPOM.id || this.cupomCached.id || -1
        clearStructure.uuid = this.CUPOM.uuid || this.cupomCached.uuid || ''
        clearStructure.name = this.CUPOM.name || this.cupomCached.name || ''
        clearStructure.description = this.CUPOM.description || this.cupomCached.description || ''
        clearStructure.discount = this.CUPOM.discount || this.cupomCached.discount || 0.0
        clearStructure.isPercent = this.CUPOM.isPercent || this.cupomCached.isPercent || false
        clearStructure.quantity = this.CUPOM.quantity || this.cupomCached.quantity || 0
        clearStructure.active = this.CUPOM.active || this.cupomCached.active || false
        clearStructure.code = this.CUPOM.code || this.cupomCached.code || ''
        clearStructure.type = this.CUPOM.type || this.cupomCached.type || ''

        // return

        this.SET_CUPOM(clearStructure)
        this.cupom = clearStructure
        console.log('getParseCupomRaw', clearStructure)
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    /**
     * Apply the cupom selected on CUPOM state
     * @param {*} cupom The cupom target to select
     */
    setSelectCupom(cupom = null) {
      try {
        if (!cupom) return

        console.log('setSelectCupom', cupom)

        this.SET_CUPOM(cupom)
        this.getParseCupomRaw()

        this.selectPageView = 1
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    setResetCupom() {
      try {
        const cupomBase = {
          amountCart: {
            value: 0.0,
            when: ''
          },
          amountProduct: {
            value: 0.0,
            when: ''
          },
          quantityCart: {
            value: 0,
            price: 0.0,
            when: ''
          },
          quantityProduct: {
            value: 0,
            when: ''
          },
          categoryProduct: {
            value: {
              name: '',
              id: 0
            }
          },
          product: {
            value: []
          },
          installments: {
            value: 0,
            when: ''
          },
          date: {
            start: '',
            end: ''
          },
          id: -1,
          uuid: '',
          name: '',
          code: '',
          quantity: 0,
          discount: 0.0,
          isPercent: false,
          description: '',
          active: '',
          type: 'direct'
        }
        this.cupom = cupomBase
        this.SET_CUPOM(cupomBase)

        const selectTypes = document.querySelector('#select-type-cupom')

        if (selectTypes) selectTypes.options[0].selected = true
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    /**
     * Filter a list of data with bases on perPage and fromPage params
     * @param {number} perPage Receive the total of items that will showed per page
     * @param {*} fromPage Receive the position from where the data will filtered
     */
    setPaginator(perPage = 16, fromPage = 1, toPage = 2, pageID = -1) {
      try {
        const dataList = this.CUPONS
        let itemsPerPage = []

        if (!dataList || dataList?.length <= 0) return

        if (perPage >= dataList?.length) {
          perPage = dataList?.length || 3
        }

        itemsPerPage = dataList.slice((fromPage - 1), (toPage - 1))

        this.cupomFromPaginate = itemsPerPage
        this.getPage(pageID)
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Mount the UI of to paginate with base on total of item per page
     *
     * @param {number} perPage Receive the total of item that will showed per page
     * @return {*} true or undefined
     */
    setPageNavigator(perPage = 3) {
      try {
        const dataList = this.CUPONS
        this.navigationPaginate = []

        if (!dataList || dataList?.length <= 0) return

        let totalPages = perPage >= dataList.length || perPage <= 0 ? 1 : Math.round(dataList.length / perPage)

        if (perPage > 1 && (perPage + totalPages) === dataList.length) {
          perPage = perPage + 1
        }

        for (let j = 1; j <= totalPages; j++) {
          const paginatedID = j > 1 ? (j + perPage) : j
          const pageEndRaw = (j * perPage) + 1
          const pageEnd = (pageEndRaw - 1) > dataList.length ? dataList.length + 1 : pageEndRaw

          const pageStartRaw = ((pageEnd - 1) - perPage) + 1
          const pageStart = pageStartRaw > dataList.length ? dataList.length + 1 : pageStartRaw

          if (j <= dataList.length) {
            this.navigationPaginate.push({
              id: paginatedID,
              perPage: perPage,
              page: j,
              pageEnd,
              pageStart
            })
          }
        }

        return true
      } catch (error) {
        console.log(`handlePaginator: ${error?.message}`, error)
      }
    },

    /**
     *  Get a list of data from a unique page
     * @param {*} pageID The pageID from page target
     * @param {*} fromArrows If was from arrows left/right
     */
    getPage(pageID = 1, fromArrows = false, arrowStart = 1, arrowEnd = 1) {
      try {
        const elementsPerPageRaw = document.querySelectorAll(".perPage")
        const elementsPerPageToFocus = document.querySelector(`#perPageID_${pageID}`)
        const elementsPerPageToFocused = document.querySelector(`.perPageInFocus`)

        if (pageID <= 0 || !elementsPerPageToFocus || !elementsPerPageRaw) return

        const elementsPerPageParsed = Array.from(elementsPerPageRaw)

        if (!elementsPerPageParsed || elementsPerPageParsed.length <= 0) return

        elementsPerPageParsed?.forEach(element => {
          if (element?.classList?.contains("perPageInFocus")) element?.classList?.remove("perPageInFocus")
        })

        elementsPerPageToFocus?.classList?.add("perPageInFocus")

        const perPage = parseInt(elementsPerPageToFocus?.getAttribute("perPage") || '16')
        const pageEnd = parseInt(elementsPerPageToFocus?.getAttribute("pageEnd") || '16')
        const pageStart = parseInt(elementsPerPageToFocus?.getAttribute("pageStart") || '16')

        if (fromArrows) {
          console.log('getPage', arrowStart, arrowEnd)
          this.setPaginator(perPage, arrowStart, arrowEnd)
        } else {
          this.setPaginator(perPage, pageStart, pageEnd)
        }
      } catch (error) {
        // do anything
      }
    },

    /**
     * Callback to use only on Watch
     */
    callbackStartPaginator() {
      try {
        const lengthDataList = this.CUPONS.length <= 8 ? this.CUPONS.length : 4

        if (this.setPageNavigator(lengthDataList)) {
          setTimeout(() => { this.getPage(1, false) }, 470)
        }
      } catch (error) {
        console.log('')
        throw error
      }
    },

    setClearPaginator() {
      try {
        const elementContainerElencoList = document.querySelector("#div-pagination")

        if (!elementContainerElencoList) return

        elementContainerElencoList.innerHTML = ""
      } catch (error) {
        // do anything
      }
    }
  }
}
</script>
