<template>
  <div></div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["last_search"]),
    actionMap() {
      return {
        posts: {
          paginate: cb => cb("PAGINATE_LOAD_POSTS"),
          search: cb => cb("SEARCH_POST"),
          generateUrl: true
        },
        products: {
          paginate: cb => cb("PAGINATE_LOAD_PRODUCTS"),
          search: cb => cb("SEARCH_PRODUCT"),
          generateUrl: true
        },
        orders: {
          paginate: cb => cb("PAGINATE_LOAD_ORDERS"),
          search: cb => cb("SEARCH_ORDER"),
          generateUrl: true
        },
        users: {
          paginate: cb => cb("PAGINATE_LOAD_USERS"),
          search: cb => cb("SEARCH_USER"),
          generateUrl: true
        },
        seller: {
          paginate: cb => cb("SEARCH_SELLER_ORDER"),
          search: cb => cb("SEARCH_SELLER_ORDER"),
          generateUrl: false
        }
      };
    }
  },
  mounted() {
    // console.log("Mixin: _PaginatorRules loaded");
  },
  methods: {
    ...mapActions([
      "SET_PARAMS",
      "LOAD_POSTS",
      "PAGINATE_LOAD_POSTS",
      "SEARCH_POST",
      "PAGINATE_LOAD_TAGS",
      "PAGINATE_LOAD_CATEGORIES",
      "PAGINATE_LOAD_PRODUCTS",
      "SEARCH_PRODUCT",
      "LOAD_PRODUCTS",
      "SEARCH_ORDER",
      "LOAD_ORDERS",
      "PAGINATE_LOAD_ORDERS",
      "PAGINATE_LOAD_USERS",
      "SEARCH_USER"
    ]),

    pageNavigate(pageName, url) {
      console.log("Page name is " + pageName + " and the url: " + url);

      if (this.$masterHelper.isEmpty(this.last_search)) {
        if (!this.actionMap[pageName].generateUrl) {
          this.actionMap[pageName].paginate(action =>
            this[action](this.last_search)
          );
        } else {
          console.log('User Paginator Rules',this.last_search)
          let payload = {
            params:{
              search:{},
              relationships: {
                "0": "tags",
                "1": "categories",
                "2": "media",
                // "3": "user"
              }
            },
            model: pageName.charAt(0).toUpperCase() + pageName.slice(1, pageName.length - 1),
            goToPage: this.clearUrl(url)
          }

          this.actionMap[pageName].search(action => this[action](payload || this.last_search));
        }
      } else {
        this.last_search.goToPage = this.clearUrl(url);

        this.actionMap[pageName].search(action =>
          this[action](this.last_search)
        );
      }
      this.$forceUpdate();
    },

   clearUrl(url) {
      return parseInt(url.replaceAll(/[A-Za-z]/g, "").replace(/[^\w\s]/gi,'').trim());
    },
  }
};
</script>
