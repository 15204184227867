<template>
  <div class="card">
    <div class="card-body">
      <h4><strong>Integrações</strong></h4>
      <div class="row justify-content-center">
        <div class="col-6">
          <div class="mt-3">
            <strong>Pagamento</strong><br />
            <small
              >Confira as integrações de pagamentos, é facil de começar.</small
            >
          </div>
          <div class="mt-3">
            <PaymentGateway />
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-6">
          <div class="mt-3">
            <strong>Frete</strong><br />
            <small
              >Confira as integrações de frete, é facil de começar.</small
            >
          </div>
          <div class="mt-3">
            <Shippings />
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-6">
          <div class="mt-3">
            <strong>SEO Google</strong><br />
            <small
              >Confira as integrações de SEO, é facil de começar.</small
            >
          </div>
          <div class="mt-3">
            <SEO />
          </div>
        </div>
      </div>

      <div class="row justify-content-center" v-if="$masterHelper.hasRole(loggedUser, ['dev']) === true">
        <div class="col-6">
          <div class="mt-3">
            <strong>Analytics</strong><br />
            <small>Confira as integrações de Analytics, é facil de começar.</small
            >
          </div>
          <div class="mt-3">
            <Analytics />
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-4">
        <div class="col-6">
          <hr />
          <div class="mt-3">
            <strong>Nota fiscal</strong><br />
            <small
              >Confira as integrações de pagamentos, é facil de começar.</small
            >
          </div>
          <div class="mt-3">
            <!-- <ul class="list-group">
              <li class="list-group-item">Bling</li>

            </ul> -->
            <InvoiceGateway/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaymentGateway from "@/components/configs/sections/integrations/PaymentGateway";
import Shippings from "@/components/configs/sections/integrations/Shippings";
import SEO from "@/components/configs/sections/integrations/SEO";
import Analytics from "@/components/configs/sections/integrations/Analytics";
import InvoiceGateway from "@/components/configs/sections/integrations/InvoiceGateway";

export default {
  data() {
    return {};
  },
  components: { PaymentGateway, InvoiceGateway,Shippings,SEO ,Analytics},
  computed: {
    ...mapGetters(["settings", "setting","loggedUser"])
  },
  methods: {}
};
</script>

<style></style>
