const url = window.location.origin + "/storage/";

//TODO escolha uma imagem local e não url. Url abaixo é um placeholder
const defaultImg =
  "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";

export const masterHelper = {
  compare_arrays: function (a1, a2) {
    var a = [];
    var diff = [];
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var j = 0; j < a2.length; j++) {
      if (a[a2[j]]) {
        delete a[a2[j]];
      } else {
        a[a2[j]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  },
  removeHtml: function (s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  },
  isEmpty: function (obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  },
  formatOptionsFromBotInChat(text) {
    let txt = this.formatTostrong(text);
    if (txt.includes("|")) {
      txt = txt.replace("|", "<br>");
    }
    return txt;
  },
  formatTostrong(text) {
    // console.log("incoming", text);
    // var regex = /([^*]*(?:\*(?!\*)[^*]*)*)/g;
    // var replacement = "<strong>$1</strong>";
    // console.log("incoming", text.replace(regex, replacement));
    // return text.replace(regex, replacement);
    let newText = "";
    let count = 0;
    let makeStrong = /\*/g,
      match;
    while ((match = makeStrong.exec(text))) {
      if (match.index && match.index > -1) {
        count++;

        console.log(match.index);
        if (count % 2 != 0) {
          // text.replace(text[match.index], "<strong>");
          text =
            text.substring(0, match.index) +
            "<strong>" +
            text.substring(match.index + 1);
        } else {
          // text.replace(text[match.index], "</strong>");
          text =
            text.substring(0, match.index) +
            "</strong>" +
            text.substring(match.index + 1);
        }
        // console.log("find index 54", text[54]);
        // console.log("find index 80", text[80]);
      }
    }
    // console.log("text-----", text);
    return text;
  },
  findImg: (media, position, getUrl = false) => {
    if (!media.length)
      return "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";
    let backend = process.env.VUE_APP_BACK_BASE_URL + "storage/";
    let img = media.find(m => {
      return m.position === position;
    });
    // console.log("the media", media);
    if (getUrl) {
      if (img) {
        return backend + img.path + ".webp";
      } else {
        return "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";
      }
    } else if (!getUrl) {
      return img;
    }
  },
  butonsBlockOnRequest: () => {
    window.addEventListener("load", function (event) {
      //console.log("butonsBlockOnRequest");
      let elements = document.getElementsByClassName(".btn-request");
      // console.log("butonsBlockOnRequest : elements", elements);
      if (elements.length) {
        elements.forEach(el => {
          el.addEventListener("click", e => {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, 3000);
          });
        });
      }
    });
  },
  checkForExtentions: imgPath => {
    let removable = [".webp", ".png"];
    removable.forEach(ex => {
      if (imgPath.includes(ex)) {
        imgPath = imgPath.replaceAll(ex, "");
      }
    });
    return imgPath;
  },
  filterMedia: (data, type, position = "gallery") => {
    // console.log("filterMedia--------------------------------------", data);
    //console.log(`type: ${type} position: ${position}`);

    let url = process.env.VUE_APP_BACK_BASE_URL + "storage/";

    if (!data?.media || !data?.media?.length) {

      if (data?.thumbnail) {
        return url + masterHelper.checkForExtentions(data?.thumbnail) + ".png";
      }

      // console.log("no data media--------------------------------------");
      return defaultImg;
    }

    // console.log("baseUrl", url);
    if (data?.thumbnail) {
      //console.log("data.thumbnail", data.thumbnail);

      return url + masterHelper.checkForExtentions(data.thumbnail) + ".png";
    }
    if (position == "all") {
      // console.log("all images");
      let all = data?.media.filter(m => {
        return m.type === type;
      });
      // console.log("all images", all);

      return all.map(m => {
        m.path = masterHelper.checkForExtentions(m.path);
        return { path: m.path ? url + m.path + ".png" : "" };
      });
    }
    if (type == "video") {
      let vid = data.media.find(m => {
        return m.type === type;
      });
      return vid.path || "";
    }

    if (position == "gallery") {
      let media = data.media.filter(m => {
        return m.type === type && m.position === position;
      });
      let m = media.map(m => {
        m.path = masterHelper.checkForExtentions(m.path);
        return { path: url + m.path + ".png" };
      });
      // console.log("m", m);
      return m;
    }

    if (position == "header") {
      // console.log("header position");

      let found = data.media.find(
        m => m.position == position && m.type == type
      );

      //console.log("header position media", found);
      if (!found) return defaultImg;
      found.path = masterHelper.checkForExtentions(found.path);
      return url + found.path + ".png";
    }

    return defaultImg;
  },
  typeOfPayments: data => {
    let currentPayment = data?.card?.type || "";
    let name = "indefinido";

    if (currentPayment == "creditcard" || currentPayment == "credit") name = "Cartão de crédito";
    if (currentPayment == "debitcard" || currentPayment == "debit") name = "Cartão de debito";
    if (currentPayment == "boleto") name = "Boleto";

    return name;
  },
  getURL: (thumbnailURL = false) => {
    try {
      return process.env.VUE_APP_ENVIRONMENT === "dev"
        ?
        thumbnailURL ? process.env.VUE_APP_THUMB_BASE_URL : process.env.VUE_APP_BACK_BASE_URL
        :
        thumbnailURL ? process.env.VUE_APP_THUMB_URL : process.env.VUE_APP_BACK_URL
    } catch (error) {
      // do anything
    }
  },
  hasRole: (user, roles) => {
    return user.roles.some(role => roles.includes(role.name || ""))
  },
  assingObject(sourceRaw = null, target = null) {
    try {
      if (!sourceRaw || !target) throw new Exception("")

      const sourceParsed = JSON.parse(JSON.stringify(sourceRaw))

      // console.log('assingObject', sourceRaw)

      Object.entries(sourceParsed).forEach(objectTemplate => {

        if (Object.keys(objectTemplate[1] || {}).length > 0) {

          target[objectTemplate[0]] = {
            ...sourceParsed[objectTemplate[0]], // continuing old properteis

            ...objectTemplate[1] // added news properties
          }

        } else {
          target[objectTemplate[0]] = objectTemplate[1] // added news values like string, int, boolean etc...
        }

      })

      return target
    } catch (error) {
      // do anything
    }
  },
  getBytes(bytes, decimals = 2, sizeCompareRaw) {
    if (bytes === 0) return;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const iTarget = Math.floor(Math.log(bytes) / Math.log(k));
    const iComparare = Math.floor(Math.log(bytes) / Math.log(k));

    const sizeTarget = parseFloat((bytes / Math.pow(k, iTarget)).toFixed(dm));
    const sizeCompare = parseFloat((sizeCompareRaw / Math.pow(k, iComparare)).toFixed(dm));

    // console.log('Size Target', sizeTarget, sizes[iTarget])
    // console.log('Size To Compare', sizeCompare, sizes[iComparare])

    if (sizes[iTarget] === "MB" && sizes[iComparare] === "MB") {
      if (sizeTarget > sizeCompare) {
        return false
      }

      return true
    } else if (sizes[iTarget] === "KB" && sizes[iComparare] === "KB") {
      if (sizeTarget > sizeCompare) {
        return false
      }

      return true
    }

    return false
  },
  handlerInputModel: (from = "", target = "", event = null, type = "text", targetStatement = {}, callback = () => ({})) => {
    try {
      if (from.trim() === "" || target.trim() === "" || !event) return

      if (!targetStatement || (!targetStatement[from] || !targetStatement[from][target])) {
        targetStatement = {
          ...targetStatement,
          [from]: {
            ...targetStatement[from],
            [target]: ""
          }
        }
      }
      // return


      if (type === "radio" || type === "checkbox") {
        if (event.target.checked) {
          targetStatement[from][target] = event.target?.value
        } else {
          targetStatement[from][target] = false
        }
      } else {
        targetStatement[from][target] = event.target?.value.trim()
      }

      console.log('handlerInputModel', target, targetStatement[from][target], event.target?.value)

      if (callback) callback(targetStatement)
    } catch (error) {
      // do anything
    }
  }
};
