<template>
  <div id="app">
    <transition name="slide-in" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

window.$ = require("jquery");
window.JQuery = require("jquery");

// Mixins
import Auth from "./mixins/Auth.vue"

export default {
  mixins: [Auth],
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    loggedUser: {
      deep: true,
      immediate: true,
      handler(loggedUserRaw) {
        try {
          const timeOfSession = parseInt(sessionStorage.getItem("TIME_OF_SESSION") || -1)
          const dayOfSession = new Date(timeOfSession).getDay()
          const monthOfSession = new Date(timeOfSession).getMonth()

          console.log('Time of session', (new Date().getMinutes() - new Date(timeOfSession).getMinutes()))

          if (timeOfSession > 1 && dayOfSession === new Date().getDay() && monthOfSession === new Date().getMonth()) {
            if ((new Date().getMinutes() - new Date(timeOfSession).getMinutes()) > 15) {

              setTimeout(() => {
                if (!this.$masterHelper.isEmpty(loggedUserRaw)) {
                  if (!this.CHECK_USER()) {

                    this.$toast.info("Sua sessão expirou! Faça o login novamente", "", {
                      icon: "icon-person",
                      position: "topRight",
                      timeout: 2000
                    })

                    setTimeout(() => {
                      //this.$router.push('/login')
                    }, 2500)
                  }
                }
              }, 2000)
            }
          }

          if (this.$masterHelper.isEmpty(loggedUserRaw)) {
            this.LOGIN_RECOVERY().then(resolve => {
              //if(resolve.status === 200) this.$router.push('/')
            }).catch(reject => {
              if (reject?.status === 401) {
                this.$toast.info("Sua sessão expirou! Faça o login novamente", "", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 2000
                })
              }
            })
          }

        } catch (error) {
          this.$toast.info("Sua sessão expirou! Faça o login novamente", "", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          })
        }
      }
    }
  },
  mounted() {
    document.title = process.env.VUE_APP_PROJECT;

    setTimeout(() => {
      window.removeEventListener("click", (event) => {
        event.preventDefault()
        return
      })
    }, 1500)
  },
  methods: {
    ...mapActions([
      "CHECK_USER",
      'LOGIN_RECOVERY'
    ])
  }
};
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background-color: rgb(233, 233, 233) !important;
}

.pointer {
  cursor: pointer !important;
}

.pointer-border {
  cursor: pointer !important;

  &:hover {
    border-bottom: 1px solid #5f76e8;
  }
}

.iziToast.iziToast-color-red {
  background-color: #ff4f70 !important;
}

.border-danger {
  border: 1px solid red !important;
}

.input-disabled {
  border: none !important;
  pointer-events: none !important;
  background-color: #f5f5f5 !important;

  &::placeholder {
    color: #e0e0e0 !important;
  }
}

.seller-comission-alert {
  margin-bottom: 1rem;

  font-size: 0.83rem;
  color: #615d5d;
}
</style>
