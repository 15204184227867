<template>
    <div>
        <div class="card">
            <LoadingScreen
                eventName="loading_products"
                dir="column"
                bgColor="#fff"
                message="Pesquisando..."
            />
            <div class="card-body">
                <div
                    id="action_container"
                    v-if="selectedItems.length"
                    class="
                        w-100
                        d-flex
                        align-items-center
                        justify-content-end
                        p-2
                    "
                >
                    <div class="d-flex">
                        <LoaderButton
                            b_class="btn-warning ml-3 slide-in-blurred-top"
                            :wasClicked="isGoingOff"
                        >
                            <button
                                v-if="!isGoingOn"
                                :disabled="isGoingOn || isDeleting"
                                class="btn btn-warning"
                                v-show="
                                    selectedItems.filter(
                                        (i) => i.active == 1 || i.active == 2
                                    ).length
                                "
                                @click="unableProducts('isGoingOff')"
                            >
                                Desativar
                            </button>
                        </LoaderButton>

                        <LoaderButton
                            b_class="btn-warning ml-3 slide-in-blurred-top"
                            :wasClicked="isGoingOn"
                        >
                            <button
                                v-if="!isGoingOff"
                                :disabled="isDeleting || isGoingOff"
                                class="btn btn-warning"
                                v-show="
                                    selectedItems.filter(
                                        (i) => i.active == 0 || i.active == 2
                                    ).length
                                "
                                @click="unableProducts('isGoingOn')"
                            >
                                Ativar
                            </button>
                        </LoaderButton>

                        <LoaderButton
                            b_class="btn-danger ml-3 slide-in-blurred-top"
                            :wasClicked="isDeleting"
                        >
                            <button
                                :disabled="isGoingOn || isGoingOff"
                                @click="unableProducts('isDeleting')"
                                class="btn btn-danger"
                                v-if="selectedItems.length"
                            >
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </LoaderButton>
                    </div>
                </div>
                <table class="table table-inverse admin-list">
                    <thead class="thead-inverse">
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        v-model="selectAll"
                                        @click="selectAllItems()"
                                    />
                                </label>
                            </th>
                            <th>Produto</th>
                            <th>Nome</th>
                            <th class="text-center">Valores</th>
                            <th class="text-center">Qtde.</th>

                            <th class="text-center">Status</th>
                            <th class="text-center d-none d-xxl-block">Origem</th>

                            <th class="text-center d-none d-xxl-block">Criado em</th>
                        </tr>
                    </thead>
                    <tbody v-show="productsList && productsList.length > 0">
                        <tr
                            class="pointer hover slide-in-blurred-top"
                            v-for="(product, index) in productsList"
                            :key="index"
                            :class="[{ selectedRow: isSelected(product.uuid) }]"
                            @dblclick="viewProduct(product)"
                        >
                            <th>
                                <label>
                                    <input
                                        @click="selecteItem(product)"
                                        type="checkbox"
                                        :checked="selectedItem(product)"
                                    />
                                </label>
                            </th>
                            <td @click="selectProduct(product)">
                               <img loading="lazy"
                                    width="150"
                                    height="100"
                                    :src="`${$masterHelper.getURL(true)}${product.thumbnail}.png`"
                                    alt=""
                                    class="img-fluid prod-list-img-item"
                                />
                            </td>

                            <td @click="selectProduct(product)">
                                <div>
                                    {{ product.name }}
                                </div>
                                <br />
                                <div
                                    :style="{
                                        width: '70%',
                                    }"
                                ></div>
                            </td>
                            <td
                                @click="selectProduct(product)"
                                class="text-center"
                                style="width: 20%"
                            >
                                <div class="w-100">
                                    R$ {{
                                        product.price
                                            ? $format($money(product.price))
                                            : "0,00"
                                    }}
                                </div>
                            </td>
                            <td
                                @click="selectProduct(product)"
                                class="text-center"
                            >
                                {{ product.quantity ? product.quantity : 0 }} un
                            </td>

                            <td
                                @click="selectProduct(product)"
                                class="text-center"
                            >
                                <i
                                    class="fas fa-circle text-success"
                                    v-show="product.active"
                                    title="Ativado"
                                ></i>
                                <i
                                    class="far fa-dot-circle text-danger"
                                    v-show="!product.active"
                                    title="Desativado"
                                ></i>
                            </td>
                            <td class="d-none d-xxl-block">
                                <div v-if="product.origin">
                                  {{ product.origin.name }}
                                  <img loading="lazy"
                                    :src="product.origin.logo"
                                    width="50"
                                    :title="product.origin.name"
                                    v-if="product.origin.logo && product.origin.logo.trim() !== ''"
                                  />
                                </div>
                                <div v-else>
                                    {{ "-" }}
                                </div>
                            </td>
                            <td class="d-xxl-block d-none ">
                                {{ $date(product.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <Pagination
                    v-model="productsPaginate"
                    :eventName="'products_loaded'"
                    :currentPageName="'products'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LoadingScreen from "@/components/navigation/LoadingScreen";

import LoaderButton from "@/components/buttons/LoaderButton";

import Pagination from "@/components/navigation/Paginator";

import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            rowStart: null,
            rowEnd: null,
            perPage: 10,
            currentPage: 1,
            loading: false,
            selectAll: false,
            selectedItems: [],
            userSelected: [],
            UPDATED: 0,
            isGoingOff: false,
            isGoingOn: false,
            isDeleting: false,
        };
    },
    props: {
        list: {
            type: Array,
        },
    },
    components: {
        LoadingScreen,
        LoaderButton,
        Pagination,
    },
    mounted() {
      if (!this.products?.length) {
        this.LOAD_PRODUCTS(() => {
          this.$event.$emit("loading_products", false);
        });
      }

      this.$event.$on("product_updated", () => {
          this.UPDATED++;
      });

      this.$event.$on("edit_product", () => {
          this.selectedItems = [];
          this.selectAll = false;
      });
    },
    computed: {
        ...mapGetters(["products", "product", "productsPaginate"]),
        productsList() {
            this.UPDATED;
            this.list.forEach((item, index) => {
                if (item.product_variations && item.product_variations.length) {
                    let variation = item.product_variations[0];
                    this.list[index].price = variation.price;
                    this.list[index].cost_price = variation.cost_price;
                    this.list[index].quantity = variation.quantity;
                    this.list[index].retail_price = variation.retail_price;
                    this.list[index].sale_price = variation.sale_price;
                    this.list[index].wholesale_quantity =
                        variation.wholesale_quantity;
                }
            });
            return this.list;
        },
    },
    methods: {
        ...mapActions([
            "LOAD_PRODUCTS",
            "VIEW_PRODUCT",
            "BULK_UPDATE_PRODUCTS",
            "UPDATE_PRODUCT",
            "BULK_DELETE_PRODUCTS",
        ]),

        viewProduct(prod = null) {
          try {
            if(!prod) return

           // console.log("------------ viewProduct ----------", prod);
            this.VIEW_PRODUCT(prod);

            let p = prod;

            p['video'] = { path: "" };

            this.$router.push({
                name: "product.details",
                params: { uuid: p?.uuid, product: p },
            });
          } catch (error) {
            // do anything
          }
        },

        _filterMedia(data, type) {
          try {
            let medias =
                data.media.filter((media) => media.type === type) || [];
            if (medias.length) {
                let maped = medias.map((m) => m.path);
                return this.$api + maped[0];
            } else {
                return "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";
            }
          } catch (error) {
            // do anything
          }
        },

        isSelected(uuid = null) {
          try {
            if(!uuid) return false

            return this.product.uuid === uuid;
          } catch (error) {
            // do anything
          }
        },

        selectAllItems() {
          try {
            if (!this.selectAll) {
                this.selectedItems = [...this.products];
            } else if (this.selectAll) {
                this.selectedItems = [];
            }
          } catch (error) {
            // do anything
          }
        },

        selecteItem(product = null) {
          try {
            if(!product) return null

            let isSelected = this.selectedItems.findIndex((item) => {
                return item.uuid === product.uuid;
            });
            if (isSelected < 0) {
                this.selectedItems.push(product);
            } else {
                this.selectedItems.splice(isSelected, 1);
            }
          } catch (error) {
            // do anything
          }
        },

        selectedItem(product = null) {
          try {
            if(!product) return false
            return this.selectedItems.some((item) => {
                return item.uuid === product.uuid;
            });
          } catch (error) {
            // do anything
          }
        },

        selectProduct(product = null) {
          try {
            if(!product) return
            this.$event.$emit("product_selected");
            this.VIEW_PRODUCT(product);
          } catch (error) {
            // do anything
          }
        },

        reset() {
          try {
            this.userSelected = [];
            this.selectedItems = [];
          } catch (error) {
            // do anything
          }
        },

        async unableProducts(action) {
          try {
            this[action] = true;

            switch (action) {
                case "isDeleting":
                    await this.deleteSelectedProducts();
                    break;
                case "isGoingOn":
                    this.toggleVisability(action, 1);
                    break;
                case "isGoingOff":
                    this.toggleVisability(action, 0);
                    break;
                default:
                    break;
            }
          } catch (error) {
            // do anything
          }
        },

        toggleVisability(action, status) {
          try {
            if (this.selectedItems.length) {
                this.selectedItems.forEach((item, index) => {
                    this.selectedItems[index].active = status;
                });

                this.$toast.info(" ", `Atualizando`, {
                    icon: "icon-person",
                    position: "topRight",
                    timeout: 2000,
                });

                this.BULK_UPDATE_PRODUCTS(this.selectedItems).then(() => {
                    this.$toast.success(" ", `Atualizado`, {
                        icon: "icon-person",
                        position: "topRight",
                        timeout: 2000,
                    });

                    this[action] = false;
                    this.reset();
                });
            }
          } catch (error) {
            // do anything
          }
        },

        deleteSelectedProducts() {
          try {
            this.$toast.info(" ", `Deletando`, {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.BULK_DELETE_PRODUCTS(this.selectedItems).then(() => {
              this.$toast.success(" ", `Deletado!`, {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 2000,
              });

              this.isDeleting = false;
              this.reset();
            });
          } catch (error) {
            // do anything
          }
        },
    },
};
</script>

<style lang="scss">
.products {
    .__item {
        display: flex;
        align-items: center;
        .__info {
            width: 100%;
            display: flex;
            /* align-items: center; */
            justify-content: space-between;
        }
        .__basic {
            margin-left: 1.5em;
            .__description {
                width: 80%;
                margin-top: 1.5em;
            }
            .__informations {
                /* width: 20%; */
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
.admin-list {
    .prod-list-img-item {
      max-height: 100px;
      max-width: 100px;
      width: auto;
    }
}

</style>
