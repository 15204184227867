import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    orderReport: {},
    financialReport: {},
    productReport: {},
    sellerReport: {},
    clientReport: {}
  },
  getters: {
    orderReport: state => state.orderReport,
    financialReport: state => state.financialReport,
    productReport: state => state.productReport,
    sellerReport: state => state.sellerReport,
    clientReport: state => state.clientReport
  },
  mutations: {
    ORDER_REPORT(state, data) {
      state.orderReport = data?.report;
    },
    FINANCIAL_REPORT(state, data) {
      state.financialReport = data?.report;
    },
    PRODUCT_REPORT(state, data) {
      state.productReport = data?.report;
    },
    SELLER_REPORT(state, data) {
      state.sellerReport = data?.report;
    },
    CLIENT_REPORT(state, data) {
      state.clientReport = data?.report;
    }
  },
  actions: {
    ORDER_REPORT: async ({ commit }, payload = false) => {
      try {
        let response = await axios().post("/reports/order-report", payload);

        commit("ORDER_REPORT", response.data);
        return response;
      } catch (error) {
       // console.log("ORDER_REPORT :: error ===", error);

        return error;
      }
    },
    FINANCIAL_REPORT: async ({ commit }, payload = false) => {
      try {
        let response = await axios().post("/reports/financial-report", payload);
        commit("FINANCIAL_REPORT", response.data);

        return response;
      } catch (error) {
       // console.log("FINANCIAL_REPORT :: error ===", error);

        return error;
      }
    },
    PRODUCT_REPORT: async ({ commit }, payload = false) => {
      try {
        let response = await axios().post("/reports/product-report", payload);
        commit("PRODUCT_REPORT", response.data);

        return response;
      } catch (error) {
       // console.log("PRODUCT_REPORT :: error ===", error);

        return error;
      }
    },
    SELLER_REPORT: async ({ commit }, payload = false) => {
      try {
        let response = await axios().post("/reports/seller-report", payload);
        commit("SELLER_REPORT", response.data);

        return response;
      } catch (error) {
      //  console.log("SELLER_REPORT :: error ===", error);

        return error;
      }
    },
    CLIENT_REPORT: async ({ commit }, payload = false) => {
      try {
        let response = await axios().post("/reports/client-report", payload);
       // console.log("jhony", response);
        commit("CLIENT_REPORT", response.data);

        return response;
      } catch (error) {
       // console.log("CLIENT_REPORT :: error ===", error);

        return error;
      }
    }
  }
};
