<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-baseline justify-content-between">
        <h4>
          <strong>Dados da empresa</strong>
        </h4>
        <button class="btn btn-primary" @click="save()" :disabled="isSaving">
          {{ isSaving ? 'Por Favor, aguarde!' : 'Salvar' }}
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label for="">Nome</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="Nome"
              v-model.lazy="store_company.nome"
            />
          </div>

          <div class="form-group">
            <label for="">Fantasia</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="Fantasia"
              v-model.lazy="store_company.fantasia"
            />
          </div>

          <div class="form-group">
            <label for="">Endereço</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="Endereço"
              v-model.lazy="store_company.endereco"
            />
          </div>

          <div class="form-group">
            <label for="">Logo</label>
            <div
              class="
                d-flex
                w-100
                h-100
                flex-column
                align-items-center
                justify-content-center
                gap-3
              "
            >
              <div
                style="max-width: 500px; max-height: 400px"
                v-show="store_company.logo.background.image && store_company.logo.background.image.trim() !== '' && hasUpload === false"
              >
                <div class="d-flex flex-column
                align-items-center
                justify-content-center">
                  <small class="ml-2">Logo atual</small>
                 <img loading="lazy"
                    :src="`${$masterHelper.getURL(true)}${store_company.logo.background.image}.png`"
                    width="300"
                    height="150"
                  />
                </div>
              </div>

              <div style="max-width: 500px; max-height: 400px">
                <div>
                  <Upload
                    position="header"
                    ref="up1"
                    :noFavorite="true"
                    :height="250"
                    btnText="Adicionar nova logo"
                    @data_deleted="(data) => handlerDeleteLogo(data)"
                    @added_item="(data) => handlerLogo(data)"
                    :limit="1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="">E-mail</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="E-mail"
              v-model.lazy="store_company.email"
            />
          </div>

          <div class="form-group">
            <label for="">CNPJ</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="CNPJ"
              v-model.lazy="store_company.cnpj"
            />
          </div>

          <div class="form-group">
            <label for="">Razão Social</label>
            <input
              :disabled="isSaving"
              type="string"
              class="form-control"
              placeholder="Razão Social"
              v-model.lazy="store_company.razao_social"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VMoney } from "v-money";

// Components
import Upload from "@/components/imageUpload/Upload";

// Mixins
import Settings from "@/mixins/Settings.vue";

export default {
  directives: { money: VMoney },
  components: { Upload },
  mixins: [Settings],
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isEditing: false,
      store_company: {
        nome: "",
        fantasia: "",
        endereco: "",
        logo: {
          background: {
            image: ""
          }
        },
        email: "",
        cnpj: "",
        razao_social: "",
      },
      store_company_temp: {
        nome: "",
        fantasia: "",
        endereco: "",
        logo: {
          background: {
            image: ""
          }
        },
        email: "",
        cnpj: "",
        razao_social: "",
      },
      hasUpload: false,
      formatString: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 1,
        masked: false,
      },
    };
  },
  watch: {
    myGateways: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          this.store_company_temp.logo?.background.image?.trim() === "" &&
          val.content.logo?.background.image?.trim() !== ""
        )
          this.store_company_temp.logo.background.image = val.content.logo?.background?.image || "";
      },
    },
  },
  computed: {
    ...mapGetters(["settings"]),

    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "company";
        }) || false
      );
    },
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      this.store_company = this.myGateways.content;
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS"]),
    save() {
      let data = this.store_company;
      // if (this.store_company.comission) {
      //   data.comission = parseFloat(this.store_company.comission);
      // }
      this.isSaving = true;
      this.$pace.restart();

      // TODO - THIS NEWS VERSION (V2) CREATE THE SETTINGS ALSO CAN UPDATE THE SETTINGS
      this.CREATE_SETTINGS({
        content: data,
        key: 'company',
      }).then((res) => {
        this.$pace.restart();
        this.UPDATE++;

        if (res.code === 201) {
          this.$toast.success(" ", "Concluido", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });

          const template = res.data?.content
          //console.log( template?.logo?.background?.image || "" )

          this.$event.$emit("template-company", template?.logo?.background?.image || "");

          this.store_company.logo.background.image = template?.logo?.background?.image || ""
          this.loadSet();
        } else {
          this.$toast.error(" ", "Houve uma falha.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }

        this.isSaving = false;
      });


    },
    handlerLogo(data = null) {
      try {
        if (!data) {
          this.store_company.logo = "";
          this.hasUpload = false;
          return;
        }

        if (data?.src) {
          this.store_company.logo.background.image = data?.src || "";
          this.hasUpload = true;
        }

        return;
      } catch (error) {
        // do anything
      }
    },
    handlerDeleteLogo(data = null) {
      try {
        this.store_company.logo = "";
        this.hasUpload = false;
        return;
      } catch (error) {
        // do anything
      }
    },
  },
};
</script>

<style></style>
