<template>
  <div class="card" v-if="(orders && orders.data && orders.data.length > 0) || (orders && orders.length > 0)">

    <div class="card-header d-flex">
      <h3 class="text-dark mt-2">{{ listTitle }}</h3>
      <hr />
    </div>
    <div class="card-body">

      <table class="table"
        v-if="(users && users.length) || (usersList && usersList.length) || (sellers && sellers.length > 0)">
        <thead>
          <tr>
            <th>Cod</th>
            <th v-role="['admin', 'dev']">Vendedor</th>

            <th>Cliente</th>
            <th>Valor</th>
            <th>Data</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="pointer" v-for="order in orders.data || orders" :key="order.uuid"
            @dblclick="openOrder(order, true)" @click.prevent="openOrder(order)">
            <td scope="row">{{ order.id }}</td>

            <td v-role="['admin', 'dev']">
              {{ order.seller_id ? getSeller(order.seller_id).name : "-" }}
            </td>

            <td>
              {{ order.buyer_id && order.user ? order.user.name : getUser(order.buyer_id).name }}
            </td>

            <td>R$ {{ $format($money(order.price)) }}</td>

            <td>{{ $dateTime(order.created_at) }}</td>

            <td>
              <StatusBadge :status="order.status" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <Pagination :value="ordersPaginate" eventName="orders_loaded" :currentPageName="currentPage" />
    </div>
  </div>
  <div class="col-9 mt-5" v-else>
    <h3>Nenhum pedido disponível</h3>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatusBadge from "@/components/status/Index";
import Pagination from "@/components/navigation/Paginator";

export default {
  data() {
    return {
      loadingIDs: [],
    };
  },
  props: {
    orders: {
      required: true,
    },
    changePage: {
      required: false,
      default: null,
    },
    listTitle: {
      required: false,
      default: "Pedidos",
    },
  },
  components: {
    StatusBadge,
    Pagination,
  },
  mounted() {
    if (this.users && !this.users.length) {
      this.$event.$emit("page_loaded", { isLoading: true });
      this.LOAD_USERS().then(() => {
        this.$event.$emit("page_loaded", { isLoading: false });
      });
      this.QUICK_LIST();
      this.LOAD_STATUS();
    }

    this.LOAD_USERS_SELLERS();
    this.LOAD_PAGINATOR_SELLER_ORDERS(this.user?.seller?.id);
  },
  computed: {
    ...mapGetters([
      "users",
      "user",
      "usersList",
      "ordersPaginate",
      "last_search",
      "sellers",
      "seller_orders",
    ]),
    orderList() {
      return this.orders;
    },
    currentPage() {
      if (this.changePage) return this.changePage;
      return "orders";
    },
  },
  methods: {
    ...mapActions([
      "SELECT_ORDER",
      "LOAD_USERS",
      "FETCH_USER",
      "QUICK_LIST",
      "LOAD_STATUS",
      "LOAD_USERS_SELLERS",
      "LOAD_PAGINATOR_SELLER_ORDERS",
    ]),
    loadOrders() {
      // console.log("loading");
    },
    getUser(id) {
      const usersRaw = this.users?.length > 0 ? this.users : this.usersList
      let user = usersRaw?.find((user) => user.id === id);
      // console.log("getUser-------------", id);
      if (!user) {
        if (!this.isLoadingUser(id)) {
          this.loadingIDs.push(id);
          this.FETCH_USER(id).then((r) => {
            if (r?.status === 200) {
              return r.data;
            }
          });
        }

        return { name: "Carregando ..." };
      }
      return user;
    },
    getSeller(id) {
      try {
        let hasSeller =
          this.sellers?.find((seller) => {
            return seller?.id === id;
          })?.user || { name: "-" };


        if (hasSeller) return hasSeller;
      } catch (error) {
        // do anything
      }
    },
    async openOrder(order, modal = false) {
      try {
        await this.SELECT_ORDER(order);
        this.$forceUpdate();
        if (modal) {
          setTimeout(() => {
            $("#OrderModal").modal("toggle");
          }, 500);
        }
      } catch (error) {
        // console.log("openOrder::error", error);
      }
    },
    isLoadingUser(id) {
      return this.loadingIDs.some((loadingID) => loadingID == id);
    },
  },
};
</script>

<style lang="scss">
.table>tbody>tr>td {
  vertical-align: middle;
}
</style>
