<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div
                  class="d-flex align-items-baseline justify-content-between"
                >
                  <h4>
                    <strong> Painel de Administração</strong>
                  </h4>
                  <button
                      class="btn btn-primary"
                      @click="save('template', admin_template)"
                      :disabled="isSaving"
                  >
                      {{ isSaving ? "Por Favor, aguarde!" : "Salvar" }}
                  </button>
                </div>
                <div class="row mt-5">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="">Cor dos ícones</label>
                            <input
                                type="color"
                                class="form-control"
                                name=""
                                id=""
                                v-model.lazy="admin_template.icons_color"
                            />
                        </div>

                        <div class="form-group">
                            <label for="">Cor dos Botões</label>
                            <input
                                type="color"
                                class="form-control"
                                name=""
                                id=""
                                v-model.lazy="admin_template.buttons_color"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div
              class="d-flex align-items-baseline justify-content-between"
            >
              <h4>
                <strong>Login</strong>
              </h4>
              <button
                class="btn btn-primary"
                @click="save('admin_template', admin_template)"
                :disabled="isSaving"
              >
                {{ isSaving ? "Por Favor, aguarde!" : "Salvar" }}
              </button>
            </div>
            <div class="row" v-if="admin_template && admin_template.general">
              <div
                class="
                  d-flex
                  flex-column
                  p-2
                  flex-fill flex-glow-1
                  mt-5
                  border-left border-light
                "
              >
                <div class="w-100 d-flex align-items-center">
                  <h3 class="my-auto pr-4">Gerais</h3>
                  <hr class="w-100" />
                </div>
                <div
                  class="d-flex flex-row flex-wrap mt-1 flex-fill"
                  style="gap: 0.5rem"
                >
                  <div class="form-group">
                    <label for="">
                      <strong>Cor de Tíitulos</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model="admin_template.general.text.colorTitle"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Cor de descrição</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.text.colorDescription"
                    />
                  </div>

                  <div class="form-group">
                    <label for=""><strong>Cor de Subtítulo</strong></label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.text.colorSubTitle"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Cor de texto do botão</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.button.text"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Cor de fundo botão</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.button.background"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Cor de fundo Primária</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.background.color"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Cor de fundo Secundária</strong>
                    </label>
                    <input
                      type="color"
                      class="form-control"
                      name=""
                      id=""
                      v-model.lazy="admin_template.general.background.color2"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">
                      <strong>Imagem de Logo </strong>
                    </label>
                   <img loading="lazy"
                      v-if="
                        admin_template.general.background.image
                        &&
                        admin_template.general.background.image.trim() !== ''
                        &&
                        admin_template.general.background.image.length <= 75
                      "
                      :src="`${$masterHelper.getURL(true)}${admin_template.general.background.image || ''}.png`"
                      alt="Image da logo da loja"
                    />
                    <Upload
                      position="header"
                      ref="up1"
                      :noFavorite="true"
                      :height="250"
                      btnText="Adicionar nova image de fundo"
                      @data_deleted="
                        (data) =>
                          handlerDeleteImage(
                            'admin_template',
                            'background',
                            'image',
                            data,
                            '',
                            'admin_template'
                          )
                      "
                      @added_item="
                        (data) =>
                          handlerImage(
                            'general',
                            'background',
                            'image',
                            data,
                            '',
                            'admin_template'
                          )
                      "
                      :limit="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
            <div class="card-body">

                <div class="d-flex align-items-baseline justify-content-between">
                  <h4>
                    <strong>Aparência de Loja</strong>
                  </h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="save('shopping')"
                    :disabled="isSaving"
                  >
                    {{ isSaving ? "Por Favor, aguarde!" : "Salvar" }}
                  </button>
                </div>
                <nav id="guide-main-wrapper">
                  <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-general')">
                    Gerais
                    <!-- <ul class="guide-ul">
                      <li>
                        Sub Item 1
                      </li>
                    </ul> -->
                  </li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-header')">Cabeçalho</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-menu')">Menu</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-general-information')">Informações Gerais</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-contact-form')">Formúlario de Contato</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-footer')">Rodapé</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-about')">Página Sobre</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-location')">Informações de localização</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-window-shopping')">Vitrine</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-newsletter')">Newsletter</li>
                   <li class="guide" title="Clique aqui para Abrir/Fechar a aba" @click.prevent="setTemplateGuideTarget('template-highlighted')">Highlighted</li>
                </nav>

                <!-- Gerais -->
                <div class="row guide-target-hidden" v-if="store_template.general" id="template-general">
                  <div
                      class="
                          d-flex
                          flex-column
                          p-2
                          flex-fill flex-glow-1
                          mt-5
                          border-left border-light
                      "
                  >
                      <div class="w-100 d-flex align-items-center">
                          <h3 class="my-auto pr-4">Gerais</h3>
                          <hr class="w-100" />
                      </div>
                      <div
                          class="d-flex flex-row flex-wrap mt-1 flex-fill"
                          style="gap: 0.5rem"
                      >
                          <div class="form-group">
                              <label for=""
                                  ><strong>Cor de Tíitulos</strong></label
                              >
                              <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model="
                                      store_template.general.text.colorTitle
                                  "
                              />
                          </div>

                          <div class="form-group">
                              <label for=""
                                  ><strong>Cor de descrição</strong></label
                              >
                              <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="
                                      store_template.general.text
                                          .colorDescription
                                  "
                              />
                          </div>

                          <div class="form-group">
                              <label for=""
                                  ><strong>Cor de Subtítulo</strong></label
                              >
                              <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="
                                      store_template.general.text
                                          .colorSubTitle
                                  "
                              />
                          </div>

                          <div class="form-group">
                              <label for=""
                                  ><strong
                                      >Cor de texto do botão</strong
                                  ></label
                              >
                              <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="
                                      store_template.general.button.text
                                  "
                              />
                          </div>

                          <div class="form-group">
                              <label for=""
                                  ><strong>Cor de fundo botão</strong></label
                              >
                              <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="
                                      store_template.general.button.background
                                  "
                              />
                          </div>

                          <!-- <div class="form-group">
              <label for=""><strong>Tipo de fonte</strong></label>

              <input type="color"
              class="form-control" name="" id=""
                v-model.lazy="store_template.general.text.fontType"
                placeholder="Selecione uma fonte"
                label="text"
                track-by="text"
                :options="fontTypeList"
                :show-labels="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="d-flex align-items-center">
                    <span class="option__desc ml-3">
                      <span
                        class="option__title"
                        :style="'font-family:' + props.option.family || ''"
                      >
                        {{ props.option.name || "" }}
                      </span>
                    </span>
                  </div>
                </template>

            </div> -->

                          <div class="form-group">
                              <label for=""
                                  ><strong>Imagem de Logo </strong></label
                              >
                              <img loading="lazy"
                                  v-if="
                                      store_template.general.background
                                          .image &&
                                      store_template.general.background.image.trim() !==
                                          '' &&
                                      store_template.general.background.image
                                          .length <= 75
                                  "
                                  :src="`${$masterHelper.getURL(true)}${
                                      store_template.general.background
                                          .image || ''
                                  }.png`"
                                  alt="Image da logo da loja"
                              />
                              <Upload
                                  position="header"
                                  ref="up1"
                                  :noFavorite="true"
                                  :height="250"
                                  btnText="Adicionar nova logo"
                                  @data_deleted="
                                      (data) =>
                                          handlerDeleteImage(
                                              'general',
                                              'background',
                                              'image',
                                              data
                                          )
                                  "
                                  @added_item="
                                      (data) =>
                                          handlerImage(
                                              'general',
                                              'background',
                                              'image',
                                              data
                                          )
                                  "
                                  :limit="1"
                              />
                          </div>
                      </div>
                  </div>
                </div>

                <!-- Cabeçalho -->
                <div class="row guide-target-hidden" id="template-header">
                  <div
                      class="
                          d-flex
                          flex-row
                          align-items-start
                          justify-content-between
                          gap-1
                          flex-wrap
                      "
                      style="gap: 1rem"
                  >
                      <div
                          class="
                              d-flex
                              flex-column
                              p-2
                              flex-fill flex-glow-1
                              mt-5
                              border-left border-light
                          "
                      >
                          <div class="w-100 d-flex align-items-center">
                              <h3 class="my-auto pr-4">Cabeçalho</h3>
                              <hr class="w-100" />
                          </div>
                          <div
                              class="d-flex flex-row flex-wrap mt-1 flex-fill"
                              style="gap: 0.5rem"
                          >
                              <div class="form-group">
                                  <label for=""
                                      ><strong>Cor de fundo</strong></label
                                  >
                                  <input
                                      type="color"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                          store_template.header.background
                                              .color
                                      "
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                </div>

                <!-- Menu -->
                <div class="row guide-target-hidden" id="template-menu">
                    <div
                        class="
                            d-flex
                            flex-column
                            p-2
                            flex-fill flex-glow-1
                            mt-5
                            border-left border-light
                        "
                        style="gap: 1rem"
                    >
                        <h3>Menu</h3>
                        <div
                            class="
                                d-flex
                                flex-row
                                flex-wrap
                                flex-align-start
                                flex-justify-start
                            "
                            style="gap: 1rem"
                        >
                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Item (1)</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Nome</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.primary
                                                    .name
                                            "
                                            placeholder="Digite o nome"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Link</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.primary
                                                    .link
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Item (2)</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Nome</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.secondary
                                                    .name
                                            "
                                            placeholder="Digite o nome"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Link</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.secondary
                                                    .link
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Item (3)</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Nome</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.thirdy
                                                    .name
                                            "
                                            placeholder="Digite o nome"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Link</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.thirdy
                                                    .link
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Item (4)</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Nome</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.fourthy
                                                    .name
                                            "
                                            placeholder="Digite o nome"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Link</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.fourthy
                                                    .link
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Item (5)</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Nome</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.fifth.name
                                            "
                                            placeholder="Digite o nome"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Link</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.header.fifth.link
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Informações gerais -->
                <div class="row guide-target-hidden" id="template-general-information">
                    <div
                        class="
                            d-flex
                            flex-column
                            p-2
                            flex-fill flex-glow-1
                            mt-5
                            border-left border-light
                        "
                        style="gap: 1rem"
                    >
                        <h3>Informações Gerais</h3>
                        <div
                            class="
                                d-flex
                                flex-row
                                flex-wrap
                                flex-align-start
                                flex-justify-start
                            "
                            style="gap: 1rem"
                        >
                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Contato</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>E-mail</strong></label
                                        >
                                        <input
                                            type="email"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.email
                                            "
                                            placeholder="Digite o e-mail"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Telefone</strong></label
                                        >
                                        <input
                                            type="tel"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.phone
                                            "
                                            placeholder="Digite o telefone"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column
                                    flex-align-start
                                    flex-justify-start
                                "
                                style="
                                    min-width: 49% !important;
                                    max-width: 100%;
                                "
                            >
                                <span>Rede Social</span>
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        flex-wrap
                                        flex-align-center
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 0.4rem"
                                >
                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Facebook</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.social
                                                    .facebook
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Twitter</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.social
                                                    .twitter
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Instagram</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.social
                                                    .instagram
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>WhatsApp</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.social
                                                    .whastapp
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>

                                    <div class="form-group d-flex flex-column">
                                        <label for=""
                                            ><strong>Youtube</strong></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.general.social
                                                    .youtube
                                            "
                                            placeholder="Digite o link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Formulário de contato -->
                <div class="row guide-target-hidden" id="template-contact-form">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                          class="
                            d-flex
                            flex-column
                            p-2
                            flex-fill flex-glow-1
                            mt-5
                            border-left border-light
                          "
                        >
                          <h3>Formulário de contato</h3>
                           <div
                            class="d-flex flex-column mt-4 flex-fill"
                            style="gap: 1.5rem"
                          >
                            Contato

                            <div
                              class="d-flex flex-row flex-wrap mt-1 flex-fill"
                              style="gap: 0.5rem; border-bottom: 0.1rem solid #d5d5d5"
                            >
                              <div class="form-group">
                                <label for="">
                                  <strong>Titulo</strong>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  id=""
                                  placeholder="Digite o titulo da página"
                                  v-model.lazy=" store_template.form.contact.title"
                                />
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>Descrição</strong>
                                </label>
                                <textarea
                                  class="form-control"
                                  name=""
                                  id=""
                                  placeholder="Digite a descrição da página"
                                  v-model.lazy=" store_template.form.description"
                                />
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>E-mail de remetente</strong>
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  name=""
                                  id=""
                                  placeholder="Digite o e-mail de remetente"
                                  v-model.lazy=" store_template.form.contact.email.from"
                                />
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>Assunto do e-mail</strong>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  id=""
                                  placeholder="Digite o e-mail de remetente"
                                  v-model.lazy=" store_template.form.contact.email.subject"
                                />
                              </div>
                            </div>

                            Cores

                            <div
                              class="d-flex flex-row flex-wrap mt-1 flex-fill"
                              style="gap: 0.5rem; border-bottom: 0.1rem solid #d5d5d5"
                            >
                              <div class="form-group">
                                <label for="">
                                  <strong>Cor de fundo</strong>
                                </label>
                                <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="store_template.form.background.color"
                                />
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>Cor de fundo Secundária</strong>
                                </label>
                                <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="store_template.form.background.color2"
                                />
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>Cor de fundo Terciária</strong>
                                </label>
                                <input
                                  type="color"
                                  class="form-control"
                                  name=""
                                  id=""
                                  v-model.lazy="store_template.form.contact.background.color"
                                />
                              </div>

                              <div class="form-group">
                                  <label for=""
                                      ><strong>Cor de texto</strong></label
                                  >
                                  <input
                                      type="color"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                          store_template.form.text.color
                                      "
                                  />
                              </div>

                              <div class="form-group">
                                  <label for=""
                                      ><strong
                                          >Cor de texto do botão</strong
                                      ></label
                                  >
                                  <input
                                      type="color"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                          store_template.form.button.text
                                      "
                                  />
                              </div>

                              <div class="form-group">
                                  <label for=""
                                      ><strong
                                          >Cor de fundo botão</strong
                                      ></label
                                  >
                                  <input
                                      type="color"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                          store_template.form.button
                                              .background
                                      "
                                  />
                              </div>
                            </div>

                            Imagens

                            <div
                              class="d-flex flex-row flex-wrap mt-1 flex-fill"
                              style="gap: 0.5rem; border-bottom: 0.1rem solid #d5d5d5"
                            >
                              <div class="form-group" >
                                <label for="">
                                  <strong>Imagem de fundo</strong> (Recomendado imagens de 1300x400)
                                </label>
                                  <img loading="lazy"
                                      v-show="
                                          store_template.form.background
                                              .image &&
                                          store_template.form.background.image.trim() !==
                                              '' &&
                                          store_template.form.background.image
                                              .length <= 75
                                      "
                                      :src="`${$masterHelper.getURL(true)}${
                                          store_template.form.background
                                              .image || ''
                                      }.png`"
                                      alt="Image da logo da loja"
                                  />
                                  <ImageCardUpload
                                    :iconType="`button`"
                                    :typeFile="`gallery`"
                                    @imageUploaded=" (data) => handlerImage('form', 'background', 'image', data )"
                                  />
                                  <!-- <Upload
                                      position="header"
                                      ref="up1"
                                      :noFavorite="true"
                                      :height="250"
                                      btnText="Adicionar nova imagem"
                                      @data_deleted="
                                          (data) =>
                                              handlerDeleteImage(
                                                  'form',
                                                  'background',
                                                  'image',
                                                  data
                                              )
                                      "
                                      @added_item="
                                          (data) =>
                                              handlerImage(
                                                  'form',
                                                  'background',
                                                  'image',
                                                  data
                                              )
                                      "
                                      :limit="1"
                                  /> -->
                              </div>

                              <div class="form-group">
                                <label for="">
                                  <strong>Imagem de logo do contato</strong>
                                </label>
                               <img loading="lazy"
                                  v-show="
                                    store_template.form.contact.background.image &&
                                    store_template.form.contact.background.image.trim() !== ''
                                    &&
                                    store_template.form.contact.background.image.length <= 75
                                  "
                                  :src="`${$masterHelper.getURL(true)}${store_template.form.contact.background.image || ''}.png`"
                                  alt="Image da logo da loja"
                                />
                                <Upload
                                    position="header"
                                    ref="up1"
                                    :noFavorite="true"
                                    :height="250"
                                    btnText="Adicionar nova imagem"
                                    @data_deleted="
                                      (data) =>
                                        handlerDeleteImage(
                                          'form',
                                          'background',
                                          'image',
                                          data,
                                          'contact'
                                        )
                                    "
                                    @added_item="
                                      (data) =>
                                        handlerImage(
                                          'form',
                                          'background',
                                          'image',
                                          data,
                                          'contact'
                                        )
                                    "
                                    :limit="1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

                <!-- Rodapé  -->
                <div class="row guide-target-hidden" id="template-footer">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <h3>Rodapé</h3>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 0.5rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.footer.background
                                                .color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.footer.text.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto 2</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.footer.text.color_two
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.footer.background
                                                .image &&
                                            store_template.footer.background.image.trim() !==
                                                '' &&
                                            store_template.footer.background
                                                .image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.footer.background
                                                .image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'footer',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'footer',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong
                                            >Imagem de Logo 2</strong
                                        ></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.footer.logo
                                                .background.image &&
                                            store_template.footer.logo.background.image.trim() !==
                                                '' &&
                                            store_template.footer.logo
                                                .background.image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.footer.logo
                                                .background.image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'footer',
                                                    'background',
                                                    'image',
                                                    data,
                                                    'logo'
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'footer',
                                                    'background',
                                                    'image',
                                                    data,
                                                    'logo'
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sobre  -->
                <div class="row guide-target-hidden" id="template-about">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <h3>Sobre</h3>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 0.5rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo 1</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.about.background
                                                .color
                                        "
                                        placeholder="Selecione uma cor de fundo"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo 2</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.about.background
                                                .color_two
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.about.text.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto 2</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.about.text.color_two
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.about.background
                                                .image &&
                                            store_template.about.background.image.trim() !==
                                                '' &&
                                            store_template.about.background
                                                .image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.about.background
                                                .image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'about',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'about',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div
                                  class="
                                      d-flex
                                      flex-column
                                      flex-align-start
                                      flex-justify-start
                                      p-1
                                  "
                                  style="min-width: 40%; max-width: 40%; gap: 1rem"
                                >
                                  <div class="form-group">
                                    <label for="">
                                      <strong>Titulo</strong>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                        store_template.about.company.title
                                      "
                                      placeholder="Digite um titulo"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label for="">
                                      <strong>Descrição da Empresa</strong>
                                    </label>
                                    <textarea
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                        store_template.about.company.text
                                      "
                                      placeholder="Digite a descrição da empresa"
                                    />
                                  </div>
                                </div>

                                 <div
                                  class="
                                      d-flex
                                      flex-column
                                      flex-align-start
                                      flex-justify-start
                                      p-1
                                  "
                                  style="min-width: 40%; max-width: 40%; gap: 1rem"
                                >
                                  <div class="form-group">
                                    <label for="">
                                      <strong>Titulo</strong>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                        store_template.about.team.title
                                      "
                                      placeholder="Digite um titulo"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label for="">
                                      <strong>Descrição da Equipe</strong>
                                    </label>
                                    <textarea
                                      class="form-control"
                                      name=""
                                      id=""
                                      v-model.lazy="
                                        store_template.about.team.text
                                      "
                                      placeholder="Digite a descrição da empresa"
                                    />
                                  </div>
                                </div>

                                <div
                                    class="
                                        d-flex
                                        flex-column
                                        flex-align-start
                                        flex-justify-start
                                        p-1
                                    "
                                    style="gap: 1rem"
                                >
                                    <span>Valores (1)</span>

                                    <div
                                        class="
                                            d-flex
                                            flex-row
                                            flex-wrap
                                            flex-align-center
                                            flex-justify-start
                                            p-1
                                        "
                                        style="gap: 0.4rem"
                                    >
                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong>Título</strong></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about.primary
                                                        .title
                                                "
                                                placeholder="Digite o título"
                                            />
                                        </div>

                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong
                                                    >Descrição</strong
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about.primary
                                                        .description
                                                "
                                                placeholder="Digite a descrição"
                                            />
                                        </div>

                                        <div class="form-group">
                                            <label for=""
                                                ><strong>Imagem</strong></label
                                            >
                                           <img loading="lazy"
                                                v-show="
                                                    store_template.about.primary
                                                        .background.image &&
                                                    store_template.about.primary.background.image.trim() !==
                                                        '' &&
                                                    store_template.about.primary
                                                        .background.image
                                                        .length <= 75
                                                "
                                                :src="`${$masterHelper.getURL(
                                                    true
                                                )}${
                                                    store_template.about.primary
                                                        .background.image || ''
                                                }.png`"
                                                alt="Image da logo da loja"
                                            />
                                            <Upload
                                                position="header"
                                                ref="up1"
                                                :noFavorite="true"
                                                :height="250"
                                                btnText="Adicionar nova imagem"
                                                @data_deleted="
                                                    (data) =>
                                                        handlerDeleteImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'primary'
                                                        )
                                                "
                                                @added_item="
                                                    (data) =>
                                                        handlerImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'primary'
                                                        )
                                                "
                                                :limit="1"
                                            />
                                        </div>
                                    </div>

                                    <span>Valores (2)</span>

                                    <div
                                        class="
                                            d-flex
                                            flex-row
                                            flex-wrap
                                            flex-align-center
                                            flex-justify-start
                                            p-1
                                        "
                                        style="gap: 0.4rem"
                                    >
                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong>Título</strong></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about
                                                        .secondary.title
                                                "
                                                placeholder="Digite o título"
                                            />
                                        </div>

                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong
                                                    >Descrição</strong
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about
                                                        .secondary.description
                                                "
                                                placeholder="Digite a descrição"
                                            />
                                        </div>

                                        <div class="form-group">
                                            <label for=""
                                                ><strong>Imagem</strong></label
                                            >
                                           <img loading="lazy"
                                                v-show="
                                                    store_template.about
                                                        .secondary.background
                                                        .image &&
                                                    store_template.about.secondary.background.image.trim() !==
                                                        '' &&
                                                    store_template.about
                                                        .secondary.background
                                                        .image.length <= 75
                                                "
                                                :src="`${$masterHelper.getURL(
                                                    true
                                                )}${
                                                    store_template.about
                                                        .secondary.background
                                                        .image || ''
                                                }.png`"
                                                alt="Image da logo da loja"
                                            />
                                            <Upload
                                                position="header"
                                                ref="up1"
                                                :noFavorite="true"
                                                :height="250"
                                                btnText="Adicionar nova imagem"
                                                @data_deleted="
                                                    (data) =>
                                                        handlerDeleteImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'secondary'
                                                        )
                                                "
                                                @added_item="
                                                    (data) =>
                                                        handlerImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'secondary'
                                                        )
                                                "
                                                :limit="1"
                                            />
                                        </div>
                                    </div>

                                    <span>Valores (3)</span>

                                    <div
                                        class="
                                            d-flex
                                            flex-row
                                            flex-wrap
                                            flex-align-center
                                            flex-justify-start
                                            p-1
                                        "
                                        style="gap: 0.4rem"
                                    >
                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong>Título</strong></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about.thirdy
                                                        .title
                                                "
                                                placeholder="Digite o título"
                                            />
                                        </div>

                                        <div
                                            class="
                                                form-group
                                                d-flex
                                                flex-column
                                            "
                                        >
                                            <label for=""
                                                ><strong
                                                    >Descrição</strong
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name=""
                                                id=""
                                                v-model.lazy="
                                                    store_template.about.thirdy
                                                        .description
                                                "
                                                placeholder="Digite a descrição"
                                            />
                                        </div>

                                        <div class="form-group">
                                            <label for=""
                                                ><strong>Imagem</strong></label
                                            >
                                           <img loading="lazy"
                                                v-show="
                                                    store_template.about.thirdy
                                                        .background.image &&
                                                    store_template.about.thirdy.background.image.trim() !==
                                                        '' &&
                                                    store_template.about.thirdy
                                                        .background.image
                                                        .length <= 75
                                                "
                                                :src="`${$masterHelper.getURL(
                                                    true
                                                )}${
                                                    store_template.about.thirdy
                                                        .background.image || ''
                                                }.png`"
                                                alt="Image da logo da loja"
                                            />
                                            <Upload
                                                position="header"
                                                ref="up1"
                                                :noFavorite="true"
                                                :height="250"
                                                btnText="Adicionar nova imagem"
                                                @data_deleted="
                                                    (data) =>
                                                        handlerDeleteImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'thirdy'
                                                        )
                                                "
                                                @added_item="
                                                    (data) =>
                                                        handlerImage(
                                                            'about',
                                                            'background',
                                                            'image',
                                                            data,
                                                            'thirdy'
                                                        )
                                                "
                                                :limit="1"
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <!-- Localização  -->
                <div class="row guide-target-hidden" id="template-location">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <h3>Localização</h3>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 1rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.location.background
                                                .color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.location.text.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.location.background
                                                .image &&
                                            store_template.location.background.image.trim() !==
                                                '' &&
                                            store_template.location.background
                                                .image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.location.background
                                                .image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'location',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'location',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong
                                            >Imagem de fundo 2</strong
                                        ></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.location.icone
                                                .background.image &&
                                            store_template.location.icone.background.image.trim() !==
                                                '' &&
                                            store_template.location.icone
                                                .background.image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.location.icone
                                                .background.image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'location',
                                                    'background',
                                                    'image',
                                                    data,
                                                    'icone'
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'location',
                                                    'background',
                                                    'image',
                                                    data,
                                                    'icone'
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div class="form-group p-1">
                                    <label for=""
                                        ><strong>Título</strong></label
                                    >
                                    <div class="row">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.location.text
                                                    .title
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="form-group p-1">
                                    <label for="">
                                        <strong>Endereço</strong>
                                    </label>
                                    <div class="row">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            id=""
                                            v-model.lazy="
                                                store_template.location.text
                                                    .description
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="form-group p-1">
                                    <label for="">
                                        <strong>Disponível</strong>
                                    </label>
                                    <div class="row">
                                        <toggle-button
                                            v-model.lazy="
                                                store_template.location
                                                    .available
                                            "
                                            class="ml-3"
                                            :width="100"
                                            :switch-color="{
                                                checked: '#fff',
                                                unchecked: '#fff',
                                                disabled: '#CCCCCC',
                                            }"
                                            :sync="true"
                                            :labels="{
                                                checked: 'Ativado',
                                                unchecked: '  Desativado',
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Vitrine  -->
                <div class="row guide-target-hidden" id="template-window-shopping">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <h3>Vitrine</h3>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 0.5rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo</strong></label
                                    >
                                    <input
                                        type="color"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.windowShop.background
                                                .color
                                        "
                                        class="form-control"
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de texto</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.windowShop.text.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.windowShop.background
                                                .image &&
                                            store_template.windowShop.background.image.trim() !==
                                                '' &&
                                            store_template.windowShop.background
                                                .image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.windowShop.background
                                                .image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'windowShop',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'windowShop',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Newsletter  -->
                <div class="row guide-target-hidden" id="template-newsletter">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <div class="w-100 d-flex align-items-center">
                                <h3 class="my-auto pr-4">Newsletter</h3>
                                <hr class="w-100" />
                            </div>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 0.5rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.newsletter.background
                                                .color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor dos textos</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.newsletter.text.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.newsletter.background
                                                .image &&
                                            store_template.newsletter.background.image.trim() !==
                                                '' &&
                                            store_template.newsletter.background
                                                .image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.newsletter.background
                                                .image || ''
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'newsletter',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'newsletter',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div class="form-group p-1">
                                    <label for="">
                                        <strong>Disponível</strong>
                                    </label>
                                    <div class="row">
                                        <toggle-button
                                            v-model.lazy="
                                                store_template.newsletter
                                                    .available
                                            "
                                            class="ml-3"
                                            :width="100"
                                            :switch-color="{
                                                checked: '#fff',
                                                unchecked: '#fff',
                                                disabled: '#CCCCCC',
                                            }"
                                            :sync="true"
                                            :labels="{
                                                checked: 'Ativado',
                                                unchecked: '  Desativado',
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Highlighted  -->
                <div class="row guide-target-hidden" id="template-highlighted">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-between
                            gap-1
                            flex-wrap
                        "
                    >
                        <div
                            class="
                                d-flex
                                flex-column
                                p-2
                                flex-fill flex-glow-1
                                mt-5
                                border-left border-light
                            "
                        >
                            <h3>Highlighted (Destaque rotativo)</h3>
                            <div
                                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                                style="gap: 0.5rem"
                            >
                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor de fundo</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.highlighted
                                                .background.color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Cor dos textos</strong></label
                                    >
                                    <input
                                        type="color"
                                        class="form-control"
                                        name=""
                                        id=""
                                        v-model.lazy="
                                            store_template.highlighted.text
                                                .color
                                        "
                                    />
                                </div>

                                <div class="form-group">
                                    <label for=""
                                        ><strong>Imagem de fundo</strong></label
                                    >
                                   <img loading="lazy"
                                        v-show="
                                            store_template.highlighted
                                                .background.image &&
                                            store_template.highlighted.background.image.trim() !==
                                                '' &&
                                            store_template.highlighted
                                                .background.image.length <= 75
                                        "
                                        :src="`${$masterHelper.getURL(true)}${
                                            store_template.highlighted
                                                .background.image
                                        }.png`"
                                        alt="Image da logo da loja"
                                    />
                                    <Upload
                                        position="header"
                                        ref="up1"
                                        :noFavorite="true"
                                        :height="250"
                                        btnText="Adicionar nova imagem"
                                        @data_deleted="
                                            (data) =>
                                                handlerDeleteImage(
                                                    'highlighted',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        @added_item="
                                            (data) =>
                                                handlerImage(
                                                    'highlighted',
                                                    'background',
                                                    'image',
                                                    data
                                                )
                                        "
                                        :limit="1"
                                    />
                                </div>

                                <div class="form-group p-1">
                                    <label for="">
                                        <strong>Disponível</strong>
                                    </label>
                                    <div class="row">
                                        <toggle-button
                                            v-model.lazy="
                                                store_template.highlighted
                                                    .available
                                            "
                                            class="ml-3"
                                            :width="100"
                                            :switch-color="{
                                                checked: '#fff',
                                                unchecked: '#fff',
                                                disabled: '#CCCCCC',
                                            }"
                                            :sync="true"
                                            :labels="{
                                                checked: 'Ativado',
                                                unchecked: '  Desativado',
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { VMoney } from "v-money";

// Components
import ImageCardUpload from "@/components/imageUpload/ImageCardUpload";
import Upload from "@/components/imageUpload/Upload";
import Multiselect from "vue-multiselect";

// Mixins
import Settings from "@/mixins/Settings.vue";

export default {
  directives: { money: VMoney },
  components: { Upload, Multiselect,ImageCardUpload },
  mixins: [Settings],
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isEditing: false,
      admin_template: {
        icons_color: "",
        buttons_color: "",
        general: {
          text: {
            colorTitle: "",
            colorSubTitle: "",
            colorDescription: "",
            fontType: "",
          },
          background: {
            image: "",
            color: "",
            color2: "",
          },
          button: {
            text: "",
            background: "",
          }
        },
      },
      store_template: {
        icons_color: "",
        buttons_color: "",
        general: {
            text: {
                colorTitle: "",
                colorSubTitle: "",
                colorDescription: "",
                fontType: "",
            },
            background: {
                image: "",
            },
            button: {
                text: "",
                background: "",
            },
            email: "",
            phone: "",
            social: {
                facebook: "",
                twitter: "",
                instagram: "",
                whastapp: "",
                youtube: "",
            },
        },
        header: {
            background: {
                color: "",
            },
            primary: {
                name: "",
                link: "",
            },
            secondary: {
                name: "",
                link: "",
            },
            thirdy: {
                name: "",
                link: "",
            },
            fourthy: {
                name: "",
                link: "",
            },
            fifth: {
                name: "",
                link: "",
            },
        },
        form: {
          background: {
            color: "",
            color2: "",
            image: "",
          },
          text: {
            color: "",
          },
          button: {
            text: "",
            background: "",
          },
          contact: {
            background: {
              color: "",
              image: "",
            },
            email: {
              from: "",
              subject: "",
            },
            title: "",
            description: ""
          }
        },
        windowShop: {
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
            },
        },
        footer: {
            logo: {
                background: {
                    image: "",
                },
            },
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
                color_two: "",
            },
        },
        about: {
          background: {
            color: "",
            color_two: "",
            image: "",
          },
          text: {
            color: "",
            color_two: "",
          },
          company: {
            title: "",
            text: "",
          },
          team: {
            title: "",
            text: ""
          },
          primary: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
          secondary: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
          thirdy: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
        },
        location: {
            icone: {
                background: {
                    image: "",
                },
            },
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
                title: "",
                description: "",
            },
            available: false,
        },
        newsletter: {
          background: {
            color: "",
            color_two: "",
            image: "",
          },
          text: {
            color: "",
          },
          available: false,
        },
        highlighted: {
            background: {
                color: "",
                color_two: "",
                image: "",
            },
            text: {
                color: "",
            },
            available: false,
        },
      },
      store_template_temp: {
        icons_color: "",
        buttons_color: "",
        general: {
            text: {
                colorTitle: "",
                colorSubTitle: "",
                colorDescription: "",
                fontType: "",
            },
            background: {
                image: "",
            },
            button: {
                text: "",
                background: "",
            },
        },
        header: {
            background: {
                color: "",
            },
            primary: {
                name: "",
                link: "",
            },
            secondary: {
                name: "",
                link: "",
            },
            thirdy: {
                name: "",
                link: "",
            },
            fourthy: {
                name: "",
                link: "",
            },
            fifth: {
                name: "",
                link: "",
            },
        },
        form: {
          background: {
            color: "",
            color2: "",
            image: "",
          },
          text: {
            color: "",
          },
          button: {
            text: "",
            background: "",
          },
          contact: {
            background: {
              color: "",
              image: "",
            },
            email: {
              from: "",
              subject: "",
            },
            title: "",
            description: ""
          }
        },
        windowShop: {
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
            },
        },
        footer: {
            logo: {
                background: {
                    image: "",
                },
            },
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
            },
        },
        about: {
          background: {
              color: "",
              color_two: "",
              image: "",
          },
          text: {
              color: "",
              color_two: "",
          },
          company: {
            title: "",
            text: "",
          },
          team: {
            title: "",
            text: ""
          },
          primary: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
          secondary: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
          thirdy: {
              background: {
                  image: "",
              },
              title: "",
              description: "",
          },
        },
        location: {
            icone: {
                background: {
                    image: "",
                },
            },
            background: {
                color: "",
                image: "",
            },
            text: {
                color: "",
                title: "",
                description: "",
            },
            available: false,
        },
        newsletter: {
            background: {
                color: "",
                color_two: "",
                image: "",
            },
            text: {
                color: "",
            },
            available: false,
        },
        highlighted: {
            background: {
                color: "",
                color_two: "",
                image: "",
            },
            text: {
                color: "",
            },
            available: false,
        },
      },
      fontTypeList: {
          value: null,
          options: [
              {
                  name: "Lato",
                  family: "Lato",
              },
          ],
      },
      hasUpload: false,
      formatString: {
          decimal: ".",
          thousands: ".",
          prefix: "",
          suffix: "",
          precision: 1,
          masked: false,
      },
    };
  },
  watch: {
    settings: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          val.forEach((setting) => {
              if (setting?.key === "shopping") {

                const cachedStoreTemplate = JSON.parse(JSON.stringify(this.store_template))

                Object.entries(setting.content).forEach(objectTemplate => {
                  if(Object.keys(objectTemplate[1] || {}).length > 0 ) {
                    cachedStoreTemplate[objectTemplate[0]] = {
                      ...cachedStoreTemplate[objectTemplate[0]],
                      ...objectTemplate[1]
                    }
                  }else{
                    cachedStoreTemplate[objectTemplate[0]] = objectTemplate[1]
                  }
                })


                this.store_template = cachedStoreTemplate

                return false
              } else if (setting?.key === "template") {
                this.admin_template = setting.content;
              }else if(setting.key === "admin_template") {
                const cachedStoreTemplate = JSON.parse(JSON.stringify(this.admin_template))

                Object.entries(setting.content).forEach(objectTemplate => {
                  if(Object.keys(objectTemplate[1] || {}).length > 0 ) {
                    cachedStoreTemplate[objectTemplate[0]] = {
                      ...cachedStoreTemplate[objectTemplate[0]],
                      ...objectTemplate[1]
                    }
                  }else{
                    cachedStoreTemplate[objectTemplate[0]] = objectTemplate[1]
                  }
                })

                this.admin_template = cachedStoreTemplate
              }
          });
        } catch (error) {
          //console.log(error)
        }
      },
    },
  },
  computed: {
    ...mapGetters(["settings", "tagColorsList"]),
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      //console.log(this.myGateways?.content, this.myGateways);
      // this.store_template = {
      //   ...this.store_template,
      //   ...(this.myGateways?.content || {}),
      // };
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS"]),
    save(key = "template", storeUI = null) {
      let data = storeUI || this.store_template;
      this.isSaving = true;
      this.$pace.restart();

      // TODO - THIS NEWS VERSION (V2) CREATE THE SETTINGS ALSO CAN UPDATE THE SETTINGS
      this.CREATE_SETTINGS({
          content: data,
          key: key,
      }).then((res) => {
          this.$pace.restart();
          this.UPDATE++;

          if (res.code === 201) {
              this.$toast.success(" ", "Concluido", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 2000,
              });

              if (key === "template")
                  this.admin_template = storeUI || this.store_template;
              if (key === "shopping") {
                  this.store_template = res.data.content || {
                      ...this.store_template,
                  };
              }
              this.loadSet();
          } else {
              this.$toast.error(" ", "Houve uma falha.", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 2000,
              });
          }

          this.isSaving = false;
      });
    },
    handlerImage(from = null, to = null, target = null, data = null, extraFrom = "", thisState = "store_template") {
      try {
        if (!from || !to || !target || !data) {

          if (extraFrom.trim() != "") {
            this[thisState][from][extraFrom][to][target] = "";
          } else {
            this[thisState][from][to][target] = "";
          }

          this.hasUpload = false;
          return;
        }

        const sourceImage = data?.src || data?.data

        // console.log(this.store_template[from][extraFrom][to][target], from,extraFrom,to,target)
        if (sourceImage) {
            if (extraFrom.trim() !== "") {
                this[thisState][from][extraFrom][to][target] =
                    sourceImage || "";
            } else {
                this[thisState][from][to][target] = sourceImage || "";
            }
            this.hasUpload = true;
        }

        return;
      } catch (error) {
        // do anything
        //console.log(error);
      }
    },
    handlerDeleteImage( from = null, to = null, target = null, _data = "", extraFrom = "",thisState = "store_template") {
      try {
        if (from && to && target) {
          if (extraFrom.trim() !== "") {
            this[thisState][from][extraFrom][to][target] = "";
          } else {
            this[thisState][from][to][target] = "";
          }

          this.hasUpload = false;
          return;
        }
      } catch (error) {
          // do anything
      }
    },
    setTemplateGuideTarget(target = "") {
      try {
        if(target.trim() === '') return

        const elementGuideTarget = document.querySelector(`#${target}`)

        if(!elementGuideTarget) return

        if(elementGuideTarget.classList.contains('guide-target-hidden')) {
          elementGuideTarget.classList.remove('guide-target-hidden')
          return
        }

        elementGuideTarget.classList.add('guide-target-hidden')
      } catch (error) {
        return
      }
    }
  },
};
</script>

<style></style>
