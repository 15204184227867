<template>
  <div>
    <BreadCrumb :pageInfo="partners && partners.length + ' parceiros'" page="Parceiros">
      <div slot="right-side">
        <!-- <button
          class="btn btn-primary"
          @click="$router.push({ name: 'partners.create' })"
        >
          Novo
        </button> -->
        <PartnerModal />
      </div>
    </BreadCrumb>

    <div class="mb-2">
      <small class="alert alert-info">Clique no point para selecionar um parceiro</small>
    </div>

    <br />

    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <div class="card">
          <div class="card-body">
            <PartnerList :data="partnerList" v-if="!reload" />
          </div>
        </div>
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <div class="card" key="search" style="position:fixed;width:20%" v-if="$masterHelper.isEmpty(partner)">
            <div class="card-body">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label for="">Filtros</label>
                  <label @click="reset()" class="text-danger pointer">Limpar filtros</label>
                </div>
                <input @keyup.enter="filterPartners()" class="form-control" type="text" placeholder="Pesquisar"
                  v-model="filter.search" :disabled="isSearching" />
                <small>Pesquise pelo nome.</small>
              </div>
              <div class="form-group">
                <div class="form-group">
                  <label for="">Status</label>
                  <select class="form-control" name="" id="" v-model="filter.active">
                    <option value="1">Ativo</option>
                    <option value="0">Desativado</option>
                    <option value="all">Todos</option>
                  </select>
                </div>
              </div>
              <div>
                <button class="btn btn-primary btn-block" @click="filterPartners()">
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
          <div key="info" v-else>
            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <!-- <span class="pointer" @click="editPartner()" v-if="!isEditing">
                  <i class="far fa-edit"></i> Editar
                </span> -->
                <span class="pointer align-self-end text-danger ml-auto" @click="GET_PARTNER({})" v-if="!isEditing">
                  fechar</span>
                <span class="text-danger pointer" v-if="isEditing" @click="isEditing = false">Cancelar</span>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center"></div>
                <PartnerInfo />
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import PartnerModal from "@/components/partners/PartnerModal";
// Components
import PartnerCard from "@/components/partners/PartnerCard";
import PartnerInfo from "@/components/partners/PartnerInfo";
import PartnerList from "@/components/partners/PartnerList";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import MasterFilter from "@/components/Filters/MasterFilter";

export default {
  components: {
    PartnerCard,
    PartnerInfo,
    Multiselect,
    BreadCrumb,
    PartnerList,
    MasterFilter,
    PartnerModal
  },
  data() {
    return {
      reload: false,
      selectedTags: [],
      selectedUsers: [],
      isEditing: false,
      tempPost: {},
      starts_at: null,
      ends_at: null,
      isSearching: false,
      filter: {
        search: "",
        active: "all"
      },
      partnerList: []
    };
  },
  watch: {},
  mounted() {
    if (!this.partners.length) {
      this.LOAD_PARTNERS()
        .then(r => {
          this.partnerList = this.partners;
        })
        .catch(error => {
          // console.log("LOAD_PARTNERS runned with erros", error);
        });
    }
    if (!this.partnerList.length) this.partnerList = this.partners;

    this.$event.$on("partner_update", (data = null) => {
      this.partnerList = data ? data : this.partners;
    });
  },
  computed: {
    ...mapGetters(["partners", "partner"])
  },
  methods: {
    ...mapActions(["LOAD_PARTNERS", "GET_PARTNER"]),
    open(partner) {
      // console.log("Get partner");
      this.GET_PARTNER(partner);
    },
    reset() {
      this.filter = {
        active: "all",

        start: null,
        end: null,
        search: null
      };
      this.partnerList = this.partners;
    },
    filterPartners() {
      this.isSearching = true;
      let res = this.partners;

      if (this.filter.search.length) {
        res = this.partners.filter(partner => {
          return partner.name
            .toLowerCase()
            .includes(this.filter.search.toLowerCase());
        });
      }

      if (this.filter.active !== "all") {
        res = res.filter(r => {
          return r.active == this.filter.active;
        });
      }
      this.partnerList = res;
      this.isSearching = false;
    },
    searchFilter() { }
  }
};
</script>

<style lang="scss">
.first-item {
  display: flex;

  .image {
    width: 120;
  }

  .description {
    margin-left: 1em;
  }
}
</style>
