<template>
  <ul class="list-group">
    <li
      class="list-group-item"
      v-for="(gateway, index) in invoices"
      :key="index"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
         <img loading="lazy" height="20" :src="gateway.logo" alt="" class="mr-2" />
          <strong>{{ gateway.label }}</strong>
        </div>
        <div class="d-flex">
          <strong
            v-if="
              isSaving &&
              !isDeleting &&
              selectedProvider &&
              selectedProvider.name === gateway.name
            "
            class="text-primary"
            >Ativando ...</strong
          >
          <strong
            class="text-warning"
            v-if="isDeleting && deletingObj && deletingObj.name == gateway.name"
            >Removendo ...</strong
          >
          <strong v-if="!isSaving && !isDeleting">
            <span v-if="isSetted(gateway.name)"
              >Status - <span class="text-success">Ativo</span>
            </span>
            <span class="text-muted" v-else>
              <span>Não configurado</span>
              <span
                class="text-primary ml-3 pointer"
                @click="addGateWay(gateway)"
                >Adicionar</span
              >
            </span>
          </strong>
          <div
            class="ml-3"
            v-if="isSetted(gateway.name) && !isSaving && !isDeleting"
          >
            <!-- <span class="pointer"><i class="fas fa-power-off"></i></span> -->
            <span class="pointer" @click="edit(gateway)"
              ><i class="fas fa-pencil-alt ml-4"></i
            ></span>
            <span class="pointer ml-4" @click="_delete(gateway)"
              ><i class="far fa-trash-alt"></i
            ></span>
          </div>
        </div>
      </div>
      <div
        class="container alert alert-warning mt-2"
        v-if="
          selectedProvider &&
          selectedProvider.name === gateway.name &&
          isAdding &&
          !isSaving &&
          !isDeleting
        "
      >
        <div class="d-flex flex-column">
          <div
            class="form-group"
            v-for="(input, index) in selectedProvider.inputs"
            :key="index"
          >
            <label :for="'input_ ' + input">{{ input }}</label>
            <input
              class="form-control"
              type="text"
              :id="'input_ ' + input"
              v-model="invoice_provider[input]"
            />
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button
              class="btn btn-outline-danger"
              @click="selectedProvider = null"
            >
              Cancelar
            </button>
            <button class="btn btn-primary ml-2" @click="save()">Salvar</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isDeleting: false,
      isSaving: false,
      isAdding: false,
      isEditing: false,
      invoice_provider: {},
      selectedProvider: null,
      deletingObj: null,
      UPDATE: 0,
      invoices: [
        {
          name: "bling",
          label: "Bling",
          inputs: ["apikey", "endpoint"],
          logo: require("../../../../assets/images/bling-logo.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["settings"]),
    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "invoice_provider";
        }) || false
      );
    },
  },
  methods: {
    ...mapActions([
      "LOAD_SETTINGS",
      "UPDATE_SETTINGS",
      "CREATE_SETTINGS",
      "DELETE_SETTINGS_PROVIDER",
    ]),
    isSetted(name) {
      //buscar se tem um invoice_provider cadastrado
      if (!this.myGateways) return false;
      return this.myGateways.content.hasOwnProperty(name);
    },
    addGateWay(data) {
      this.isAdding = true;
      this.selectedProvider = data;
    },
    save() {
      this.isSaving = true;
      let data = {
        [this.selectedProvider.name]: this.invoice_provider,
      };
      if (this.myGateways) {
        data[this.selectedProvider.name].name = this.selectedProvider.name;
        this.UPDATE_SETTINGS({
          content: data,
          id: this.myGateways.id,
        }).then((res) => {
          if (res.code == 201) {
            this.$toast.success(" ", "Adicionado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          } else {
            this.$toast.error(" ", "Houve uma falha.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          }
        });
        //console.log("updating ... ---");
        setTimeout(() => {
          this.isSaving = false;
          this.selectedProvider = null;
          this.UPDATE++;
        }, 1000);
      } else {
        //console.log("creating ... ---");
        data[this.selectedProvider.name].name = this.selectedProvider.name;
        this.CREATE_SETTINGS({
          content: data,
          key: "invoice_provider",
        }).then((res) => {
          if (res.code === 201) {
            this.$toast.success(" ", "Adicionado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.LOAD_SETTINGS() //window.location.reload()

          } else {
            this.$toast.error(" ", "Houve uma falha.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          }
        });
        setTimeout(() => {
          this.isSaving = false;
          this.selectedProvider = null;
          this.UPDATE++;
        }, 1000);
      }

      //console.log("saving", { content: data });
    },
    edit(prov) {
      this.isAdding = true;
      this.invoice_provider = this.myGateways.content[prov.name];
      this.selectedProvider = prov;
      this.$forceUpdate();
    },
    _delete(prov) {
      this.isDeleting = true;
      this.deletingObj = prov;
      let data = this.myGateways.content;
      delete data[prov.name];
      //console.log("going to remove", data);
      this.DELETE_SETTINGS_PROVIDER({
        content: data,
        id: this.myGateways.id,
      }).then((res) => {
        if (res.code === 201) {
          this.$toast.success(" ", "Desativado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        } else {
          this.$toast.error(" ", "Houve uma falha ao desativar.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
        this.isDeleting = false;
        this.deletingObj = null;
      });

      this.invoice_provider = {};
      this.selectedProvider = null;
      this.isAdding = false;
    },
  },
};
</script>

<style></style>
