export default () => {
  let clear = v => {
    return v.replace(/\D/g, "");
  };

  //console.log("InputValidations.js started");
  //For inputs to be number
  //native input type = number is accepting text sometimes
  //so this function is to prevent and reenforce that inputs stays as number
  document.querySelectorAll("[number]").forEach(e => {
    e.addEventListener("keyup", key => {
      // key.preventDefault();
      //console.log("number key up");

      key.target.value = clear(key.target.value);
    });
  });

  //console.log("InputValidations.js is loaded");
};
