<template>
  <div class="d-flex">
    <input
      ref="files"
      multiple
      type="file"
      hidden
      @change="handler"
      v-if="position !== 'header'"
    />
    <input
      ref="files"
      type="file"
      hidden
      @change="handler"
      v-if="position === 'header'"
    />

    <div
      class="d-flex imgs-slider"
      :class="[{ 'justify-content-center': position == 'gallery' }]"
    >
    <!-- {{ tempImages }} -->
      <div
        class="d-flex flex-column m-2"
        v-for="(img, index) in tempImages"
        :key="img.src || img.path"
      >
        <div
          style="position: relative; max-width: 100%; max-height: 500px"
          class="action-container"
        >
          <div
            v-if="!_isRemoving(img)"
            class="image-action"
            style="position: absolute; background: #606060bf"
          >
            <button
              class="btn pointer"
              v-if="!noFavorite"
              @click.prevent="selectFavorite(index)"
            >
              <i class="far fa-star text-white" v-show="!isFav(img)"></i>
              <i class="fas fa-star text-white" v-show="isFav(img)"></i>
            </button>
            <button class="btn pointer" @click="removeByConfirmation(img)">
              <i class="far fa-trash-alt text-white"></i>
            </button>
          </div>
          <div
            style="position: absolute"
            class="
              w-100
              h-100
              bg-warning
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
            v-if="_isRemoving(img)"
          >
            <h5 class="text-dark text-center">
              <strong
                >Confirma Remover?<br /><i
                  ><small>Esta ação não poderá ser desfeita.</small></i
                ></strong
              >
            </h5>
            <div class="mt-3">
              <button class="btn btn-danger" @click="_confimingRemoval()">
                Não
              </button>
              <button
                class="btn btn-primary ml-2"
                @click="_confimingRemoval(true)"
              >
                Sim
              </button>
            </div>
          </div>
          <div style="width: 100%">
           <img loading="lazy"
              class="pointer"
              :height="height || 200"
              :src="(img.src || $api + img.path).replace('webp', 'png')"
              alt=""
              style="object-fit: cover; width: 100% !important"
              :style="
                !newStyle ? 'object-fit: cover;width:100%!important;' : newStyle
              "
            />
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex justify-content-start"
        v-if="!tempImages.length"
      >
        <button class="btn btn-primary" @click="loadPreview()">
          {{ btnText || "Selecionar imagens" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tempImages: [],
      favorite: null,
      toRemove: {},
    };
  },
  props: {
    modelThumbnail: {
      type: Boolean,
    },
    limit: {
      type: Number,
    },
    newStyle: {
      type: String,
    },
    btnText: {
      type: String,
    },
    height: {
      type: Number,
    },
    noFavorite: {
      type: Boolean,
      default: false,
    },
    consume: {
      type: Array,
    },
    position: {
      type: String,
    },
  },
  watch: {
    // consume(val) {
    //   let self = this;
    //   let hasHeader = val.find(img => {
    //     return img.position === "header";
    //   });
    //   if (hasHeader && self.position === "header") {
    //     self.tempImages = [hasHeader];
    //   }
    // }
  },
  mounted() {
    this.$event.$on("upload_trigger", () => {
      this.loadPreview();
    });
   // console.log("input value (v-model)", this);
    this.$on("input", (data) => {
      //console.log("input value (v-model)", vm);
    });
  },
  methods: {
    // =============image handler===========
    ...mapActions(["REMOVE_MEDIA_BY_ID"]),
    async loadPreview() {
      let self = this;
      await self.$refs.files.click();
    },
    selectFavorite(index) {
      let self = this;

      for (let i = 0; i < self.tempImages.length; i++) {
       // console.log(`you selected index ${index} and the loop i is: ${i}`);
        if (i !== index) {
          this.tempImages[i].position = "gallery";
        }
      }
      this.tempImages[index].position = "header";
      this.$emit("favorite_selected", this.tempImages[index]);
      this.$emit("data_update", this.tempImages);
    },
    removeByConfirmation(img) {
      this.toRemove = img;
    },
    _isRemoving(img) {
      if (!this.$masterHelper.isEmpty(this.toRemove)) {
        if (this.toRemove.src) {
          return this.toRemove.src === img.src;
        }
        if (this.toRemove.path) {
          return this.toRemove.path === img.path;
        }
      } else {
        return false;
      }
    },
    _confimingRemoval(choice = false) {
      if (choice) {
        //get the index from array, use the path or src as identifier
        if (this.modelThumbnail) {
          this.tempImages = [];
        } else {
          let index = this.tempImages.findIndex((img) => {
            if (this.toRemove.path) {
              // call action to remove media by id from product
              if (
                img.path === this.toRemove.path ||
                img.src === this.toRemove.src
              ) {
                this.REMOVE_MEDIA_BY_ID(img);
                return true;
              } else {
                return false;
              }
            } else {
              return img.src === this.toRemove.src;
            }
          });
          let beforeRemove = this.tempImages[index];
          this.remove(index);
          this.$emit("data_deleted", beforeRemove);
          this.toRemove = {};
        }
      } else {
        this.toRemove = {};
      }
    },
    remove(index) {
      let self = this;
      let stored = self.tempImages[index];
     // console.log("remiving");
      if (self.position === "header") {
        self.tempImages[0].position = "gallery";
        this.$emit("data_update", self.tempImages[0]);
        self.tempImages = [];
      } else {
        self.tempImages.splice(index, 1);
        this.$emit("data_deleted", stored);
      }
    },
    isFav(img) {
      return img.position === "header";
    },
    async handler(e) {
      e.preventDefault;


      let self = this;
      let tempFiles = this.$refs.files?.files || [];

      for (let i = 0; i < tempFiles.length; i++) {

        // console.log(this.getBytes(tempFiles[i].size,2, 25000))

        if(!this.getBytes(tempFiles[i].size,2, 2024**2)) {
          this.$toast.info(" ","Selecione uma imagem com menos de 2MB",{
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          })
          break
        }

        if (self.tempImages.length < self.limit) {
          if (self.listForImage(tempFiles[i].name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function () {
                if (reader.result !== undefined) {
                  let data = {
                    src: reader.result,
                    name: tempFiles[i].name,
                    size: self.formatBytes(tempFiles[i].size),
                    sizeRaw: tempFiles[i].size,
                    position: self.position ? self.position : "gallery",
                  };
                  self.$emit("data_as_obj", data);
                  self.tempImages.push(data);
                  if (self.position === "header") {
                    self.$emit("added_item", data);
                  } else {
                    self.$emit("data_update", self.tempImages);
                  }
                }
              }.bind(this),
              false
            );
            reader.readAsDataURL(tempFiles[i]);
          }
        } else {
          alert("Limite atingido");
        }
      }

      // const input = self.$refs.files;
      // input.type = "text";
      // input.type = "file";
    },
    limitRule() {
      let self = this;
      return self.tempImages.length == self.limit;
    },
    listForImage(file) {
      let self = this;

      var forImage = ["jpeg", "jpg", "png", "gif", "webp", "jfif"];
      var canUpload = forImage.some((ext) => {
        return ext === self.getExtention(file);
      });
      return canUpload;
    },
    getExtention(file) {
      //console.log("fiole", file);
      return file.substring(file.lastIndexOf(".") + 1);
    },
    setByEvent(data) {
      let self = this;
      //console.log("setting header -*-*-*", data);
      self.tempImages = [data];
      this.$emit("data_update", self.tempImages);
    },
    unFavByEvent(data) {
      let self = this;
      let imgIndex = self.tempImages.findIndex((temp) => {
        return temp.src === data.src;
      });
      self.tempImages.splice(imgIndex, 1);
      for (let i = 0; i < self.tempImages.length; i++) {
        self.tempImages[i].position = "gallery";
      }
      this.$emit("data_update", self.tempImages);
    },
    eventSetData(media) {
      let self = this;
      let header = media.find((img) => {
        return img.position === "header";
      });
      if (header) {
        self.$emit("favorite_selected", header);
      }
      self.tempImages = media;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    getBytes(bytes, decimals = 2, sizeCompareRaw) {
      if (bytes === 0) return;

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const iTarget = Math.floor(Math.log(bytes) / Math.log(k));
      const iComparare = Math.floor(Math.log(bytes) / Math.log(k));

      const sizeTarget = parseFloat((bytes / Math.pow(k, iTarget)).toFixed(dm));
      const sizeCompare = parseFloat((sizeCompareRaw / Math.pow(k, iComparare)).toFixed(dm));

      // console.log('Size Target', sizeTarget,sizes[iTarget])
      // console.log('Size To Compare', sizeCompare ,sizes[iComparare])

      if(sizes[iTarget] === "MB" && sizes[iComparare] === "MB") {
        if(sizeTarget > sizeCompare){
          return false
        }

        return true
      }else if(sizes[iTarget] === "KB" && sizes[iComparare] === "KB"){
        if(sizeTarget > sizeCompare){
          return false
        }

        return true
      }

      return false
    },

    update(data) {
      let index = this.tempImages.findIndex((img) => {
        return img.src === data.src;
      });
      this.tempImages[index] = data;
      this.$emit("data_update", self.tempImages);
    },
    addByEvent(data) {
      let self = this;
      let index = this.tempImages.findIndex((img) => {
        if (img.path) {
          return img.path === data.path;
        }
        if (img.src) {
          return img.src === data.src;
        }
        return false;
      });
      if (index < 0) {
        this.tempImages.push(data);
      }
      this.$emit("data_update", self.tempImages);
    },
    removeByEvent(data) {
      let self = this;

      let index = self.tempImages.findIndex((img) => {
        if (img.path) {
          return img.path === data.path;
        }
        if (img.src) {
          return img.src === data.src;
        }
      });
      if (index > -1) self.tempImages.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.imgs-slider {
  /* padding: 1.5em; */
  width: 100%;
  height: auto;
  /* white-space: nowrap; */
  overflow-x: auto;

  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.action-container {
  .image-action {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    visibility: hidden !important;
  }
  &:hover {
    .image-action {
      visibility: visible !important;
    }
  }
}
</style>
