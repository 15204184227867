import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    posts: [],
    post: {},
    postBackup: [],
    preview: null
  },
  getters: {
    posts: state => state.posts.data,
    postsPaginate: state => state.posts,
    post: state => state.post,
    preview: state => state.preview
  },
  mutations: {
    LOAD_POSTS(state, posts) {
      state.posts = posts;
    },
    LOAD_PREVIEW(state, preview) {
      state.preview = preview;
    },
    GET_POST(state, post) {
      state.post = post;
    },
    NEW_POST(state, post) {
      state.posts.data.push(post);
    },
    UPDATE_POST(state, post) {
      let index = state.posts.data.findIndex(p => {
        return p.id === post.id;
      });
      state.posts.data[index] = post;
    },
    FILTER_POSTS(state, posts) {
      if (!state.postBackup.length) state.postBackup = [...state.posts];
      state.posts = posts;
    },
    CLEAN_FILTER_POSTS(state) {
      state.posts = state.postBackup;
      state.postBackup = [];
    },
    REMOVE_POSTS(state, post) {
      let index = state.posts.data.findIndex(item => item.id === post.id);
      state.posts.data.splice(index, 1);
    }
  },
  actions: {
    CLEAN_FILTER_POSTS: async context => {
      context.commit("CLEAN_FILTER_POSTS");
    },
    FILTER_POSTS: async (context, payload) => {
      context.commit("FILTER_POSTS", payload);
    },
    PAGINATE_LOAD_POSTS: async (context, payload) => {
      try {
        let response = await axios().get(payload);
        console.log("posts loaded by pagination", response, payload);
        context.commit("LOAD_POSTS", response.data.posts);
        return response;
      } catch (error) {
        console.log("LOAD_POSTS:error", error);
        return error;
      }
    },
    LOAD_POSTS: async (context, payload) => {
      try {
        let response = await axios().get("/posts/pagination");

        if (response.data?.code !== 200) {
          context.commit("LOAD_POSTS", []);
          return
        }

        context.commit("LOAD_POSTS", response.data?.data);
        return response.data?.data;
      } catch (error) {
        // console.log("LOAD_POSTS:error", error);
        return error;
      }
    },
    VIEW_POST: (context, payload) => {
      try {
        context.commit("GET_POST", payload);
      } catch (error) {
        //console.log("GET_POST:error", error);
        return error;
      }
    },
    GET_POST: async (context, uuid) => {
      try {
        let response = await axios().get("/posts/show/" + uuid);
        // console.log("posts loaded", response);
        context.commit("GET_POST", response.data.post);
        return response.data;
      } catch (error) {
        //console.log("LOAD_POSTS:error", error);
        return error;
      }
    },

    SEARCH_POST: async ({ commit, dispatch }, payload) => {
      try {
        // console.log("search post params sending ...", payload);
        let response = await axios().post("/posts/search", payload);

        dispatch("SET_PARAMS", payload);

        commit("LOAD_POSTS", response.data);

        return response;
      } catch (error) {
        //console.log("LOAD_POSTS:error", error);
        return error;
      }
    },
    CREATE_POST: async (context, payload) => {
      try {
        let response = await axios().post("/posts/store", payload);
        // console.log("posts loaded", response);
        context.commit("NEW_POST", response.data.post);
        return response.data.post;
      } catch (error) {
        // console.log("CREATE_POST:error", error);
        return error;
      }
    },
    GET_MEDIA: async (context, payload) => {
      try {
        let response = await axios().post("/model_media", payload);

        return response;
      } catch (error) {
        // console.log("GET_MEDIA:error", error);

        return error;
      }
    },
    PREVIEW_POST: async ({ commit }, payload) => {
      try {
        let response = await axios().post("/preview_post", payload);

        // console.log("PRWVIEW_POST loaded", response);

        commit("LOAD_PREVIEW", response.data);
        localStorage.setItem("preview", response.data);

        return response;
      } catch (error) {
        // console.log("PRWVIEW_POST:error", error);

        return error;
      }
    },

    UPDATE_POST: async (context, payload) => {
      try {
        let response = await axios().post(
          "/posts/update/" + payload.id,
          payload
        );
        // console.log("posts updated", response);
        context.commit("UPDATE_POST", response.data.post);
        return response;
      } catch (error) {
        //console.log("UPDATE_POST:error", error);
        return error;
      }
    },

    BULK_UPDATE_POSTS: async (context, payload) => {
      try {
        let response = await axios().post("/posts/bulk_update", payload);

        return response;
      } catch (error) {
        //console.log("BULK_UPDATE_POSTS:error", error);

        return error;
      }
    },
    BULK_DELETE_POSTS: async ({ commit, dispatch }, payload) => {
      try {
        let ids = payload.map(item => item.id);

        let response = await axios().post("/posts/bulk_delete", ids);
        // console.log("payload ===========", payload);
        payload.forEach(product => {
          commit("REMOVE_POSTS", product);
        });

        return response;
      } catch (error) {
        // console.log("UPDATE_PRODUCT:error", error);
        return error;
      }
    }
  }
};
