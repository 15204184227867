<template>
  <div class="d-flex justify-content-center main-paginator">
    <nav>
      <ul class="pagination" id="pagination_container">
        <li class="page-item pointer">
          <a
            @click.prevent="nextPage(model.prev_page_url)"
            class="page-link"
            v-if="model.prev_page_url"
            >‹</a
          >
          <span class="page-link" v-else>‹</span>
        </li>

        <li
          class="page-item pointer"
          v-for="page in buildPageBtns"
          :key="page.href"
          :class="[{ active: page.active }]"
          @click.prevent="nextPage(page.href)"
        >
          <a class="page-link">{{ page.number }}</a>
        </li>

        <li class="page-item pointer">
          <a
            class="page-link"
            v-if="model.next_page_url"
            @click.prevent="nextPage(model.next_page_url)"
            >›</a
          >
          <span class="page-link" v-else>›</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import "pace-js";
import { mapActions, mapGetters } from "vuex";
import PaginatorRules from "@/components/navigation/Paginator/_PaginatorRules.vue"
export default {

  mixins: [PaginatorRules],

  data() {
    return {
      model: this.value,
      btn_pages: []
    };
  },
  props: {
    value: {
      required: false
    },
    eventName: {
      required: true
    },
    currentPageName: {
      required: true
    }
  },
  computed: {
    buildPageBtns() {
      let btns = [];
      for (let i = 1; i <= this.model.last_page; i++) {
        let link_limit = 7;

        let half_total_links = Math.floor(link_limit / 3);

        let from = this.model.current_page - half_total_links;

        let to = this.model.current_page + half_total_links;

        if (this.model.current_page < half_total_links) {
          to += half_total_links - this.model.current_page;
        }

        if (this.model.last_page - this.model.current_page < half_total_links) {
          from -=
            half_total_links -
            (this.model.last_page - this.model.current_page) -
            1;
        }
        if (from < i && i < to) {
          btns.push(this.buildBTN(i, this.model.current_page));
        }
      }
      return btns;
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.model = val;
        }
      }
    },
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
        }
      }
    }
  },
  methods: {
    buildElement(tag) {
      return document.createElement(tag);
    },

    buildBTN(incrementor, currentPage) {
      return {
        href: this.model.path + "?page=" + incrementor,
        active: incrementor == currentPage,
        number: incrementor
      };
    },
    nextPage(url) {
      this.$pace.restart();
      this.$pace.start();

      //From mixin _PaginatorRules
      this.pageNavigate(this.currentPageName, url)
    }
  }
};
</script>

<style></style>
