<template>
  <div>
    <BreadCrumb pageInfo="" page="Configurações" />

    <div class="row " id="responsive-page-container">
      <div class="col-3 table-report order-0" id="responsive-page-table">
        <div class="card config-card-submenu" id="config-card-menu">
          <div class="card-body">
            <ul class="list-group">
              <router-link v-if="
                  $masterHelper.hasRole(loggedUser, [
                      'dev',
                  ]) === true
              " :to="{ name: 'config.roles' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-shield-alt mr-2"></i>
                Segurança</router-link>
              <router-link exact :to="{ name: 'config.integrations' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-plug mr-2"></i>
                Integrações</router-link>
              <router-link exact :to="{ name: 'config.store_rules' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-percent mr-2"></i> Regras da
                loja</router-link>
              <router-link exact :to="{ name: 'config.company' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-building mr-2"></i>
                Empresa</router-link>
              <router-link exact :to="{ name: 'config.template' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-desktop mr-2"></i>
                Aparência</router-link>

              <router-link exact :to="{ name: 'config.slider' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-desktop mr-2"></i>
                Slider</router-link>
              <router-link exact :to="{ name: 'config.paginas' }" tag="li" class="list-group-item pointer"><i
                  class="fas fa-desktop mr-2"></i>
                Páginas</router-link>
              <router-link exact :to="{ name: 'config.pessoal' }" tag="li" class="list-group-item pointer">
                <i class="fas fa-shield-alt mr-2"></i>
                Dados pessoais
              </router-link>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-9 order-1" id="responsive-filter-container">
        <transition name="slide-in" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BreadCrumb,
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style lang="scss" >
.config-card-submenu {
  position: fixed !important;
  width: calc(25% - 30px);
  transition: all;
}

.page-wrapper:not(.collapsed-wrapper) {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .config-card-submenu {
    width: calc(20% - 30px);
  }

  .page-breadcrumb {
    padding-left: 1rem;
  }
}
</style>
