/* eslint-disable no-useless-catch */
/*
  About this File: A box of functions that can be utils for all application
*/

/**
 * Organize your form data to Object
 * @param String idForm - The string dont have the # special character
 * @returns Object data or void
 */
export function handlerOrganizeForm(idForm = "") {
  try {
    if (idForm.trim() === "") throw new Error("The id of form should be sended")

    const elementForm = document.querySelector(`#${idForm}`)

    if (!elementForm) throw new Error(`The form with ID '${idForm}' not was found`)

    const elementFormInputs = document.querySelectorAll(`#${idForm} input, textarea`)

    if (!elementFormInputs || elementFormInputs.length <= 0) throw new Error('The form dont have inputs')

    const elementFormInputsParsed = Array.from(elementFormInputs)

    if (elementFormInputsParsed.length <= 0) throw new Error("Have an error during the parse of input from form")

    const objectForm = {}

    elementFormInputsParsed.forEach(elementInput => {

      if (elementInput.name.trim() !== '' && elementInput.value.trim() !== '') {

        objectForm[elementInput.name] = elementInput.value

      }

    })


    if (Object.entries(objectForm).length <= 0) throw new Error("Dont possible organize your form data")

    console.log('te', objectForm)
    return objectForm
  } catch (error) {
    throw error
    // console.log(`Have an error during the attempt to organize the form: ${error?.message}`)
  }
}

/**
 * Popule your form with your data
 * @param String idForm - The string dont have the # special character
 * @param Object data - The data that represent your form, like the return from utlis/handlerOrganizeForm function
 * @returns void
 */
export function handlerPopuleForm(idForm = "", data = {}) {
  try {
    if (idForm.trim() === "") throw new Error("The id of form should be sended")
    if (Object.entries(data).length <= 0) throw new Error("The data is empty")

    const elementForm = document.querySelector(`#${idForm}`)

    if (!elementForm) throw new Error(`The form with ID '${idForm}' not was found`)

    const elementFormInputs = document.querySelectorAll(`#${idForm} input, textarea`)

    if (!elementFormInputs || elementFormInputs.length <= 0) throw new Error('The form dont have inputs')

    const elementFormInputsParsed = Array.from(elementFormInputs)

    if (elementFormInputsParsed.length <= 0) throw new Error("Have an error during the parse of input from form")

    // console.log(`Form ID: ${idForm}`, data)

    elementFormInputsParsed.forEach(elementInput => {
      if (data?.[elementInput?.name]) elementInput.value = data?.[elementInput?.name]
    })
  } catch (error) {
    // console.log(`Have an error during the attempt to popule the form: ${error?.message}`)
  }
}

/**
 * Clear the form
 * @param String idForm - The string dont have the # special character
 * @returns void
 */
export function handlerClearForm(idForm = "") {
  try {
    if (idForm.trim() === "") throw new Error("The id of form should be sended")

    const elementForm = document.querySelector(`#${idForm}`)

    if (!elementForm) throw new Error(`The form with ID '${idForm}' not was found`)

    const elementFormInputs = document.querySelectorAll(`#${idForm} input, textarea`)

    if (!elementFormInputs || elementFormInputs.length <= 0) throw new Error('The form dont have inputs')

    const elementFormInputsParsed = Array.from(elementFormInputs)

    if (elementFormInputsParsed.length <= 0) throw new Error("Have an error during the parse of input from form")

    elementFormInputsParsed.forEach(elementInput => {
      elementInput.value = ""
    })
  } catch (error) {
    // console.log(`Have an error during the attempt to clear the form: ${error?.message}`)
  }
}

/**
 * Validate a input field to CPF and or CNPJ
 * @param event A event object, not necessary an element as param, only the event of input
 *
 */
export function handlerValidateCPFCNPJ(event) {
  try {
    const valueInput = event.target.value.replace(/\/\./g, "");

    if (valueInput?.length > 19) {
      event.target.value = ""
      return
    }

    if (valueInput.length > 6 && valueInput.length <= 11) {

      event.target.value = valueInput
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");

    } else if (valueInput.length > 14 && valueInput.length <= 19) {

      event.target.value = valueInput
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")

    }
  } catch (error) {
    //
  }
}

/**
 * DEBOUNCE FUNCTION
 *
 * An debounce function is a type of function to debounce a sequencial of actions of user and consider only the last action
 *
 * Initialization by debounceFunction
 *
 */

export function debounceFunction(callback, time, ...rest) {
  try {
    handlerClearIDTimeOut(handlerGetIDTimeOut());

    let timeOutID = setTimeout(async function () {
      await callback(...rest)
    }, time);

    handlerSetIDTimeOut(timeOutID)
  } catch (error) {
    // do anything
  }
}

/**
 *  Return an list of bank, with your details
 * @returns An arra of bank
 */
export function handlerGetBanks() {
  try {
    return [
      { code: '001', name: ' BANCO DO BRASIL S.A (BB)' },
      { code: 237, name: ' BRADESCO S.A' },
      { code: 335, name: ' Banco Digio S.A' },
      { code: 260, name: ' NU PAGAMENTOS S.A' },
      { code: 290, name: ' Pagseguro Internet S.A' },
      { code: 380, name: ' PicPay Servicos S.A.' },
      { code: 323, name: ' Mercado Pago - conta do Mercado Livre' },
      { code: 237, name: ' NEXT BANK (UTILIZAR O MESMO CÓDIGO DO BRADESCO)' },
      { code: 637, name: ' BANCO SOFISA S.A (SOFISA DIRETO)' },
      { code: '077', name: ' BANCO INTER S.A' },
      { code: 341, name: ' ITAÚ UNIBANCO S.A' },
      { code: 104, name: ' CAIXA ECONÔMICA FEDERAL (CEF)' },
      { code: '033', name: ' BANCO SANTANDER BRASIL S.A' },
      { code: 212, name: ' BANCO ORIGINAL S.A' },
      { code: 756, name: ' BANCOOB (BANCO COOPERATIVO DO BRASIL)' },
      { code: 655, name: ' BANCO VOTORANTIM S.A' },
      { code: 655, name: ' NEON PAGAMENTOS S.A (OS MESMOS DADOS DO BANCO VOTORANTIM)' },

      { code: '041', name: ' BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A' },
      { code: 389, name: ' BANCO MERCANTIL DO BRASIL S.A' },
      { code: 422, name: ' BANCO SAFRA S.A' },
      { code: '070', name: ' BANCO DE BRASÍLIA (BRB)' },
      { code: 136, name: ' UNICRED COOPERATIVA' },
      { code: 741, name: ' BANCO RIBEIRÃO PRETO' },
      { code: 739, name: ' BANCO CETELEM S.A' },
      { code: 743, name: ' BANCO SEMEAR S.A' },
      { code: 100, name: ' PLANNER CORRETORA DE VALORES S.A' },
      { code: '096', name: ' BANCO B3 S.A' },
      { code: 747, name: ' Banco RABOBANK INTERNACIONAL DO BRASIL S.A' },
      { code: 748, name: ' SICREDI S.A' },
      { code: 752, name: ' BNP PARIBAS BRASIL S.A' },
      { code: '091', name: ' UNICRED CENTRAL RS' },
      { code: 399, name: ' KIRTON BANK' },
      { code: 108, name: ' PORTOCRED S.A' },
      { code: 757, name: ' BANCO KEB HANA DO BRASIL S.A' },
      { code: 102, name: ' XP INVESTIMENTOS S.A' },
      { code: 348, name: ' BANCO XP S/A' },
      { code: 340, name: ' SUPER PAGAMENTOS S/A' },
      { code: 364, name: ' GERENCIANET PAGAMENTOS DO BRASIL' },
      { code: '084', name: ' UNIPRIME NORTE DO PARANÁ' },
      { code: 180, name: ' CM CAPITAL MARKETS CCTVM LTDA' },
      { code: '066', name: ' BANCO MORGAN STANLEY S.A' },
      { code: '015', name: ' UBS BRASIL CCTVM S.A' },
      { code: 143, name: ' TREVISO CC S.A' },
      { code: '062', name: ' HIPERCARD BM S.A' },
      { code: '074', name: ' BCO. J.SAFRA S.A' },
      { code: '099', name: ' UNIPRIME CENTRAL CCC LTDA' },
      { code: '025', name: ' BANCO ALFA S.A.' },
      { code: '075', name: ' BCO ABN AMRO S.A' },
      { code: '040', name: ' BANCO CARGILL S.A' },
      { code: 190, name: ' SERVICOOP' },
      { code: '063', name: ' BANCO BRADESCARD' },
      { code: 191, name: ' NOVA FUTURA CTVM LTDA' },
      { code: '064', name: ' GOLDMAN SACHS DO BRASIL BM S.A' },
      { code: '097', name: ' CCC NOROESTE BRASILEIRO LTDA' },
      { code: '016', name: ' CCM DESP TRÂNS SC E RS' },
      { code: '012', name: ' BANCO INBURSA' },
      { code: '003', name: ' BANCO DA AMAZONIA S.A' },
      { code: '060', name: ' CONFIDENCE CC S.A' },
      { code: '037', name: ' BANCO DO ESTADO DO PARÁ S.A' },
      { code: 159, name: ' CASA CREDITO S.A' },
      { code: 172, name: ' ALBATROSS CCV S.A' },
      { code: '085', name: ' COOP CENTRAL AILOS' },
      { code: 114, name: ' CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO' },

      { code: '036', name: ' BANCO BBI S.A' },
      { code: 394, name: ' BANCO BRADESCO FINANCIAMENTOS S.A' },
      { code: '004', name: ' BANCO DO NORDESTE DO BRASIL S.A.' },
      { code: 320, name: ' BANCO CCB BRASIL S.A' },
      { code: 189, name: ' HS FINANCEIRA' },
      { code: 105, name: ' LECCA CFI S.A' },
      { code: '076', name: ' BANCO KDB BRASIL S.A.' },
      { code: '082', name: ' BANCO TOPÁZIO S.A' },
      { code: 286, name: ' CCR DE OURO' },
      { code: '093', name: ' PÓLOCRED SCMEPP LTDA' },
      { code: 273, name: ' CCR DE SÃO MIGUEL DO OESTE' },
      { code: 157, name: ' ICAP DO BRASIL CTVM LTDA' },
      { code: 183, name: ' SOCRED S.A' },
      { code: '014', name: ' NATIXIS BRASIL S.A' },
      { code: 130, name: ' CARUANA SCFI' },
      { code: 127, name: ' CODEPE CVC S.A' },
      { code: '079', name: ' BANCO ORIGINAL DO AGRONEGÓCIO S.A' },
      { code: '081', name: ' BBN BANCO BRASILEIRO DE NEGOCIOS S.A' },
      { code: 118, name: ' STANDARD CHARTERED BI S.A' },
      { code: 133, name: ' CRESOL CONFEDERAÇÃO' },
      { code: 121, name: ' BANCO AGIBANK S.A' },
      { code: '083', name: ' BANCO DA CHINA BRASIL S.A' },
      { code: 138, name: ' GET MONEY CC LTDA' },
      { code: '024', name: ' BCO BANDEPE S.A' },
      { code: '095', name: ' BANCO CONFIDENCE DE CÂMBIO S.A' },
      { code: '094', name: ' BANCO FINAXIS' },
      { code: 276, name: ' SENFF S.A' },
      { code: 137, name: ' MULTIMONEY CC LTDA' },
      { code: '092', name: ' BRK S.A' },
      { code: '047', name: ' BANCO BCO DO ESTADO DE SERGIPE S.A' },
      { code: 144, name: ' BEXS BANCO DE CAMBIO S.A.' },
      { code: 126, name: ' BR PARTNERS BI' },
      { code: 301, name: ' BPP INSTITUIÇÃO DE PAGAMENTOS S.A' },
      { code: 173, name: ' BRL TRUST DTVM SA' },
      { code: 119, name: ' BANCO WESTERN UNION' },
      { code: 254, name: ' PARANA BANCO S.A' },
      { code: 268, name: ' BARIGUI CH' },
      { code: 107, name: ' BANCO BOCOM BBM S.A' },
      { code: 412, name: ' BANCO CAPITAL S.A' },
      { code: 124, name: ' BANCO WOORI BANK DO BRASIL S.A' },
      { code: 149, name: ' FACTA S.A. CFI' },
      { code: 197, name: ' STONE PAGAMENTOS S.A' },
      { code: 142, name: ' BROKER BRASIL CC LTDA' },
      { code: 389, name: ' BANCO MERCANTIL DO BRASIL S.A.' },
      { code: 184, name: ' BANCO ITAÚ BBA S.A' },
      { code: 634, name: ' BANCO TRIANGULO S.A (BANCO TRIÂNGULO)' },
      { code: 545, name: ' SENSO CCVM S.A' },
      { code: 132, name: ' ICBC DO BRASIL BM S.A' },
      { code: 298, name: ' VIPS CC LTDA' },
      { code: 129, name: ' UBS BRASIL BI S.A' },
      { code: 128, name: ' MS BANK S.A BANCO DE CÂMBIO' },
      { code: 194, name: ' PARMETAL DTVM LTDA' },
      { code: 310, name: ' VORTX DTVM LTDA' },
      { code: 163, name: ' COMMERZBANK BRASIL S.A BANCO MÚLTIPLO' },
      { code: 280, name: ' AVISTA S.A' },
      { code: 146, name: ' GUITTA CC LTDA' },
      { code: 279, name: ' CCR DE PRIMAVERA DO LESTE' },
      { code: 182, name: ' DACASA FINANCEIRA S/A' },
      { code: 278, name: ' GENIAL INVESTIMENTOS CVM S.A' },
      { code: 271, name: ' IB CCTVM LTDA' },
      { code: '021', name: ' BANCO BANESTES S.A' },
      { code: 246, name: ' BANCO ABC BRASIL S.A' },
      { code: 751, name: ' SCOTIABANK BRASIL' },
      { code: 208, name: ' BANCO BTG PACTUAL S.A' },
      { code: 746, name: ' BANCO MODAL S.A' },
      { code: 241, name: ' BANCO CLASSICO S.A' },
      { code: 612, name: ' BANCO GUANABARA S.A' },
      { code: 604, name: ' BANCO INDUSTRIAL DO BRASIL S.A' },
      { code: 505, name: ' BANCO CREDIT SUISSE (BRL) S.A' },
      { code: 196, name: ' BANCO FAIR CC S.A' },
      { code: 300, name: ' BANCO LA NACION ARGENTINA' },
      { code: 477, name: ' CITIBANK N.A' },
      { code: 266, name: ' BANCO CEDULA S.A' },
      { code: 122, name: ' BANCO BRADESCO BERJ S.A' },
      { code: 376, name: ' BANCO J.P. MORGAN S.A' },
      { code: 473, name: ' BANCO CAIXA GERAL BRASIL S.A' },
      { code: 745, name: ' BANCO CITIBANK S.A' },
      { code: 120, name: ' BANCO RODOBENS S.A' },
      { code: 265, name: ' BANCO FATOR S.A' },
      { code: '007', name: ' BNDES (Banco Nacional do Desenvolvimento Social)' },
      { code: 188, name: ' ATIVA S.A INVESTIMENTOS' },
      { code: 134, name: ' BGC LIQUIDEZ DTVM LTDA' },
      { code: 641, name: ' BANCO ALVORADA S.A' },
      { code: '029', name: ' BANCO ITAÚ CONSIGNADO S.A' },
      { code: 243, name: ' BANCO MÁXIMA S.A' },
      { code: '078', name: ' HAITONG BI DO BRASIL S.A' },
      { code: 111, name: ' BANCO OLIVEIRA TRUST DTVM S.A' },
      { code: '017', name: ' BNY MELLON BANCO S.A' },
      { code: 174, name: ' PERNAMBUCANAS FINANC S.A' },
      { code: 495, name: ' LA PROVINCIA BUENOS AIRES BANCO' },
      { code: 125, name: ' BRASIL PLURAL S.A BANCO' },
      { code: 488, name: ' JPMORGAN CHASE BANK' },
      { code: '065', name: ' BANCO ANDBANK S.A' },
      { code: 492, name: ' ING BANK N.V' },
      { code: 250, name: ' BANCO BCV' },
      { code: 145, name: ' LEVYCAM CCV LTDA' },
      { code: 494, name: ' BANCO REP ORIENTAL URUGUAY' },
      { code: 253, name: ' BEXS CC S.A' },
      { code: 269, name: ' HSBC BANCO DE INVESTIMENTO' },
      { code: 213, name: ' BCO ARBI S.A' },
      { code: 139, name: ' INTESA SANPAOLO BRASIL S.A' },
      { code: '018', name: ' BANCO TRICURY S.A' },
      { code: 630, name: ' BANCO INTERCAP S.A' },
      { code: 224, name: ' BANCO FIBRA S.A' },
      { code: 600, name: ' BANCO LUSO BRASILEIRO S.A' },
      { code: 623, name: ' BANCO PAN' },
      { code: 204, name: ' BANCO BRADESCO CARTOES S.A' },
      { code: 479, name: ' BANCO ITAUBANK S.A' },
      { code: 456, name: ' BANCO MUFG BRASIL S.A' },
      { code: 464, name: ' BANCO SUMITOMO MITSUI BRASIL S.A' },
      { code: 613, name: ' OMNI BANCO S.A' },
      { code: 652, name: ' ITAÚ UNIBANCO HOLDING BM S.A' },
      { code: 653, name: ' BANCO INDUSVAL S.A' },
      { code: '069', name: ' BANCO CREFISA S.A' },
      { code: 370, name: ' BANCO MIZUHO S.A' },
      { code: 249, name: ' BANCO INVESTCRED UNIBANCO S.A' },
      { code: 318, name: ' BANCO BMG S.A' },
      { code: 626, name: ' BANCO FICSA S.A' },
      { code: 270, name: ' SAGITUR CC LTDA' },
      { code: 366, name: ' BANCO SOCIETE GENERALE BRASIL' },
      { code: 113, name: ' MAGLIANO S.A' },
      { code: 131, name: ' TULLETT PREBON BRASIL CVC LTDA' },
      { code: '011', name: ' C.SUISSE HEDGING-GRIFFO CV S.A' },
      { code: 611, name: ' BANCO PAULISTA' },
      { code: 755, name: ' BOFA MERRILL LYNCH BM S.A' },
      { code: '089', name: ' CCR REG MOGIANA' },
      { code: 643, name: ' BANCO PINE S.A' },
      { code: 140, name: ' EASYNVEST - TÍTULO CV S.A' },
      { code: 707, name: ' BANCO DAYCOVAL S.A' },
      { code: 288, name: ' CAROL DTVM LTDA' },
      { code: 101, name: ' RENASCENCA DTVM LTDA' },
      { code: 487, name: ' DEUTSCHE BANK S.A BANCO ALEMÃO' },
      { code: 233, name: ' BANCO CIFRA' },
      { code: 177, name: ' GUIDE' },
      { code: 633, name: ' BANCO RENDIMENTO S.A' },
      { code: 218, name: ' BANCO BS2 S.A' },
      { code: 292, name: ' BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS' },
      { code: 169, name: ' BANCO OLÉ BONSUCESSO CONSIGNADO S.A' },
      { code: 293, name: ' LASTRO RDV DTVM LTDA' },
      { code: 285, name: ' FRENTE CC LTDA' },
      { code: '080', name: ' B&T CC LTDA' },
      { code: 753, name: ' NOVO BANCO CONTINENTAL S.A BM' },
      { code: 222, name: ' BANCO CRÉDIT AGRICOLE BR S.A' },
      { code: 754, name: ' BANCO SISTEMA' },
      { code: '098', name: ' CREDIALIANÇA CCR' },
      { code: 610, name: ' BANCO VR S.A' },
      { code: 712, name: ' BANCO OURINVEST S.A' },
      { code: '010', name: ' CREDICOAMO' },
      { code: 283, name: ' RB CAPITAL INVESTIMENTOS DTVM LTDA' },
      { code: 217, name: ' BANCO JOHN DEERE S.A' },
      { code: 117, name: ' ADVANCED CC LTDA' },
      { code: 336, name: ' BANCO C6 S.A - C6 BANK' },
      { code: 654, name: ' BANCO DIGIMAIS S.A' },
    ]
  } catch (error) {
    // do anything
  }
}

/**
 *  Return an list of account bank types, with your details
 * @returns An arra of bank
 */
export function handlerGetAccountBankTypes() {
  try {
    return [
      {
        code: "CC",
        name: "Conta Corrente"
      },
      {
        code: "CP",
        name: "Conta Poupança"
      },
      {
        code: "CS",
        name: "Conta Salário"
      }
    ]
  } catch (error) {
    // do anything
  }
}

function handlerClearIDTimeOut(idTimeOut) {
  if (idTimeOut || idTimeOut > 0) {
    clearTimeout(idTimeOut)
  }
}

function handlerSetIDTimeOut(id) {
  if (typeof id === 'undefined') {
    return;
  }

  sessionStorage.setItem("KROOMA_DEBOUNCE_TIMEOUT_ID", String(id).trim());
}

function handlerGetIDTimeOut() {
  return Number(sessionStorage.getItem("KROOMA_DEBOUNCE_TIMEOUT_ID"));
}



