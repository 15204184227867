<template>
  <div>
    <div
      id="action_container"
      v-if="selectedItems.length"
      class="w-100 d-flex align-items-center justify-content-end p-2"
    >
      <button
        :disabled="isDeleting"
        class="btn btn-warning mr-3"
        v-show="selectedItems.filter((i) => i.active == 1).length"
        @click="unablePost(false)"
      >
        Desativar
      </button>

      <button
        :disabled="isDeleting"
        class="btn btn-warning slide-in-blurred-top"
        v-show="
          selectedItems.filter((i) => i.active == 0 || i.active == 2).length
        "
        @click="unablePost(true)"
      >
        Ativar
      </button>
      <LoaderButton
        b_class="btn-danger slide-in-blurred-top ml-3"
        :wasClicked="isDeleting"
      >
        <button
          @click="
            deleteSelectedPosts();
            isDeleting = true;
          "
          class="btn btn-danger"
          v-if="selectedItems.length"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </LoaderButton>
    </div>

    <table class="table" v-if="data && data.length > 0">
      <thead>
        <tr>
          <th>
            <label>
              <input
                type="checkbox"
                :checked="selectAll"
                @click="selectAllItems()"
              />
            </label>
          </th>
          <th>Imagem</th>
          <th>Título</th>
          <th>Resumo</th>
          <th>Autor</th>
          <th>Criado em</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in data"
          :key="item.uuid"
          class="pointer hover"
          :class="[{ 'soft-shadow ': item.id == post.id }]"
        >
          <td>
            <label>
              <input
                type="checkbox"
                :checked="isSelected(item)"
                @click="selecteItem(item)"
              />
            </label>
          </td>
          <td>
           <img loading="lazy"
              width="150"
              height="100"
              :src="$filterMedia(item, 'header', true)"
              alt=""
              style="max-width: 100%; height: auto"
            />
          </td>
          <td
            scope="row"
            :title="item.title"
            @click="viewInfo(item)"
            @dblclick="open()"
          >
            {{ item.title.substr(0, 30) }}...
          </td>
          <td @click="viewInfo(item)" @dblclick="open()">
            {{ item.summary ? item.summary.substr(0, 30) : "-" }}
          </td>
          <td @click="viewInfo(item)" @dblclick="open()">
            {{ item.user ? item.user.name : "-" }}
          </td>
          <td>{{ $date(item.created_at) }}</td>

          <td class="text-center">
            <i
              class="fas fa-thumbtack mr-3"
              v-if="item.highlight == 1"
              title="Destaque"
            ></i>
            <i
              class="fas fa-thumbtack mr-3"
              v-if="item.highlight == 0"
              style="visibility: hidden"
            ></i>
            <i
              class="fas fa-circle text-success"
              v-show="item.active == 1"
              title="Ativado"
            ></i>
            <i
              class="fas fa-circle text-warning"
              v-show="item.active == 2"
              title="Rascunho"
            ></i>
            <i
              class="far fa-dot-circle text-danger"
              v-show="!item.active"
              title="Desativado"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
     <div class="col-9 mt-5" v-else>
      <h3>Nenhum post disponível</h3>
    </div>
    <!-- <div class="mt-2" v-if="data && data.length > 0">
      <Pagination
        v-model="postsPaginate"
        eventName="posts_loaded"
        currentPageName="posts"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";

// =========================Pagination Component=============================
import Pagination from "@/components/navigation/Paginator/Paginator";
//If Pagination component above stops working use this below
// import Pagination from "@/components/navigation/Paginator";

export default {
  data() {
    return {
      selectAll: false,
      selectedItems: [],
      isDeleting: false,
      loadingIDs: [],
    };
  },
  components: {
    LoaderButton,
    Pagination,
  },
  props: {
    data: {
      required: true,
    },
  },
  watch: {
    postsPaginate(val) {
      if (val.data.length) {
        // this.$event.$emit('posts_loaded')
      }
    },
  },
  computed: {
    ...mapGetters(["posts", "post", "postsPaginate", "users"]),
  },
  mounted() {
    this.$event.$emit("posts_loaded");
    this.LOAD_POSTS()
    //console.log("mounted posts page");
  },
  methods: {
    ...mapActions([
      "LOAD_POSTS",
      "VIEW_POST",
      "BULK_UPDATE_POSTS",
      "BULK_DELETE_POSTS",
      "FETCH_USER",
    ]),
    isLoadingUser(id) {
      return this.loadingIDs.some((loadingID) => loadingID == id);
    },
    getUser(id) {
      let user = this.users.find((user) => user.id === id);

      if (!user) {
        if (!this.isLoadingUser(id)) {
          this.loadingIDs.push(id);
          this.FETCH_USER(id).then((r) => {
            if (r?.status === 200) {
              return r.data;
            }
          });
        }

        return { name: "Carregando ..." };
      }
      return user;
    },
    isSelected(post) {
      return this.selectedItems.some((item) => {
        return item.id == post.id;
      });
    },

    selecteItem(post) {
      let isSelected = this.selectedItems.findIndex((item) => {
        return item.uuid === post.uuid;
      });
      if (isSelected < 0) {
        this.selectedItems.push(post);
      } else {
        this.selectedItems.splice(isSelected, 1);
      }
    },
    selectAllItems() {
      if (!this.selectAll) {
        this.selectedItems = [...this.data];
        this.selectAll = true;
      } else if (this.selectAll) {
        this.selectedItems = [];
        this.selectAll = false;
      }
    },

    unablePost(activate = false) {
      this.$toast.info(" ", "Atualizando", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      let count = this.selectedItems.length;
      if (this.selectedItems.length) {
        console.log("arr", this.selectedItems);
        this.selectedItems.forEach((item, index) => {
          this.selectedItems[index].active = activate ? 1 : 0;
        });

        this.BULK_UPDATE_POSTS(this.selectedItems).then((response) => {
          this.selectedItems = [];
          this.isDeleting = false;
          if (response.status == 200) {
            this.$toast.info(" ", `${count} itens atualizados`, {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          }
        });
        // console.log()
      }
      let element = document.getElementById("action_container");

      setTimeout(() => {
        element.classList.add("scale-out-center");
      }, 500);
      setTimeout(() => {
        this.selectedItems = [];
      }, 1000);
    },
    deleteSelectedPosts() {
      this.$toast.info(" ", `Removendo ...`, {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      this.BULK_DELETE_POSTS(this.selectedItems).then((response) => {
        this.selectedItems = [];
        if (response.status == 200) {
          this.$toast.success(" ", `Removido`, {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.isDeleting = false;
        }
      });
    },
    viewInfo(post) {
      this.VIEW_POST(post);
    },
    open() {
     // console.log("openning post");
      let temp = this.post;
      temp.video = { path: "" };
      this.VIEW_POST(temp);
      this.$router.push({ path: `/posts/details/${temp.uuid}` });
    },
    _filterMedia(data, type) {
      let medias = data.media || [];

      medias = medias.filter((media) => media.type === type);

      if (medias.length) {
        let maped = medias.map((m) => m.path);
        return this.$api + maped[0];
      } else {
        return "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";
      }
    },
  },
};
</script>

<style></style>
