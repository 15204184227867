var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BreadCrumb',{attrs:{"pageInfo":!_vm.displayBlingProducts
        ? _vm.products
          ? _vm.products.length + ' produtos'
          : ''
        : _vm.blingProducts && _vm.blingProducts.length + ' produtos listados na Bling',"page":!_vm.displayBlingProducts ? 'Produtos' : 'Produtos do Bling'}},[_c('div',{attrs:{"slot":"right-side"},slot:"right-side"},[_c('button',{staticClass:"btn btn-success mr-4",on:{"click":function($event){return _vm.loadBlingProducts()}}},[(_vm.displayBlingProducts)?_c('span',[_vm._v("Voltar")]):(
            _vm.storeRules &&
            _vm.storeRules.hasBling &&
            _vm.storeRules.hasBling.inSaleRight
          )?_c('span',[_vm._v("Produtos Bling")]):_c('span',[_vm._v("Voltar")])]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'product.create' })}}},[_vm._v(" Novo ")])])]),_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col",attrs:{"id":"prod-report-table-col"}},[_c('LatestSoldProducts')],1),_c('ProductReports',{directives:[{name:"can",rawName:"v-can",value:(['product-report']),expression:"['product-report']"}]})],1),_vm._m(0),_c('div',{staticClass:"mb-2 mt-3"},[(!_vm.displayBlingProducts)?_c('small',{staticClass:"alert alert-info"},[_vm._v(" Dê dois cliques para abrir os detalhes do produto. ")]):_vm._e()]),(!_vm.productsFilterCore || _vm.productsFilterCore.length <= 0)?_c('div',{staticClass:"col-9 mt-5"},[_c('h3',[_vm._v("Nenhum produto disponível")])]):_vm._e(),_c('div',{staticClass:"row",attrs:{"id":"responsive-page-container"}},[(_vm.productsFilterCore && _vm.productsFilterCore.length > 0)?_c('div',{staticClass:"col-9 product-wrapper table-report",attrs:{"id":"responsive-page-table"}},[_c('transition-group',{attrs:{"name":"slide-in","mode":"out-in"}},[(
            !_vm.displayBlingProducts &&
            _vm.productsFilterCore &&
            _vm.productsFilterCore.length > 0
          )?_c('ProductList',{key:"products_list",attrs:{"list":_vm.productsFilterCore}}):_c('BlingProductList',{key:"bling_products_list",ref:"bling_list",model:{value:(_vm.searchInBling),callback:function ($$v) {_vm.searchInBling=$$v},expression:"searchInBling"}})],1)],1):_vm._e(),_c('div',{staticClass:"col-3 product-wrapper",attrs:{"id":"responsive-filter-container"}},[_c('transition-group',{attrs:{"name":"slide-in","mode":"out-in"}},[_c('MasterFilter',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$masterHelper.isEmpty(_vm.product) &&
            !_vm.displayBlingProducts
          ),expression:"\n            $masterHelper.isEmpty(product) &&\n            !displayBlingProducts\n          "}],key:"info",attrs:{"hideInputs":['roles', 'status', 'users'],"page":"Products","rules":{
  searchable: [
    'name',
    'description',
    'short_description',
    'summary',
  ],
  relationships: [
    'tags',
    'categories',
    'media',
    'user',
  ],
}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.$masterHelper.isEmpty(_vm.product) &&
            !_vm.displayBlingProducts
          ),expression:"\n            !$masterHelper.isEmpty(product) &&\n            !displayBlingProducts\n          "}],key:"edition"},[_c('ProductInfo')],1),(_vm.displayBlingProducts)?_c('div',{key:"bling_options",staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Bling")]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Pesquisa")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInBling.query),expression:"searchInBling.query"}],staticClass:"form-control",attrs:{"type":"text","name":"","id":"","aria-describedby":"helpId","placeholder":""},domProps:{"value":(_vm.searchInBling.query)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.searchInBling, "query", $event.target.value)}}}),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"helpId"}},[_vm._v("Pesquise por id, código, marca e descrição.")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Situação")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInBling.situation),expression:"searchInBling.situation"}],staticClass:"form-control",attrs:{"id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.searchInBling, "situation", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Ativo"}},[_vm._v("Ativo")]),_c('option',{attrs:{"value":"Desativado"}},[_vm._v(" Desativados ")])])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$refs.bling_list ? _vm.$refs.bling_list .selectedProducts.length : 0)+" itens selecionados")])]),_c('LoaderButton',{attrs:{"b_class":" btn-primary ","wasClicked":_vm.$refs.bling_list
                    ? _vm.$refs.bling_list.isImporting
                    : false}},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"disabled":_vm.$refs.bling_list &&
                    !_vm.$refs.bling_list.selectedProducts
                      .length},on:{"click":function($event){return _vm.$refs.bling_list.startImport()}}},[_vm._v(" Importar ")])])],1)])]):_vm._e()],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex mt-2"},[_c('h2',{staticClass:"text-dark mb-3 dash-session-title"},[_vm._v("Todos os produtos")]),_c('hr',{staticClass:"w-100"})])
}]

export { render, staticRenderFns }