<template>
  <div class="row" v-if="order && order.payments">
    <div class="col">
      <div class="mt-3">
        <strong for="">Tipo pagamento:</strong>
        <p>
          {{ $masterHelper.typeOfPayments(order.payments.content) }}
        </p>
      </div>
      <div class="mt-3">
        <strong for="">Pago em:</strong>
        <p v-if="order.payments && order.payments.content.payment">
          <span v-if="order.payments.content.payment.receivedDate">
            {{ new Date(order.payments.content.payment.receivedDate).toLocaleString() }}
          </span>
          <span v-else-if="order.payments.created_at">
            {{ new Date(order.payments.created_at).toLocaleString() }}
          </span>
          <span v-else>
            Indefinido
          </span>
        </p>
        <p v-if="order.payments && order.payments.content.payment">
          {{ order.payments.content.payment.returnMessage || order.payments.gateway_status || 'indefinido' }}
        </p>

        <p v-if="!order.payments && !order.payments.content.payment">
          Informação indisponivel no momento
        </p>
      </div>
      <div class="mt-3">
        <strong for="">Provedor de Pagamento:</strong>
        <p v-if="order.payments && order.payments.content.payment">
          {{ order.payments.content.payment.provider || order.payments.content.provider || 'indefinido' }}
        </p>
        <p v-if="!order.payments || !order.payments.content.payment">
          Informação indisponivel no momento
        </p>
      </div>
      <div class="mt-3">
        <strong for="">Indentificação do pagamento:</strong>
        <p v-if="order.payments">
          ID {{ order.payments.uuid || 'indefinido' }}
        </p>
        <p v-if="order.payments">
          TID {{ order.payments.transactionID || order.payments.content.payment['tid'] ||
              order.payments.content.transactionID || 'indefinido'
          }}
        </p>
        <p v-if="!order.payments || !order.payments.content.payment">
          Informação indisponivel no momento
        </p>
      </div>
      <div class="mt-3">
        <strong for="">Condição:</strong>
        <p v-if="order.payments.installments === 1">A vista</p>
        <p v-else>Parcelado em {{ order.payments.installments }} vezes</p>
      </div>
      <div class="mt-3">
        <!-- //Caso houver outra forma de pagamento onde o admin consiga mudar o status -->
        <!-- <div v-if="!chaningStatus">
          <div>
            <strong for="">Status</strong>
            <span v-role="'admin'">
              <i
                @click="changeStatus()"
                class="fas fa-pencil-alt ml-4 pointer"
                title="Mudar status"
              ></i>
            </span>
          </div>
          <div v-if="order.payments === null">
            <span class="badge badge-warning ">Pagamento não realizado</span>
          </div>
          <StatusBadge v-else :status="order.payments.status" />
        </div> -->
        <!-- <div class="form-group" v-if="chaningStatus"> -->

        <!-- <label for="">Atualize o status</label> -->
        <!-- <select
            class="form-control"
            v-model="order.payments.status"
            @change.prevent="changed()"
          >
            <option :value="1">Processando pagamento</option>
            <option :value="2">Pago</option>
            <option :value="3">Recusado</option>
          </select> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="col">
      <div class="mt-3">
        <strong for="">Final do cartão:</strong>
        <p>
          {{ parseCardNumber(order.payments.content.card.number) }}
        </p>
      </div>
      <div class="mt-3">
        <strong for="">Endereço de cobrança:</strong>
        <p>
          {{ order.payments.address.street }} nº
          {{ order.payments.address.number }} /
          {{ order.payments.address.city }} - {{ order.payments.address.state }}
        </p>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col text-center align-center">
      <p>--- <strong>Pagamento não registrado</strong> ---</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import StatusBadge from "@/components/status/Index";
import StatusSelect from "@/components/status/StatusSelect";
export default {
  data() {
    return {
      chaningStatus: false,
      admin: "admin", // para as permissões. Verificar array para passar mais de uma
    };
  },
  components: {
    StatusBadge,
    StatusSelect,
  },
  mounted() {
    console.log(this.order?.payments, this.order?.payments?.content?.payment)
  },
  props: ["order"],
  methods: {
    ...mapActions(["UPDATE_ORDER"]),
    changeStatus() {
      this.chaningStatus = true;
    },
    changed() {
      this.UPDATE_ORDER(this.order);
      this.chaningStatus = false;
    },
    parseCardNumber(card_number) {
      let num = card_number?.replace(/\D/g, "");
      // console.log("parseCardNumber", {
      //   num: num,
      //   type: typeof num,
      //   length: num?.length || 0,
      // });
      num = num?.slice(-4);
      //console.log("parseCardNumber num splice", num);
      return `**** **** **** ${num}`;
    },
  },
};
</script>

<style>
</style>
