<template>
  <ul class="list-group">
    <li
      class="list-group-item"
      v-for="(gateway, index) in payments"
      :key="index"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
         <img loading="lazy" height="30" :src="gateway.logo" alt="" class="mr-2" />
          <strong>{{ gateway.label }}</strong>
        </div>
        <div class="d-flex">
          <strong
            v-if="
              isSaving &&
              !isDeleting &&
              selectedProvider &&
              selectedProvider.name === gateway.name
            "
            class="text-primary"
            >Ativando ...</strong
          >
          <strong
            class="text-warning"
            v-if="isDeleting && deletingObj && deletingObj.name == gateway.name"
            >Removendo ...</strong
          >
          <strong v-if="!isSaving && !isDeleting">
            <span v-if="isSetted(gateway.name)"
              >Status - <span class="text-success">Ativo</span>
            </span>
            <span class="text-muted" v-else>
              <span>Não configurado</span>
              <span
                class="text-primary ml-3 pointer"
                @click="addGateWay(gateway)"
                >Adicionar</span
              >
            </span>
          </strong>
          <div
            class="ml-3"
            v-if="isSetted(gateway.name) && !isSaving && !isDeleting"
          >
            <!-- <span class="pointer"><i class="fas fa-power-off"></i></span> -->
            <span class="pointer" @click="edit(gateway)"
              ><i class="fas fa-pencil-alt ml-4"></i
            ></span>
            <span class="pointer ml-4" @click="_delete(gateway)"
              ><i class="far fa-trash-alt"></i
            ></span>
          </div>
        </div>
      </div>
      <div
        class="container alert alert-warning mt-2"
        v-if="
          selectedProvider &&
          selectedProvider.name === gateway.name &&
          isAdding &&
          !isSaving &&
          !isDeleting
        "
      >
        <div class="d-flex flex-column" :id="`provider_${selectedProvider.name}`">
          <div
            class="form-group"
            v-for="(input, index) in selectedProvider.inputs"
            :key="index"
          >
            <div v-if="!input.name">
              <label :for="'input_ ' + input">{{ input }}</label>
              <input
                class="form-control"
                type="text"
                :id="'input_ ' + input"
                :name="input"
                v-model="payment_provider[input]"
              />
            </div>
            <div v-else class="d-flex flex-row flex-nowrap align-items-center" style="gap:1rem">
              <label :for="'input_ ' + input.name" style=" margin:0 !important">{{ input.name }}</label>
              <input
                class="form-control"
                style="width: 1rem; height: 1rem; margin:0 !important"
                :type="input.type"
                :id="'input_ ' + input.nameID"
                :checked="input.defaultValue || false"
                :name="input.nameID"
                :value="input.value"
                v-model="payment_provider[input.nameID]"
              />
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button
              class="btn btn-outline-danger"
              @click="selectedProvider = null"
            >
              Cancelar
            </button>
            <button class="btn btn-primary ml-2" @click="save()">Salvar</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isDeleting: false,
      isSaving: false,
      isAdding: false,
      isEditing: false,
      payment_provider: {},
      selectedProvider: null,
      deletingObj: null,
      UPDATE: 0,
      // LISTA DE PAGAMENTOS ACEITOS. vÃO SER VALIDADOS/INSERIDOS COM O QUE VEM DO BANCO
      payments: [
        {
          name: "sentry",
          label: "Sentry",
          inputs: [
              "DSN",
              {name: "Para Produção", type: "radio", nameID:"environment", value: "production", defaultValue: false},
              {name: "Em Desenvolvimento", type: "radio", nameID:"environment", value: "development", defaultValue: false},
              {name: "Em Teste", type: "radio", nameID:"environment", value: "testing", defaultValue: false},
              {name: "Estagiando", type: "radio", nameID:"environment", value: "staging", defaultValue: false},
            ],
          logo: "",
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["settings"]),
    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "analytics_provider";
        }) || false
      );
    },
    myActualProvider() {
      //console.log('myActualProvider',this.settings.find(set => set.key === "analytics_provider"),this.settings)
      return Object.keys(this.settings.find(set => set.key === "analytics_provider")?.content || {})[0]
    }
  },
  methods: {
    ...mapActions([
      "LOAD_SETTINGS",
      "UPDATE_SETTINGS",
      "CREATE_SETTINGS",
      "DELETE_SETTINGS_PROVIDER",
    ]),
    isSetted(name) {
      //buscar se tem um payment_provider cadastrado
      if (!this.myGateways) return false;
      return this.myGateways.content.hasOwnProperty(name);
    },
    addGateWay(data) {
      this.isAdding = true;
      this.selectedProvider = data;
    },
    save(key = "analytics_provider") {

      try {
        this.isSaving = true;
        let data = {
          [this.selectedProvider.name]: {
            ...this.payment_provider,
            name: this.selectedProvider.name,
          },
        };

        this.handlerValidationForm(`provider_${this.selectedProvider?.name}`)

        this.CREATE_SETTINGS({
          content: data,
          key: key
        }).then((res) => {

          this.$pace.restart();
          this.UPDATE++;

          if (res.code === 201) {
            this.$toast.success(" ", "Concluido", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
            // this._isSetted(this.selectedProvider.name)
            // if(this.myActualProvider && this.myActualProvider !== this.selectedProvider?.name) {
            //   console.log('CREATE_SETTINGS',this.myActualProvider, this.selectedProvider?.name)
            //   this._delete({name: this.myActualProvider},true)
            // }
            this.UPDATE++;

            this.LOAD_SETTINGS() //window.location.reload()
          }else {
            this.$toast.error(" ", "Houve uma falha.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
          }
        });

        setTimeout(() => {
          this.isSaving = false;
          this.selectedProvider = null;
          this.UPDATE++;
        }, 1000);

        //console.log("saving", { content: data });
      }catch(error){
         this.$toast.info(" ", error?.message, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        this.isSaving = false;
      }
    },
    edit(prov) {
      this.isAdding = true;
      this.payment_provider = this.myGateways.content[prov.name];
      this.selectedProvider = prov;
      this.$forceUpdate();
    },
    _delete(prov,isOld = false) {
      this.isDeleting = true;
      this.deletingObj = prov;
      let data = this.myGateways.content;
      delete data[prov.name];
      //console.log("going to remove", data);
      this.DELETE_SETTINGS_PROVIDER({
        content: data,
        id: this.myGateways.id,
      }).then((res) => {
        if (res.code === 201) {
          this.$toast.success(" ", `Integração ${isOld ? 'anterior' : ''} ${prov.name} foi desativada`, {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        } else {
          this.$toast.error(" ", "Houve uma falha ao desativar.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
        this.isDeleting = false;
        this.deletingObj = null;
      });

      this.payment_provider = {};
      this.selectedProvider = null;
      this.isAdding = false;
    },
    handlerValidationForm(formID = ""){
      try {
        if(formID.trim() === "") return

        const inputsFromFormRaw = document.querySelectorAll(`#${formID} input,select,textarea`)

        if(!inputsFromFormRaw) return

        const inputsFromFormParsed = Array.from(inputsFromFormRaw)

        if(inputsFromFormParsed.length <= 0) return

        inputsFromFormParsed.forEach(inputElement => {
          if(inputElement?.type === "text") {

            if(inputElement?.value?.trim() === "" && inputElement?.required) throw new Error(`Preencha o campo ${inputElement?.name}`)

          }else if(inputElement?.type === "select") {

            if(!inputElement?.selectedOption) throw new Error(`Preencha o campo ${inputElement?.name}`)

          }else if(inputElement?.type === "radio" || inputElement?.type === "checkbox" ) {

            if(inputElement?.required) throw new Error(`Marque o campo ${inputElement?.name}`)

          }
        })
      } catch (error) {
        throw new Error(error?.message)
      }
    }
  },
};
</script>

<style></style>
