import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    variations: [],
    options: []
  },
  getters: {
    variations: state => state.variations,
    options: state => state.options
  },
  mutations: {
    LOAD_VARIATION(state, variations) {
      state.variations = variations;
    },
    CREATE_VARIATION(state, variation) {
      state.variations.push(variation);
    },
    UPDATE_VARIATION(state, variation) {
      let index = state.variations.findIndex(v => v.id == variation.id);
      state.variations[index] = variation;
    },
    DELETE_VARIATION(state, variation) {
      let index = state.variations.findIndex(v => v.id == variation.id);
      state.variations.splice(index, 1);
    },
    // ----------------------OPTIONS----------------------------
    LOAD_OPTIONS(state, options) {
      state.options = options;
    },
    CREATE_OPTIONS(state, options) {
      state.options.push(options);
    },
    UPDATE_OPTIONS(state, option) {
      let index = state.options.findIndex(o => o.id == option.id);
      state.options[index] = option;
    },
    DELETE_OPTIONS(state, option) {
      let index = state.options.findIndex(o => o.id == option.id);
      state.options.splice(index, 1);
    }
  },
  actions: {
    LOAD_VARIATION: async (context, payload) => {
      try {
        let response = await axios().get("variations");
        context.commit("LOAD_VARIATION", response.data.data);
        return response;
      } catch (error) {
       // console.log("LOAD_VARIATION:error", error);
        return error;
      }
    },
    CREATE_VARIATION: async (context, payload) => {
      try {
        let response = await axios().post("variations/store", payload);
        context.commit("CREATE_VARIATION", response.data.data);
        return response;
      } catch (error) {
        //console.log("CREATE_VARIATION:error", error);
        return error;
      }
    },
    UPDATE_VARIATION: async (context, payload) => {
      try {
        let response = await axios().put(
          `variations/update/${payload.id}`,
          payload
        );
        context.commit("UPDATE_VARIATION", response.data.data);
        return response;
      } catch (error) {
       // console.log("UPDATE_VARIATION:error", error);
        return error;
      }
    },
    DELETE_VARIATION: async (context, payload) => {
      try {
        let response = await axios().delete(
          `variations/delete/${payload.id}`,
          payload
        );
        if (!response.data.data.error) {
          context.commit("DELETE_VARIATION", payload);
        }

        return response;
      } catch (error) {
       // console.log("DELETE_VARIATION:error", error);
        return error;
      }
    },
    // ----------------------OPTIONS----------------------------

    LOAD_OPTIONS: async (context, payload) => {
      try {
        let response = await axios().get("variations_option");
        context.commit("LOAD_OPTIONS", response.data.data);
        return response;
      } catch (error) {
        //console.log("LOAD_OPTIONS:error", error);
        return error;
      }
    },
    CREATE_OPTIONS: async (context, payload) => {
      try {
        let response = await axios().post("variations_option/store", payload);
        context.commit("CREATE_OPTIONS", response.data.data);
        return response;
      } catch (error) {
        //console.log("CREATE_OPTIONS:error", error);
        return error;
      }
    },
    UPDATE_OPTIONS: async (context, payload) => {
      try {
        let response = await axios().put(
          `variations_option/update/${payload.id}`,
          payload
        );
        context.commit("UPDATE_OPTIONS", response.data.data);
        return response;
      } catch (error) {
       // console.log("UPDATE_OPTIONS:error", error);
        return error;
      }
    },
    DELETE_OPTIONS: async (context, payload) => {
      try {
        let response = await axios().delete(
          `variations_option/delete/${payload.id}`
        );
        if (!response.data.data.error) {
          context.commit("DELETE_OPTIONS", payload);
        }
        return response;
      } catch (error) {
        //console.log("DELETE_OPTIONS:error", error);
        return error;
      }
    }
  }
};
