<template>
  <div>
    <div class="form-row">
      <div class="col-15" style="width: 100%">
        <div style="width:100%; height:auto;">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <Upload
              :modelThumbnail="true"
              newStyle=""
              ref="thumbnailRef"
              position="header"
              :noFavorite="true"
              :height="200"
              btnText="Imagem do parceiro"
              @data_deleted="unRegisterThumbnail"
              @added_item="registerThumbnail"
              :limit="1"
            />
            <small class="mt-2">Dimensões: 300 x 300px</small>
          </div>
        </div>

        <div style="width: 100%;" class="mt-4">
          <strong for="">Nome</strong>
          <input
            :disabled="isSaving"
            id="name"
            name="name"
            type="text"
            class="form-control"
            v-model="tempPartner.partner.name"
            maxlength="21"
          />
        </div>

        <div class="mt-4">
          <strong for="">Descrição</strong>
          <textarea
            :disabled="isSaving"
            id="summary"
            name="summary"
            class="form-control"
            v-model="tempPartner.partner.summary"
            maxlength="90"
          />
        </div>

        <br />

        <div
          style="display: flex;flex-flow:row nowrap; align-items:center; gap: 5px"
        >
          <strong for="">Highlight</strong>
          <toggle-button
            :disabled="isSaving"
            v-model="tempPartner.partner.highlight"
            ref="toggleHighlight"
            class="ml-auto"
            :width="90"
            :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC'
            }"
            :labels="{ checked: 'Ativo', unchecked: '  Inativo' }"
            :sync="true"
          />
        </div>

        <br />

        <div
          style="display: flex;flex-flow:row nowrap; align-items:center; gap: 5px"
        >
          <strong for="">Ativo</strong>
          <toggle-button
            :disabled="isSaving"
            v-model="tempPartner.partner.active"
            ref="toggleActive"
            class="ml-auto"
            :width="90"
            :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC'
            }"
            :labels="{ checked: 'Ativo', unchecked: '  Inativo' }"
            :sync="true"
          />
        </div>

        <br />

        <div
          slot="right-side"
          style="display: flex;flex-flow:row nowrap; align-items:center; gap: 5px"
        >
          <button class="btn btn-primary btn-block" @click="savePartner()">
            Atualizar
          </button>
          <!-- <button
            class="btn btn-primary"
            style="flex:1 !important; width: inhreit !important; background-color:#458ee8 !important; border:1px solid #5a779b !important"
            @click="createPartner()"
          >
            Criar
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";

// Components
import Upload from "@/components/imageUpload/Upload";

export default {
  components: {
    Multiselect,
    Upload
  },
  data() {
    return {
      isSaving: false,
      thumbnail: null,
      tempPartner: {
        index: 0,
        partner: {
          active: false,
          highlight: false,
          name: "",
          summary: "",
          thumbnail: null
        }
      },
      basePartner: {
        index: 0,
        partner: {
          active: false,
          highlight: false,
          name: "",
          summary: "",
          thumbnail: null
        }
      }
    };
  },
  watch: {
    tempPartner: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.partner) {
          if (typeof val.partner.active === Number) {
            if (val.partner.active === 1) {
              this.tempPartner.partner.active = true;
            }
            if (val.partner.active === 0) {
              this.tempPartner.partner.active = false;
            }
            if (val.partner.highlight === 1) {
              this.tempPartner.partner.highlight = true;
            }
            if (val.partner.highlight === 0) {
              this.tempPartner.partner.highlight = false;
            }
          }
        }
      }
    },
    // partner: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     if (val.partner) {
    //       console.log(val.partner);
    //       if (
    //         val.partner &&
    //         val.partner.active !== null &&
    //         !isNaN(val.partner.active)
    //       ) {
    //         this.$refs.toggleActive.toggled =
    //           this.partner.partner.active === 1 ? true : false;
    //       }
    //       if (
    //         val.partner &&
    //         val.partner.highlight !== null &&
    //         !isNaN(val.partner.highlight)
    //       ) {
    //         this.$refs.toggleHighlight.toggled =
    //           this.partner.partner.highlight === 1 ? true : false;
    //       }
    //       this.tempPartner = JSON.parse(
    //         JSON.stringify(this.$store.getters.partner)
    //       );
    //     }
    //   }
    // },
    partner: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          val.partner &&
          val.partner.active !== null &&
          val.partner.active === 1
        ) {
          this.tempPartner.partner.active = true;
        }
        if (
          val.partner &&
          val.partner.highlight !== null &&
          val.partner.highlight === 1
        ) {
          this.tempPartner.partner.highlight = true;
        }

        if (
          val.partner &&
          val.partner.active !== null &&
          val.partner.active === 0
        ) {
          this.tempPartner.partner.active = false;
        }
        if (
          val.partner &&
          val.partner.highlight !== null &&
          val.partner.highlight === 0
        ) {
          this.tempPartner.partner.highlight = false;
        }
        if (val.partner.thumbnail) {
          //console.log("partner thumb", val.partner.thumbnail + ".png");
          this.setImage(val.partner);
        } else {
          this.setImage(val.partner);
        }
      }
    }
  },
  props: {
    // partner: {
    //   required: true
    // },
    // editable: {
    //   required: true
    // }
  },
  mounted() {
    this.tempPartner = JSON.parse(JSON.stringify(this.partner));
    if (!this.$masterHelper.isEmpty(this.partner)) {
      this.tempPartner = JSON.parse(JSON.stringify(this.partner));
      setTimeout(() => {
        //console.log("mounted-------------");
        this.setImage(this.tempPartner.partner);
      }, 1000);
    }
  },
  computed: {
    ...mapGetters(["partners", "partner"])
  },
  methods: {
    ...mapActions([
      "UPDATE_USER",
      "UPDATE_PARTNERS",
      "STORE_PARTNERS",
      "GET_PARTNER"
    ]),
    async savePartner() {
      try {
        this.isSaving = true;
        const nameElement = this.tempPartner.partner.name.length;
        const summaryElement = this.tempPartner.partner.summary.length;

        if (!nameElement || !summaryElement) {
          this.$toast.error(" ", "Verifique os campos!", {
            position: "topRight",
            timeout: 3000
          });
         // console.log(nameElement, summaryElement);
          return;
        }

        // if (
        //   typeof this.thumbnail === "object" &&
        //   Object.entries(this.thumbnail).length <= 0
        // ) {
        //   this.$toast.error(" ", "Selecione uma thumbnail", {
        //     position: "topRight",
        //     timeout: 3000
        //   });

        //   return;
        // }

        if (
          !this.tempPartner.partner.name.length ||
          !this.tempPartner.partner.summary.length
        ) {
          this.$toast.error(" ", "Preencha todos os campos", {
            position: "topRight",
            timeout: 3000
          });
          this.isSaving = false;
          return;
        }

        const data = {
          id: this.tempPartner.partner.id || 0,
          name: this.tempPartner.partner.name,
          summary: this.tempPartner.partner.summary,
          highlight: this.tempPartner.partner.highlight ? 1 : 0,
          active: this.tempPartner.partner.active ? 1 : 0,
          index: this.tempPartner.index,
          thumbnail: this.tempPartner.partner.thumbnail
        };

        const partner = await this.UPDATE_PARTNERS(data);

        //console.log("SavePartnerReturn:", partner);

        if (Object.entries(partner)?.length > 1) {
          this.$toast.success(" ", partner.name + " Atualizado com sucesso", {
            position: "topRight",
            timeout: 3000
          });
          this.isSaving = false;
          this.GET_PARTNER({});
          this.$event.$emit("partner_update");
          this.$forceUpdate();
          // this.unRegisterThumbnail();

          // this.tempPartner = JSON.parse(JSON.stringify(this.basePartner));
        }
      } catch (error) {
        this.isSaving = false;
        this.$toast.error(
          " ",
          error?.message || "Houve um erro! tente novamente",
          {
            position: "topRight",
            timeout: 3000
          }
        );
       // console.log(`SavePartnerError: ${error.message}`);
      }
    },
    async createPartner() {
      try {
        this.isSaving = true;
        const nameElement = this.tempPartner.partner.name.trim();
        const summaryElement = this.tempPartner.partner.summary.trim();

        if (!nameElement || !summaryElement) {
          this.$toast.error(" ", "Verifique os campos!", {
            position: "topRight",
            timeout: 3000
          });
         // console.log(nameElement, summaryElement);
          return;
        }

       // console.log(typeof this.thumbnail);

        if (
          typeof this.thumbnail === "object" &&
          Object.entries(this.thumbnail || {}).length <= 0
        ) {
          this.$toast.error(" ", "Selecione uma thumbnail", {
            position: "topRight",
            timeout: 3000
          });
          return;
        }

        if (
          nameElement?.value?.trim() === "" ||
          summaryElement?.value?.trim() === ""
        ) {
          this.$toast.error(" ", "Preencha todos os campos", {
            position: "topRight",
            timeout: 3000
          });
          return;
        }

        const data = {
          id: this.tempPartner.partner.id || 0,
          name: nameElement,
          summary: summaryElement,
          highlight: this.tempPartner.partner.highlight,
          active: this.tempPartner.partner.active,
          index: this.tempPartner.index,
          thumbnail: this.tempPartner.partner.thumbnail
        };

        const partner = await this.STORE_PARTNERS(data);
        this.isSaving = false;

        //console.log("CreatePartnerReturn:", partner);

        if (Object.entries(partner)?.length > 1) {
          this.$toast.success(" ", partner.name + " Cadastrado com sucesso", {
            position: "topRight",
            timeout: 3000
          });

          // this.unRegisterThumbnail();
          // this.tempPartner = JSON.parse(JSON.stringify(this.basePartner));
        }
      } catch (error) {
        this.isSaving = false;
        this.$toast.error(
          " ",
          error?.message || "Houve um erro! tente novamente",
          {
            position: "topRight",
            timeout: 3000
          }
        );
        //console.log(`CreatePartnerError: ${error.message}`);
      }
    },
    registerThumbnail(thumbnail = null) {
      try {
        if (!thumbnail) return;

        //console.log("registerThumbnail", thumbnail);
        this.tempPartner.partner.thumbnail = thumbnail;
        this.thumbnail = thumbnail;
        // this.setImage(this.tempPartner.partner);
      } catch (error) {
        this.$toast.error(" ", "Houve um erro! tente novamente", {
          position: "topRight",
          timeout: 3000
        });
        //console.log(`RegisterThumbnailError: ${error?.message}`);
      }
    },
    unRegisterThumbnail() {
      try {
        //if(thumbnail) return

       // console.log("unRegisterThumbnail");
        this.thumbnail = {};
      } catch (error) {
        this.$toast.error(" ", "Houve um erro! tente novamente", {
          position: "topRight",
          timeout: 3000
        });
       // console.log(`UnRegisterThumbnailError: ${error?.message}`);
      }
    },

    setImage(partner) {
      this.$refs.thumbnailRef._data.tempImages = [];
     // console.log("setImage funxtion", partner);
      // this.thumbnail =
      if (!partner.thumbnail) {
        this.$refs.thumbnailRef._data.tempImages = [];
        return;
      }
      this.tempPartner.partner.thumbnail = partner.thumbnail;
     // console.log("this.$refs.thumbnailRef", this.$refs.thumbnailRef);
      this.$refs.thumbnailRef.addByEvent({ path: partner.thumbnail + ".png" });
      this.$forceUpdate();
    }
  }
};
</script>

<style></style>
