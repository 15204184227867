<template>
  <div class="card mt-5">
    <div class="card-body">
      <h1>Sou sua pagina e o nome dessa rota é {{ $route.name }}</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
