<template>
  <div>
    <div class="card">
      <!-- <LoadingScreen
        eventName="loading_tags"
        dir="column"
        bgColor="#fff"
        message="Carregando..."
      /> -->
      <div class="card-body">
        <div
          class="w-100 d-flex align-items-center justify-content-between p-2"
        >
          <div>
            <h4>Total: {{ tags.length }}</h4>
          </div>
          <div class="d-flex justify-content-center">
            <button
              :disabled="loading"
              class="btn btn-warning slide-in-blurred-top mr-3"
              v-show="selectedItems.filter((i) => i.active == 1).length"
              @click="unableTags(false)"
            >
              Desativar
            </button>
            <button
              :disabled="loading"
              class="btn btn-warning slide-in-blurred-top"
              v-show="selectedItems.filter((i) => i.active == 0).length"
              @click="unableTags(true)"
            >
              Ativar
            </button>
            <LoaderButton
              b_class="btn-danger slide-in-blurred-top ml-3"
              :wasClicked="loading"
            >
              <button
                :disabled="loading"
                @click="
                  deleteSelectedTags();
                  loading = true;
                "
                class="btn btn-danger"
                v-if="selectedItems.length"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </LoaderButton>
            <CreateModal :hideAddBtn="true" />
          </div>
        </div>
        <table class="table table-inverse w-100">
          <thead class="thead-inverse">
            <tr>
              <th style="width: 25px">
                <label>
                  <input
                    type="checkbox"
                    :checked="selectAll"
                    @click="selectAllItems()"
                  />
                </label>
              </th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Criado em</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="pointer hover slide-in-blurred-top"
              v-for="(tag, index) in tagsList"
              :key="index"
              :class="[{ selectedRow: isSelected(tag.uuid) }]"
            >
              <td style="width: 25px">
                <label>
                  <input
                    @click="selecteItem(tag)"
                    type="checkbox"
                    :checked="selectedItem(tag) || false"
                  />
                </label>
              </td>
              <td @click="selectTag(tag)">{{ tag.name }}</td>
              <td @click="selectTag(tag)">{{ tag.description || "-" }}</td>
              <td @click="selectTag(tag)">{{ $date(tag.created_at) }}</td>
              <td @click="selectTag(tag)">
                <i
                  class="fas fa-circle text-success"
                  v-show="tag.active"
                  title="Ativado"
                ></i>
                <i
                  class="far fa-dot-circle text-danger"
                  v-show="!tag.active"
                  title="Desativado"
                ></i>
              </td>
            </tr>
            <tr v-if="!tagsList.length">
              <td colspan="4">
                <h5 class="font-weight-bold">Nenhuma tag registrada</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="card-footer ">
        <Pagination
          v-model="tagsPaginate"
          eventName="tags_loaded"
          currentPageName="tags"
        />
      </div> -->
    </div>
    <MainModal
      modal_id="tag_bulk"
      modalTitle="Tags em uso"
      ref="modal_res"
      v-show="inUseTags.length"
      modalSize="modal-md"
    >
      <div slot="body">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(usedTag, index) in inUseTags"
            :key="index"
          >
            Tag
            <span class="font-weight-bold text-danger">{{ usedTag.name }}</span>
            está em uso:
            <div class="d-flex flex-column">
              <div v-for="post in usedTag.posts" :key="'post_' + post.id">
                <a :href="`/posts/details/${post.uuid}`" target="_blank"
                  >Post: {{ post.title }}</a
                >
              </div>
            </div>
            <div class="d-flex flex-column">
              <div
                v-for="product in usedTag.products"
                :key="'product_' + product.id"
              >
                <a :href="`/products/details/${product.uuid}`" target="_blank"
                  >Produto: {{ product.name }}</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </MainModal>
  </div>
</template>

<script>
import LoadingScreen from "@/components/navigation/LoadingScreen";
import CreateModal from "@/components/tags/CreateModal";
import LoaderButton from "@/components/buttons/LoaderButton";
import Pagination from "@/components/navigation/Paginator";
import MainModal from "@/components/modals/MainModal";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      rowStart: null,
      rowEnd: null,
      perPage: 10,
      currentPage: 1,
      loading: false,
      selectAll: false,
      selectedItems: [],
      userSelected: [],
      UPDATED: 0,
      inUseTags: [],
    };
  },
  components: {
    LoadingScreen,
    CreateModal,
    LoaderButton,
    Pagination,
    MainModal,
  },
  mounted() {
    // this.LOAD_TAGS();
    this.$event.$on("tag_updated", () => {
      this.UPDATED++;
    });
    this.$event.$on("edit_tag", () => {
      this.selectedItems = [];
      this.selectAll = false;
    });
    this.$event.$emit("loading_tags", false);
    if (!this.tags.length) {
      // this.$event.$emit("page_loaded", { isLoading: true });
      this.LOAD_TAGS().then(() => {
        // this.$event.$emit("page_loaded", { isLoading: false });
      });
    }
  },
  computed: {
    ...mapGetters(["tags", "tag", "tagsPaginate"]),
    tagsList() {
      if (!this.tags && this.tags.length) return false;
      this.UPDATED;
      return this.tags;
    },
  },
  methods: {
    ...mapActions([
      "LOAD_TAGS",
      "VIEW_TAG",
      "BULK_UPDATE_TAGS",
      "UPDATE_TAG",
      "BULK_DELETE_TAGS",
    ]),

    isSelected(uuid) {
      return this.tag.uuid === uuid;
    },
    selectAllItems() {
      if (!this.selectAll) {
        this.selectedItems = [...this.tags];
        this.selectAll = !this.selectAll;
      } else if (this.selectAll) {
        this.selectedItems = [];
        this.selectAll = false;
      }
    },

    selecteItem(tag) {
      let isSelected = this.selectedItems.findIndex((item) => {
        return item.uuid === tag.uuid;
      });
      if (isSelected < 0) {
        this.selectedItems.push(tag);
      } else {
        this.selectedItems.splice(isSelected, 1);
      }
    },

    selectedItem(tag) {
      return this.selectedItems.some((item) => {
        return item.uuid === tag.uuid;
      });
    },
    selectTag(tag) {
      this.$event.$emit("tag_selected");
      this.VIEW_TAG(tag);
    },
    unableTags(activate = false) {
      if (this.selectedItems.length) {
       // console.log("arr", this.selectedItems);
        this.selectedItems.forEach((item, index) => {
          this.selectedItems[index].active = activate ? 1 : 0;
        });
        this.$toast.info(" ", "Atualizando!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });
        this.BULK_UPDATE_TAGS(this.selectedItems).then(() => {
          this.$toast.success(" ", "Atualizado!", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        });
        this.selectedItems = [];
        this.userSelected = [];
        // console.log()
      }
    },
    deleteSelectedTags() {
      this.$toast.info(" ", "Removendo!", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      this.BULK_DELETE_TAGS(this.selectedItems).then((response) => {
        if (response.code !== 201) {
         // console.log("response", response);
          if (response.code === 400) {
            this.$toast.info(" ", response.data.message, {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
            //show modal and display tags in use

            this.inUseTags = response.data.data;

            this.$refs.modal_res.open();

            this.selectedItems = [];

            this.userSelected = [];
          }
        } else {
          this.$toast.success(" ", "Tag Removida!", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }

        this.loading = false;
        this.selectedItems = [];
        this.userSelected = [];
        this.selectAll = false;
      });
    },
  },
};
</script>

<style></style>
