import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    settings: [],
    setting: {},
    store_rules: {},
    admin_template: {
      content: {
        icons_color: "",
        buttons_color: "",
        general: {
          text: {
            colorTitle: "#000",
            colorSubTitle: "#000",
            colorDescription: "#000",
            fontType: "",
          },
          background: {
            image: "#",
            color: "#fff",
            color2: "#f5f5f5",
          },
          button: {
            text: "#000",
            background: "#d5d5d5",
          }
        },
      }
    },
    company: {},
  },
  getters: {
    settings: state => state.settings,
    setting: state => state.setting,
    store_rules: (state) => state.store_rules,
    company: (state) => state.company,
    admin_template: (state) => state.admin_template,
  },
  mutations: {
    LOAD_SETTINGS(state, settings) {
      state.settings = settings;
    },
    CREATE_SETTINGS(state, setting) {
      state.settings.push(setting);
    },
    UPDATE_SETTINGS(state, setting) {
      let index = state.settings.findIndex(set => {
        return set.key == setting.key;
      });
      state.settings[index] = setting;
    },
    DELETE_SETTINGS(state, setting) {
      const settigProvider = state.settings.find(settinged => settinged.id === setting.id)
      if (settigProvider) settigProvider.content = setting.content
      state.settings = [...state.settings, settigProvider]
    },
    SET_SETTINGS_STORE_RULES: (state, payload) => {
      state.store_rules = payload
    },
    SET_SETTINGS_ADMIN_TEMPLATE: (state, payload) => {
      state.admin_template = payload
    },
    SET_SETTINGS_COMPANY_TEMPLATE: (state, payload) => {
      state.company = payload
    },
  },
  actions: {
    LOAD_SETTINGS: async (context, payload) => {
      try {
        let response = await axios().get("/settings");

        if (response.data.code !== 200) {
          context.commit("LOAD_SETTINGS", []);
          return
        }

        context.commit("LOAD_SETTINGS", response.data.data);
        context.dispatch("LOAD_STORE_RULES")
        context.dispatch("LOAD_SETTINGS_ADMIN_TEMPLATE")
        context.dispatch("LOAD_SETTINGS_COMPANY_TEMPLATE")
        return response.data;
      } catch (error) {
        // console.log("LOAD_SETTINGS:error", error);
        return error;
      }
    },
    UPDATE_SETTINGS: async (context, payload) => {
      // console.log("UPDATE_SETTINGS the payload is", payload);
      // return true;
      try {
        context.commit("UPDATE_SETTINGS", payload);
      } catch (error) {
        // console.log("UPDATE_SETTINGS:error", error);
        return error;
      }
    },
    CREATE_SETTINGS: async (context, payload) => {
      try {
        let response = await axios().post("/settings", payload);
        // console.log("response:response", response);

        if (response.data.code !== 201) {
          context.commit("CREATE_SETTINGS", {});
          return
        }

        context.commit("CREATE_SETTINGS", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("CREATE_SETTINGS:error", error);
        return error;
      }
    },
    DELETE_SETTINGS_PROVIDER: async (context, payload) => {
      // console.log("DELETE_SETTINGS_PROVIDER the payload is", payload);
      // return true;
      try {
        let response = await axios().post(
          "/settings/delete/" + payload.id,
          payload
        );

        if (response.data.code !== 201) {
          context.commit("DELETE_SETTINGS", {});
          return
        }

        context.commit("DELETE_SETTINGS", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("DELETE_SETTINGS_PROVIDER:error", error);
        return error;
      }
    },
    LOAD_STORE_RULES: async function (context, _payload) {
      try {
        if (context.state.settings && context.state.settings.length > 0) {
          context.state.settings.every(setting => {
            if (setting?.key === "store_rules") {
              context.commit("SET_SETTINGS_STORE_RULES", setting)
              return false
            }

            return true
          })
        }
      } catch (error) {
        // do anything
      }
    },
    LOAD_SETTINGS_ADMIN_TEMPLATE: async function (context, _payload) {
      try {
        if (context.state.settings && context.state.settings.length > 0) {
          context.state.settings.every(setting => {
            if (setting?.key === "admin_template") {
              context.commit("SET_SETTINGS_ADMIN_TEMPLATE", setting)
              return false
            }

            return true
          })
        }
      } catch (error) {
        // do anything
      }
    },
    LOAD_SETTINGS_COMPANY_TEMPLATE: async function (context, _payload) {
      try {
        if (context.state.settings && context.state.settings.length > 0) {
          context.state.settings.every(setting => {
            if (setting?.key === "company") {
              context.commit("SET_SETTINGS_COMPANY_TEMPLATE", setting)
              return false
            }

            return true
          })
        }
      } catch (error) {
        // do anything
      }
    },
  }
};
