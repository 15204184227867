<template>
  <div class="card low-shadow report-table-card report-number-table-card">
    <div class="card-header">
      <h3 class="text-dark mt-2">Últimos Produtos Negociados</h3>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th colspan="2">Produto</th>
            <th>Qtde.</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="prod in getLastProductsSold" :key="prod.uuid">
            <td>{{ prod.id }}</td>
            <td colspan="2">{{ prod.product.name }}</td>
            <td>{{ prod.quantity }}</td>
            <td>R$ {{ $format($money(prod.total_price)) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    params: {
      required: false,
      default: null
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(["productReport"]),

    getLastProductsSold() {
      if (!this.productReport.report?.last_products_sold?.length) return [];

      return this.productReport.report.last_products_sold;
    }
  },
  methods: {
    ...mapActions(["PRODUCT_REPORT"]),
    load() {
      let action = params => this.PRODUCT_REPORT(params);

      // console.log("LatestSoldProducts params for PRODUCT_REPORT", {
      //   params: this.params
      // });

      if (this.params) {
        action({ params: this.params });
      } else {
        if (this.$masterHelper.isEmpty(this.productReport)) {
          action(false);
        }
      }
    }
  }
};
</script>

<style lang="scss">



</style>
