/* eslint-disable no-useless-catch */
import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    partners: [],
    partner: {}
  },
  getters: {
    partners: state => state.partners,
    partner: state => state.partner
  },
  mutations: {
    LOAD_PARTNERS(state, partnersArr) {
      state.partners = partnersArr;
    },
    GET_PARTNER(state, partnerArr) {
      state.partner = partnerArr;
    },
    ADD_PARTNER(state, partnerArr) {
      state.partners.push(partnerArr);
    },
    CLEAR_PARTNER(state, _partnerArr) {
      state.partner = {};
    },
    DELETE_PARTNER(state, partner) {
      let index = state.partners.findIndex(part => {
        return part.id == partner.id;
      });
      state.partners.splice(1, index);
    },
    UPDATE_PARTNER(state, partner) {
      let index = state.partners.findIndex(part => {
        return part.id == partner.id;
      });
      state.partners[index] = partner;
    }
  },
  actions: {
    LOAD_PARTNERS: async (context, payload) => {
      try {
        let response = await axios().get("/partners", payload);

        if (response.data?.erro) throw new Error(response.data?.erro?.trim());

        if (response.data?.partners?.length <= 0)
          throw new Error("The partners is empty");

        context.commit("LOAD_PARTNERS", response.data?.partners || []);
        return response;
      } catch (error) {
        //console.log("LOAD_PARTNERS:error", error?.message);
        return error;
      }
    },
    UPDATE_PARTNERS: async ({ commit }, payload) => {
      try {
        //console.log("UPDATE_PARTNERS, payload", payload);
        let response = await axios().post("/partners/update/" + payload?.id, {
          active: payload?.active,
          highlight: payload?.highlight,
          id: payload?.id,
          name: payload?.name,
          summary: payload?.summary
        });

        if (response.data?.erro) throw new Error(response.data?.erro?.trim());

        if (Object.entries(response.data?.partner)?.length <= 0)
          throw new Error("Houve um erro ao atualizar! tente novamente");

        if (payload.thumbnail.src) {
          const responseThumbnail = await axios().post(
            "/partners/thumbnail/update",
            {
              thumbnail: payload?.thumbnail,
              model_type: "partner",
              partner: {
                id: payload?.id
              }
            }
          );

          if (responseThumbnail.data?.error)
            throw new Error(responseThumbnail.data?.error?.trim());

          //console.log(responseThumbnail.data);

          if (Object.entries(responseThumbnail.data?.partner)?.length <= 0)
            throw new Error("The partner of thumbnail is empty");

          // state.partners[payload.index] =
          //   responseThumbnail.data?.partner || response.data?.partner;

          commit("UPDATE_PARTNER", responseThumbnail.data.partner);
          // console.log("did it update?", responseThumbnail.data.partner);
          return (
            responseThumbnail.data.partner || responseThumbnail.data.partner
          );
        } else {
          // console.log(
          //   "the real response read me -----------",
          //   response.data.partner
          // );
          //console.log("did it update? only texts", response.data.partner);
          commit("UPDATE_PARTNER", response.data.partner);
          return response.data.partner;
        }
      } catch (error) {
        //console.log("LOAD_PARTNERS:error", error?.message);
        throw error;
      }
    },
    STORE_PARTNERS: async (context, payload) => {
      try {
        const response = await axios().post("/partners/store", payload);

        if (response.data?.erro) throw new Error(response.data?.erro?.trim());

        //console.log(response.data);

        if (response.data?.error) {
          throw new Error(responseThumbnail.data?.error?.trim() || "");
        }

        if (Object.entries(response.data?.data || {})?.length <= 0) {
          throw new Error("Houve um erro ao cadastrar! tente novamente");
        }

        const responseThumbnail = await axios().post(
          "/partners/thumbnail/update",
          {
            thumbnail: payload?.thumbnail,
            model_type: "partner",
            partner: {
              id: response.data?.data?.id || 0
            }
          }
        );

        if (responseThumbnail.data?.error) {
          throw new Error(responseThumbnail.data?.error?.trim() || "");
        }
       // console.log(responseThumbnail?.data || {});

        if (Object.entries(responseThumbnail.data?.partner)?.length <= 0) {
          throw new Error(
            "Houve um erro ao atualizar a thumbnail! tente novamente"
          );
        }

        //padrao errado ========================
        // context.state.partners.push(
        //   responseThumbnail.data?.partner || response.data?.partner
        // );

        // context.commit("LOAD_PARTNERS", context.state.partners || []);

        context.commit(
          "ADD_PARTNER",
          responseThumbnail.data?.partner || response.data?.partner || {}
        );
        context.commit("CLEAR_PARTNER", {});

        return responseThumbnail.data?.partner || response.data?.partner || {};
      } catch (error) {
        //console.log("STORE_PARTNERS:error", error?.message || '');
        //console.log(error)
        throw error;
      }
    },
    DELETE_PARTNERS: async (context, payload) => {
      try {
        const response = await axios().delete(
          "/partners/delete/" + payload?.partner?.id || 0
        );

        if (response.data?.erro || response.data?.excluido === false)
          throw new Error(
            response.data?.erro?.trim() || "Houve um erro! tente novamente"
          );

        //console.log(response.data);

        if (response.data?.excluido !== true)
          throw new Error("Houve um erro ao excluir! tente novamente");

        context.state.partners = context.state.partners.filter(
          partner => partner?.id !== payload?.partner?.id
        );

        context.commit("DELETE_PARTNER", payload.partner);
        context.commit("CLEAR_PARTNER", {});
        return payload?.partner;
      } catch (error) {
        //console.log("DELETE_PARTNERS:error", error?.message);
        throw error;
      }
    },
    GET_PARTNER: (context, payload) => {
      try {
        context.commit("GET_PARTNER", payload);
        return payload;
      } catch (error) {
       // console.log("GET_PARTNER:error", error);
        return error;
      }
    },
    BULK_UPDATE_PARTNERS: async (context, payload) => {
      try {
        let response = await axios().post("/partners/bulk_update", payload);
        // context.commit("UPDATE_TAG", response.data.tag);
        return response.data;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    },
    BULK_DELETE_PARTNERS: async (context, payload) => {
      try {
        let ids = payload.map(item => item.id);

        let response = await axios().post("/partners/bulk_delete", ids);
        // context.commit("UPDATE_TAG", response.data.tag);
        if (response.data.data) {
          payload.forEach(partner => {
            context.commit("DELETE_PARTNERS", partner);
          });
        }
        return response;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    },
    CLEAR_PARTNER: (context, payload) => {
      try {
        context.commit("CLEAR_PARTNER", {});
        return payload;
      } catch (error) {
       // console.log("CLEAR_PARTNER:error", error);
        return error;
      }
    }
  }
};
