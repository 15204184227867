<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <input
        v-model="filter"
        type="text"
        class="form-control col-8"
        placeholder="Pesquisar"
      />
      <CategoryModal btnClass="btn-warning" />
    </div>
    <ul
      class="list-group mt-3"
      v-if="loaded"
      style=" height: 500px; overflow-y: auto;"
    >
      <li
        class="list-group-item d-flex flex-column"
        v-for="category in nestedCategories"
        :key="category.uuid"
      >
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div>
            <input
              type="checkbox"
              class="mr-2"
              :id="category.id"
              @change="selectCategory(category)"
              :checked="
                hasSubSelected(category.id).length ||
                  selectedCategories.some(s => s.id === category.id)
              "
            />
            <label class="pointer" :for="category.id"
              ><strong>{{ category.name }}</strong></label
            >
          </div>
          <button class="btn" @click="toogleItem(category)">
            <span
              v-show="
                isSelectedItem(category.id) &&
                  category.subCategories &&
                  category.subCategories.length
              "
              >-</span
            >
            <span
              v-show="
                !isSelectedItem(category.id) &&
                  category.subCategories &&
                  category.subCategories.length
              "
              >+</span
            >
          </button>
        </div>
        <transition name="scale-in" mode="out-in">
          <ul class="list-group mt-2" v-if="isSelectedItem(category.id)">
            <li
              class="list-group-item"
              v-for="subCate in category.subCategories"
              :key="subCate.uuid"
            >
              <input
                @change="selectCategory(subCate)"
                type="checkbox"
                class="mr-1"
                value=""
                :checked="
                  isSelected(subCate.id) &&
                    isParentSelected(subCate.category_id)
                "
                :id="subCate.id"
              />
              <label
                @change="selectCategory(subCate)"
                class="pointer ml-1 mb-0"
                :for="subCate.id"
                :class="[{ 'text-success': similarToSearch(subCate) }]"
              >
                <strong>{{ subCate.name }}</strong>
              </label>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CategoryModal from "@/components/categories/CategoryModal";

export default {
  data() {
    return {
      loaded: true,
      selectedCategories: [],
      openItems: [],
      filter: ""
    };
  },
  components: { CategoryModal },
  mounted() {},
  computed: {
    ...mapGetters(["categories"]),
    nestedCategories() {
      let allParents = this.categories.filter(p => {
        return !p.category_id && p.active === 1;
      });

      allParents.forEach(cate => {
        cate.subCategories = this.getChilds(cate.id) || [];
      });

      let parentCat = allParents.filter(nest => {
        return nest.name.toLowerCase().includes(this.filter.toLowerCase());
      });
      //console.log("parentCat", parentCat);

      if (!parentCat.length) {
        let temp = [];
        allParents.forEach(par => {
          if (par.subCategories.length) {
            par.subCategories.filter(sub => {
              if (
                sub.name.toLowerCase().includes(this.filter.toLowerCase()) &&
                sub.active === 1
              ) {
                let exists = temp.findIndex(t => {
                  return t.id === par.id;
                });
                if (exists < 0) {
                  temp.push(par);
                }
                return true;
              } else {
                return false;
              }
            });
          }
        });
        temp.forEach(pC => {
          this.filterToggleItem(pC);
        });
        return temp;
      } else {
        return parentCat;
      }
    }
  },
  methods: {
    getChilds(cat_id) {
      return this.categories.filter(cate => {
        return cate.category_id === cat_id;
      });
    },
    isParentSelected(parent_id) {
      return this.selectedCategories.some(selected => selected.id == parent_id);
    },
    selectCategory(cat) {
      // if (!cat.category_id) {
      // let subCategories = this.getChilds(cat.id);
      // let wasSelected = this.hasSubSelected(cat.id);
      // if (!subCategories.length) {
      //   let index = this.selectedCategories.findIndex(sel => {
      //     return sel.id === cat.id;
      //   });
      //   if (index < 0) {
      //     this.selectedCategories.push(cat);
      //   } else {
      //     this.selectedCategories.splice(index, 1);
      //   }
      //   this.$emit("change", this.selectedCategories);
      // }
      if (!this.isSelected(cat.id)) {
        if (cat.category_id && !this.isParentSelected(cat.category_id)) {
          let parentCat = this.nestedCategories.find(
            cate => cate.id == cat.category_id
          );
          this.selectedCategories.push(parentCat);
        }
        this.selectedCategories.push(cat);
        this.$emit("change", this.selectedCategories);
      } else {
        let index = this.selectedCategories.findIndex(sel => {
          return sel.id === cat.id;
        });
        if (cat.sub_categories && cat.sub_categories.length) {
          let ids = cat.sub_categories.map(sub_cat => sub_cat.id);

          ids.forEach(id => {
            let sub_index = this.selectedCategories.findIndex(
              sel => sel.id === id
            );
            this.selectedCategories.splice(sub_index, 1);
          });
          this.$emit("change", this.selectedCategories);
        }
        this.selectedCategories.splice(index, 1);
        this.$emit("change", this.selectedCategories);
      }

      // if (!wasSelected.length) {
      //   subCategories.forEach(sub => {
      //     this.selectedCategories.push(sub);
      //   });
      // } else {
      //   subCategories.forEach(sub => {
      //     let index = this.selectedCategories.findIndex(sel => {
      //       return sel.id === sub.id;
      //     });
      //     this.selectedCategories.splice(index, 1);
      //   });
      // }
      // }
    },
    isSelected(id) {
      let isSelected = this.selectedCategories.some(category => {
        return category.id === id;
      });

      return isSelected;
    },
    hasSubSelected(parent_id) {
      return this.selectedCategories.filter(category => {
        return category.category_id === parent_id;
      });
    },
    toggleSub(cat) {
      let index = this.selectedCategories.findIndex(sel => {
        return sel.id === cat.id;
      });
      if (index < 0) {
        this.selectedCategories.push(cat);
      } else {
        this.selectedCategories.splice(index, 1);
      }
      this.$emit("change", this.selectedCategories);
    },
    toogleItem(item, isFilter = null) {
      if (this.getChilds(item.id).length) {
        let index = this.openItems.findIndex(sel => {
          return sel.id === item.id;
        });
        if (index < 0) {
          this.openItems.push(item);
        } else {
          this.openItems.splice(index, 1);
        }
      }
    },
    filterToggleItem(item) {
      let index = this.openItems.findIndex(sel => {
        return sel.id === item.id;
      });
      if (index < 0) {
        this.openItems.push(item);
      }
    },
    isSelectedItem(id) {
      let isSelected = this.openItems.some(category => {
        return category.id === id;
      });
      return isSelected;
    },
    similarToSearch(cate) {
      if (!this.filter.length) return false;
      //console.log("cate---", cate);
      return cate.name.toLowerCase().includes(this.filter.toLowerCase());
    }
  }
};
</script>

<style></style>
