<script>
import { mapGetters, mapActions } from "vuex";

// Helpers
import dynamicStyle from "@/helpers/DynamicStyle.js";

export default {
  data() {
    return {
      // do anything
      selectedSettings: undefined,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["settings"]),
  },
  methods: {
    ...mapActions(["LOAD_SETTINGS"]),
    mySettings(type = "template") {
      const set =
        this.settings.find((set) => {
          return set.key === type;
        }) || false;

      //console.log("TEMPLATE_DEBUG", set);
      return set;
    },
    async loadSet(type = "template") {
      const loaded = await this.LOAD_SETTINGS();

      if (loaded) {
        if (type === "template") {
          this.createTemplate(type);
        } else {
          return this.mySettings(type);
        }
      }
    },
    createTemplate(type = "template") {
      try {
        let xpto = this.mySettings(type);

        if (xpto) {
          const template = `

            * .btn-primary {
              background-color: ${xpto?.content?.buttons_color} !important;
              color: ${xpto?.content?.buttons_color?.color} !important;
              border: 1px solid ${xpto?.content?.buttons_color} !important;
            }

            * .router-link-exact-active, * .router-link-active {
              background-color: ${xpto?.content?.icons_color}80 !important;
              box-shadow: 0px 7px 12px 0px ${xpto?.content?.icons_color}30 !important;
              color: #444 !important;
            }

            aside .sidebar-link.router-link-exact-active  .fa-box, #main-wrapper[data-layout="vertical"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            .feather-icon, #main-wrapper[data-layout="vertical"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            i, #main-wrapper[data-layout="horizontal"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            .feather-icon, #main-wrapper[data-layout="horizontal"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            i ,#main-wrapper[data-layout="vertical"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            .feather-icon, #main-wrapper[data-layout="vertical"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            i, #main-wrapper[data-layout="horizontal"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            .feather-icon, #main-wrapper[data-layout="horizontal"]
            .left-sidebar[data-sidebarbg="skin6"]
            .sidebar-nav
            ul
            .sidebar-item
            .sidebar-link
            i.fa-box, i.fas:not(.text-success) {
              color: ${xpto?.content?.icons_color} !important;
              fill: ${xpto?.content?.icons_color} !important;
            }

            aside .sidebar-link.router-link-exact-active {
            }
        `;

          //console.log("TEMPLATE_DEBUG ", template, xpto);

          this.applyTemplate(template);
        }
      } catch (error) {
        // do anything
      }
    },
    applyTemplate(template = "") {
      var style = document.createElement("style");
      style.setAttribute("style", "text/css");
      style.innerHTML = template;
      // document.querySelector("body").classList.add(className);

     // console.log("STYLE TEMPLATE", style);

      document.getElementsByTagName("head")[0].appendChild(style);
      return;
    },
  },
};
</script>
