var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6"},[_vm._m(1),_c('div',{staticClass:"mt-3"},[_c('PaymentGateway')],1)])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6"},[_vm._m(2),_c('div',{staticClass:"mt-3"},[_c('Shippings')],1)])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6"},[_vm._m(3),_c('div',{staticClass:"mt-3"},[_c('SEO')],1)])]),(_vm.$masterHelper.hasRole(_vm.loggedUser, ['dev']) === true)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6"},[_vm._m(4),_c('div',{staticClass:"mt-3"},[_c('Analytics')],1)])]):_vm._e(),_c('div',{staticClass:"row justify-content-center mt-4"},[_c('div',{staticClass:"col-6"},[_c('hr'),_vm._m(5),_c('div',{staticClass:"mt-3"},[_c('InvoiceGateway')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('strong',[_vm._v("Integrações")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Pagamento")]),_c('br'),_c('small',[_vm._v("Confira as integrações de pagamentos, é facil de começar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Frete")]),_c('br'),_c('small',[_vm._v("Confira as integrações de frete, é facil de começar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("SEO Google")]),_c('br'),_c('small',[_vm._v("Confira as integrações de SEO, é facil de começar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Analytics")]),_c('br'),_c('small',[_vm._v("Confira as integrações de Analytics, é facil de começar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Nota fiscal")]),_c('br'),_c('small',[_vm._v("Confira as integrações de pagamentos, é facil de começar.")])])
}]

export { render, staticRenderFns }