import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    products: {},
    productsNoPaginate: [],
    blingProducts: [],
    product: {},
    productBackup: []
  },
  getters: {
    products: state => state.products.data,
    product: state => state.product,
    productsPaginate: state => state.products,
    productsNoPaginate: state => state.productsNoPaginate,
    productBackup: state => state.productBackup,
    blingProducts: state => state.blingProducts
  },
  mutations: {
    BLING_LOAD_PRODUCTS(state, products) {
      state.blingProducts = products;
    },
    LOAD_PRODUCTS(state, products) {
      state.products = products;
    },
    LOAD_ALL_PRODUCTS(state, products) {
      state.productsNoPaginate = products;
    },
    GET_PRODUCT(state, product) {
      state.product = product;
    },
    NEW_PRODUCT(state, product) {
      state.products.data.unshift(product);
    },
    UPDATE_PRODUCT(state, product) {
      let index = state.products.data.findIndex(t => {
        return t.id === product.id;
      });

      state.products[index] = product;
    },
    REMOVE_PRODUCT(state, product) {
      let index = state.products.data.findIndex(item => item.id === product.id);
      state.products.splice(index, 1);
    },
    FILTER_PRODUCTS(state, products) {
      if (!state.productBackup.length)
        state.productBackup = [...state.products];
      state.products = products;
    },
    CLEAN_FILTER_PRODUCTS(state) {
      state.products = state.productBackup;
      state.productBackup = [];
    }
  },
  actions: {
    CLEAN_FILTER_PRODUCTS: async context => {
      context.commit("CLEAN_FILTER_PRODUCTS");
    },
    FILTER_PRODUCTS: async (context, payload) => {
      context.commit("FILTER_PRODUCTS", payload);
    },
    PAGINATE_LOAD_PRODUCTS: async (context, payload) => {
      try {
        const response = await axios().get(payload);
        //console.log("products loaded", response);
        // let formated =[];
        // response.data.products.forEach(prod =>{
        //   formated.push()
        // })

        if (response.data.code !== 200) {
          context.commit("LOAD_PRODUCTS", []);
          return
        }

        context.commit("LOAD_PRODUCTS", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("LOAD_PRODUCTS:error", error);
        return error;
      }
    },
    SEARCH_PRODUCT: async ({ commit, dispatch }, payload, goToPage = 1) => {
      try {
        // console.log("search product params sending ...", payload, goToPage)


        if (Object.entries(payload).length <= 0) {
          payload = { "per_page": 16, "params": { "search": {}, "relationships": { "0": "tags", "1": "categories", "2": "media", "3": "user" } }, "model": "Product", "goToPage": "1" }
        }

        let response = await axios().post("/products/search/0/1", { per_page: 16, ...payload });

        dispatch("SET_PARAMS", payload);

        // if(response.data.code !== 200){
        //   commit("LOAD_PRODUCTS", []);
        //   return
        // }

        commit("LOAD_PRODUCTS", response.data);

        return response;
      } catch (error) {
        // console.log("SEARCH_PRODUCT:error", error);
        return error;
      }
    },
    LOAD_PRODUCTS: async (context, payload) => {
      try {
        let response = await axios().post("/products/search/0/1", { "per_page": 16, "params": { "search": {}, "relationships": { "0": "tags", "1": "categories", "2": "media", "3": "user" } }, "model": "Product", "goToPage": "1" });
        //console.log("products loaded", response);
        // let formated =[];
        // response.data.products.forEach(prod =>{
        //   formated.push()
        // })
        context.commit("LOAD_PRODUCTS", response.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_PRODUCTS:error", error);
        return error;
      }
    },
    // LOAD ALL PRODUCTS WITHOUT PAGINATION
    LOAD_ALL_PRODUCTS: async (context) => {
      try {
        let response = await axios().get("/products/all");

        if (response.data?.code !== 200) {
          context.commit("LOAD_ALL_PRODUCTS", []);
        }

        context.commit("LOAD_ALL_PRODUCTS", response.data?.data || []);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    VIEW_PRODUCT: (context, payload) => {
      try {
        context.commit("GET_PRODUCT", payload);
      } catch (error) {
        //console.log("GET_PRODUCT:error", error);
        return error;
      }
    },
    CREATE_PRODUCT: async (context, payload) => {
      try {
        let response = await axios().post("/products/store", payload);
        //console.log("CREATE_PRODUCT->response.data", response.data);

        if (response.data.code !== 201) {
          context.commit("NEW_PRODUCT", {});
          return
        }

        context.commit("NEW_PRODUCT", response.data.data);

        return response.data;
      } catch (error) {
        //console.log("CREATE_PRODUCT:error", error);
        return error;
      }
    },
    GET_PRODUCT: async (context, uuid) => {
      try {
        let response = await axios().get("/products/show/" + uuid);
        //  console.log("products loaded", response);

        if (response.data.code !== 200) {
          context.commit("GET_PRODUCT", []);
          return
        }

        context.commit("GET_PRODUCT", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("LOAD_PRODUCTS:error", error);
        return error;
      }
    },
    GET_PRODUCT_BY_ID: async (context, id) => {
      try {
        let response = await axios().get("/products/show_by_id/" + id);

        return response;
      } catch (error) {
        // console.log("LOAD_PRODUCTS:error", error);
        return error;
      }
    },
    BLING_PRODUCTS: async (context, uuid) => {
      try {
        let response = await axios().get("/products/by/bling");

        if (response.data?.code !== 200) {
          context.commit("BLING_LOAD_PRODUCTS", []);
          return
        }
        //console.log("products loaded", response);

        context.commit("BLING_LOAD_PRODUCTS", response.data?.data?.retorno.produtos);

        return response;
      } catch (error) {
        // console.log("LOAD_PRODUCTS:error", error);
        return error;
      }
    },
    IMPORT_BLING_PRODUCT: async ({ dispatch }, payload) => {
      try {
        let response = await axios().post("/products/bling_import", payload);
        dispatch("LOAD_PRODUCTS");
        return response;
      } catch (error) {
        //console.log("UPDATE_PRODUCT:error", error);

        return error;
      }
    },
    UPDATE_PRODUCT: async ({ commit, dispatch }, payload) => {
      try {
        const response = await axios().post(
          "/products/update/" + payload.id,
          payload
        );

        if (response.data.code !== 201) {
          context.commit("UPDATE_PRODUCT", {});
          return response.data
        }

        commit("UPDATE_PRODUCT", response.data.data);
        dispatch("LOAD_PRODUCTS")

        return response.data;
      } catch (error) {
        // console.log("UPDATE_PRODUCT:error", error);
        return error;
      }
    },
    REMOVE_MEDIA_BY_ID: async (context, payload) => {
      try {
        let response = await axios().delete(`/media/delete/${payload.id}`);
        // context.commit("UPDATE_PRODUCT", response.data.product);
        return response.data;
      } catch (error) {
        //console.log("UPDATE_PRODUCT:error", error);
        return error;
      }
    },
    BULK_UPDATE_PRODUCTS: async ({ commit, dispatch }, payload) => {
      try {
        let response = await axios().post("/products/bulk_update", payload);
        // context.commit("UPDATE_PRODUCT", response.data.product);
        dispatch("LOAD_PRODUCTS")
        return response.data;
      } catch (error) {
        // console.log("UPDATE_PRODUCT:error", error);
        return error;
      }
    },
    BULK_DELETE_PRODUCTS: async ({ commit, dispatch }, payload) => {
      try {
        let ids = payload.map(item => item.id);

        let response = await axios().post("/products/bulk_delete", ids);
        // context.commit("UPDATE_PRODUCT", response.data.product);
        dispatch("LOAD_PRODUCTS")
        payload.forEach(product => {
          context.commit("REMOVE_PRODUCT", product);
        });

        return response.data;
      } catch (error) {
        // console.log("UPDATE_PRODUCT:error", error);
        return error;
      }
    }
  }
};
