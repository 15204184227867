<template>
  <div>
    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <div class="card">
          <div class="card-body">
            <VisualizationList />
          </div>
        </div>
      </div>
      <div class="col-3" id="responsive-filter-container">
        <div class="card">
          <div class="card-body">
            <VisualizationFilter page="visualization" key="info" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// Components
import VisualizationList from "@/components/visualization/VisualizationList";
import VisualizationFilter from "@/components/Filters/VisualizationFilter";

export default {
  components: {
    VisualizationList,
    VisualizationFilter,
  },
  data() {
    return {
      //...
    };
  },
  mounted() {
    this.LOAD_VISUALIZATION()
      .then((_resolve) => { })
      .catch((error) => {
        this.$toast.error(" ", error?.message || "Houve um erro ", {
          position: "topRight",
          timeout: 3000,
        });
        //console.log(`LoadVisualizationError: ${error?.message}`);
      });
  },
  computed: {
    ...mapGetters(["visualizations"]),
  },
  methods: {
    ...mapActions(["LOAD_VISUALIZATION"]),
    //...
  },
};
</script>
