<template>
  <transition name="slide-in" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  components: {}
};
</script>

<style lang="scss"></style>
