<template>
  <div class="card">
    <div class="card-header ">
      <div>Variações</div>
    </div>
    <div class="card-body">
      <small class="">
        * Dê dois cliques na variação para abrir as opções. <br />
        * Ao adicionar variações, a quantidade geral passa a ser a soma do total
        de opções.
      </small>

      <div class="col-9 mt-2 align-items-center justify-content-start w-100"
        style="padding: 0.5rem; height: 2rem; height: auto; border: 0.1rem solid #ffa7a7; background-color: #f1d8d8;">
        <span style="color:#000" class="p-0 m-0">
          Atenção:  Você pode selecionar apenas uma(1) variação por produto
        </span>
      </div>

      <div class="col-9 mt-2 align-items-center justify-content-start w-100"
        style="padding: 0.5rem; height: 2rem; height: auto; border: 0.1rem solid #ffa7a7; background-color: #f1d8d8;">
        <span style="color:#000" class="p-0 m-0">
          Atenção:  Preencha todo o formulário da opção selecionada
        </span>
      </div>

      <div class="col-9 mt-2 align-items-center justify-content-start w-100"
        style="padding: 0.5rem; height: 2rem; height: auto; border: 0.1rem solid #cac679; background-color: #f3e8a3;">
        <span style="color:#000" class="p-0 m-0">
          Atenção:  Clique sempre no icone
          <span>
            <i title="Selecionar" class="fas fa-pencil-alt"></i>
          </span>
          e depois no icone do
          <i title="Salvar" class="far fa-save"></i>
          para salvar a variação e suas opções
        </span>
      </div>

      <div class="d-flex justify-content-center  w-100 mt-3">
        <div ref="add_variation_btn" v-if="starting">
          <button class="btn btn-primary" @click="addVariation">
            Adicionar variação ao produto
          </button>
        </div>
        <div v-else class="form-row scale-in-center w-100 ">
          <div class="col-4 p-2">
            <div class="d-flex justify-content-between align-items-baseline">
              <strong>Variações</strong>
              <div>
                <i
                  v-if="!isCreatingVariation"
                  class="fas fa-plus mr-2 pointer"
                  @click="toggleNewVariation()"
                ></i>

                <i
                  v-else
                  class="fas fa-times mr-2 pointer"
                  @click="toggleNewVariation()"
                ></i>
              </div>
            </div>

            <ul class="list-group mt-3">
              <!-- ===================== Creating variation =================================-->
              <li
                v-if="isCreatingVariation"
                class="list-group-item d-flex align-items-center pointer selected scale-in-center"
              >
                <CreatingInput
                  class="selected "
                  v-if="isCreatingVariation"
                  v-model="tempVariationName"
                  @save="saveNewVariation()"
                  @cancel="toggleNewVariation()"
                />
              </li>

              <!-- ===================== Creating variation =================================-->
              <li
                class="list-group-item d-flex align-items-center pointer"
                v-for="variation in variations"
                :key="variation.id"
                :ref="'variation_el_' + variation.id"
                :class="[
                  {
                    selected: variationIsSelected(variation.id)
                  }
                ]"
                @dblclick="selectVariation(variation)"
              >
                <div v-if="editingVar.id !== variation.id" class="w-100 d-flex">
                  <div>
                    <label class="mb-0">
                      <input
                        type="checkbox"
                        :checked="_isSettedInArr(variation.id, 'variations')"
                        :disabled="!isInDetails('variation_id', variation.id)"
                        @click="chooseVariation(variation.id)"
                      />
                    </label>
                    <span class="ml-3 w-100">
                      {{ variation.name }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center ml-auto">
                    <i
                      class="fas fa-pencil-alt"
                      @click="editVariation(variation)"
                    ></i>
                    <i
                      class="far fa-trash-alt ml-4"
                      @click="deleteVariation(variation)"
                    ></i>
                  </div>
                </div>
                <CreatingInput
                  v-if="editingVar.id === variation.id && isEditingVariation"
                  class="selected "
                  v-model="editingVar.name"
                  @save="updateVariation()"
                  @cancel="cancelVariationEdition()"
                />
              </li>
            </ul>
          </div>
          <div class="col-4 p-2">
            <div class="d-flex justify-content-between align-items-baseline">
              <strong>Opções</strong>

              <i
                class="fas fa-plus mr-2 pointer"
                v-if="
                  !$masterHelper.isEmpty(tempVariation) &&
                    !isCreatingVariation &&
                    !isEditingVariation
                "
                @click="toggleNewOption()"
              ></i>
            </div>
            <ul class="list-group mt-3">
              <li
                class="list-group-item d-flex align-items-center pointer selected scale-in-center"
                v-if="isCreatingOption"
              >
                <CreatingInput
                  class="selected "
                  v-model="tempOptionName"
                  @save="saveNewOption()"
                  @cancel="toggleNewOption()"
                />
              </li>
              <li
                class="list-group-item"
                v-if="
                  !$masterHelper.isEmpty(tempVariation) &&
                    !hasOptions.length &&
                    !isCreatingOption &&
                    !isEditingOption
                "
              >
                Crie uma nova opção.
              </li>
              <li
                :ref="'option_el_' + option.id"
                class="list-group-item d-flex align-items-center pointer"
                v-for="option in hasOptions"
                :key="option.id"
                @dblclick="selectOption(option)"
                :class="[
                  {
                    selected: optionIsSelected(option.id)
                  }
                ]"
              >
                <!-- list options -->
                <div
                  class="w-100 d-flex align-items-center"
                  v-if="option.id !== editOption.id"
                >
                  <div>
                    <label class="mb-0">
                      <input
                        @click="chooseOption(option.id)"
                        type="checkbox"
                        :checked="_isSettedInArr(option.id)"
                        :disabled="!isInDetails('option_id', option.id)"
                      />
                    </label>
                    <span class="ml-3 w-100">
                      {{ option.name }}
                    </span>
                  </div>
                  <div class="ml-auto">
                    <i
                      class="fas fa-pencil-alt"
                      @click="editOptions(option)"
                    ></i>
                    <i
                      class="far fa-trash-alt ml-4"
                      @click="deleteOption(option)"
                    ></i>
                  </div>
                </div>
                <!-- list options -->
                <!-- edit option -->
                <CreatingInput
                  v-if="editOption.id === option.id && isEditingOption"
                  class="selected w-100"
                  v-model="editOption.name"
                  @save="updateOption()"
                  @cancel="cancelOptionEdition()"
                />
                <!-- edit option -->
              </li>
            </ul>
          </div>

          <div class="col-4 p-2">
            <div class="d-flex justify-content-between align-items-baseline">
              <strong
                >Detalhes{{
                  !$masterHelper.isEmpty(tempOption)
                    ? ` da opção "${tempOption.name}"`
                    : ""
                }}</strong
              >

              <!-- <i class="fas fa-plus mr-2"></i> -->
            </div>
            <div
              class="mt-2"
              v-if="!$masterHelper.isEmpty(tempOption) && canAddMore()"
            >
              <div v-if="getDetailIndex() == -1">
                <div class="form-group">
                  <label for="">Quantidade</label>
                  <input
                    v-number
                    v-model="tempDetails.quantity"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de venda</label>
                  <input
                    v-money="money"
                    v-model="tempDetails.price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de custo</label>
                  <input
                    v-money="money"
                    v-model="tempDetails.cost_price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço promocional</label>

                  <input
                    v-money="money"
                    v-model="tempDetails.sale_price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Quantidade para Atacado</label>
                  <input
                    v-number
                    v-model="tempDetails.wholesale_quantity"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de Atacado</label>
                  <input
                    v-money="money"
                    v-model="tempDetails.retail_price"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="">SKU</label>
                  <input
                    v-model="tempDetails.sku"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div v-else>
                <div class="form-group">
                  <label for="">Quantidade</label>
                  <input
                    v-model="details[getDetailIndex()].quantity"
                    type="text"
                    class="form-control"
                    placeholder="$0"
                    v-number
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de venda</label>
                  <input type="hidden" name="" />
                  <input
                    v-money="money"
                    v-model="details[getDetailIndex()].price"
                    type="text"
                    class="form-control"
                    placeholder="$0"
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de custo</label>

                  <input
                    v-money="money"
                    v-model="details[getDetailIndex()].cost_price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço promocional</label>

                  <input
                    v-money="money"
                    v-model="details[getDetailIndex()].sale_price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">Quantidade para Atacado</label>
                  <input
                    v-number
                    v-model="details[getDetailIndex()].wholesale_quantity"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="">Preço de Atacado</label>
                  <input
                    v-money="money"
                    v-model="details[getDetailIndex()].retail_price"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="">SKU</label>
                  <input
                    v-model="details[getDetailIndex()].sku"
                    type="text"
                    class="form-control"
                    placeholder="$0"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="
                !canAddMore() &&
                  !$masterHelper.isEmpty(this.tempVariation) &&
                  model.length
              "
            >
              <strong
                >Você precisa desmarcar a variação selecionada anteriormente e
                salvar o produto.</strong
              >
              <br />
              <strong
                >Depois você consegue voltar aqui e adicionar uma variação
                diferente.</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreatingInput from "@/components/variations/CreationInput";
import INPUT_VALIDATIONS from "@/helpers/InputValidation.js";

import { VMoney } from "v-money";
export default {
  components: { CreatingInput },
  directives: { money: VMoney },

  data() {
    return {
      model: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "R$ ",
        suffix: "",
        precision: 2
      },
      quantity: {
        decimal: "",
        thousands: "",
        prefix: " ",
        suffix: "",
        precision: 1
      },
      //component switches
      isCreatingVariation: false,
      isEditingVariation: false,
      editingVar: {},
      tempVariation: {},
      selectedOption: {},
      tempVariationName: "",
      // options--------
      isCreatingOption: false,
      isEditingOption: false,
      editOption: {},
      tempOption: {},
      tempOptionName: "",
      //
      selectedVariations: [],
      selectedOptions: [],

      //details
      details: [],

      tempDetails: {
        wholesale_quantity: 0,
        variation_id: 0,
        option_id: 0,
        price: "",
        cost_price: "",
        sale_price: "",
        retail_price: "",
        sku: "",
        quantity: 0
      },

      starting: false,
      UPDATE: 0,
      loadedPage: false
    };
  },
  props: {
    value: {
      required: false
    },
    one_variation: {
      default: true
    }
  },
  watch: {
    tempDetails: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.sku.length && val.price.length && val.quantity) {
          this.getDetails();
        }
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.model = val;

        if (val.length) {
          if (!this.loadedPage) {
            this.details = val;
            this.onLoadSet();
            this.loadedPage = true;
          }
        }
      }
    }
  },
  mounted() {
    this.LOAD_VARIATION();
    this.LOAD_OPTIONS();
    INPUT_VALIDATIONS();
  },
  computed: {
    ...mapGetters(["variations", "options"]),
    hasOptions() {
      this.UPDATE;
      if (this.$masterHelper.isEmpty(this.tempVariation)) return [];
      return this.options.filter(
        op => op.variation_id == this.tempVariation.id
      );
    }
  },
  methods: {
    ...mapActions([
      "LOAD_VARIATION",
      "CREATE_VARIATION",
      "UPDATE_VARIATION",
      "DELETE_VARIATION",
      "LOAD_OPTIONS",
      "CREATE_OPTIONS",
      "UPDATE_OPTIONS",
      "DELETE_OPTIONS"
    ]),
    canAddMore() {
      if (!this.details.length) return true;
      if (this.one_variation) {
        return this.details.some(de => {
          return de.variation_id === this.tempVariation.id;
        });
      }
    },
    reset() {
      this.isCreatingVariation = false;
      this.isEditingVariation = false;
      this.editingVar = {};
      this.tempVariation = {};
      this.selectedOption = {};
      this.tempVariationName = "";
    },
    resetTempDetais() {
      this.tempDetails = {
        wholesale_quantity: 0,
        variation_id: 0,
        option_id: 0,
        price: "",
        cost_price: "",
        sale_price: "",
        retail_price: "",
        sku: "",
        quantity: 0
      };
    },
    addVariation(e) {
      //add class,
      this.$refs.add_variation_btn.classList.add("scale-out-center");
      //hide btn
      setTimeout(() => {
        this.starting = false;
      }, 700);
    },
    toggleNewVariation() {
      this.isCreatingVariation = !this.isCreatingVariation;
      this.isEditingVariation = false;
      this.tempVariation = {};
      this.editingVar = {};
      this.isCreatingOption = false;
      this.isEditingOption = false;

      this.tempOption = {};
      this.editOption = {};
      if (this.isCreatingVariation) {
      } else {
        this.tempVariationName = "";
      }
    },
    variationIsSelected(id) {
      if (this.$masterHelper.isEmpty(this.tempVariation)) return false;
      return this.tempVariation.id == id;
    },
    optionIsSelected(id) {
      if (this.$masterHelper.isEmpty(this.tempOption)) return false;
      return this.tempOption.id == id;
    },
    selectVariation(variation) {
      if (!this.$masterHelper.isEmpty(this.tempVariation)) {
        if (this.tempVariation.id == variation.id) return;
      }
      this.tempVariation = { ...variation };
      this.isCreatingOption = false;
      this.isEditingOption = false;
      this.tempOption = {};
    },
    editVariation(variation) {
      //console.log("variation", variation);
      if (this.isCreatingVariation) this.isCreatingVariation = false;
      this.editingVar = { ...variation };
      this.tempVariation = { ...variation };
      this.isEditingVariation = true;
    },
    cancelVariationEdition() {
      this.editingVar = {};
      this.isEditingVariation = false;
    },
    async saveNewVariation() {
      if (!this.tempVariationName.length || this.tempVariationName.length < 2) {
        this.$toast.error(
          " ",
          "Nome da variação esta vazia ou é curta de mais.",
          {
            position: "topRight",
            timeout: 3000
          }
        );
      } else {
        try {
          let res = await this.CREATE_VARIATION({
            name: this.tempVariationName
          });
          if (res.data.data.error) {
            this.$toast.error(
              " ",
              res.data.data.message ||
                "Algo inesperado aconteceu, tente novamente",
              {
                position: "topRight",
                timeout: 5000
              }
            );
            this.reset();
          }

          if (!res.data.data.error) {
            this.$toast.success(
              " ",
              "Variação criada, agora adicione opções.",
              {
                position: "topRight",
                timeout: 5000
              }
            );
            this.reset();
            this.tempVariation = res.data.data;
          }
        } catch (error) {
          this.$toast.error(" ", "Algo inesperado aconteceu, tente novamente", {
            position: "topRight",
            timeout: 5000
          });
          this.reset();
        }
      }
    },
    async deleteVariation(variation) {
      let el = this.$refs[`variation_el_${variation.id}`][0];

      try {
        el.classList.add("slide-out-left");
        let res = await this.DELETE_VARIATION(variation);

        if (res.data.data.error) {
          this.$toast.error(" ", res.data.message, {
            position: "topRight",
            timeout: 5000
          });
          el.classList.remove("slide-out-left");
        } else {
          this._removefromSettedArr(variation.id, "variations");
          this.$toast.success(" ", `Variação ${variation.name} deletada`, {
            position: "topRight",
            timeout: 3000
          });

          el.remove();
        }
      } catch (error) {
        el.classList.add(`slide-in-right`);
        this.$toast.error(
          " ",
          `Erro ao deletar ${variation.name}, tente novamente`,
          {
            position: "topRight",
            timeout: 3000
          }
        );
      }
    },
    async updateVariation() {
      let variation = { ...this.editingVar };
      try {
        if (!variation.name.length || variation.name.length < 2) {
          this.$toast.error(
            " ",
            "Nome da variação esta vazia ou é curta de mais.",
            {
              position: "topRight",
              timeout: 3000
            }
          );
        } else {
          let res = await this.UPDATE_VARIATION(variation);
          if (res.data.data.error) {
            this.$toast.danger(
              " ",
              res.data.message || `Algo inesperado aconteceu, tente novamente.`,
              {
                position: "topRight",
                timeout: 3000
              }
            );
            this.reset();
            this.tempVariation = variation;
            this.isEditingVariation = false;
          } else {
            this.$toast.success(" ", `Variação ${variation.name} atualizada`, {
              position: "topRight",
              timeout: 3000
            });

            this.reset();
            this.tempVariation = res.data.data;
          }
        }
      } catch (error) {
       // console.log("error----", error);
        this.$toast.error(
          " ",
          `Erro ao atualizar ${variation.name}, tente novamente`,
          {
            position: "topRight",
            timeout: 3000
          }
        );
        this.reset();
        this.editingVar = variation;
        this.tempVariation = variation;
      }
    },
    editOptions(option) {
     // console.log("editOptions option", option);
      if (this.isCreatingOption) this.isCreatingOption = false;
      this.editOption = { ...option };
      this.tempOption = { ...option };
      this.isEditingOption = true;
    },
    chooseVariation(id) {
      let index = this.selectedVariations.findIndex(selVar => {
        return selVar.id === id;
      });
      if (index == -1) {
        this.selectedVariations.push({ id: id });
        this.checkIfDetailCanExist("variation_id", id);
        this.checkDetailsToTransmit();
      } else {
        this.selectedVariations.splice(index, 1);
        this.checkIfDetailCanExist("variation_id", id);
        this.removeVariationOption(id);
        this.checkDetailsToTransmit();
      }
    },
    removeVariationOption(variation_id) {
      let details = this.details.filter(detail => {
        return detail.variation_id === variation_id;
      });
      //remove selectedOptions that belongs to the incomming id
      details.forEach(d => {
        let index = this.selectedOptions.findIndex(selOp => {
          return selOp.id == d.option_id;
        });
        this.selectedOptions.splice(index, 1);
      });
    },

    checkDetailsToTransmit() {
      let details = [...this.details];
      let transmitThis = [];
      const containsBoth = (variation_id, option_id) => {
        let selectedVar = this._isSettedInArr(variation_id, "variations");
        let selectedOpt = this._isSettedInArr(option_id);
        return selectedVar && selectedOpt;
      };
      transmitThis = details.filter(detail => {
        return containsBoth(detail.variation_id, detail.option_id);
      });
     // console.log("the event back is", transmitThis);
      this.$emit("input", transmitThis);
      this.$forceUpdate();
    },

    chooseOption(id) {
      let index = this.selectedOptions.findIndex(selected => {
        return selected.id === id;
      });
      if (index == -1) {
        this.selectedOptions.push({ id: id });
        this.checkIfDetailCanExist("option_id", id);
        this.checkDetailsToTransmit();
      } else {
        this.selectedOptions.splice(index, 1);
        this.checkIfDetailCanExist("option_id", id);
        this.checkDetailsToTransmit();
      }
    },
    onLoadSet() {
      this.details.forEach(det => {
        let containsTheVariation = this._isSettedInArr(
          det.variation_id,
          "variations"
        );
        let containsTheOption = this._isSettedInArr(det.option_id);
        // console.log("isInDetails", {
        //   containsTheOption: containsTheOption,
        //   containsTheVariation: containsTheVariation
        // });
        if (!containsTheVariation) {
          this.selectedVariations.push({ id: det.variation_id });
        }
        if (!containsTheOption) {
          this.selectedOptions.push({ id: det.option_id });
        }
      });
    },
    checkIfDetailCanExist(key, val) {
      // loop through details
      let emitThis = [];

      let data = [
        ...this.details.filter(de => {
          return de[key] == val;
        })
      ];

      data.forEach((d, index) => {
        //get index to remove
        emitThis.splice(index, 1);
      });

      // emit only if details has both ids
      this.$emit("input", emitThis);
    },
    _isSettedInArr(id, what = null) {
      let searchName = "Options";
      if (what && what === "variations") {
        searchName = "Variations";
      }
      return this[`selected${searchName}`].some(selected => {
        return selected.id === id;
      });
    },
    _removefromSettedArr(id, what = null) {
      let searchName = "Options";
      if (what && what === "variations") {
        searchName = "Variations";
      }
      let index = this[`selected${searchName}`].findIndex(selected => {
        return selected.id === id;
      });
      this[`selected${searchName}`].splice(index, 1);
      this.$forceUpdate();
    },
    isInDetails(key, value) {
      let isIt = this.details.some(detail => {
        return detail[key] == value;
      });

      return isIt;
    },
    getDetailIndex() {
      let variation_id = this.tempVariation.id;
      let option_id = this.tempOption.id;
      if (!this.details.length) return -1;
      return this.details.findIndex(de => {
        return de.variation_id == variation_id && de.option_id == option_id;
      });
    },
    getDetails() {
      let variation_id = this.tempVariation.id;
      let option_id = this.tempOption.id;
      //check details
      let data = { ...this.tempDetails };
      data.variation_id = variation_id;
      data.option_id = option_id;

      if (!this.details.length) {
        this.details.push(data);
        this.selectedVariations.push({ id: variation_id });
        this.selectedOptions.push({ id: option_id });
        this.checkDetailsToTransmit();
        return 0;
      } else {
        //check if exists
        let index = this.details.findIndex(de => {
          return de.variation_id == variation_id && de.option_id == option_id;
        });

        if (index == -1) {
          this.details.push(data);
          if (!this._isSettedInArr(variation_id, "variations")) {
            this.selectedVariations.push({ id: variation_id });
          }

          this.selectedOptions.push({ id: option_id });
          this.checkDetailsToTransmit();
          return this.details.findIndex(de => {
            return de.variation_id == variation_id && de.option_id == option_id;
          });
        } else {
          return index;
        }
      }
    },
    selectOption(option) {
      this.resetTempDetais();
      if (!this.$masterHelper.isEmpty(this.tempOption)) {
        if (this.tempOption.id == option.id) return;
      }
      this.tempOption = { ...option };
      this.editOption = {};
      this.isCreatingOption = false;
      this.isEditingOption = false;
    },
    toggleNewOption() {
      this.isCreatingOption = !this.isCreatingOption;
      this.isEditingOption = false;
      this.tempOption = {};
      this.editOption = {};

      if (this.isCreatingOption) {
      } else {
        this.tempOptionName = "";
      }
    },
    async saveNewOption() {
      if (!this.tempOptionName.length) {
        this.$toast.error(" ", "Nome da opção esta vazia.", {
          position: "topRight",
          timeout: 3000
        });
      } else {
        try {
          let res = await this.CREATE_OPTIONS({
            name: this.tempOptionName,
            variation_id: this.tempVariation.id
          });
          if (res.data.data.error) {
            this.$toast.error(
              " ",
              res.data.data.message ||
                "Algo inesperado aconteceu, tente novamente",
              {
                position: "topRight",
                timeout: 5000
              }
            );
            this.isCreatingOption = false;
          }

          if (!res.data.data.error) {
            this.$toast.success(
              " ",
              "Opção criada, agora adicione os detalhes.",
              {
                position: "topRight",
                timeout: 5000
              }
            );
            this.tempOption = res.data.data;
            this.isCreatingOption = false;
            this.tempOptionName = "";
          }
        } catch (error) {
          //console.log("error on creating option", error);
          this.$toast.error(" ", "Algo inesperado aconteceu, tente novamente", {
            position: "topRight",
            timeout: 5000
          });
          this.isCreatingOption = false;
        }
      }
    },
    async updateOption() {
      let option = { ...this.editOption };
      try {
        if (!option.name.length || option.name.length < 2) {
          this.$toast.error(" ", "Nome da opção está vazia.", {
            position: "topRight",
            timeout: 3000
          });
        } else {
          let res = await this.UPDATE_OPTIONS(option);
          if (res.data.data.error) {
            this.$toast.danger(
              " ",
              res.data.message || `Algo inesperado aconteceu, tente novamente.`,
              {
                position: "topRight",
                timeout: 3000
              }
            );

            this.tempOption = option;
            this.isEditingOption = false;
          } else {
            this.$toast.success(" ", `Opção ${option.name} atualizada`, {
              position: "topRight",
              timeout: 3000
            });
            this.editOption = {};
            this.tempOption = res.data.data;
            this.isEditingOption = false;
            this.UPDATE++;
          }
        }
      } catch (error) {
        //console.log("error----", error);
        this.$toast.error(
          " ",
          `Erro ao atualizar a opção ${option.name}, tente novamente`,
          {
            position: "topRight",
            timeout: 3000
          }
        );
        this.editOption = option;
        this.tempOption = option;
        this.isEditingOption = false;
      }
    },
    cancelOptionEdition() {
      this.editOption = {};
      this.isEditingOption = false;
    },
    async deleteOption(option) {
      let el = this.$refs[`option_el_${option.id}`][0];
      try {
        //console.log("refs in ", el);

        el.classList.add("slide-out-left");

        let res = await this.DELETE_OPTIONS(option);

        if (res.data.data.error) {
          this.$toast.error(" ", res.data.message, {
            position: "topRight",
            timeout: 5000
          });
          el.classList.remove("slide-out-left");
        } else {
          this._removefromSettedArr(option.id, "option");
          this.$toast.success(" ", `Variação ${option.name} deletada`, {
            position: "topRight",
            timeout: 3000
          });

          el.remove();
        }
      } catch (error) {
       // console.log("error here", error);
        el.classList.remove(`slide-out-right`);
        this.$toast.error(
          " ",
          `Erro ao deletar ${option.name}, tente novamente`,
          {
            position: "topRight",
            timeout: 3000
          }
        );
      }
    }
  }
};
</script>

<style>
.selected {
  background-color: #093d22 !important;
  border-color: #093d22 !important;
  color: rgb(236, 188, 117) !important;
}
</style>
