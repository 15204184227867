<template>
  <div>
    <div class="form-row" v-if="model.name">
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Nome:</strong>
          <p v-if="!isEditing">{{ model.name }}</p>
          <input id="name" v-if="isEditing" type="text" v-model="tempUser.name" class="form-control"
            placeholder="Nome" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Email:</strong>
          <p v-if="!isEditing">
            {{ model.email }}
          </p>

          <input id="email" v-if="isEditing" type="text" v-model="tempUser.email" class="form-control"
            placeholder="CPF ou CNPJ" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Contatos:</strong>
          <small v-if="isEditing"> Use virgula para separar os números</small>
          <p v-if="!model.user_info || (!model.user_info.contacts && !isEditing)">
            -
          </p>
          <div v-if="
            model.user_info && model.user_info.contacts.length && !isEditing
          ">
            <span v-for="(contact, index) in model.user_info.contacts" :key="contact" class="pointer text-primary">{{
              contact
            }}<span v-if="index < model.user_info.contacts.length - 1" class="mr-1">,</span>
            </span>
          </div>
          <div v-if="!model.user_info.contacts.length">-</div>
          <input v-if="isEditing" id="contacts" type="text" class="form-control" @keyup="traceNumbers" v-model="numbers"
            placeholder="contatos" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3" v-if="$hasRole(tempUser, ['client', 'seller', 'user', ''])">
          <strong for="">CPF/CNPJ:</strong>

          <p v-if="!isEditing">
            {{ model.cpf_cnpj || model.user_info.cpf_cnpj || "-" }}
          </p>

          <input id="cpf_cnpj" v-if="isEditing" type="text" v-model="tempUser.cpf_cnpj" class="form-control"
            placeholder="CPF ou CNPJ" />
        </div>
      </div>

      <div class="col-3">
        <div class="mt-3">
          <div class="form-group">
            <strong for="">Tipo da conta</strong>
            <multiselect v-if="isEditing" :disabled="isSaving" :multiple="false" id="role" v-model="tempUser.roles[0]"
              :options="roles.filter((role) => role.guard_name === 'api')" :show-labels="false" track-by="name"
              label="name">
            </multiselect>
            <p v-if="model.roles && model.roles.length && !isEditing">
              {{ model.roles[0].name }}
            </p>
            <p v-if="!model.roles || (!model.roles.length && !isEditing)">-</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3" v-if="$hasRole(tempUser, ['client', 'seller'])">
          <strong for="">I.E</strong>
          <p v-if="!isEditing">
            {{ (model.user_info && model.user_info.ie) || "-" }}
          </p>

          <input id="ie" v-if="isEditing" type="text" v-model="tempUser.user_info.ie" class="form-control"
            placeholder="I.E" />
        </div>
      </div>

      <div class="col-3">
        <div class="mt-3" v-if="$hasRole(tempUser, ['client'])">
          <strong for="">Codigo do vendedor:</strong>
          <p v-if="!isEditing">
            {{ model.client ? model.client.seller_id : "" }}
          </p>
          <input id="seller_id" v-if="isEditing" type="text" v-model="tempUser.seller_id" class="form-control"
            placeholder="Codigo do vendedor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      isSaving: false,
      model: this.value,
      numbers: "",
      tempUser: {
        roles: [],
        user_info: {
          cpf_cnpj: "",
        },
      },
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.model = val;
        if (val) {
          this.tempUser = this.value;
        }
        if (val && val.user_info && !val.user_info.contacts) {
          this.tempUser.user_info.contacts = [];
        }
      },
    },
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit("input", val);
        }
      },
    },
    isEditing: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == true) {
          this.tempUser = this.value;
          if (!this.value.roles || !this.value.roles.length) {
            this.tempUser.roles = [];
          }
          if (this.value.user_info && !this.value.user_info.contacts) {
            this.tempUser.user_info.contacts = [];
          } else {
            this.numbers = this.value.user_info
              ? this.value.user_info.contacts.toString()
              : [];
          }
        }
      },
    },
  },
  mounted() { },
  props: {
    isEditing: {
      default: false,
    },
    value: {
      required: false,
    },
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  methods: {
    ...mapActions(["UPDATE_USER"]),
    traceNumbers() {
      let split = this.numbers.split(",");
      if (split.length <= 5) {
        this.tempUser.user_info.contacts = split;
      }

      // console.log(split);
    },
    update() {
      this.$toast.info(" ", "Atualizando", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      this.UPDATE_USER(this.tempUser)
        .then((response) => {
          if (response?.code !== 201) {
            this.$toast.warning(
              " ",
              response?.messageRaw?.message || response?.message,
              {
                icon: "icon-person",
                position: "topRight",
                timeout: 2000,
              }
            );

            return;
          }

          this.$toast.success(
            " ",
            response?.messageRaw?.message || response?.message || "Atualizado",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          );
        })
        .catch((error) => {
          this.$toast.warning(
            " ",
            error?.message || "Não foi possivel atualizar os dados",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          );
        });
    },
  },
};
</script>

<style>

</style>
