<template>
  <div>
    <BreadCrumb pageInfo="Todos as categorias" page="Categorias">
      <div slot="right-side">
        <!-- <button class="btn btn-primary">Nova categoria</button> -->
        <CategoryModal btnClass="btn-primary" />
      </div>
    </BreadCrumb>
    <div class="mb-2">
      <small class="alert alert-info">Dê dois cliques para editar.</small>
    </div>
    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <CategoriesList />
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <div key="info" class="card" style="position:fixed; width:20%" v-show="$masterHelper.isEmpty(category)">
            <div class="card-body">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label for="">Filtros</label>
                  <label class="text-danger pointer" @click="reset()">Limpar filtros</label>
                </div>
                <input @keyup.enter="filterCate()" class="form-control" type="text" placeholder="Pesquisar"
                  v-model="filter.search" :disabled="isSearching" />
                <small>Pesquise por nome ou descrição.</small>
              </div>
              <div class="form-group">
                <div class="form-group">
                  <label for="">Status</label>
                  <select class="form-control" v-model="filter.active">
                    <option value="1">Ativo</option>
                    <option value="0">Desativado</option>
                    <option value="all">Todos</option>
                  </select>
                </div>
              </div>
              <div>
                <button class="btn btn-primary btn-block" :disabled="isSearching" @click="filterCate()">
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
          <div v-show="!$masterHelper.isEmpty(category)" key="edition" style="position:fixed; width:20%">
            <CategoryInfo />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CategoriesList from "@/components/categories/CategoriesList";
import CategoryInfo from "@/components/categories/CategoryInfo";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import CategoryModal from "@/components/categories/CategoryModal";

export default {
  components: {
    CategoriesList,
    CategoryInfo,
    BreadCrumb,
    CategoryModal
  },
  data() {
    return {
      isSearching: false,
      filter: {
        active: "all",
        start: null,
        end: null,
        search: null
      }
    };
  },
  computed: {
    ...mapGetters(["categories", "category", "categoryBackup"])
  },
  mounted() {
    this.LOAD_CATEGORIES();
  },
  methods: {
    ...mapActions(["LOAD_CATEGORIES", "FILTER_CATEGORIES", "CLEAN_FILTER_CATEGORIES"]),
    filterCate() {
      this.isSearching = true;
      let starts = Date.parse(this.filter.start);
      let ends = Date.parse(this.filter.end);
      if (this.categoryBackup.length) {
        this.CLEAN_FILTER_CATEGORIES();
      }

      let res = [];
      if (this.filter.search) {
        res = this.categories.filter(tag => {
          return (
            tag.name.toLowerCase().includes(this.filter.search.toLowerCase()) ||
            tag.description
              ?.toLowerCase()
              .includes(this.filter.search.toLowerCase())
          );
        });
      } else {
        res = this.categories;
      }

      if (this.filter.start) {
        res = res.filter(tag => {
          // console.log(
          //   `tag created_at: ${this.$date(tag.created_at)} = ${Date.parse(
          //     this.$date(tag.created_at)
          //   )} filter starts at ${this.$date(this.filter.start)} = ${starts}`
          // );

          // return Date.parse(this.$date(tag.created_at)) >= starts;
          return (
            new Date(this.$date(tag.created_at) + " 00:00") >=
            new Date(this.$date(this.filter.start) + " 00:00")
          );
        });
      }
      if (this.filter.end) {
        res = res.filter(t => {
          // console.log(
          //   `tag created_at:${new Date(this.$date(t.created_at) + " 00:00")}`
          // );
          // console.log(`tag below :${new Date(this.filter.end + " 00:00")}`);
          // return Date.parse(this.$date(t.created_at)) <= ends;
          return (
            new Date(this.$date(t.created_at) + " 00:00") <=
            new Date(this.$date(this.filter.end) + " 00:00")
          );
        });
      }

      if (this.filter.active !== "all") {
        res = res.filter(r => {
          return r.active == this.filter.active;
        });
      }

      //console.log("filter:res final", res);
      this.FILTER_CATEGORIES(res).then(() => {
        this.isSearching = false;
      });
    },

    reset() {
      this.filter = {
        active: "all",

        start: null,
        end: null,
        search: null
      };
      this.filterCate();
    }
  }
};
</script>

<style>

</style>
