<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-baseline justify-content-between">
          <hgroup>
            <h4>
              <strong>Políticas</strong>
            </h4>
          </hgroup>
          <button class="btn btn-primary" @click="save('pages_laws')" :disabled="isSaving">
            {{
              isSaving ? 'Por Favor, aguarde!' : 'Salvar'
             }}
          </button>
        </div>
        <div class="col mt-5">
          <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <h4>Política de Privacidade</h4>
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  gap-1
                  flex-wrap
                "
              >
                <vue-editor
                  :editorToolbar="customToolbar"
                  v-model="pages_default.private"
                  :disabled="isSaving"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <h4>Termos de uso</h4>
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  gap-1
                  flex-wrap
                "
              >
               <vue-editor
                  :editorToolbar="customToolbar"
                  v-model="pages_default.terms"
                  :disabled="isSaving"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <h4>Política de Troca</h4>
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  gap-1
                  flex-wrap
                "
              >
               <vue-editor
                  :editorToolbar="customToolbar"
                  v-model="pages_default.replacement"
                  :disabled="isSaving"
                />
              </div>
            </div>
          </div>
      </div>
    </div>

      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { VMoney } from "v-money";

// Components
import Upload from "@/components/imageUpload/Upload";
import Multiselect from "vue-multiselect";
import { VueEditor } from "vue2-editor";

// Mixins
import Settings from "@/mixins/Settings.vue";

export default {
  directives: { money: VMoney },
  components: { Upload, Multiselect ,VueEditor},
  mixins: [Settings],
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isEditing: false,
      countSlider: 0,
      wantSlider: [],
      pages_default: {
        terms: "",
        private: "",
        replacement: ""
      },
      pages_default_temp: {},
      fontTypeList: {
        value: null,
        options: [
          {
            name: "Lato",
            family: "Lato",
          },
        ],
      },
      hasUpload: false,
      formatString: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 1,
        masked: false,
      },
    };
  },
  watch: {
    settings: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          let actualGroupID = -1
          this.store_sliders = []
          val.forEach(setting => {
            if (setting?.key === "pages_laws") {
              this.pages_law = setting?.content || {}
            }
          })
        } catch (error) {
          // do anything
        }
      },
    }
  },
  computed: {
    ...mapGetters(["settings", "tagColorsList"]),

    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "pages_laws";
        }) || false
      );
    },
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      this.pages_default = {
        ...this.myGateways?.content || {}
      };
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS"]),
    save(key = "pages_laws") {
      let data = this.pages_default;
      this.isSaving = true;
      this.$pace.restart();

      // TODO - THIS NEWS VERSION (V2) CREATE THE SETTINGS ALSO CAN UPDATE THE SETTINGS
      this.CREATE_SETTINGS({
        content: data,
        key: key,
      }).then((res) => {
        this.$pace.restart();
        this.UPDATE++;
        if (res.code === 201) {
          this.$toast.success(" ", "Concluido", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.loadSet();
        } else {
          this.$toast.error(" ", "Houve uma falha.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
        this.isSaving = false;
      });
    }
  },
};
</script>

<style></style>
