<template>
  <div id="order_page">
    <!-- <BreadCrumb pageInfo="" page="Pedidos">
      <div slot="right-side"></div>
    </BreadCrumb> -->
    <!-- <div class="row">
      <div class="col-12 d-flex">
        <h2 class="text-dark mb-3 dash-session-title">Números de Produtos</h2>
        <hr class="w-100" />
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-6">
        <LatestSoldProducts />
      </div>
      <ProductReports v-can="['product-report']" />
    </div> -->
    <LastOrders v-can="['financial-report']" />

    <div class="row">
      <div class="col-12 d-flex">
        <h2 class="text-dark mb-3 dash-session-title">Pedidos</h2>
        <hr class="w-100" />
      </div>
    </div>
    <div class="mb-2 mt-4">
      <small class="alert alert-info">Dê dois cliques para abrir ver o pedido.</small>
    </div>

    <div class="row" id="responsive-page-container">
      <div class="col-9 product-wrapper table-report" id="responsive-page-table">
        <OrderList :orders="orders" />
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <div key="info" v-if="$masterHelper.isEmpty(order)" ref="orderFilter">
            <MasterFilter class="fix-in-page w-100" smallLabel="Pesquise pelo código do pedido" page="Orders"
              :hideInputs="['categories', 'tags', 'active', 'roles']" :rules="{
                searchable: ['id'],
                relationships: ['user'],
              }" />
          </div>
          <div v-if="!$masterHelper.isEmpty(order)" key="edition">
            <div class="card">
              <div class="
                  card-header
                  d-flex
                  align-items-center
                  justify-content-between
                ">
                <div>Pedido nº {{ order.id }}</div>
                <span class="pointer align-self-end text-danger" @click="SELECT_ORDER({})">fechar</span>
              </div>
              <div class="card-body">
                <strong>Status</strong>
                <p class="mt-1">
                  <StatusBadge :status="order.status" />
                </p>
                <strong>Nome cliente</strong>
                <p v-if="order.user">
                  {{ order.user.name || getUser(order.buyer_id).name || '-' }}

                </p>
                <!-- <p v-if="users && users.length">
                  {{ order.buyer_id ? getUser(order.buyer_id).name : "-" }}
                </p> -->
                <strong>Vendedor</strong>
                <p v-if="order.seller">
                  {{ order.seller.name || getUser(order.seller_id).name || '-' }}
                </p>
                <!-- <p v-if="users && users.length">
                  {{ order.seller_id ? getUser(order.seller_id).name : "-" }}
                </p> -->
                <strong>Valor</strong>
                <p>R$ {{ $format($money(order.price)) }}</p>
                <div>
                  <a href="#">Nota fiscal</a>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <OrderModal />
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";
import OrderList from "@/components/orders/OrderList";
import OrderModal from "@/components/orders/OrderModal";
import StatusBadge from "@/components/status/Index";
import MasterFilter from "@/components/Filters/MasterFilter";

import LatestSoldProducts from "@/components/products/LatestSoldProducts";
import ProductReports from "@/components/products/ProductReports";

import LastOrders from "@/components/orders/LastOrders";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BreadCrumb,
    OrderList,
    OrderModal,
    StatusBadge,
    MasterFilter,
    LatestSoldProducts,
    ProductReports,
    LastOrders,
  },
  data() {
    return {
      UPDATE: 0,
      search: {
        client: null,
        code: null,
        status: null,
      },
      date: {
        starts: null,
        ends: null,
      },
    };
  },
  mounted() {
    this.$event.$on("order_updated", () => {
      this.UPDATE++;
    });
    this.positionTrigger();
    this.loadOrders();
  },
  watch: {
    // orders(val) {
    //   console.log("update", val);
    //   this.UPDATE++;
    // },
  },
  computed: {
    ...mapGetters(["orders", "order", "users", "ordersPaginate"]),
    orderList() {
      this.UPDATE;
      return this.orders;
    },
    loaded() {
      return this.users?.length && this.orders?.length;
    },
  },
  methods: {
    ...mapActions([
      "LOAD_ORDERS",
      "SELECT_ORDER",
      "SELECT_ORDER",
      "QUICK_LIST",
      "CLEAR_ORDERS",
    ]),
    loadOrders() {
      this.QUICK_LIST();
      if (this.$route.name !== "user.details") {
        // if (this.CLEAR_ORDERS()) this.LOAD_ORDERS();
        // console.log(`ROTA ATUAL: ${this.$route.name}`);
      }
      //console.log("loading");
    },
    getUser(id) {
      const usersRaw = this.users?.length > 0 ? this.users : this.usersList

      return usersRaw?.find((user) => user.id === id);
    },
    positionTrigger() {
      setTimeout(() => {
        let el = document.getElementById("main-wrapper");

        // console.log("the positionTrigge0r element", el);

        el.addEventListener("scroll", (e) => {
          // console.log("scrolling", e);
          // console.log("scrollTop", this.scrollTop);

          if (this.scrollTop > 147) {
            e.classList.add("fix-in-page");
          } else {
            e.classList.contains("fix-in-page");
            e.classList.remove("fix-in-page");
          }
        });
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.fix-in-page {
  position: fixed;
  width: 20%;
}
</style>
