import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";

Vue.use(Vuex);

export default {
  state: {
    status: [],
    isLoad: false
  },

  getters: {
    status_list: state => state.status,
    isLoad: state => state.isLoad
  },

  mutations: {
    LOAD_STATUS(state, status) {
      state.status = status;
    },
    SET_LOAD(state, isLoad) {
      state.isLoad = isLoad;
    }
  },

  actions: {
    LOAD_STATUS: async ({ commit, getters }, payload) => {
      try {

        if (getters.status?.length >= 1) return
        if (getters.isLoad === true) return

        commit('SET_LOAD', true)
        let response = await axios().get("/status_list");

        let data = response.data;

        let keys = Object.keys(data);

        let status = [];

        //console.log("the keys: ", keys);

        keys.forEach(k => {
          if (!isNaN(k)) {
            status.push({
              id: parseInt(k),
              name: data[k]
            });
          }
        });

        commit("LOAD_STATUS", status);
        // commit('SET_LOAD', false)
        return response;
      } catch (error) {
       // console.log("LOAD_STATUS:error", error);
        commit('SET_LOAD', false)
        return error;
      }
    }
  }
};
