<template>
  <header class="topbar" data-navbarbg="skin6" id="topHeader">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header" data-logobg="skin6" id="navBrand">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
            class="ti-menu ti-close"></i></a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-brand">
          <!-- Logo icon -->
          <span v-if="$hasRole(loggedUser, ['seller'])">
            <b class="logo-icon">
              <!-- Dark Logo icon -->
              <img loading="lazy" v-if="logo.trim() !== '#'" :src="`${$masterHelper.getURL(true)}${logo}.png`"
                alt="homepage" class="dark-logo w-100 mt-3" />
              <!-- Light Logo icon -->
              <!--<img loading="lazy"
                src="https://www.adminmart.com/src/assets/images/logo-icon.png"
                alt="homepage"
                class="light-logo"
              /> -->
            </b>
            <!--End Logo icon -->
            <!-- Logo text -->
            <!-- <span class="logo-text"> -->
            <!-- dark Logo text -->
            <!--<img loading="lazy" src="https://www.adminmart.com/src/assets/images/logo-text.png" alt="homepage" class="dark-logo" /> -->
            <!-- Light Logo text -->
            <!--<img loading="lazy" src="../assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->
            <!-- </span> -->
          </span>
          <span v-else @click.prevent="$router.push({ name: 'index' })">
            <b class="logo-icon">
              <!-- Dark Logo icon -->
              <img loading="lazy" v-if="logo.trim() !== '#'" :src="`${$masterHelper.getURL(true)}${logo}.png`"
                alt="homepage" class="dark-logo w-100 mt-3" />
            </b>
          </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation"><i class="ti-more"></i></a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav float-left mr-auto ml-3 pl-1">
          <!-- Notification -->
          <!-- <li
            id="toggle-menu-btn"
            class="nav-item dropdown"
            @click="toggleSideNav()"
          >
            <i class="fas fa-bars"></i>
          </li> -->
          <!-- End Notification -->
          <!-- ============================================================== -->
          <!-- create new -->
          <!-- ============================================================== -->
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-settings svg-icon"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                ></path>
              </svg>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li> -->
          <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link" href="javascript:void(0)">
              <div class="customize-input">
                <select
                  class="custom-select form-control bg-white custom-radius custom-shadow border-0"
                >
                  <option selected="">EN</option>
                  <option value="1">AB</option>
                  <option value="2">AK</option>
                  <option value="3">BE</option>
                </select>
              </div>
            </a>
          </li> -->
        </ul>
        <!-- ============================================================== -->
        <!-- Right side toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav float-right">
          <!-- ============================================================== -->
          <!-- Search -->
          <!-- ============================================================== -->
          <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link" href="javascript:void(0)">
              <form>
                <div class="customize-input">
                  <input
                    class="form-control custom-shadow custom-radius border-0 bg-white"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search form-control-icon"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </form>
            </a>
          </li> -->
          <!-- ============================================================== -->
          <!-- User profile and search -->
          <!-- ============================================================== -->

          <!--  Notification -->
          <!--=============================================================-->
          <li v-if="notification" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle pl-md-3 position-relative" href="javascript:void(0)" id="bell"
              role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-bell svg-icon">
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg></span>
              <span class="badge badge-primary notify-no rounded-circle">5</span>
            </a>
            <div class="
                dropdown-menu dropdown-menu-left
                mailbox
                animated
                bounceInDown
              ">
              <ul class="list-style-none">
                <li>
                  <div class="
                      message-center
                      notifications
                      position-relative
                      ps-container ps-theme-default
                    " data-ps-id="20df756e-5cda-00c1-72da-10db13468e40">
                    <!-- Message -->
                    <a href="javascript:void(0)" class="
                        message-item
                        d-flex
                        align-items-center
                        border-bottom
                        px-3
                        py-2
                      ">
                      <div class="btn btn-danger rounded-circle btn-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-airplay text-white">
                          <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                          <polygon points="12 15 17 21 7 21 12 15"></polygon>
                        </svg>
                      </div>
                      <div class="w-75 d-inline-block v-middle pl-2">
                        <h6 class="message-title mb-0 mt-1">Luanch Admin</h6>
                        <span class="font-12 text-nowrap d-block text-muted">Just see the my new admin!</span>
                        <span class="font-12 text-nowrap d-block text-muted">9:30 AM</span>
                      </div>
                    </a>
                    <!-- Message -->
                    <a href="javascript:void(0)" class="
                        message-item
                        d-flex
                        align-items-center
                        border-bottom
                        px-3
                        py-2
                      ">
                      <span class="
                          btn btn-success
                          text-white
                          rounded-circle
                          btn-circle
                        "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                          fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-calendar text-white">
                          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                          <line x1="16" y1="2" x2="16" y2="6"></line>
                          <line x1="8" y1="2" x2="8" y2="6"></line>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg></span>
                      <div class="w-75 d-inline-block v-middle pl-2">
                        <h6 class="message-title mb-0 mt-1">Event today</h6>
                        <span class="
                            font-12
                            text-nowrap
                            d-block
                            text-muted text-truncate
                          ">Just a reminder that you have event</span>
                        <span class="font-12 text-nowrap d-block text-muted">9:10 AM</span>
                      </div>
                    </a>
                    <!-- Message -->
                    <a href="javascript:void(0)" class="
                        message-item
                        d-flex
                        align-items-center
                        border-bottom
                        px-3
                        py-2
                      ">
                      <span class="btn btn-info rounded-circle btn-circle"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings text-white">
                          <circle cx="12" cy="12" r="3"></circle>
                          <path
                            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                          </path>
                        </svg></span>
                      <div class="w-75 d-inline-block v-middle pl-2">
                        <h6 class="message-title mb-0 mt-1">Settings</h6>
                        <span class="
                            font-12
                            text-nowrap
                            d-block
                            text-muted text-truncate
                          ">You can customize this template as you want</span>
                        <span class="font-12 text-nowrap d-block text-muted">9:08 AM</span>
                      </div>
                    </a>
                    <!-- Message -->
                    <a href="javascript:void(0)" class="
                        message-item
                        d-flex
                        align-items-center
                        border-bottom
                        px-3
                        py-2
                      ">
                      <span class="btn btn-primary rounded-circle btn-circle"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" class="feather feather-box text-white">
                          <path
                            d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z">
                          </path>
                          <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                          <line x1="12" y1="22.76" x2="12" y2="11"></line>
                        </svg></span>
                      <div class="w-75 d-inline-block v-middle pl-2">
                        <h6 class="message-title mb-0 mt-1">Pavan kumar</h6>
                        <span class="font-12 text-nowrap d-block text-muted">Just see the my admin!</span>
                        <span class="font-12 text-nowrap d-block text-muted">9:02 AM</span>
                      </div>
                    </a>
                    <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px">
                      <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px"></div>
                    </div>
                    <div class="ps-scrollbar-y-rail" style="top: 0px; right: 3px">
                      <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 0px"></div>
                    </div>
                  </div>
                </li>
                <li>
                  <a class="nav-link pt-3 text-center text-dark" href="javascript:void(0);">
                    <strong>Check all notifications</strong>
                    <i class="fa fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <!--  Notification -->
          <li class="nav-item dropdown pointer" title="Recarregar" onClick="location.reload()">
            <a class="nav-link"> <i class="fas fa-sync-alt"></i></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <!--<img loading="lazy"
                src="../../assets/images/users/1.png"
                alt="user"
                class="rounded-circle"
                width="40"
                height="40"
              /> -->
              <span class="ml-2 d-none d-lg-inline-block"><span>Olá, </span>
                <span class="text-dark">{{ loggedUser.name }}</span>

                <!-- <span class="text-dark"> {{ loggedUser.name }}</span> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-chevron-down svg-icon">
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY">
              <!-- <a class="dropdown-item mt-1" href="javascript:void(0)"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user svg-icon mr-2 ml-1"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                My Profile</a
              > -->

              <!-- <div class="dropdown-divider"></div> -->
              <a v-role="['dev', 'admin']" class="dropdown-item" @click.prevent="
                () => $router.push({ name: 'config.integrations' })
              "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-settings svg-icon mr-2 ml-1">
                  <circle cx="12" cy="12" r="3"></circle>
                  <path
                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                  </path>
                </svg>
                Configurações</a>
              <div v-role="['dev', 'admin']" class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click.prevent="logout()"><svg xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-power svg-icon mr-2 ml-1">
                  <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                  <line x1="12" y1="2" x2="12" y2="12"></line>
                </svg>
                Sair</a>
              <!-- <div class="dropdown-divider"></div>
              <div class="pl-4 p-3">
                <a href="javascript:void(0)" class="btn btn-sm btn-info"
                  >View Profile</a
                >
              </div> -->
            </div>
          </li>
          <!-- ============================================================== -->
          <!-- User profile and search -->
          <!-- ============================================================== -->
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Mixins
import Settings from "@/mixins/Settings.vue";

export default {
  name: "TopNav",
  mixins: [Settings],
  data() {
    return {
      logo: "",
      notification: false,
    };
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {},
  mounted() {
    this.loadSet("company").then((resolver) => {
      this.logo = resolver?.content?.logo?.background?.image || "#";
    });

    this.$event.$on("template-company", (data) => {
      this.logo = data || "#";
    });
  },
  methods: {
    ...mapActions(["LOGOUT"]),
    logout() {
      this.LOGOUT();
    },
    toggleSideNav() {
      this.$event.$emit("toggleSideNav");
    },
  },
};
</script>

<style>
.el-header.nav-header {
  width: 100% !important;
}

#toggle-menu-btn:hover {
  cursor: pointer;
}
</style>
