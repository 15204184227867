<template>
  <!-- SEO card -->
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <strong>Seo</strong>
      <strong class="text-primary pointer" v-if="!isEditing" @click="edit()"
        >Personalizar</strong
      >
      <strong class="text-primary pointer" v-else @click="cancel()"
        >Cancelar</strong
      >
    </div>
    <div class="card-body">
      <!-- =======================Default seo================================= -->
      <div v-if="!isEditing && !hasSeo">
        <strong class="seo-component-title">{{ formatTitle() }}</strong>

        <br />
        <small class="mt-2 seo-component-link"
          >/nome_produto_123</small
        >
        <br />
        <small style="color:#545454" v-if="model.summary">
          {{
            $masterHelper.removeHtml(model.summary.substr(0, 155)) ||
              "Mussum Ipsum cacilds vidis litro abertis."
          }}
        </small>
        <small style="color:#545454" v-if="model.body">
          {{
            $masterHelper.removeHtml(model.body.substr(0, 155)) ||
              "Mussum Ipsum cacilds vidis litro abertis."
          }}
        </small>
      </div>

      <div v-if="!isEditing && hasSeo">
        <strong class="seo-component-title">{{ model.seo.title }}</strong>

        <br />
        <small class="mt-2 seo-component-link"
          >/nome_produto_123</small
        >
        <br />
        <small style="color:#545454" v-if="model.seo.summary">
          {{
            $masterHelper.removeHtml(model.seo.summary.substr(0, 155)) ||
              "Mussum Ipsum cacilds vidis litro abertis."
          }}
        </small>
      </div>

      <div class="" v-if="isEditing">
        <div class="form-group">
          <label for="">Título</label>
          <input
            type="text"
            class="form-control"
            v-model="tempSeo.title"
            ref="seo_title"
          />
        </div>
        <div class="form-group">
          <label for="">Resumo</label>
          <textarea
            ref="seo_summary"
            class="form-control"
            v-model="tempSeo.summary"
            rows="2"
          ></textarea>
        </div>
        <button class="btn btn-primary btn-block" @click="save()">
          Salvar SEO
        </button>
      </div>
    </div>
  </div>
  <!-- SEO card -->
</template>

<script>
export default {
  name: "SimpleSeo",
  data() {
    return {
      model: this.value,
      isEditing: false,
      tempSeo: {}
    };
  },
  computed:{
    hasSeo(){
      return !this.$masterHelper.isEmpty(this.model.seo)
    }
  },
  watch: {
    tempSeo: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.summary) {
          this.tempSeo.summary = this.$masterHelper.removeHtml(
            val.summary
          );
        }
      }
    },
    value:{
       deep: true,
      immediate: true,

      handler(val){
        this.model = val
        if(val.seo){
          //('updated------------')
        }
      }

    },
    model: {
      deep: true,
      immediate: true,

      handler(val){
       // console.log('value in ==========', val)
        if(val && val.seo){
       // console.log('value in === seo ==', val)

          this.tempSeo = val.seo || {}
        }
      }

    }
  },
  props: {
    value: {
      required: false
    }
  },
  mounted(){
    // this.$forceUpdate();
  },
  methods: {
    edit() {
      this.isEditing = true;
      if(this.hasSeo){
        this.tempSeo = this.model.seo ;
      }else{
       // console.log('no seo ===')
        this.tempSeo = {
          title: this.model.title || this.model.name,
          summary: this.model.summary
         };
      }

    },
    cancel() {
      this.isEditing = false;
      this.tempSeo = {};
    },
    formatTitle() {
      return (
        (this.model.title && this.model.title.substr(0, 60)) ||
        (this.model.name && this.model.name.substr(0, 60)) ||
        "Título aqui"
      );
    },
    save() {

       let data = { ...this.model };

        data.seo = this.tempSeo ;

      if(!data.seo.title || !data.seo.title.length){
        this.$refs.seo_title.classList.add('border-danger')
      }

      // if(!data.seo.summary || !data.seo.summary.length){
      //   this.$refs.seo_summary.classList.add('border-danger')
      // }

      // if(data.seo.title && data.seo.summary){

      if(data.seo.title){
          this.$emit("input", data);
          this.isEditing = false;
          this.$forceUpdate();
      }


    },

  }
};
</script>

<style lang="scss">
.seo-component-title {
  color: #1a0dab;
}
.seo-component-link {
  color: #006621;
}
</style>
