<template>
  <div class="card" style="overflow-y: auto; max-height: 40rem !important">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span class="pointer" @click="editCategory()" v-if="!isEditing">
        <i class="far fa-edit"></i> Editar
      </span>
      <span
        class="pointer align-self-end text-danger"
        @click="VIEW_CATEGORY({})"
        v-if="!isEditing"
        >fechar</span
      >
      <span
        class="text-danger pointer"
        v-if="isEditing"
        @click="isEditing = false"
        >Cancelar</span
      >
    </div>
    <div class="card-body" v-if="!isEditing">
      <div class="form-group">
        <strong for="">Nome</strong>
        <p class="mt-1">{{ category.name }}</p>
      </div>
      <div class="form-group">
        <strong for="">Descrição</strong>
        <p class="mt-1">{{ category.description }}</p>
      </div>
      <div class="form-group">
        <strong for="">Sub categorias</strong>

        <div class="mt-1" v-if="category.sub_categories">
          <span
            class="badge badge-dark mr-2"
            v-for="category in category.sub_categories"
            :key="category.uuid"
            ><i class="fas fa-sitemap mr-1"></i> {{ category.name }}
          </span>
        </div>
      </div>
      <div class="form-group">
        <strong for="">Categoria principal</strong>
        <div>
          <span class="badge badge-dark mr-2 mt-2" v-if="category.category_main"
            ><i class="fas fa-sitemap mr-1"></i>
            {{ category.category_main.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="isEditing">
      <div class="form-group">
        <strong for="">Nome</strong>
        <input class="form-control" type="text" v-model="tempCategory.name" />
      </div>

      <div class="form-group">
        <strong for="">Descrição</strong>
        <textarea class="form-control" v-model="tempCategory.description" />
      </div>

      <div class="row" style="gap: 1rem">
        <div class="form-group">
          <toggle-button
            :width="80"
            :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC',
            }"
            v-model="tempCategory.active"
            :sync="true"
            :labels="{ checked: 'Ativo', unchecked: '  Desativado' }"
          />
        </div>

        <div class="form-group">
          <toggle-button
            :width="80"
            :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC',
            }"
            v-model="tempCategory.highlighted "
            :sync="true"
            :labels="{ checked: 'Destaque', unchecked: '  Desativado' }"
          />
        </div>
      </div>

      <div
        style="max-width: 500px; max-height: 400px"
        v-show="categoryImage !== '' && hasUpload === false"
      >
        <div>
         <img loading="lazy"
            :src="`https://krooma-api.011brasil.com.br/storage/${categoryImage}.png`"
            style="max-width: 100%; height: auto"
            title="Thumbnail atual"
          />
        </div>
      </div>

      <div style="max-width: 500px; max-height: 400px">
        <div>
          <Upload
            position="header"
            ref="up1"
            :noFavorite="true"
            :height="250"
            btnText="Adicionar nova thumbnail"
            @data_deleted="(data) => handlerDeleteLogo(data)"
            @added_item="(data) => handlerLogo(data)"
            :limit="1"
          />
        </div>
      </div>
      <br />

      <div class="row mb-2">
        <div class="col">
          <p class="alert alert-primary">
            Para cadastrar como subcategoria, selecione abaixo:
          </p>
          <label for="select-category-modal"
            >Pertence a alguma categoria?
            <small
              class="pointer text-danger ml-2"
              v-if="selectedParent"
              @click="selectedParent = null"
              >remover</small
            ></label
          >
          <multiselect
            :disabled="isSaving"
            v-model="selectedParent"
            @select="setSubCatParentId"
            :options="categories"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :preserve-search="true"
            placeholder="Selecione suas categorias"
            label="name"
            track-by="id"
            :multiple="false"
            id="select-category-modal"
          />
        </div>
      </div>

      <button class="btn btn-primary btn-block" @click="updateCategory()">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

// Components
import Upload from "@/components/imageUpload/Upload";

export default {
  components: { Multiselect, Upload },
  data() {
    return {
      isEditing: false,
      tempCategory: {},
      compareCategory: {},
      subsElement: null,
      canRun: false,
      selectedParent: null,
      isSaving: false,
      categoryImage: "",
      categoryImageDataURI: "",
      hasUpload: false,
    };
  },
  computed: {
    ...mapGetters(["category", "categories"]),
  },
  mounted() {
    this.$event.$on("category_selected", () => {
      this.$pace.restart();
      this.isEditing = false;
    });
    this.run();

    this.$event.$on("edit_category", () => {
      this.tempCategory = this.category;
      this.categoryImage = this.category?.thumbnail;
      this.isEditing = true;
    });
  },
  methods: {
    ...mapActions([
      "VIEW_CATEGORY",
      "UPDATE_CATEGORY",
      "BULK_UPDATE_CATEGORIES",
    ]),
    run() {
      //console.log("run");
      setTimeout(() => {
        this.canRun = true;
        this.subsElement = document.getElementById("subs");
        //console.log("run:done");
      }, 5000);
    },
    editCategory() {
      this.tempCategory = {};
      this.tempCategory = this.category;
      // this.tempCategory.active =
      //   parseInt(this.tempCategory.active) === true ? false : true;

      Object.assign(this.compareCategory, this.tempCategory);

      this.isEditing = !this.isEditing;
      this.$event.$emit("edit_category");
    },
    updateCategory() {
      this.isSaving = true;
      this.$toast.info(" ", "Atualizando", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      this.$pace.restart();
      this.VIEW_CATEGORY(this.tempCategory);
      this.UPDATE_CATEGORY({
        ...this.tempCategory,
        categoryImageDataURI: this.categoryImageDataURI,
      }).then((d) => {
        if (d.code === 201) {
          this.hasUpload = false;
          // this.tempCategory = { ...this.tempCategory, ...d.data };
          //console.log(`UPDATE_CATEGORY`, { ...this.tempCategory, ...d.data });
          this.$forceUpdate();
          this.$toast.success(
            " ",
            `A categoria ${this.tempCategory.name} foi atualizada`,
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          );
        }
        this.isSaving = true;
      });
      this.isEditing = false;
      this.$event.$emit("category_updated");
    },
    getSubCategories() {
      //console.log("this.category", this.category);

      // let cteIds = this.category.category_main.map(cte => cte.id);
      // return this.categories.filter(category => {
      //   return cteIds.some(cId => {
      //     return cId === category.id;
      //   });
      // });
    },
    setSubCatParentId(e) {
      this.tempCategory.category_id = e.id;
    },
    recurse(node) {
      let classes = [
        "badge",
        "bg-danger",
        "font-12",
        "text-white",
        "font-weight-medium",
        "badge-pill",
        "ml-2",
      ];
      for (var i = 0, count = node.sub_categories_all.length; i < count; i++) {
        //console.log(node.sub_categories_all[i].name);
        let badge = document.createElement("span");
        let ID = "subs_ " + node.sub_categories_all[i].id;
        badge.id = ID;
        badge.innerText = node.sub_categories_all[i].name;
        classes.forEach((cls) => {
          badge.classList.add(cls);
        });
        //console.log("node.sub_categories_all[i]", node.sub_categories_all[i]);
        this.subsElement.appendChild(badge);
        // if (node.sub_categories_all[i].sub_categories_all.length) {
        //   let sub = document.getElementById(
        //     "subs_ " + node.sub_categories_all[i].id
        //   );
        //   let subEl = document.createElement("span");
        //   subEl.id = "subs_ " + node.sub_categories_all[i].id;
        //   subEl.innerText = node.sub_categories_all[i].name;
        //   classes.forEach(cls => {
        //     subEl.classList.add(cls);
        //   });
        //   sub.appendChild(subEl);
        // }

        this.recurse(node.sub_categories_all[i]);
      }
    },
    handlerLogo(data = null) {
      try {
        if (!data) {
          this.hasUpload = false;
          return;
        }

        if (data?.src) {
          // this.categoryImage = data?.src || "";
          this.categoryImageDataURI = {
            data: data?.src || "",
            dataRaw: [],
          };
          this.hasUpload = true;
        }

        return;
      } catch (error) {
        // do anything
      }
    },
    handlerDeleteLogo(data = null) {
      try {
        this.categoryImageDataURI = "";
        this.hasUpload = false;
        return;
      } catch (error) {
        // do anything
      }
    },
  },
};
</script>

<style>
ul.tree,
ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  color: #369;
  font-weight: bold;
  border-left: 1px solid rgb(100, 100, 100);
}
ul.tree li:last-child {
  border-left: none;
}
ul.tree li:before {
  position: relative;
  top: -0.3em;
  height: 1em;
  width: 12px;
  color: white;
  border-bottom: 1px solid rgb(100, 100, 100);
  content: "";
  display: inline-block;
  left: -7px;
}
ul.tree li:last-child:before {
  border-left: 1px solid rgb(100, 100, 100);
}
</style>
