<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
       <span
        class="pointer align-self-end text-danger"
        @click="VIEW_PRODUCT({})"
        v-if="!isEditing"
        >fechar</span
      >
      <span class="pointer" @click="editProduct()" v-if="!isEditing">
        <i class="far fa-edit"></i> Editar
      </span>

      <span
        class="text-danger pointer"
        v-if="isEditing"
        @click="isEditing = false"
        >Cancelar</span
      >
    </div>
    <div style="" class="info_container scroll">
      <div class="card-body scroll" v-if="!isEditing">
        <div class="form-group">
          <strong for="">Nome</strong>
          <p class="mt-1">{{ product.name }}</p>
        </div>
        <hr />
        <div class="form-row">
          <div class="col">
            <strong for="">Preço</strong>
            <p class="mt-1">R$ {{ product.price || "-" }}</p>
          </div>
          <div class="col">
            <strong for="">Preço de custo</strong>
            <p class="mt-1">R$ {{ product.cost_price || "-" }}</p>
          </div>
        </div>
        <hr />
        <div class="form-row">
          <div class="col">
            <strong for="">Preço de promoção</strong>
            <p class="mt-1">R$ {{ product.sale_price || "-" }}</p>
          </div>
          <div class="col">
            <div>
              <strong for="">Preço de atacado</strong>
              <p class="mt-1 mb-0 pb-0">R$ {{ product.retail_price || "-" }}</p>
              <small class="text-primary mt-0 pr-0" v-if="product.retail_price"
                >a partir de {{ product.wholesale_quantity }}
                {{ product.unit || "un" }}</small
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group">
          <strong for="">Quantidade em estoque</strong>
          <p class="mt-1">{{ product.quantity }}</p>
        </div>

        <hr />
        <div class="form-group">
          <strong for="">Categorias</strong>
          <div
            class="mt-2"
            v-if="product.categories && product.categories.length"
          >
            <span
              class="badge badge-dark mr-2"
              v-for="cat in product.categories"
              :key="cat.uuid"
              ><i class="fas fa-sitemap mr-1"></i> {{ cat.name }}
            </span>
          </div>
          <div v-else><small>Sem categorias</small></div>
        </div>
        <div class="form-group">
          <strong for="">Tags</strong>
          <div class="mt-2" v-if="product.tags && product.tags.length">
            <span
              class="badge badge-dark mr-2"
              v-for="tags in product.tags"
              :key="tags.uuid"
              ><i class="fas fa-tags mr-1"></i> {{ tags.name }}
            </span>
          </div>
          <div v-else><small>Sem tags</small></div>
        </div>
        <hr />
        <div class="form-group">
          <strong for="">Descrição</strong>
          <p class="mt-1 text-muted" v-html="product.description"></p>
        </div>
      </div>
      <div class="card-body" v-if="isEditing">
        <div class="form-group">
          <strong for="">Nome</strong>
          <input class="form-control" type="text" v-model="tempProduct.name" />
        </div>

        <div class="form-group">
          <strong for="">Quantidade</strong>
          <input
            type="text"
            class="form-control"
            v-model="tempProduct.quantity"
          />
        </div>
        <div class="form-group">
          <strong for="">Preço</strong>
          <input type="text" class="form-control" v-model="tempProduct.price" />
        </div>
        <div class="form-group">
          <strong for="">Preço de custo</strong>
          <input
            type="text"
            class="form-control"
            v-model="tempProduct.cost_price"
          />
        </div>
        <div class="form-group">
          <strong for="">Preço de promoção</strong>
          <input
            type="text"
            class="form-control"
            v-model="tempProduct.sale_price"
          />
        </div>
        <div class="form-group">
          <strong for="">Preço de atacado</strong>
          <input
            type="text"
            class="form-control"
            v-model="tempProduct.retail_price"
          />
        </div>
        <div class="form-group">
          <toggle-button
            ref="toggleComp"
            :width="80"
            :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC',
            }"
            v-model="tempProduct.active"
            :labels="{ checked: 'Ativo', unchecked: '  Desativado' }"
          />
        </div>
        <button class="btn btn-primary btn-block" @click="updateProduct()">
          Atualizar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isEditing: false,
      tempProduct: {
        active: null,
      },
      compareProduct: {},
    };
  },
  computed: {
    ...mapGetters(["product"]),
  },
  mounted() {
    this.$event.$on("product_selected", () => {
      this.isEditing = false;
    });
    setTimeout(() => {
      this.$forceUpdate();
    }, 500);
  },
  watch: {
    // tempProduct: {
    //   deep: true,
    //   immediate: true,
    //   // handler(val) {
    //   //   console.log('product info val', val)
    //   //   if (val && val.active !== null && !isNaN(val.active)) {
    //   //     console.log("the product status===============", this.tempProduct.active == 0)
    //   //     this.tempProduct.active =
    //   //      this.tempProduct.active == 0 ? false : true;
    //   //     //  this.$refs.toggleComp.toggled = this.tempProduct.active
    //   //   }
    //   // }
    // }
  },
  methods: {
    ...mapActions(["VIEW_PRODUCT", "UPDATE_PRODUCT", "BULK_UPDATE_PRODUCTS"]),
    editProduct() {
      this.tempProduct = {};
      this.tempProduct = Object.freeze(this.product);
      this.tempProduct.active = this.tempProduct.active == 1 ? true : false;

      Object.assign(this.compareProduct, this.tempProduct);

      this.isEditing = !this.isEditing;
      this.$event.$emit("edit_product");
    },
    updateProduct() {
      this.$toast.info(" ", "Atualizando!", {
        icon: "icon-person",
        position: "topRight",
        timeout: 3000,
      });

      this.tempProduct.active = this.tempProduct.active == true ? 1 : 0;

      this.tempProduct.price = this.tempProduct.price?.replaceAll(/^[a-zA-Z][!@#$%^&*]/g, '')?.trim()?.replaceAll(/,/g, '.') || this.tempProduct.price
      this.tempProduct.cost_price = this.tempProduct.cost_price?.replaceAll(/^[a-zA-Z][!@#$%^&*]/g, '')?.trim()?.replaceAll(/,/g, '.') || this.tempProduct.cost_price
      this.tempProduct.sale_price = this.tempProduct.sale_price?.replaceAll(/^[a-zA-Z][!@#$%^&*]/g, '')?.trim()?.replaceAll(/,/g, '.') || this.tempProduct.sale_price
      this.tempProduct.retail_price = this.tempProduct.retail_price?.replaceAll(/^[a-zA-Z][!@#$%^&*]/g, '')?.trim()?.replaceAll(/,/g, '.') || this.tempProduct.retail_price

      this.VIEW_PRODUCT(this.tempProduct);

      let update = {
        ...this.tempProduct,
      };

      update.categories = update.categories.map((cat) => cat.id);
      update.tags = update.tags.map((cat) => cat.id);

      this.UPDATE_PRODUCT(update).then((res) => {
        if (res.code !== 201) {
          this.$toast.warning(" ", "Produto não foi atualizado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
          return;
        }

        this.$toast.success(" ", "Produto atualizado!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      });
      this.isEditing = false;
      this.$event.$emit("product_updated");
      this.VIEW_PRODUCT({});
    },
  },
};
</script>

<style lang="scss">
.info_container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 65vh;

  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: rgba(138, 138, 138, 0.575) rgb(219, 216, 216);
}
</style>
