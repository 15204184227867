<template>
  <div class="card soft-shadow " style="border:1px solid #efefe ">
    <LoadingScreen
      eventName="creating_tag"
      dir="row"
      bgColor="#fff"
      message="Criando..."
      :isLoading="loading"
    />
    <div class="card-header bg-warning text-dark">Nova categoria</div>
    <div class="card-body">
      <div class="form-group">
        <label for="">Nome</label>
        <input type="text" class="form-control" v-model="tempCategory.name" />
      </div>
      <div class="form-group">
        <label for="">Descrição</label>
        <textarea
          class="form-control"
          rows="1"
          v-model="tempCategory.description"
        ></textarea>
      </div>
      <button class="btn btn-primary btn-block" @click="create()">
        Salvar
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingScreen from "@/components/navigation/LoadingScreen";

export default {
  components: {
    LoadingScreen
  },
  props: {
    possibleName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tempCategory: {
        name: null,
        description: null,
        active: 1
      },
      loading: false
    };
  },
  watch: {},
  mounted() {
    //console.log("the props", this.possibleName);
    if (this.possibleName) {
      this.tempCategory.name = this.possibleName;
    }
  },
  computed: {},
  methods: {
    ...mapActions(["CREATE_CATEGORY"]),
    async create() {
      try {
        this.loading = true;
        let res = await this.CREATE_CATEGORY(this.tempCategory);
        this.loading = true;
        this.$emit("manager_save_category", res.data.category);
        this.tempCategory = {
          name: null,
          description: null,
          active: 1
        };
      } catch (err) {
        //console.log("error", err);
      }
    }
  }
};
</script>

<style></style>
