<template>
  <div class="card " :style="setFixed">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <label for="">Filtros</label>
        <label @click="reset()" class="text-danger pointer">Limpar filtros</label>
      </div>
      <div class="form-group mt-3" v-role="['seller']">
        <label for="">Cliente</label>
        <multiselect :disabled="isSearching" v-model="filter.selectedUsers" :options="seller_clients"
          :close-on-select="true" :clear-on-select="false" :hide-selected="true" :preserve-search="true"
          placeholder="Selecione suas categorias" label="name" track-by="id" :multiple="true"
          id="select-category-modal" />
      </div>
      <div class="form-group mt-3" v-role="['admin', 'dev']" v-if="$route.path == '/comissions'">
        <label for="">Vendedores</label>
        <multiselect :disabled="isSearching" v-model="filter.selectedSellers" :options="sellers" :close-on-select="true"
          :clear-on-select="false" :hide-selected="true" :preserve-search="true" placeholder="Selecione suas categorias"
          label="name" track-by="id" :multiple="true" id="select-category-modal" />
      </div>
      <div class="form-group">
        <label for="">Status</label>
        <!-- <multiselect
          :disabled="isSearching"
          v-model="filter.status"
          :options="status_list"
          :close-on-select="true"
          :clear-on-select="false"
          :hide-selected="true"
          :preserve-search="true"
          placeholder="Selecione suas categorias"
          label="name"
          track-by="id"
          :multiple="true"
          id="select-category-modal"
        /> -->
      </div>
      <div class="form-group">
        <label for="">Data</label>
        <div>
          <small>Inicio</small>
          <input class="form-control" type="date" v-model="filter.starts" />
        </div>
        <div>
          <small>Fim</small>
          <input class="form-control" type="date" v-model="filter.ends" />
        </div>
      </div>
      <LoaderButton b_class="btn-primary" :wasClicked="isSearching" message="Pesquisando...">
        <button class="btn btn-primary btn-block" :disabled="isSearching" @click="searchFilter()">
          Pesquisar
        </button>
      </LoaderButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import LoaderButton from "@/components/buttons/LoaderButton";
export default {
  data() {
    return {
      isSearching: false,
      sellers: [],
      filter: {
        search: "",
        selectedRoles: [],
        selectedTags: [],
        selectedCategories: [],
        selectedUsers: [],
        selectedSellers: [],
        starts: "",
        ends: "",
        status: [],
      },
    };
  },
  components: {
    Multiselect,
    LoaderButton,
  },

  props: {
    page: {
      required: false,
    },
    isFixed: {
      required: false,
      default: true,
    },
  },
  mounted() {
    this.LOAD_STATUS();

    this.getSellers();
    // if (this.filterActionsMap[this.page.toLowerCase()].onPageLoad) {
    //   let { search } = this.filterActionsMap[this.page.toLowerCase()];
    //   search(action => {
    //     this[action](obj).then(res => {
    //       this.isSearching = false;
    //       if (res.status == 200) {
    //         console.log("ok works");
    //       }
    //     });
    //   });
    // }
    this.QUICK_LIST().then(() => { });
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          let users = !val.selectedUsers.length;
          let status = !val.status.length;
          let starts = !val.starts.length;
          let ends = !val.ends.length;
          let sellers = !val.selectedSellers.length;

          let loads =
            this.filterActionsMap[this.page.toLowerCase()].loadOnEmptyParams;
          let emptyParams = this.$masterHelper.isEmpty(this.last_search);
          // console.log("users", users);
          // console.log("status", status);
          // console.log("starts", starts);
          // console.log("ends", ends);

          // console.log("loads", loads);
          // console.log("emptyParams", !emptyParams);

          if (
            users &&
            status &&
            starts &&
            ends &&
            !emptyParams &&
            loads &&
            sellers
          ) {
            this.searchFilter(false);
          }
        }
      },
    },
    sellers: {
      deep: true,
      immediate: false,
      handler(sellersRaw) {
        try {

          console.log('sellers', sellersRaw, sellersRaw.find(seller => seller.id == this.loggedUser.id))

          let paramName, data
          let type = 'whereIn';
          const sellerID = sellersRaw.find(seller => seller.id == this.loggedUser.id)?.seller_id || - 1

          if (
            this.page == "seller" &&
            this.$route.path == "/comissions" &&
            this.$hasRole(this.loggedUser, ["dev", "admin"])
          ) {
            // c//onsole.log("seller_id condition path = /comissions to admin/dev logged");
            //console.log('Sellers', sellersRaw)

            paramName = "seller_id";
            data = sellersRaw.map((seller) => seller.id);
          }

          if (
            this.page == "seller" &&
            this.$route.path == "/comissions" &&
            this.$hasRole(this.loggedUser, ["seller"])
          ) {
            ///console.log("seller_id condition path = /comissions");

            paramName = "seller_id";
            data = [sellerID]
          }

          if (this.page == "seller" && this.$route.name == "user.details") {
            //console.log("seller_id condition name user.details");
            paramName = "seller_id";
            data = sellerID;
          }

          if (this.page == "client" && this.$route.name == "user.details") {
            // console.log("buyer_id condition name user.details");
            paramName = "buyer_id";
            type = "where"
            data = this.user.id;
          }

          //console.log(`the param name is ${paramName} and the val ${data}`);

          this.ORDER_SIMPLE_FILTER({
            params: {
              search: {
                [paramName]: {
                  type,
                  operator: "=",
                  value: data
                },
              }
            },
            model: "Order"
          });

        } catch (error) {
          // do anything
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "status_list",
      "seller_clients",
      "loggedUser",
      "sellers",
      "last_search",
      "usersList",
      "user",
    ]),
    setFixed() {
      if (this.isFixed) {
        return "position:fixed; width:20%";
      } else {
        return "";
      }
    },
    filterActionsMap() {
      return {
        seller: {
          load: (cb) => cb("ORDER_SIMPLE_FILTER"),
          search: (cb) => cb("ORDER_SIMPLE_FILTER"),
          loadOnEmptyParams: true,
          model: "Order",
        },
        client: {
          load: (cb) => cb("ORDER_SIMPLE_FILTER"),
          search: (cb) => cb("ORDER_SIMPLE_FILTER"),
          loadOnEmptyParams: true,
          model: "Order",
        },
      };
    },
  },

  methods: {
    ...mapActions([
      "ORDER_SIMPLE_FILTER",
      "LOAD_SELLER_ORDERS",
      "SEARCH_SELLER_ORDER",
      "LOAD_STATUS",
      "SET_PARAMS",
      "QUICK_LIST",
      "LOAD_USERS_SELLERS",
    ]),
    async getSellers() {
      try {
        const hasSellers = await this.LOAD_USERS_SELLERS();

        //console.log("SELLERS", hasSellers?.map((seller) => seller?.user) || []);

        if (hasSellers?.length > 0) {
          this.sellers =
            hasSellers?.map((seller, _index) => {
              if (seller?.user) {
                return {
                  ...seller?.user,
                  seller_id: seller?.id,
                };
              }

              return seller;
            }) || [];
        }

        return this.sellers
      } catch (error) {
        // do anything
      }
    },
    reset() {
      if (
        this.$hasRole(this.loggedUser, ["admin", "dev"]) &&
        this.$route.path == "/comissions"
      ) {
        //console.log("loaded again+--------------");
        this.SEARCH_SELLER_ORDER({
          params: {
            seller_id: this.sellers.map((user) => user.id),
          },
        });
      }
      if (
        this.$hasRole(this.user, ["seller"]) &&
        this.$route.name == "user.details"
      ) {
        // console.log("loaded again seller--------------");
        this.ORDER_SIMPLE_FILTER({
          params: {
            buyer_id: this.user.id,
          },
        });
      }

      if (
        this.$hasRole(this.user, ["client"]) &&
        this.$route.name == "user.details"
      ) {
        // console.log("loaded again seller--------------");
        this.ORDER_SIMPLE_FILTER({
          params: {
            buyer_id: this.user.id,
          },
        });
      }

      // console.log("resetting;....");

      this.filter = {
        search: "",
        selectedRoles: [],
        selectedTags: [],
        selectedCategories: [],

        selectedUsers: [],
        starts: "",
        ends: "",
        status: [],
        selectedSellers: [],
      };
    },

    searchFilter(loads = true) {
      if (loads) {
        this.isSearching = true;
      }
      this.$pace.restart();
      let { model, search } = this.filterActionsMap[this.page.toLowerCase()];

      let obj = this._simpleFilterSearchBuilder(model);
      // console.log("the obj to search", obj);
      this.$pace.restart();
      search((action) => {
        this[action](obj)
          .then((res) => {
            this.isSearching = false;
            if (res?.code === 404) {
              this.$toast.warning(res?.message);
            }
          })
          .catch((error) => {
            if (error?.code === 404) {
              this.$toast.warning(res?.message);
            }
          });
      });
    },
    _simpleFilterSearchBuilder(model) {
      this.SET_PARAMS({});
      let search = this._searchParamBuilder();
      return {
        params: search,
        model: model,
      };
    },

    _searchParamBuilder() {
      //Builded params
      let params = {};
      let range = [];
      let date;

      if (this.$route.path == "/comissions") {
        if (this.filter.selectedSellers.length) {
          params.seller_id = this.filter.selectedSellers.map((u) => u.id);
        } else {
          params.seller_id = this.sellers.map((u) => u.id);
        }

        if (this.$hasRole(this.loggedUser, ["seller"])) {
          params.seller_id = [this.loggedUser.id];
        }
      }

      if (this.$route.name == "user.details") {
        if (this.$hasRole(this.user, ["client"])) {
          params.search = {
            buyer_id: {
              type: "where",
              operator: "=",
              value: this.user.id
            }
          }
        }
        if (this.$hasRole(this.user, ["seller"])) {
          params.search = {
            seller_id: {
              type: "where",
              operator: "=",
              value: this.user?.seller?.id || 0
            }
          }
        }
      }

      if (this.filter.status.length) {
        if (params?.search) {
          let stat = this.filter.status.map((s) => s.id);
          let operator = stat.length > 1 ? 'in' : '='
          let typeCondition = "whereIn"

          if (stat.length <= 1) {
            typeCondition = "where"
          }

          stat = stat.length === 1 ? stat[0] : stat

          params.search['status'] = {
            type: typeCondition,
            operator,
            value: stat
          }
        } else {
          params.status = this.filter.status.map((s) => s.id);
        }
      }

      if (this.filter.starts.length || this.filter.ends.length) {
        if (this.filter.starts.length) {
          range.push(this.filter.starts + " 00:00:00");
        }

        if (this.filter.ends.length) {
          range.push(this.filter.ends + " 23:59:59");
        }

        // console.log("the rang is ", range);

        if (range.length > 1) {
          params.date = range;
        }
        if (range.length == 1) {
          params.date = range[0];
        }
      }

      if (this.filter.selectedUsers.length) {
        params.buyer_id = Array.from(
          this.filter.selectedUsers.map((u) => u.id)
        );
      }

      // console.log("the builded query - - - - - - - - ", params);
      this.$pace.restart();
      return params;
    },
  },
};
</script>

<style>

</style>
