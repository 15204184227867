<template>
  <div>
    <button
      @click="LOAD_CATEGORIES()"
      type="button"
      class="btn"
      :class="[{ 'btn-primary': !btnClass }, btnClass]"
      data-toggle="modal"
      data-target="#modal_container"
    >
      Criar Nova
    </button>
    <portal to="modals">
      <div
        class="modal fade"
        id="modal_container"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="modal_containerLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal_containerLabel">
                Nova Categoria
              </h5>
              <button
                v-show="!isSaving"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="cat_modal_close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <div class="col">
                  <label for="">Nome</label>
                  <input
                    :disabled="isSaving"
                    @keyup="_recheckValidation"
                    id="name"
                    type="text"
                    class="form-control mb-2"
                    placeholder="Nome"
                    v-model="tempCategory.name"
                  />
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <textarea
                      :disabled="isSaving"
                      class="form-control"
                      rows="3"
                      v-model="tempCategory.description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <p class="alert alert-primary">
                    Para cadastrar como subcategoria, selecione abaixo:
                  </p>
                  <label for="select-category-modal"
                    >Pertence a alguma categoria?
                    <small
                      class="pointer text-danger ml-2"
                      v-if="selectedParent"
                      @click="selectedParent = null"
                      >remover</small
                    ></label
                  >
                  <multiselect
                    :disabled="isSaving"
                    v-model="selectedParent"
                    @change="setParentId()"
                    :options="categories"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :preserve-search="false"
                    placeholder="Selecione suas categorias"
                    label="name"
                    track-by="id"
                    :multiple="false"
                    id="select-category-modal"
                  />
                </div>
              </div>

              <div class="row mt-2" v-if="active">
                <div class="col">
                  <label for="">Selecione as sub categorias</label>
                  <multiselect
                    :disabled="isSaving"
                    v-model="selectedCategories"
                    :options="categories"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :preserve-search="true"
                    placeholder="Selecione a categoria"
                    label="name"
                    track-by="id"
                    :multiple="true"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer mt-3">
              <button
                v-if="!isSaving"
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <LoaderButton b_class="btn-primary" :wasClicked="isSaving">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="createCategory"
                >
                  Salvar
                </button>
              </LoaderButton>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  data() {
    return {
      search: null,
      active: false,
      selectedParent: null,
      isSaving: false,
      tempCategory: {
        name: null,
        description: null,
        category_id: null,
        active: 1,
      },
    };
  },
  components: {
    Multiselect,
    LoaderButton,
  },
  props: {
    btnClass: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["categories"]),
  },
  mounted() {
    this.selectedParent = null;
  },
  methods: {
    ...mapActions(["CREATE_CATEGORY", "LOAD_CATEGORIES"]),
    setSubCatParentId() {
      this.tempCategory.category_id = this.selectedParent.id;
    },
    setParentId() {
      this.tempCategory.category_id = this.selectedParent
        ? this.selectedParent.id
        : null;
    },
    async createCategory() {
      if (this._validation()) {
        this.setParentId();
        this.isSaving = true;
        let closeBtn = document.getElementById("cat_modal_close");

        await this.CREATE_CATEGORY(this.tempCategory).then((res) => {
          if (res.code !== 201) {
            // console.log("CREATE_CATEGORY - RESPONSE ERROR");
            this.$toast.error(" ", "Houve um problema.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            return;
          }

          // console.log("CREATE_CATEGORY - RESPONSE SUCCESS");

          this.$toast.success(" ", "Categoria criada", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });

          //console.log("res -*", res.data);
          this.$emit("saved", res.data);

          closeBtn.click();
          this.reset();
        });
      }
    },
    reset() {
      this.tempCategory = {
        active: 1,
        name: null,
        description: null,
        category_id: null,
      };
      this.isSaving = false;
    },
    _recheckValidation(e) {
      if (e.target.value.length) {
        if (e.target.classList.contains("border-danger")) {
          e.target.classList.remove("border-danger");
        }
      }
    },
    _validation() {
      let rules = {
        name: {
          required: true,
        },
      };

      let keys = Object.keys(this.tempCategory);

      //console.log("keys", keys);

      let error = [];

      keys.forEach((key) => {
        if (rules[key] && rules[key].required) {
          if (
            this.tempCategory[key] == null ||
            !this.tempCategory[key].length
          ) {
            if (key == "body") {
              document
                .getElementById("quill-container")
                .classList.add("border-danger");
            }
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
