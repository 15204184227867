<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2 class="text-dark mb-3 dash-session-title">Números Financeiros</h2>
      <hr class="w-100" />
    </div>
    <div class="w-100 w-sm-25 d-flex flex-wrap">
      <div class="col-12">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">
              <sup class="set-doller">R$</sup
              ><span class="count-me" v-if="lastOrders">{{
                $format($money(lastOrders.total_bruto))
              }}</span>
            </h2>
            <strong class="">Total bruto em pedidos</strong>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">
              <sup class="set-doller">R$</sup
              ><span class="count-me">{{
                $format($money(sellerReports.total_comissions))
              }}</span>
            </h2>
            <strong class="">de Comissão</strong>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">
              <sup class="set-doller">R$</sup
              ><span class="count-me">{{
                $format($money(financialReports.total_cost))
              }}</span>
            </h2>
            <strong class="">de Custo</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 w-sm-75">
      <div class="card low-shadow">
        <div class="card-header d-flex">
          <h3 class="text-dark mt-2">Últimos Pedidos Criados</h3>
          <hr />
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th colspan="2">Cliente</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in lastOrders.last_orders" :key="order.uuid">
                <td>{{ order.id }}</td>
                <td colspan="2">{{ order.user.name }}</td>
                <td>R$ {{ $format($money(order.price)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  params: {
    required: false,
    default: null
  },
  watch: {
    orderReport: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          //console.log("watching", val);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["orderReport", "sellerReport", "financialReport"]),
    lastOrders() {
      if (this.$masterHelper.isEmpty(this.orderReport)) return [];

      return this.orderReport;
    },
    sellerReports() {
      if (this.$masterHelper.isEmpty(this.orderReport))
        return {
          total_comissions: 0.0
        };
      return this.sellerReport.report || this.sellerReport;
    },
    financialReports() {
      if (this.$masterHelper.isEmpty(this.financialReport)) {
        return { total_cost: 0.0 };
      }
      return this.financialReport.report || this.financialReport;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["ORDER_REPORT", "SELLER_REPORT", "FINANCIAL_REPORT"]),
    load() {
      this.SELLER_REPORT();
      this.FINANCIAL_REPORT();

      let action = params => this.ORDER_REPORT(params);

      // console.log("LastOrders params for ORDER_REPORT", {
      //   params: this.params
      // });

      if (this.params) {
        action({ params: this.params });
      } else {
        if (this.$masterHelper.isEmpty(this.orderReport)) {
          action(false);
        }
      }
    }
  }
};
</script>

<style></style>
