<template>
  <div class="form-group" v-if="selectComponent">
    <label for="status_selector" v-if="label">{{ label }}</label>
    <select
      class="form-control"
      id="status_selector"
      v-model="model"
      @change="select()"
    >
      <option value="0" selected>Selecione um status</option>
      <option
        v-for="status in status_list"
        :key="status.id"
        :value="status.id"
        >{{ status.name }}</option
      >
    </select>
  </div>
  <div v-else>
    <select
      class="form-control"
      id="status_selector"
      v-model="model"
      @change="select()"
    >
      <option value="0">Selecione um status</option>
      <option
        v-for="status in status_list"
        :key="status.id"
        :value="status.id"
        >{{ status.name }}</option
      >
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      model: this.value,
      selected: {}
    };
  },
  props: {
    value: {
      required: false
    },
    label: {
      required: false
    },
    selectComponent: {
      required: false,
      default: () => true
    }
  },
  mounted() {
    this.$on('default-select', payload => document.querySelector('#status_selector').options[0].selected = true)

    this.load()

  },
  computed: {
    ...mapGetters(["status_list"])
  },
  methods: {
    ...mapActions(["LOAD_STATUS"]),
    load() {
      this.LOAD_STATUS();
    },
    select() {
      this.$emit("value", this.model);
      this.$emit("change", this.model);
    },
    filterStatus(){
      return this.status_list
      // try {
      // } catch (error) {
      //   throw error
      // }
    }
  }
};
</script>

<style></style>
