/* eslint-disable no-useless-catch */
import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import router from "../../router";

Vue.use(Vuex);

export default {
  state: {
    token: "",
    loggedUser: {},
    currentRoute: null
  },
  getters: {
    token: state => state.token,
    loggedUser: state => state.loggedUser,
    currentRoute: state => state.currentRoute,

  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_LOGGED(state, user) {
      state.loggedUser = user;
      sessionStorage.setItem("ADM_LOGGED_USER", JSON.stringify(user))
    },
    SET_ROUTE(state, route) {
      state.currentRoute = route;
    }
  },

  actions: {
    LOGIN: async (context, payload) => {
      try {
        const response = await axios().post("login", payload);

        if (response.status !== 200) {
          throw new Error("Não foi possivel realizar seu login")
        }

        localStorage.setItem("token", response.data.access_token);
        sessionStorage.setItem("TIME_OF_SESSION", new Date().getTime())

        context.commit("SET_TOKEN", response.data.access_token);
        context.commit("SET_LOGGED", response.data.user);

        // return

        return response;
      } catch (error) {

        // console.log("error on login", error);

        localStorage.removeItem("token");
        context.commit("SET_TOKEN", "");
        context.commit("SET_LOGGED", {})

        return error;
      }
    },
    LOGIN_RECOVERY: async (context, _payload) => {
      try {
        const response = await axios().get("get/authenticated");

        if (response.status !== 200) {
          return
        }

        sessionStorage.setItem("TIME_OF_SESSION", new Date().getTime())

        context.commit("SET_TOKEN", response.data.access_token);
        context.commit("SET_LOGGED", response.data.user);

        // console.log('LOGIN_RECOVERY', response.data)
        // return

        return response;
      } catch (error) {
        throw error;
      }
    },
    CHECK_USER: async ({ dispatch, commit, getters, rootState }) => {
      try {
        const response = await axios().get("check_user");

        let userAuthenticate = {}
        const cookieRaw = document.cookie.split(";")
        let cookieParsed = {}

        // Recovery all cookies of application
        cookieRaw.forEach(cookie => {
          const cookiePayload = cookie.split("=")

          cookieParsed[cookiePayload[0]?.trim()] = cookiePayload[cookiePayload.length - 1]
        })

        if (response?.data === true) {
          // console.log('CHECK_USER', cookieParsed)
          // return:

          userAuthenticate = getters?.loggedUser || rootState.users?.user || JSON.parse(sessionStorage.getItem("ADM_LOGGED_USER") || {})
          commit("SET_LOGGED", userAuthenticate);
          commit("SET_TOKEN", localStorage.getItem("token"));
        }

        if (getters?.loggedUser?.roles?.map(r => r.name)?.includes('seller')) {
          if (getters?.currentRoute && getters?.currentRoute.name !== "Minhas Vendas")
            this.$routers.push({ name: "Minhas Vendas" });
        }

        // if (userAuthenticate?.roles?.map(r => r.name)?.includes('seller')) {
        //   if (getters?.currentRoute && getters?.currentRoute.name !== "Minhas Vendas")
        //     this.$routers.push({ name: "Minhas Vendas" });
        // }

        return response.data;
      } catch (error) {
        // console.log('Auth@CHECK_USER::error', error)
        throw error
      }
    },
    LOGOUT: context => {
      try {
        // axios().get("logout");
        localStorage.removeItem("token");
        context.commit("SET_TOKEN", "");
        context.commit("SET_LOGGED", {});
        sessionStorage.removeItem("ADM_LOGGED_USER")
        router.push({ name: "login" });
      } catch (err) {
        //console.log("error on Logout ::", err);
      }
    }
  }
};
