<template>
  <div>
    <BreadCrumb :pageInfo="(usersList ? usersList.length : 0) + ' usuarios'" page="Usuários">
      <div slot="right-side">
        <button v-role="['admin', 'dev']" class="btn btn-primary" data-toggle="modal" data-target="#ViewUserModal">
          Novo
        </button>
      </div>
    </BreadCrumb>
    <div class="mb-2">
      <small class="alert alert-info">Dê dois cliques para abrir o usuário.</small>
    </div>
    <div class="row" id="responsive-page-container">
      <!-- {{ usersList }} -->
      <div class="col-9 table-report" id="responsive-page-table" v-if="usersList && usersList.length > 0">
        <UserList :users="usersList" />
      </div>
      <div class="col-9 mt-5" v-else>
        <h3>Nenhum usuário disponível</h3>
      </div>

      <div class="col-3" id="responsive-filter-container" v-if="usersList && usersList.length > 0">
        <transition-group name="slide-in" mode="out-in">
          <!-- <div
            key="info"
            class="card"
            style="position:fixed; width:20%"
            v-show="$masterHelper.isEmpty(user)"
          >
            <div class="card-body">
              <div class="form-group">
                <label for="">Pesquise</label>
                <input type="text" class="form-control" />
                <small>Cod, email ou nome</small>
              </div>
              <div class="form-group">
                <label for="">Cargo</label>

                <multiselect
                  :disabled="isSaving"
                  class="mt-2 mb-2"
                  :multiple="false"
                  id="role"
                  v-model="selectedRoles"
                  :options="roles"
                  :show-labels="false"
                  track-by="name"
                  label="name"
                >
                </multiselect>
              </div>

              <button class="btn btn-primary btn-block">
                Pesquisar
              </button>
            </div>
          </div> -->
          <MasterFilter key="edition" v-if="$masterHelper.isEmpty(user)" style="position: fixed; width: 20%"
            :hideInputs="['status', 'active', 'tags', 'categories', 'users']" page="Users" :rules="{
              searchable: ['id', 'name', 'email'],
              relationships: ['roles'],
            }" />
          <div v-if="!$masterHelper.isEmpty(user)" key="edition" style="position: fixed; width: 20%">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ (user.roles.length && user.roles[0].name) || "-" }}
                </div>
                <div class="d-flex">
                  <span class="pointer text-danger" @click="reset()">Fechar</span>

                  <div class="ml-4" v-role="['admin', 'dev']">
                    <span class="text-primary pointer" @click="toggleEdition">
                      <span v-if="!isEditing && !isSaving">Editar</span>
                      <span v-if="isEditing && !isSaving" class="text-danger">Cancelar</span>
                    </span>
                    <span v-if="isEditing" class="text-primary ml-3 pointer" @click.prevent="update()">Atualizar</span>
                    <span class="text-primary" v-if="isSaving">Atualizando ...</span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div>
                  <strong for="">Nome</strong>
                  <p v-if="!isEditing">{{ user.name }}</p>
                  <input :disabled="isSaving" v-else type="text" class="form-control mt-2 mb-2"
                    v-model="tempUser.name" />
                  <strong for="">Email</strong>
                  <p v-if="!isEditing">{{ user.email || user.user_info.email }}</p>
                  <input :disabled="isSaving" v-else type="email" class="form-control mt-2 mb-2"
                    v-model="tempUser.email" />
                  <div v-if="isEditing">
                    <strong for="">Tipo conta</strong>
                    <multiselect :disabled="isSaving" class="mt-2 mb-2" :multiple="false" id="role"
                      v-model="tempUser.roles[0]" :options="
                        roles.filter((role) => role.guard_name === 'api')
                      " :show-labels="false" track-by="name" label="name">
                    </multiselect>
                  </div>
                  <strong for="">Contatos</strong>
                  <p v-if="
                    !user.user_info ||
                    user.user_info.contacts == null ||
                    !user.user_info.contacts.length
                  ">
                    Nenhum cadastrado
                  </p>
                  <p v-else>
                    <span v-for="(contact, index) in user.user_info.contacts" :key="contact"
                      class="pointer text-primary">{{
                        contact
                      }}<span v-if="index < user.user_info.contacts.length - 1">,
                      </span>
                    </span>
                  </p>
                  <strong for="">Adicionado em</strong>
                  <p>
                    {{
  new Date(user.created_at).toLocaleString()
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <UserModal />
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";
import UserList from "@/components/users/UserList";
import UserInfo from "@/components/users/UserInfo";

import UserModal from "@/components/users/UserModal";
import Multiselect from "vue-multiselect";
import MasterFilter from "@/components/Filters/MasterFilter";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      UPDATE: 0,
      selectedRoles: [],
      search: {
        client: null,
        code: null,
        status: null,
      },
      date: {
        starts: null,
        ends: null,
      },
      isEditing: false,
      isSaving: false,

      tempUser: {
        name: null,
        email: null,
        roles: [],
      },
    };
  },
  components: {
    BreadCrumb,
    UserList,
    UserModal,
    Multiselect,
    UserInfo,
    MasterFilter,
  },
  mounted() {
    try {
      this.loadUsers();
      this.SET_PARAMS({});
    } catch (error) {
      console.log('Warn::mounted', error)
    }
  },
  watch: {
    user(val) {
      this.tempUser = val;
    },
  },
  computed: {
    ...mapGetters(["users", "user", "roles", "usersList"]),
  },
  methods: {
    ...mapActions([
      "LOAD_USERS",
      "LOAD_ROLES",
      "UPDATE_USER",
      "VIEW_USER",
      "SET_PARAMS",
    ]),
    async loadUsers() {
      try {
        await this.LOAD_USERS();
      } catch (error) {
        console.log('Warn::loadUsers', error)
      }
    },
    toggleEdition() {
      this.isEditing = !this.isEditing;
    },
    reset() {
      this.isEditing = false;
      this.isSaving = false;
      this.VIEW_USER({});
    },
    async update() {
      try {
        // this.tempUser.roles = this.tempUser.roles.map(r => r.name);

        this.$toast.info(" ", `Atualizando`, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });
        this.isSaving = true;
        this.isEditing = false;
        await this.UPDATE_USER(this.tempUser).then((response) => {
          if (response.code === 201) {
            this.$toast.success(" ", `Usuário atualizado`, {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          }
          this.reset();
        });

        this.UPDATE++;
      } catch (error) {
        // console.log("error", error);
      }
    },
  },
};
</script>

<style>

</style>
