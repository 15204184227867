<template>
  <div class="card" :style="setFixed">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <label for="">Periodo</label>
        <label @click="reset()" class="text-danger pointer">
          Limpar resultados
        </label>
      </div>

      <div class="form-group">
        <div>
          <small>De</small>
          <input class="form-control" type="date" v-model="filter.starts" />
        </div>
        <div>
          <small>Até</small>
          <input class="form-control" type="date" v-model="filter.ends" />
        </div>
      </div>
      <LoaderButton b_class="btn-primary" :wasClicked="isSearching" message="Pesquisando...">
        <button class="btn btn-primary btn-block" :disabled="isSearching" @click="() => searchFilter()">
          Pesquisar
        </button>
      </LoaderButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import LoaderButton from "@/components/buttons/LoaderButton";

import axios from "../../http";

export default {
  data() {
    return {
      isSearching: false,
      filter: {
        search: "",
        selectedRoles: [],
        selectedTags: [],
        selectedCategories: [],
        selectedUsers: [],
        selectedSellers: [],
        starts: "",
        ends: "",
        status: [],
      },
    };
  },
  components: {
    Multiselect,
    LoaderButton,
  },

  props: {
    page: {
      required: false,
    },
    isFixed: {
      required: false,
      default: true,
    },
  },
  mounted() {
    this.LOAD_STATUS();

    // if (this.filterActionsMap[this.page.toLowerCase()].onPageLoad) {
    //   let { search } = this.filterActionsMap[this.page.toLowerCase()];
    //   search(action => {
    //     this[action](obj).then(res => {
    //       this.isSearching = false;
    //       if (res.status == 200) {
    //         console.log("ok works");
    //       }
    //     });
    //   });
    // }
    this.QUICK_LIST().then(() => {
      let paramName, data, model;
      let typeOperator = "where"
      let operator = "="

      if (
        this.page == "seller" &&
        this.$route.path == "/comissions" &&
        this.$hasRole(this.loggedUser, ["dev", "admin"])
      ) {
        //console.log("seller_id condition path = /comissions");

        paramName = "seller_id";
        data = this.sellers.map((user) => user.id);
        operator = data.length > 1 ? 'in' : '='
        typeOperator = "whereIn"

        if (stat.length <= 1) {
          typeOperator = "where"
        }
      }

      if (
        this.page == "seller" &&
        this.$route.path == "/comissions" &&
        this.$hasRole(this.loggedUser, ["seller"])
      ) {
        // console.log("seller_id condition path = /comissions");

        paramName = "seller_id";
        data = this.loggedUser.id;
      }

      if (this.page == "seller" && this.$route.name == "user.details") {
        //console.log("seller_id condition name user.details");
        paramName = "seller_id";
        data = this.user?.seller?.id || -1;
      }

      if (this.page == "client" && this.$route.name == "user.details") {
        //console.log("buyer_id condition name user.details");
        paramName = "buyer_id";
        data = this.user.id;
      }

      if (this.page === "orders") {
        model = "Order"
      }

      // console.log(`the param name is ${paramName} and the val ${data}`);
      this.ORDER_SIMPLE_FILTER({
        params: {
          search: {
            [paramName]: {
              type: typeOperator,
              operator,
              value: data
            }
          }
        },
        model
      });
    });
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          let users = !val.selectedUsers.length;
          let status = !val.status.length;
          let starts = !val.starts.length;
          let ends = !val.ends.length;
          let sellers = !val.selectedSellers.length;

          let loads =
            this.filterActionsMap[this.page.toLowerCase()].loadOnEmptyParams;
          let emptyParams = this.$masterHelper.isEmpty(this.last_search);
          // console.log("users", users);
          // console.log("status", status);
          // console.log("starts", starts);
          // console.log("ends", ends);

          // console.log("loads", loads);
          // console.log("emptyParams", !emptyParams);

          if (
            users &&
            status &&
            starts &&
            ends &&
            !emptyParams &&
            loads &&
            sellers
          ) {
            this.searchFilter(false);
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "status_list",
      "seller_clients",
      "loggedUser",
      "last_search",
      "usersList",
      "user",
    ]),
    setFixed() {
      if (this.isFixed) {
        return "position:relative; width:100%";
      } else {
        return "";
      }
    },
    sellers() {
      return this.$userByroles(this.usersList, ["seller"]);
    },
    filterActionsMap() {
      return {
        seller: {
          load: (cb) => cb("ORDER_SIMPLE_FILTER"),
          search: (cb) => cb("ORDER_SIMPLE_FILTER"),
          loadOnEmptyParams: true,
          model: "Order",
        },
        client: {
          load: (cb) => cb("ORDER_SIMPLE_FILTER"),
          search: (cb) => cb("ORDER_SIMPLE_FILTER"),
          loadOnEmptyParams: true,
          model: "Order",
        },
        visualization: {
          load: (cb) => cb("ORDER_SIMPLE_FILTER"),
          search: (cb) => this.getVisualization(cb),
          loadOnEmptyParams: true,
          model: "Visualization",
        },
      };
    },
  },

  methods: {
    ...mapActions([
      "ORDER_SIMPLE_FILTER",
      "LOAD_SELLER_ORDERS",
      "SEARCH_SELLER_ORDER",
      "LOAD_STATUS",
      "SET_PARAMS",
      "QUICK_LIST",
    ]),

    reset() {
      this.filter.starts = ''
      this.filter.ends = ''

      this.$event.$emit("visualizations-filter-reset", []);

      this.$toast.success(" ", `Filtro removido com sucesso!`, {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
    },
    searchFilter(loads = true) {
      if (loads) {
        this.isSearching = true;
      }
      this.$pace.restart();
      let { model, search } = this.filterActionsMap[this.page.toLowerCase()];

      let obj = this._simpleFilterSearchBuilder(model);
      //console.log("the obj to search", obj);
      this.$pace.restart();
      search(obj);
    },
    _simpleFilterSearchBuilder(model) {
      this.SET_PARAMS({});
      let search = this._searchParamBuilder();
      return {
        params: search,
        model: model,
      };
    },
    _searchParamBuilder() {
      //Builded params
      let params = {};
      let range = [];
      let date;

      if (this.$route.path == "/comissions") {
        if (this.filter.selectedSellers.length) {
          params.seller_id = this.filter.selectedSellers.map((u) => u.id);
        } else {
          params.seller_id = this.sellers.map((u) => u.id);
        }

        if (this.$hasRole(this.loggedUser, ["seller"])) {
          params.seller_id = [this.loggedUser.id];
        }
      }

      if (this.$route.name == "user.details") {
        if (this.$hasRole(this.user, ["client"])) {
          params.buyer_id = [this.user.id];
        }
        if (this.$hasRole(this.user, ["seller"])) {
          params.seller_id = [this.user.id];
        }
      }

      if (this.filter.status.length) {
        params.status = this.filter.status.map((s) => s.id);
      }

      if (this.filter.starts.length || this.filter.ends.length) {
        if (this.filter.starts.length) {
          range.push(this.filter.starts + " 00:00:00");
        }

        if (this.filter.ends.length) {
          range.push(this.filter.ends + " 23:59:59");
        }

        // console.log("the rang is ", range);

        if (range.length > 1) {
          params.date = range;
        }
        if (range.length == 1) {
          params.date = range[0];
        }
      }

      if (this.filter.selectedUsers.length) {
        params.buyer_id = Array.from(
          this.filter.selectedUsers.map((u) => u.id)
        );
      }

      // console.log("the builded query - - - - - - - - ", params);
      this.$pace.restart();
      return params;
    },
    async getVisualization(payload) {
      try {
        //console.log(`GetVisualization`, payload);

        const dates = payload.params?.date || {};

        if (Object.entries(dates).length <= 0) {
          this.$toast.error(
            " ",
            "Selecione uma data de  'De' ou 'Até'",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          )
          this.isSearching = false
          return;
        }

        const response = await axios().post("/visualization/perPeriod", {
          dates: dates,
        });

        this.$event.$emit("visualizations-filter-period", {
          dates: dates,
        });

        if (response.data.code !== 200) {
          this.isSearching = !this.isSearching;
          this.$toast.error(
            " ",
            response.data.message || "O filtro não foi aplicado",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          );
          this.$event.$emit("visualizations-filter-not-found", []);
          return;
        }

        // this.$toast.success(" ", `Filtro aplicado com sucesso!`, {
        //   icon: "icon-person",
        //   position: "topRight",
        //   timeout: 2000,
        // });

        this.isSearching = !this.isSearching;
        this.$event.$emit("visualizations-filter", response.data?.data);
      } catch (error) {
        this.isSearching = false
        //console.log("getVisualization", error?.message);
      }
    },
  },
};
</script>

<style>

</style>
