<template>
  <ul class="list-group">
    <li
      class="list-group-item"
      v-for="(gateway, index) in payments"
      :key="index"
    >

      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
         <img loading="lazy" height="30" :src="gateway.logo" alt="" class="mr-2" />
          <strong>{{ gateway.label }}</strong>
        </div>
        <div class="d-flex">
          <strong
            v-if="
              isSaving &&
              !isDeleting &&
              selectedProvider &&
              selectedProvider.name === gateway.name
            "
            class="text-primary"
            >Ativando ...</strong
          >
          <strong
            class="text-warning"
            v-if="isDeleting && deletingObj && deletingObj.name == gateway.name"
            >Removendo ...</strong
          >
          <strong v-if="!isSaving && !isDeleting">
            <span v-if="isSetted(gateway.name)"
              >Status - <span class="text-success">Ativo</span>
            </span>
            <span class="text-muted" v-else>
              <span>Não configurado</span>
              <span
                class="text-primary ml-3 pointer"
                @click="addGateWay(gateway)"
                >
                Adicionar
                </span
              >
            </span>
          </strong>
          <div
            class="ml-3"
            v-if="isSetted(gateway.name) && !isSaving && !isDeleting"
          >
            <!-- <span class="pointer"><i class="fas fa-power-off"></i></span> -->
            <span class="pointer" @click="edit(gateway)"
              ><i class="fas fa-pencil-alt ml-4"></i
            ></span>
            <span class="pointer ml-4" @click="_delete(gateway)"
              ><i class="far fa-trash-alt"></i
            ></span>
          </div>
        </div>
      </div>

      <div
        class="container alert alert-warning mt-2"
        :id="`shipping-${gateway.name}`"
         v-if="
          selectedProvider &&
          selectedProvider.name === gateway.name &&
          isAdding &&
          !isSaving &&
          !isDeleting
        "
      >
        <div class="d-flex flex-column" :id="`provider_${selectedProvider.name}`">
          <div
            class="form-group"
            v-for="(input, index) in selectedProvider.inputs"
            :key="index"
          >
            <div v-if="!input.name">
              <label :for="'input_' + input">{{ input }}</label>
              <input
                class="form-control"
                type="text"
                :id="'input_' + input"
                :name="input"
                @change="(event) => $masterHelper.handlerInputModel(selectedProvider.name,input, event, 'text',payment_provider,handlerSetShippingsStatement)"
                :value="myGateways && Object.entries(myGateways.content || {}).length > 0 && myGateways.content && myGateways.content[selectedProvider.name] ? myGateways.content[selectedProvider.name][input] : ''"
              />
            </div>
            <div v-else class="d-flex flex-row flex-nowrap align-items-center" style="gap:1rem">
              <label :for="'input_' + input.name" style=" margin:0 !important">{{ input.name }}</label>
              <input
                class="form-control"
                style="width: 1rem; height: 1rem; margin:0 !important"
                :style="`${input.type === 'text' ? 'width: 100%; height: 2rem;' : ''}`"
                :type="input.type"
                :id="'input_' + input.nameID"
                :checked="myGateways && Object.entries(myGateways.content || {}).length > 0 && myGateways.content[selectedProvider.name] ? myGateways.content[selectedProvider.name][input.nameID] ===  input.value :  false"
                :name="input.nameID"
                :value="myGateways && Object.entries(myGateways.content || {}).length > 0 && myGateways.content[selectedProvider.name] ? myGateways.content[selectedProvider.name][input.nameID] || input.value || input.defaultValue : input.value || input.defaultValue"
                @change="(event) => $masterHelper.handlerInputModel(selectedProvider.name,input.nameID, event, input.type,payment_provider,handlerSetShippingsStatement)"
                v-if="input.nameID !== 'modalidade'"
              />
              <input
              v-else
                class="form-control"
                style="width: 1rem; height: 1rem; margin:0 !important"
                :style="`${input.type === 'text' ? 'width: 100%; height: 2rem;' : ''}`"
                :type="input.type"
                :id="'input_' + input.nameID"
                :checked="myGateways && Object.entries(myGateways.content || {}).length > 0 ? myGateways.content[selectedProvider.name][input.nameID] ===  input.value :  false"
                :name="input.nameID"
                :value="input.value || input.defaultValue"
                @change="(event) => $masterHelper.handlerInputModel(selectedProvider.name,input.nameID, event, input.type,payment_provider,handlerSetShippingsStatement)"
              />
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button
              class="btn btn-outline-danger"
              @click="selectedProvider = null"
            >
              Cancelar
            </button>
            <button class="btn btn-primary ml-2" @click="save()">Salvar</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isDeleting: false,
      isSaving: false,
      isAdding: false,
      isEditing: false,
      payment_provider: {},
      selectedProvider: null,
      deletingObj: null,
      UPDATE: 0,
      payments: [
        {
          name: "correios",
          label: "",
          inputs: [
            "empresa",
            "senha",
            "cep_origem",
            {name: "Aviso de Recebimento", type: "checkbox", nameID: "aviso_recebimento", defaultValue: true},
            {name: "Formato de envio em Caixa", type: "radio", nameID:"formato", value: "caixa", defaultValue: false},
            {name: "Formato de envio em Rolo", type: "radio", nameID:"formato", value: "rolo", defaultValue: false},
            {name: "Formato de envio em Envelope", type: "radio", nameID:"formato", value: "envelope", defaultValue: false},
            {name: "Envio por Sedex ", type: "radio", nameID:"tipo", value: "sedex", defaultValue: false},
            {name: "Envio por Sedex a Cobrar ", type: "radio", nameID:"tipo", value: "sedex_a_cobrar", defaultValue: false},
            {name: "Envio por Sedex 10 ", type: "radio", nameID:"tipo", value: "sedex_10", defaultValue: false},
            {name: "Envio por Sedex Hoje ", type: "radio", nameID:"tipo", value: "sedex_hoje", defaultValue: false},
            {name: "Envio por PAC ", type: "radio", nameID:"tipo", value: "pac", defaultValue: false},
            {name: "Envio por PAC Contrato ", type: "radio", nameID:"tipo", value: "pac_contrato", defaultValue: false},
            {name: "Envio por Sedex Contrato ", type: "radio", nameID:"tipo", value: "sedex_contrato", defaultValue: false},
            {name: "Envio por eSEDEX ", type: "radio", nameID:"tipo", value: "esedex", defaultValue: false},
          ],
          logo: "https://www.correios.com.br/++theme++tema-do-portal-correios/static/imagens/correios.svg",
        },
        {
          name: "jadlog",
          label: "",
          inputs: [
            "key",
            "url",
            "cnpj",
            {name: "Origem", type: "text", nameID: "cepori", defaultValue: ''},

            {name: "Cobrar no destino", type: "radio", nameID: "frap", value:'S', defaultValue: true},
            {name: "Cobrar do remetente", type: "radio", nameID: "frap", value:'N', defaultValue: false},

            {name: "Entrega em domicilio", type: "radio", nameID:"tpentrega", value: "D", defaultValue: true},
            {name: "Retirar", type: "radio", nameID:"tpentrega", value: "R", defaultValue: false},

            {name: "Apólice (Seguro)", type: "radio", nameID:"tpseguro", value: "A", defaultValue: false},
            {name: "Normal (Seguro) ", type: "radio", nameID:"tpseguro", value: "N", defaultValue: false},

            {name: "Envio por EXPRESSO ", type: "radio", nameID:"modalidade", value: "0", defaultValue: false},
            {name: "Envio por .PACKAGE ", type: "radio", nameID:"modalidade", value: "3", defaultValue: false},
            {name: "Envio por RODOVIÁRIO ", type: "radio", nameID:"modalidade", value: "4", defaultValue: false},
            {name: "Envio por ECONÔMICO ", type: "radio", nameID:"modalidade", value: "5", defaultValue: false},
            {name: "Envio por DOC ", type: "radio", nameID:"modalidade", value: "6", defaultValue: false},
            {name: "Envio por CORPORATE ", type: "radio", nameID:"modalidade", value: "7", defaultValue: false},
            {name: "Envio por .COM ", type: "radio", nameID:"modalidade", value: "9", defaultValue: false},
            {name: "Envio por INTERNACIONAL ", type: "radio", nameID:"modalidade", value: "10", defaultValue: false},
            {name: "Envio por CARGO ", type: "radio", nameID:"modalidade", value: "12", defaultValue: false},
            {name: "Envio por EMERGÊNCIAL ", type: "radio", nameID:"modalidade", value: "14", defaultValue: false},
            {name: "Envio por PICKUP ", type: "radio", nameID:"modalidade", value: "40", defaultValue: false},
          ],
          logo: "https://www.jadlog.com.br/jadlog/img/logo_home.png",
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["settings"]),
    myGateways() {
      return (
        this.settings.find((set) => {
          return set.key === "shipping_provider";
        }) || false
      );
    },
    myActualProvider() {
      console.log('myActualProvider',this.settings.find(set => set.key === "shipping_provider"),this.settings)
      return Object.keys(this.settings.find(set => set.key === "shipping_provider")?.content || {})[0]
    }
  },
  methods: {
    ...mapActions([
      "LOAD_SETTINGS",
      "UPDATE_SETTINGS",
      "CREATE_SETTINGS",
      "DELETE_SETTINGS_PROVIDER",
    ]),
    isSetted(name) {
      //buscar se tem um payment_provider cadastrado
      if (!this.myGateways) return false;
      return this.myGateways.content.hasOwnProperty(name);
    },
    addGateWay(data) {
      console.log('addGateWay', data.inputs)
      this.isAdding = true;
      this.selectedProvider = data;

      this.setShowShippingForm(data.name)
    },
    save(key = "shipping_provider") {
      try {
        this.isSaving = true;

        let data =  this.payment_provider

        this.handlerValidationForm(`provider_${this.selectedProvider?.name}`)

        this.CREATE_SETTINGS({
          content: data,
          key: key
        }).then((res) => {

          this.$pace.restart();
          this.UPDATE++;

          if (res.code === 201) {
            this.$toast.success(" ", "Concluido", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
            // this._isSetted(this.selectedProvider.name)
            // if(this.myActualProvider && this.myActualProvider !== this.selectedProvider?.name) {
            //   console.log('CREATE_SETTINGS',this.myActualProvider, this.selectedProvider?.name)
            //   this._delete({name: this.myActualProvider},true)
            // }
            this.UPDATE++;

            this.LOAD_SETTINGS() //window.location.reload()
          }else {
            this.$toast.error(" ", "Houve uma falha.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
          }
        });

        setTimeout(() => {
          this.isSaving = false;
          this.selectedProvider = null;
          this.UPDATE++;
        }, 1000);

        // this.LOAD_SETTINGS() //window.location.reload()

        //console.log("saving", { content: data });
      }catch(error){
         this.$toast.info(" ", error?.message, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        this.isSaving = false;
      }
    },
    edit(prov) {
      this.isAdding = true;
      this.payment_provider [prov.name] = {...this.myGateways.content[prov.name]}
      this.selectedProvider = prov;
      this.$forceUpdate();
    },
    async _delete(prov,isOld = false) {
      this.isDeleting = true;
      this.deletingObj = prov;

      let data = this.myGateways.content;
      delete data[prov.name];

      //console.log("going to remove", data);
      const response = await this.DELETE_SETTINGS_PROVIDER({
        content: data,
        id: this.myGateways.id,
      })

      if (response.code !== 201) {
        this.$toast.error(" ", "Houve uma falha ao desativar.", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return
      }

      this.$toast.success(" ", `Integração ${isOld ? 'anterior' : ''} ${prov.name} foi desativada`, {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      console.log('DELETE_SETTINGS_PROVIDER',response)

      this.payment_provider = response.data.content
      this.isDeleting = false;
      this.deletingObj = null;
    },
    handlerValidationForm(formID = ""){
      try {
        if(formID.trim() === "") return

        const inputsFromFormRaw = document.querySelectorAll(`#${formID} input,select,textarea`)

        if(!inputsFromFormRaw) return

        const inputsFromFormParsed = Array.from(inputsFromFormRaw)

        if(inputsFromFormParsed.length <= 0) return

        inputsFromFormParsed.forEach(inputElement => {
          if(inputElement?.type === "text") {

            if(inputElement?.value?.trim() === "" && inputElement?.required) throw new Error(`Preencha o campo ${inputElement?.name}`)

          }else if(inputElement?.type === "select") {

            if(!inputElement?.selectedOption) throw new Error(`Preencha o campo ${inputElement?.name}`)

          }else if(inputElement?.type === "radio" || inputElement?.type === "checkbox" ) {

            if(inputElement?.required) throw new Error(`Marque o campo ${inputElement?.name}`)

          }
        })
      } catch (error) {
        throw new Error(error?.message)
      }
    },
    handlerSetShippingsStatement(param = {}){
      try {
        this.payment_provider = {
          ...this.payment_provider,
          ...param
        }
      } catch (error) {
        // do anything
      }
    },
    setShowShippingForm(nameID = ""){
      try {
        if(nameID.trim() === '') return

        const elementShippingForm = document.querySelector(`#shipping-${nameID}`)

        if(!elementShippingForm) return

        if(elementShippingForm.classList.contains('shipping-opened')) {
          elementShippingForm.classList.remove('shipping-opened')
          elementShippingForm.style.display = "none"

          return
        }

        elementShippingForm.classList.add('shipping-opened')
        elementShippingForm.style.display = "block"
      } catch (error) {

      }
    }
  },
  watch: {
    myGateways: {
      deep: true,
      immediate: true,
      handler(val) {
        this.payments.forEach(payment => {
          const providerContent = val?.content?.[payment?.name] || {}

          this.payment_provider[payment?.name] = providerContent
        })
      }
    }
  }
};
</script>

<style></style>
