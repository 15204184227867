<template>
  <aside class="left-sidebar" data-sidebarbg="skin6" id="left_side_bar" v-if="nav">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" data-sidebarbg="skin6">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <!-- <li class="sidebar-item">
            <router-link
              aria-expanded="false"
              class="sidebar-link sidebar-link"
              :to="{ name: 'index' }"
              ><i class="mr-3 fas fa-home"></i
              ><span class="hide-menu">Dashboard</span></router-link
            >
          </li>
          <li class="list-divider"></li>
          <li class="nav-small-cap">
            <span class="hide-menu">Menu</span>
          </li> -->
          <!-- <li class="sidebar-item" v-has:access.permission="'manage-settings'"> -->
          <!-- <li class="sidebar-item">
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'pagina_2' }"
              ><i class="mr-3 fas fa-cogs"></i
              ><span class="hide-menu">Pagina 2</span></router-link
            >
          </li> -->
          <DynamicList />
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DynamicList from "@/components/navigation/DynamicList";

export default {
  name: "LeftNav",
  components: {
    DynamicList,
  },
  computed: {
    ...mapGetters(["setting"]),
  },
  data() {
    return {
      nav: true,
    };
  },
  mounted() {
    this.$event.$on("toggleSideNav", () => {
      var wrapper = document.getElementById("mainWrapper");
      var navHeader = document.getElementById("navbarSupportedContent");
      var topHeader = document.getElementById("topHeader");
      var navBrand = document.getElementById("navBrand");

      this.nav = !this.nav;
      if (this.nav) {
        wrapper.classList.remove("collapsed-wrapper");
        navHeader.classList.remove("collapsed-wrapper");

        wrapper.style.marginLeft = "260px";
        topHeader.classList.remove("collapsed-wrapper");
        navBrand.style.display = "block";
      } else {
        wrapper.classList.add("collapsed-wrapper");
        navHeader.classList.add("collapsed-wrapper");

        topHeader.classList.add("collapsed-wrapper");
        navBrand.style.display = "none";
        wrapper.style.marginLeft = "unset";
        this.LOAD_SETTINGS()
      }
    });
  },
  methods: {
    ...mapActions({
      LOAD_SETTINGS: "LOAD_SETTINGS"
    })
  }
};
</script>

<style lang="scss">
aside .sidebar-link.router-link-exact-active {
  border-radius: 0px 60px 60px 0px;
  color: #fff !important;
  background-color: #204f9c;
  /* #093D22; */
  box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
  opacity: 1;
}

@media only screen and (max-width: 1366px) and (max-height: 900px) {
  .sidebar-nav {
    padding-top: .51rem !important;

  }
}
</style>
