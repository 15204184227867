<template>
  <ul class="list-group">
    <li class="list-group-item" v-for="(gateway, index) in payments" :key="index">

      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <img loading="lazy" height="30" :src="gateway.logo" alt="" class="mr-2" />
          <strong>{{ gateway.label }}</strong>
        </div>
        <div class="d-flex">
          <strong v-if="
            isSaving &&
            !isDeleting &&
            selectedProvider &&
            selectedProvider.name === gateway.name
          " class="text-primary">Ativando ...</strong>
          <strong class="text-warning" v-if="isDeleting && deletingObj && deletingObj.name == gateway.name">Removendo
            ...</strong>
          <strong v-if="!isSaving && !isDeleting">
            <span v-if="isSetted(gateway.name)">Status - <span class="text-success">Ativo</span>
            </span>
            <span class="text-muted" v-else>
              <span>Não configurado</span>
              <span class="text-primary ml-3 pointer" @click="addGateWay(gateway)">
                Adicionar
              </span>
            </span>
          </strong>
          <div class="ml-3" v-if="isSetted(gateway.name) && !isSaving && !isDeleting">
            <!-- <span class="pointer"><i class="fas fa-power-off"></i></span> -->
            <span class="pointer" @click="edit(gateway)"><i class="fas fa-pencil-alt ml-4"></i></span>
            <span class="pointer ml-4" @click="_delete(gateway)"><i class="far fa-trash-alt"></i></span>
          </div>
        </div>
      </div>
      <!-- 
      {{ selectedProvider &&
          selectedProvider.name === gateway.name
      }}
      {{ selectedProvider }}
      {{ selectedProviderselectedProvider && selectedProvider.name }}
      {{ gateway.name }} -->
      <div class="container alert alert-warning mt-2" :id="`shipping-${gateway.name}`" v-if="
        selectedProvider &&
        selectedProvider.name === gateway.name &&
        isAdding &&
        !isSaving &&
        !isDeleting
      ">
        <div class="d-flex flex-column" :id="`provider_${selectedProvider.name}`">
          <div class="form-group" v-for="(input, index) in selectedProvider.inputs" :key="index">
            <div class="d-flex flex-row flex-nowrap align-items-center" style="gap:1rem">
              <label :for="'input_' + input.name" style=" margin:0 !important">
                {{ input.name }}
              </label>

              <input :type="input.type" :name="input.nameID" :id="input.nameID"
                :value="getInputValue(input.nameID) || input.value" :default-value="input.defaultValue"
                @change="(event) => $masterHelper.handlerInputModel(selectedProvider.name, input.nameID, event, input.type, payment_provider, handlerSetShippingsStatement)">
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-outline-danger" @click="selectedProvider = null">
              Cancelar
            </button>
            <button class="btn btn-primary ml-2" @click="save()">Salvar</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isDeleting: false,
      isSaving: false,
      isAdding: false,
      isEditing: false,
      payment_provider: {},
      selectedProvider: null,
      deletingObj: null,
      UPDATE: 0,
      payments: [
        {
          name: "cielo",
          label: "Cielo",
          inputs: [
            { name: "Merchant ID", type: "text", nameID: "merchant_id", value: '', defaultValue: '' },
            { name: "Merchant Key", type: "text", nameID: "merchant_key", value: '', defaultValue: '' },
            { name: "Produção", type: "checkbox", nameID: "production", value: false, defaultValue: false },
            { name: "Tem boleto", type: "checkbox", nameID: "hasBoleto", value: false, defaultValue: false },
            { name: "emitir boleto por Banco do Brasil", type: "radio", nameID: "debitProvider", value: 'BancoDoBrasil2', defaultValue: false },
            { name: "emitir boleto por Bradesco", type: "radio", nameID: "debitProvider", value: 'Bradesco2', defaultValue: false },
            { name: "Descrição demonstrativa", type: "text", nameID: "demonstrative", defaultValue: '', placeholder: "Uma breve descrição", title: "Uma breve descrição sobre o boleto" },
            { name: "Identificador do boleto", type: "text", nameID: "identification", defaultValue: '', placeholder: "Digite um código ou nome unicos para identifcar o seu boleto", title: "Um código ou nome unicos para identifcar o seu boleto" },
            { name: "Instruções sobre o boleto", type: "text", nameID: "instructions", defaultValue: '', placeholder: "Ex. Pagar antes da data de vencimento", title: "Instruções sobre o boleto" },
            { name: "Data de vencimento", type: "date", nameID: "expirationDate", defaultValue: '', placeholder: new Date().toLocaleString(), title: `Informe uma data de vencimento, por exemplo ${new Date().toLocaleString()}` },
          ],
          logo: require("../../../../assets/images/cielo_logo.png")
        },
        {
          name: "erede",
          label: "e-Rede",
          inputs: [
            { name: "PV", type: "text", nameID: "pv", value: '', defaultValue: '' },
            { name: "Token", type: "text", nameID: "tokeb", value: '', defaultValue: '' }
          ],
          logo: "",
        },
        {
          name: "Yapay",
          label: "Vendi",
          inputs: [
            { name: "Token Access", type: "text", nameID: "token_access", value: '', defaultValue: '' },
            { name: "Produção", type: "checkbox", nameID: "production", value: true, defaultValue: true },
          ],
          logo: require("../../../../assets/images/vindi.png"),
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["settings"]),
    myGateways() {
      return (
        this.settings.find((set) => {
          return set.key === "payment_provider";
        }) || false
      );
    },
    myActualProvider() {
      console.log('myActualProvider', this.settings.find(set => set.key === "payment_provider"), this.settings)
      return Object.keys(this.settings.find(set => set.key === "payment_provider")?.content || {})[0]
    }
  },
  methods: {
    ...mapActions([
      "LOAD_SETTINGS",
      "UPDATE_SETTINGS",
      "CREATE_SETTINGS",
      "DELETE_SETTINGS_PROVIDER",
    ]),
    isSetted(name) {
      //buscar se tem um payment_provider cadastrado
      if (!this.myGateways) return false;
      return this.myGateways.content.hasOwnProperty(name);
    },
    addGateWay(data) {
      console.log('addGateWay', data.inputs)
      this.isAdding = true;
      this.selectedProvider = data;

      // this.setShowShippingForm(data.name)
    },
    save(key = "payment_provider") {
      try {
        this.isSaving = true;

        let data = this.payment_provider

        this.handlerValidationForm(`provider_${this.selectedProvider?.name}`)

        console.log(data, this.selectedProvider.name, data[this.selectedProvider.name])

        data[this.selectedProvider.name]['name'] = this.selectedProvider.name;

        data = { [this.selectedProvider.name]: data[this.selectedProvider.name] }

        this.CREATE_SETTINGS({
          content: data,
          key: key
        }).then((res) => {

          this.$pace.restart();
          this.UPDATE++;

          if (res.code === 201) {
            this.$toast.success(" ", "Concluido", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
            // this._isSetted(this.selectedProvider.name)
            // if(this.myActualProvider && this.myActualProvider !== this.selectedProvider?.name) {
            //   console.log('CREATE_SETTINGS',this.myActualProvider, this.selectedProvider?.name)
            //   this._delete({name: this.myActualProvider},true)
            // }
            this.UPDATE++;

            this.LOAD_SETTINGS() //window.location.reload()
          } else {
            this.$toast.error(" ", "Houve uma falha.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
          }
        });

        setTimeout(() => {
          this.isSaving = false;
          this.selectedProvider = null;
          this.UPDATE++;
        }, 1000);

        // this.LOAD_SETTINGS() //window.location.reload()

        //console.log("saving", { content: data });
      } catch (error) {
        this.$toast.info(" ", error?.message, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        this.isSaving = false;
      }
    },
    edit(prov) {
      this.isAdding = true;
      this.payment_provider[prov.name] = { ...this.myGateways.content[prov.name] }
      this.selectedProvider = prov;
      this.$forceUpdate();
    },
    async _delete(prov, isOld = false) {
      this.isDeleting = true;
      this.deletingObj = prov;

      let data = this.myGateways.content;
      delete data[prov.name];

      //console.log("going to remove", data);
      const response = await this.DELETE_SETTINGS_PROVIDER({
        content: data,
        id: this.myGateways.id,
      })

      if (response.code !== 201) {
        this.$toast.error(" ", "Houve uma falha ao desativar.", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return
      }

      this.$toast.success(" ", `Integração ${isOld ? 'anterior' : ''} ${prov.name} foi desativada`, {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      console.log('DELETE_SETTINGS_PROVIDER', response)

      this.payment_provider = response.data.content
      this.isDeleting = false;
      this.deletingObj = null;
    },
    handlerValidationForm(formID = "") {
      try {
        if (formID.trim() === "") return

        const inputsFromFormRaw = document.querySelectorAll(`#${formID} input,select,textarea`)

        if (!inputsFromFormRaw) return

        const inputsFromFormParsed = Array.from(inputsFromFormRaw)

        if (inputsFromFormParsed.length <= 0) return

        inputsFromFormParsed.forEach(inputElement => {
          if (inputElement?.type === "text") {

            if (inputElement?.value?.trim() === "" && inputElement?.required) throw new Error(`Preencha o campo ${inputElement?.name}`)

          } else if (inputElement?.type === "select") {

            if (!inputElement?.selectedOption) throw new Error(`Preencha o campo ${inputElement?.name}`)

          } else if (inputElement?.type === "radio" || inputElement?.type === "checkbox") {

            if (inputElement?.required) throw new Error(`Marque o campo ${inputElement?.name}`)

          }
        })
      } catch (error) {
        throw new Error(error?.message)
      }
    },
    handlerSetShippingsStatement(param = {}) {
      try {
        this.payment_provider = {
          ...this.payment_provider,
          ...param
        }
      } catch (error) {
        // do anything
      }
    },
    setShowShippingForm(nameID = "") {
      try {
        if (nameID.trim() === '') return

        const elementShippingForm = document.querySelector(`#shipping-${nameID}`)

        if (!elementShippingForm) return

        if (elementShippingForm.classList.contains('shipping-opened')) {
          elementShippingForm.classList.remove('shipping-opened')
          elementShippingForm.style.display = "none"

          return
        }

        elementShippingForm.classList.add('shipping-opened')
        elementShippingForm.style.display = "block"
      } catch (error) {

      }
    },
    getInputValue(inputID) {
      try {
        if (!this.myGateways) return null

        return this.myGateways.content?.[this.selectedProvider.name]?.[inputID] || null
      } catch (error) {
        console.log(error?.message || `getInputValue@error: Houve um erro`)
      }
    }
  },
  watch: {
    myGateways: {
      deep: true,
      immediate: true,
      handler(val) {
        this.payments.forEach(payment => {
          const providerContent = val?.content?.[payment?.name] || {}

          this.payment_provider[payment?.name] = providerContent
        })
      }
    }
  }
};
</script>

<style>

</style>
