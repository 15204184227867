import Vue from "vue";
import router from "../router/index";

import store from "../store/index";
import VueIziToast from "vue-izitoast";
Vue.use(VueIziToast);

Vue.directive("notify", (el, binding, vnode) => {
  const modifiers = binding.modifiers;
  const arg = binding.arg;
  const value = binding.value;
  var user = store.getters.loggedUser;
  if (user && user.roles && user.roles.length) {
    if (arg === "top") {
      if (modifiers.erro) {
        this.$toast.error(" ", "Numero invalido, curto demais", {
          icon: "icon-person",
          position: "topRight",
          timeout: 8000,
        });
      }
    }
  }
});
