<!-- eslint-disable no-case-declarations -->
<!-- eslint-disable no-case-declarations -->
<template>
  <div class="d-flex justify-content-center main-paginator">
    <nav>

      <ul class="pagination" id="pagination_container">
        <li class="page-item pointer">
          <a @click.prevent="nextPage(model.prev_page_url)" class="page-link" v-if="model.prev_page_url">‹</a>
          <span class="page-link" v-else>‹</span>
        </li>
        <li class="page-item pointer" v-for="page in buildPageBtns" :key="page.href" :class="[{ active: page.active }]"
          @click.prevent="nextPage(page.href)">
          <a class="page-link">{{ page.number }}</a>
        </li>

        <li class="page-item pointer">
          <a class="page-link" v-if="model.next_page_url" @click.prevent="nextPage(model.next_page_url)">›</a>
          <span class="page-link" v-else>›</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import "pace-js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      model: this.value,
      btn_pages: [],
    };
  },
  props: {
    value: {
      required: false,
    },
    eventName: {
      required: true,
    },
    currentPageName: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["last_search"]),
    buildPageBtns() {
      let btns = [];
      for (let i = 1; i <= this.model.last_page; i++) {
        let link_limit = 7;

        let half_total_links = Math.floor(link_limit / 3);

        let from = this.model.current_page - half_total_links;

        let to = this.model.current_page + half_total_links;

        if (this.model.current_page < half_total_links) {
          to += half_total_links - this.model.current_page;
        }

        if (this.model.last_page - this.model.current_page < half_total_links) {
          from -=
            half_total_links -
            (this.model.last_page - this.model.current_page) -
            1;
        }
        if (from < i && i < to) {
          btns.push(this.buildBTN(i, this.model.current_page));
        }
      }
      return btns;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.model = val;
        }
      },
    },
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
        }
      },
    },
  },
  mounted() {
    // do anything
  },
  methods: {
    ...mapActions([
      "SET_PARAMS",
      "PAGINATE_LOAD_POSTS",
      "SEARCH_POST",
      "PAGINATE_LOAD_TAGS",
      "PAGINATE_LOAD_CATEGORIES",
      "PAGINATE_LOAD_PRODUCTS",
      "SEARCH_PRODUCT",
      "PAGINATE_LOAD_ORDERS",
      "SEARCH_ORDER",
      "PAGINATE_LOAD_USERS",
      "SEARCH_SELLER_ORDER",
      "ORDER_SIMPLE_FILTER",
    ]),
    buildElement(tag) {
      return document.createElement(tag);
    },

    buildBTN(incrementor, currentPage) {
      return {
        href: this.model.path + "?page=" + incrementor,
        active: incrementor == currentPage,
        number: incrementor,
      };
    },
    nextPage(url) {
      this.$pace.restart();
      this.$pace.start();

      console.log(
        "PAGINATOR",
        this.model,
        url,
        this.clearUrl(url),
        this.buildPageBtns
      );

      switch (this.currentPageName) {
        case "posts":
          if (this.$masterHelper.isEmpty(this.last_search)) {
            this.PAGINATE_LOAD_POSTS(url);
          } else {
            this.last_search.goToPage = this.clearUrl(url);
            this.SEARCH_POST(this.last_search);
          }
          break;

        case "products":
          // eslint-disable-next-line no-case-declarations
          const lastSearchProduct = {
            "per_page": 16,
            "params": {
              "search": {},
              "relationships": {
                "0": "tags",
                "1": "categories",
                "2": "media",
                "3": "user"
              }
            },
            "model": "Product",
            "goToPage": this.clearUrl(url)
          }
          this.last_search['model'] = 'Product'
          this.SEARCH_PRODUCT(lastSearchProduct, this.clearUrl(url));
          break;

        case "orders":
          // eslint-disable-next-line no-case-declarations
          const lastSearchOrde = {
            "per_page": 16,
            "params": {
              "search": {},
              "relationships": {
                "0": "user",
                "1": "seller",
                "2": "payments",
                "3": "shipping",
                "4": "products",
                "5": "orderProductWithDetails"
              }
            },
            "model": "Order",
            "goToPage": this.clearUrl(url)
          }


          this.SEARCH_ORDER(lastSearchOrde)
          break;
        case "seller":
          this.last_search.goToPage = this.clearUrl(url);
          this.ORDER_SIMPLE_FILTER(this.last_search);
          this.$forceUpdate();
          break;

        case "tags":
          this.PAGINATE_LOAD_TAGS(url);
          break;
        case "categories":
          this.PAGINATE_LOAD_CATEGORIES(url);
          break;

        case "users":
          // eslint-disable-next-line no-case-declarations
          const lastSearchUser = {
            "per_page": 16,
            "params": {
              "search": {},
              "relationships": {
                "0": "roles",
                "1": "client",
                "2": "seller",
              }
            },
            "model": "User",
            "goToPage": this.clearUrl(url)
          }

          this.PAGINATE_LOAD_USERS(lastSearchUser);
          break;
        default:
          break;
      }
    },
    clearUrl(url) {
      return parseInt(url?.replaceAll(/[A-Za-z]/g, "")?.replace(/[^\w\s]/gi, '')?.trim() || 1);
    },
  },
};
</script>

<style>
</style>
