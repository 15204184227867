<template>
  <div v-if="!isEditing && !isCreating">
    <table class="table mt-4">
      <thead>
        <tr>
          <th>Local</th>
          <th>Rua</th>
          <th>Cidade/Estado</th>
          <th>Cep</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="model && model.user_info">
        <tr v-for="address in model.user_info.addresses" :key="address.uuid">
          <td>
            <span v-if="address.type == 1">Entrega</span>
            <span v-else>Cobança</span>
          </td>
          <td>
            {{ address.street }} <br />
            nº {{ address.number }}
          </td>
          <td>{{ address.city }}/ {{ address.state }}</td>
          <td>{{ address.zip }}</td>
          <td>
            <div class="d-flex">
              <i class="fas fa-pencil-alt pointer" @click="edit(address)"></i>
              <i
                class="fas fa-trash-alt ml-4 pointer"
                @click="deleteAddress(address)"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>Nenhum endereço registrado</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="scale-in-center">
    <div class="form-row mt-4">
      <div class="col-3">
        <label for="">Zip</label>
        <input
          type="text"
          class="form-control"
          placeholder="CEP"
          id="zip"
          v-model="tempAddress.zip"
          :disabled="isSaving"
        />
      </div>
      <div class="col">
        <label for="">Cidade</label>
        <input
          v-model="tempAddress.city"
          type="text"
          class="form-control"
          placeholder="Cidade"
          id="city"
          :disabled="isSaving"
        />
      </div>
      <div class="col-3">
        <label for="">Estado</label>
        <input
          type="text"
          class="form-control"
          placeholder="UF"
          id="state"
          v-model="tempAddress.state"
          :disabled="isSaving"
        />
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col">
        <label for="">Endereço</label>
        <input
          v-model="tempAddress.street"
          type="text"
          class="form-control"
          placeholder="Endereço"
          id="street"
          :disabled="isSaving"
        />
      </div>
      <div class="col-3">
        <label for="">Número</label>
        <input
          v-model="tempAddress.number"
          type="text"
          class="form-control"
          placeholder="Número"
          id="number"
          :disabled="isSaving"
        />
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col">
        <label for="">Bairro</label>
        <input
          v-model="tempAddress.district"
          type="text"
          class="form-control"
          placeholder="Bairro"
          id="district"
          :disabled="isSaving"
        />
      </div>
      <div class="col-3">
        <label for="">Complemento</label>
        <input
          v-model="tempAddress.complement"
          type="text"
          class="form-control"
          placeholder="Complemento"
          id="complement"
          :disabled="isSaving"
        />
      </div>
    </div>

    <div class="d-flex justify-content-end w-100 mt-4">
      <button class="btn btn-default" @click="cancel()" :disabled="isSaving">
        Cancelar
      </button>
      <LoaderButton b_class="btn-primary ml-3" :wasClicked="isSaving">
        <button
          class="btn btn-primary"
          @click="save()"
          v-if="isCreating && !isEditing"
        >
          Salvar
        </button>
        <button
          class="btn btn-primary"
          @click="update()"
          v-if="!isCreating && isEditing"
        >
          Atualizar
        </button>
      </LoaderButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  components: { LoaderButton },
  data() {
    return {
      isEditing: false,
      isCreating: false,
      isSaving: false,

      tempAddress: {
        zip: null,
        city: null,
        state: null,
        street: null,
        number: null,
        district: null,
        complement: null,
      },
      model: this.value,
    };
  },
  props: {
    value: { required: true },
  },
  mounted() {},
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.model = val;
      },
    },
  },
  methods: {
    ...mapActions(["ADD_ADDRESS", "REMOVE_ADDRESS", "UPDATE_ADDRESS"]),
    cancel() {
      this.isCreating = false;
      this.isEditing = false;
      this.$event.$emit("display_add_addr", false);
      this.tempAddress = {
        zip: null,
        city: null,
        state: null,
        street: null,
        number: null,
        district: null,
        complement: null,
      };
    },
    edit(address) {
      this.isEditing = true;
      this.tempAddress = address;
      this.$event.$emit("display_add_addr", true);
    },
    create() {
      this.isEditing = false;
      this.isCreating = true;
      this.$event.$emit("display_add_addr", true);
    },
    save(val) {
      if (this._validation()) {
        this.isSaving = true;
        this.tempAddress.user_info_id = this.model.user_info.id;
        this.tempAddress.zip = this.tempAddress.zip.replace(/[^\w\s]/gi, "");
        this.ADD_ADDRESS(this.tempAddress)
          .then((response) => {
            if (response?.code === 201) {
              this.$toast.warning(
                response?.messageRaw?.messge || response?.message
              );
            }

            this.isSaving = false;
            this.cancel();
          })
          .catch((error) => {
            this.$toast.warning(
              error?.message || "Não foi possivel salvar este endereço"
            );
          });
      }
    },
    update() {
      if (this._validation()) {
        this.isSaving = true;
        this.tempAddress.user_info_id = this.model.user_info.id;

        this.$toast.info(" ", "Atualizando", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        this.UPDATE_ADDRESS(this.tempAddress)
          .then((response) => {
            if (response?.code !== 201) {
              this.$toast.warning(
                response?.messageRaw?.messge || response?.message,
                {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 2000,
                }
              );
            }

            this.$toast.info(" ", "Atualizado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });

            this.isSaving = false;
            this.cancel();
          })
          .catch((_error) => {
            this.$toast.warning("Não foi possivel atualizar este endereço");
          });
      }
    },
    deleteAddress(address) {
      this.$toast.success(" ", "Endereço removido", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      this.REMOVE_ADDRESS(address)
        .then((response) => {
          if (response?.code !== 201) {
            this.$toast.warning(
              response?.messageRaw?.messge || response?.message,
              {
                icon: "icon-person",
                position: "topRight",
                timeout: 2000,
              }
            );
          }

          this.$toast.success(" ", "Endereço removido", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        })
        .catch((_error) => {
          this.$toast.warning("Não foi possivel exluir este endereço");
        });
    },
    _validation() {
      let rules = {
        zip: {
          required: true,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        street: {
          required: true,
        },
        number: {
          required: true,
        },
        district: {
          required: true,
        },
      };

      let keys = Object.keys(this.tempAddress);

     // console.log("keys", keys);

      let error = [];

      keys.forEach((key) => {
        if (rules[key] && rules[key].required) {
          if (this.tempAddress[key] == null || !this.tempAddress[key].length) {
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
