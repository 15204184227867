<template>
  <div>
    <button
      @click="reset()"
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#partnerModal"
    >
      Novo
    </button>
    <portal to="modals">
      <div
        class="modal fade"
        id="partnerModal"
        tabindex="-1"
        aria-labelledby="partnerModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="partnerModalLabel">Novo parceiro</h5>
              <button
                id=""
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeParnerModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-center">
                <Upload
                  newStyle="border-radius:25em;border: 1px solid #d5d5d5; width:250px"
                  ref="thumbnailRef"
                  position="header"
                  :noFavorite="true"
                  :height="250"
                  btnText="Imagem do parceiro"
                  @data_deleted="unRegisterThumbnail"
                  @added_item="registerThumbnail"
                  :limit="1"
                />
              </div>
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="tempPartner.name"
                  maxlength="21"
                />
              </div>
              <div class="form-group">
                <label for="name">Descrição</label>
                <textarea
                  id="summary"
                  name="summary"
                  class="form-control"
                  v-model="tempPartner.summary"
                  maxlength="90"
                />
              </div>
              <div class="d-flex justify-content-start mt-4">
                <toggle-button
                  ref="toggleHighlight"
                  class=""
                  :width="90"
                  :switch-color="{
                    checked: '#fff',
                    unchecked: '#fff',
                    disabled: '#CCCCCC'
                  }"
                  :labels="{ checked: 'Ativo', unchecked: '  Inativo' }"
                  v-model="tempPartner.active"
                />
                <strong for="" class="ml-3">Ativo</strong>
              </div>
              <div class="d-flex justify-content-start mt-4">
                <toggle-button
                  ref="toggleHighlight"
                  class=""
                  :width="90"
                  :switch-color="{
                    checked: '#fff',
                    unchecked: '#fff',
                    disabled: '#CCCCCC'
                  }"
                  :labels="{ checked: 'Ativo', unchecked: '  Inativo' }"
                  v-model="tempPartner.highlight"
                />
                <strong for="" class="ml-3">Highlight</strong>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                :disabled="isSaving"
              >
                Cancelar
              </button>
              <LoaderButton :wasClicked="isSaving" class="btn-primary">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="createPartner()"
                >
                  Salvar
                </button>
              </LoaderButton>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Upload from "@/components/imageUpload/Upload";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  components: {
    Upload,
    LoaderButton
  },
  data() {
    return {
      isSaving: false,
      thumbnail: null,
      tempPartner: {
        name: "",
        summary: "",
        active: false,
        highlight: false
      }
    };
  },
  watch: {},
  methods: {
    ...mapActions(["STORE_PARTNERS"]),
    closeModal() {
      $("#partnerModal").modal("hide");
    },
    reset() {
      this.isSaving = false;
      this.thumbnail = null;
      this.tempPartner = {
        name: "",
        summary: "",
        active: false,
        highlight: false
      };
      setTimeout(() => {
        if(this.$refs.thumbnailRef) {
          this.$refs.thumbnailRef._data.tempImages = [];
        }
      }, 1000);
    },
    async createPartner() {
      try {
        this.isSaving = true;

        if (!this.tempPartner.name.length || !this.tempPartner.summary.length) {
          this.$toast.error(" ", "Verifique os campos!", {
            position: "topRight",
            timeout: 3000
          });
          this.isSaving = false;
        //  console.log(nameElement, summaryElement);
          return;
        }

        if (!this.thumbnail) {
          this.$toast.error(" ", "Selecione uma thumbnail", {
            position: "topRight",
            timeout: 3000
          });
          this.isSaving = false;
          return;
        }

        if (!this.tempPartner.name.length || !this.tempPartner.summary) {
          this.$toast.error(" ", "Preencha todos os campos", {
            position: "topRight",
            timeout: 3000
          });
          this.isSaving = false;
          return;
        }

        const data = this.tempPartner;
        data.highlight = this.tempPartner.highlight ? true : false;
        data.active = this.tempPartner.active ? true : false;
        data.thumbnail = this.thumbnail;

        const partner = await this.STORE_PARTNERS(data);

       // console.log("CreatePartnerReturn:", partner);

        this.$toast.success(" ", partner.name + " Cadastrado com sucesso", {
          position: "topRight",
          timeout: 3000
        });
        this.isSaving = false;
        this.reset();
        this.closeModal();
      } catch (error) {
        this.$toast.error(
          " ",
          error?.message || "Houve um erro! tente novamente",
          {
            position: "topRight",
            timeout: 3000
          }
        );
       // console.log(`CreatePartnerError: ${error.message}`);
      }
    },
    registerThumbnail(thumbnail = null) {
      try {
        if (!thumbnail) return;

        //console.log("registerThumbnail", thumbnail);
        this.thumbnail = thumbnail;
      } catch (error) {
        this.$toast.error(" ", "Houve um erro! tente novamente", {
          position: "topRight",
          timeout: 3000
        });
        //console.log(`RegisterThumbnailError: ${error?.message}`);
      }
    },
    unRegisterThumbnail() {
      try {
        //if(thumbnail) return

        //console.log("unRegisterThumbnail");
        this.thumbnail = {};
      } catch (error) {
        this.$toast.error(" ", "Houve um erro! tente novamente", {
          position: "topRight",
          timeout: 3000
        });
        //console.log(`UnRegisterThumbnailError: ${error?.message}`);
      }
    }
  }
};
</script>

<style></style>
