<template>
  <div class="card soft-shadow " style="border:1px solid #efefe ">
    <div class="card-header bg-warning text-dark">Nova tag</div>
    <div class="card-body">
      <div class="form-group">
        <label for="">Nome</label>
        <input
          :disabled="isSaving"
          id="name"
          type="text"
          class="form-control"
          v-model="tempTag.name"
        />
      </div>
      <div class="form-group">
        <label for="">Descrição</label>
        <textarea
          :disabled="isSaving"
          v-model="tempTag.description"
          class="form-control"
          rows="2"
        ></textarea>
      </div>
      <div class="form-group d-flex justify-content-between">
        <toggle-button
          :disabled="isSaving"
          :width="80"
          :switch-color="{
            checked: '#fff',
            unchecked: '#fff',
            disabled: '#CCCCCC'
          }"
          v-model="tempTag.active"
          :sync="false"
          :labels="{ checked: 'Ativo', unchecked: '  Desativado' }"
        />
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-secondary mr-3"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <LoaderButton b_class="btn-primary" :wasClicked="isSaving">
            <button
              @click="create()"
              type="button"
              class="btn btn-primary btn-request"
            >
              Criar
            </button>
          </LoaderButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  components: {
    LoaderButton
  },
  props: {
    possibleName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tempTag: {
        name: null,
        description: null,
        active: 1
      },
      isSaving: false
    };
  },
  watch: {
    tempTag: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          if (val.active === true) {
            this.tempTag.active = 1;
          }
          if (val.active === false) {
            this.tempTag.active = 0;
          }
        }
      }
    }
  },
  mounted() {
    //console.log("the props", this.possibleName);
    if (this.possibleName) {
      this.tempTag.name = this.possibleName;
    }
  },
  computed: {},
  methods: {
    ...mapActions(["CREATE_TAG"]),
    async create() {
      if (this._validation()) {
        try {
          this.isSaving = true;
          let res = await this.CREATE_TAG(this.tempTag);
          this.isSaving = false;
          this.$emit("manager_save_tag", res.data.tag);
          this.tempTag = {
            name: null,
            description: null,
            active: 1
          };
        } catch (err) {
          //console.log("error", err);
        }
      }
    },
    _validation() {
      let rules = {
        name: {
          required: true
        }
      };

      let keys = Object.keys(this.tempTag);

     // console.log("keys", keys);

      let error = [];

      keys.forEach(key => {
        if (rules[key] && rules[key].required) {
          if (this.tempTag[key] == null || !this.tempTag[key].length) {
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        return false;
      }

      return true;
    }
  }
};
</script>

<style></style>
