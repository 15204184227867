<template>
  <div>
    <BreadCrumb :pageInfo="pageInfoCreator" :page="pageTitle"> </BreadCrumb>

    <div class="row mt-4">
      <div class="col-3">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              R$ {{ $format($money(seller_reports.earnings)) }}
            </h2>

            <strong class="text-success">Comissão liberada</strong>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              R$ {{ $format($money(seller_reports.retained_earnings)) }}
            </h2>

            <strong class="text-warning"> Comissão a liberar</strong>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              R$ {{ $format($money(seller_reports.total_earnings)) }}
            </h2>

            <strong class="text-primary">Comissão total</strong>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              {{ seller_reports.monthly_sales }}
            </h2>

            <strong class="text-dark">
              Vendas no mês
            </strong>
            <!-- <strong class="text-dark" v-else>Total de vendas filtrada</strong> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <OrderList :orders="seller_orders" changePage="seller" :list-title="changeIfSellerSelected" />
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <SimpleFilter page="seller" key="info" />
        </transition-group>
      </div>
    </div>
    <OrderModal />
  </div>
</template>

<script>
import OrderList from "@/components/orders/OrderList";
import { mapGetters, mapActions } from "vuex";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import OrderModal from "@/components/orders/OrderModal";
import Multiselect from "vue-multiselect";
import SimpleFilter from "@/components/Filters/SimpleFilter";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      selectedClients: [],
      search: null,
      dateStarts: null,
      dateEnds: null,
      isSaving: false,
    };
  },
  components: {
    OrderList,
    BreadCrumb,
    OrderModal,
    Multiselect,
    SimpleFilter,
  },
  mounted() {
    this.LOAD_SELLER_CLIENTS(this.loggedUser.id);
    this.LOAD_SELLER_ORDERS();
    this.SET_PARAMS({});
    //("$userRoles", this.$userByroles(this.usersList, ["seller"]));

    if (this.$hasRole(this.loggedUser, ["seller"])) {
      const sellerID = this.sellers.find(seller => seller.user_id == this.loggedUser.id)?.id || - 1
      const payloadDraft = {
        params: {
          search: {
            seller_id: {
              type: "where",
              operator: "=",
              value: sellerID
            }
          }
        },
        model: "Order"
      }

      const payloadSecurityLayer = {
        set(target, prop, _value) {
          try {
            console.log('Warn::payloadSecurityLayer', `A new try to set a new value to the ${prop} property was detected`)

            const oldValue = target[prop]
            target[prop] = oldValue

            return true
          } catch (error) {
            console.log('Warn::payloadSecurityLayer', error?.message)
          }
        }
      }

      const payload = new Proxy(payloadDraft, payloadSecurityLayer)

      this.SEARCH_SELLER_ORDER(payload);
    }
  },
  computed: {
    ...mapGetters([
      "orders",
      "ordersPaginate",
      "loggedUser",
      "sellers",
      "seller_orders",
      "seller_clients",
      "seller_reports",
      "last_search",
      "usersList",
    ]),
    changeIfSellerSelected() {
      //  <!-- <h3 class="text-dark mt-2" v-if="!changeIfSellerSelected">
      //   Pedidos dos Vendedores
      // </h3>
      // <h3 class="text-dark mt-2" v-else>Pedido do Vendedor</h3> -->

      if (!this.$masterHelper.isEmpty(this.last_search)) {
        let sellers = this.last_search.params.seller_id || [];
        if (sellers.length == 1) return "Pedido do Vendedor";
        return "Pedidos dos Vendedores";
      } else {
        return "Pedidos dos Vendedores";
      }
    },
    pageInfoCreator() {
      if (this.$hasRole(this.loggedUser, ["admin", "dev"])) {
        return (
          "Vendedores registrados " +
          this.$userByroles(this.usersList, ["seller"]).length
        );
      }
      return this.myOrders.length + " vendas";
    },
    pageTitle() {
      if (this.$hasRole(this.loggedUser, ["admin", "dev"])) {
        return "Total de Vendas dos Vendedores";
      }
      return "Painel do Vendedor";
    },
    myOrders() {
      if (!this.seller_orders || !this.seller_orders.length) return [];

      return this.seller_orders;
    },
    myGains() {
      let total = 0;
      this.seller_orders.forEach((or) => {
        total = total + or.price;
      });

      return total;
    },
  },
  methods: {
    ...mapActions([
      "QUICK_LIST",
      "SEARCH_SELLER_ORDER",
      "LOAD_SELLER_ORDERS",
      "LOAD_SELLER_CLIENTS",
      "SET_PARAMS",
    ]),
    checkParams() {
      let params = Object.keys(this.last_search.params).length;
      if (params > 1) {
        return true;
      }
      return false;
    },
    myGainsByStatus(status) {
      let total = 0;

      let orders = this.seller_orders.filter((or) => {
        return or.status === status;
      });

      //console.log("your orders", orders);

      orders.forEach((or) => {
        // console.log(
        //   `total is ${total} + the price of order ${or.price} the final ${
        //     total + or.price
        //   }`
        // );
        total += or.price;
      });

      return total;
    },
  },
};
</script>

<style>

</style>
