import {ValidationError} from "yup"

/**
 * Usando do schema validation, é feito a validação do formulario e caso algum campo esteja invalido um objeto contendo o nome do campo
 * e uma mensagem de erro é retornado
 * @param SchemaValidation
 * @param dataForm
 * @returns Um objeto contendo 'N' erros de validação ou um Code e Message
 */
export async function handlerValidation(SchemaValidation, dataForm = {}) {
  try {
    await  SchemaValidation.validate(dataForm, {
      abortEarly: false
    })
  } catch (error) {
    const validationErrors = {}

    if(error instanceof ValidationError) {
        error.inner.forEach(err => {
            validationErrors[err.path || "field"] = err.message
        });
    } else {
        validationErrors['code'] = "500";
        validationErrors['message'] = error.message;
    }

    return validationErrors
  }
}
