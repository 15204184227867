<template>
  <div>
    <BreadCrumb :pageInfo="
      !displayBlingProducts
        ? products
          ? products.length + ' produtos'
          : ''
        : blingProducts && blingProducts.length + ' produtos listados na Bling'
    " :page="!displayBlingProducts ? 'Produtos' : 'Produtos do Bling'">
      <div slot="right-side">
        <button class="btn btn-success mr-4" @click="loadBlingProducts()">
          <span v-if="displayBlingProducts">Voltar</span>
          <span v-else-if="
            storeRules &&
            storeRules.hasBling &&
            storeRules.hasBling.inSaleRight
          ">Produtos Bling</span>
          <span v-else>Voltar</span>
        </button>
        <button class="btn btn-primary" @click="$router.push({ name: 'product.create' })">
          Novo
        </button>
      </div>
    </BreadCrumb>

    <div class="row flex-wrap">
      <div id="prod-report-table-col" class="col">
        <LatestSoldProducts />
      </div>

      <ProductReports v-can="['product-report']" />
    </div>

    <div class="col-12 d-flex mt-2">
      <h2 class="text-dark mb-3 dash-session-title">Todos os produtos</h2>
      <hr class="w-100" />
    </div>

    <div class="mb-2 mt-3">
      <small v-if="!displayBlingProducts" class="alert alert-info">
        Dê dois cliques para abrir os detalhes do produto.
      </small>
    </div>

    <div class="col-9 mt-5" v-if="!productsFilterCore || productsFilterCore.length <= 0">
      <h3>Nenhum produto disponível</h3>
    </div>

    <div class="row" id="responsive-page-container">
      <div class="col-9 product-wrapper table-report" id="responsive-page-table"
        v-if="productsFilterCore && productsFilterCore.length > 0">
        <transition-group name="slide-in" mode="out-in">
          <ProductList :list="productsFilterCore" v-if="
            !displayBlingProducts &&
            productsFilterCore &&
            productsFilterCore.length > 0
          " key="products_list" />

          <BlingProductList ref="bling_list" v-model="searchInBling" v-else key="bling_products_list" />
        </transition-group>
      </div>

      <div class="col-3 product-wrapper" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <MasterFilter :hideInputs="['roles', 'status', 'users']" key="info" v-show="
            $masterHelper.isEmpty(product) &&
            !displayBlingProducts
          " page="Products" :rules="{
  searchable: [
    'name',
    'description',
    'short_description',
    'summary',
  ],
  relationships: [
    'tags',
    'categories',
    'media',
    'user',
  ],
}" />

          <!-- <div
    key="info"
    class="card"
    style="position:fixed; width:20%"
    v-show="$masterHelper.isEmpty(product) && !displayBlingProducts"
  >
    <div class="card-body">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="">Filtros</label>
          <label class="text-danger">Limpar filtros</label>
        </div>
        <input
          v-model="filter.search"
          class="form-control"
          type="text"
          placeholder="Pesquisar"
        />
        <small>Pesquise por título ou por alguma frase.</small>
      </div>

      <hr />

      <div class="form-group">
        <label for="">Data</label>
        <div>
          <small>Inicio</small>
          <input
            class="form-control"
            type="date"
            v-model="filter.date.starts"
          />
        </div>
        <div>
          <small>Fim</small>
          <input
            class="form-control"
            type="date"
            v-model="filter.date.ends"
          />
        </div>
      </div>
      <button class="btn btn-primary btn-block">
        Pesquisar
      </button>
    </div>
  </div> //aki -->
          <div v-show="
            !$masterHelper.isEmpty(product) &&
            !displayBlingProducts
          " key="edition">
            <ProductInfo />
          </div>
          <div class="card" v-if="displayBlingProducts" key="bling_options">
            <div class="card-body">
              <h5 class="font-weight-bold">Bling</h5>
              <div class="mt-3">
                <div class="form-group">
                  <label for="">Pesquisa</label>
                  <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder=""
                    v-model="searchInBling.query" />
                  <small id="helpId" class="form-text text-muted">Pesquise por id, código, marca e
                    descrição.</small>
                </div>
                <div class="form-group">
                  <label for="">Situação</label>
                  <select class="form-control" v-model="searchInBling.situation" id="">
                    <option value="Ativo">Ativo</option>
                    <option value="Desativado">
                      Desativados
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">{{
                      $refs.bling_list
                        ? $refs.bling_list
                          .selectedProducts.length
                        : 0
                  }}
                    itens selecionados</label>
                </div>
                <LoaderButton b_class=" btn-primary " :wasClicked="
                  $refs.bling_list
                    ? $refs.bling_list.isImporting
                    : false
                ">
                  <button :disabled="
                    $refs.bling_list &&
                    !$refs.bling_list.selectedProducts
                      .length
                  " class="btn btn-primary btn-block" @click="$refs.bling_list.startImport()">
                    Importar
                  </button>
                </LoaderButton>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ProductList from "@/components/products/ProductList";

import BlingProductList from "@/components/products/BlingProductList";

import ProductInfo from "@/components/products/ProductInfo";

import BreadCrumb from "@/components/navigation/BreadCrumb";
import LoaderButton from "@/components/buttons/LoaderButton";
import MasterFilter from "@/components/Filters/MasterFilter";
import LatestSoldProducts from "@/components/products/LatestSoldProducts";
import ProductReports from "@/components/products/ProductReports";

export default {
  data() {
    return {
      searchInBling: {
        query: "",
        situation: "Ativo",
      },
      filter: {
        search: "",
        date: {
          starts: null,
          ends: null,
        },
      },
      displayBlingProducts: false,
    };
  },
  components: {
    ProductList,
    ProductInfo,
    BreadCrumb,
    BlingProductList,
    LoaderButton,
    MasterFilter,
    LatestSoldProducts,
    ProductReports,
  },
  computed: {
    ...mapGetters(["product", "products", "blingProducts", "settings"]),
    productsFilterCore() {
      let products = this.products || [];

      products = products.filter((prod) => {
        return (
          prod.name
            ?.toLowerCase()
            .includes(this.filter.search?.toLowerCase()) ||
          prod.description
            ?.toLowerCase()
            .includes(this.filter.search?.toLowerCase())
        );
      });

      if (this.filter.date.starts) {
        products = this.products.filter((prod) => {
          if (prod && prod.name && prod.description) {
            return (
              prod.name
                ?.toLowerCase()
                .includes(this.filter.search?.toLowerCase()) ||
              prod.description
                ?.toLowerCase()
                .includes(this.filter.search?.toLowerCase())
            );
          } else {
            return false;
          }
        });
      }

      if (this.filter.date.end) {
        products = this.products.filter((prod) => {
          return (
            this.$dayJs(this.$date(prod.created_at)).unix() <=
            this.$dayJs(this.$date(this.filter.date.ends)).unix()
          );
        });
      }

      return products;
    },
    storeRules() {
      return (
        this.settings.find((set) => {
          return set.key === "store_rules";
        })?.content || undefined
      );
    },
  },
  mounted() {
    this.$event.$on("back_to_products", () => {
      this.displayBlingProducts = false;
    });
    // this.LOAD_PRODUCTS()
  },
  methods: {
    ...mapActions(["LOAD_PRODUCTS"]),
    loadBlingProducts() {
      this.displayBlingProducts = !this.displayBlingProducts;
    },
  },
};
</script>

<style lang="scss">
#prod-report-table-col {
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (max-width: 1366px) {
  .page-wrapper:not(.collapsed-wrapper) {
    #prod-report-table-col {
      order: 2;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
