import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
import Dashboard from "../views/Dashboard";
import PaginaDois from "../views/PaginaDois";

import PostsLayout from "../views/posts/Layout";
import Posts from "../views/posts/Index";
import Post from "../views/posts/Post";

import TagsLayout from "../views/tags/Layout";
import Tags from "../views/tags/Index";

import CategoryLayout from "../views/categories/Layout";
import Categories from "../views/categories/Index";
import ProductLayout from "../views/products/Layout";
import PartnerLayout from "../views/partners/Layout";
import VisualizationLayout from "../views/visualization/Layout";
import Products from "../views/products/Index";
import Partners from "../views/partners/Index";
import Visualizations from "../views/visualization/Index";
import Product from "../views/products/Product";
import OrderLayout from "../views/orders/Layout";
import UserLayout from "../views/users/Layout";
import UserDetails from "../views/users/UserDetails";
import ConfigLayout from "../views/config/Layout";
import Cupom from "../views/cupom";

import SellerLayout from "../views/seller/Layout";
import StoreRules from "../components/configs/sections/StoreRules";
import Company from "../components/configs/sections/Company";
import Template from "../components/configs/sections/Template";
import Slider from "../components/configs/sections/Slider";
import Pages from "../components/configs/sections/Pages";
import Personal from "../components/configs/sections/Personal";
import ConfigRoles from "../components/configs/sections/Roles";
import ConfigIntegrations from "../components/configs/sections/Integrations";

import Login from "../views/Login";

import PreviewPost from "../views/posts/PreviewPost";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      // DASHBOARD
      {
        path: "",
        name: "index",
        component: Dashboard,
        icon: "fas fa-home",
        if_roles: ["dev", "admin", "user"]
      },
      // PRODUTOS
      {
        path: "/products",
        name: "Produtos",
        component: ProductLayout,
        icon: "fas fa-box",
        if_roles: ["dev", "admin", "user"],
        children: [
          {
            path: "",
            name: "product.list",
            component: Products
          },
          {
            path: "create",
            name: "product.create",
            component: Product
          },
          {
            path: "details/:uuid",
            name: "product.details",
            component: Product
          }
        ]
      },
      // PEDIDOS
      {
        path: "/orders",
        name: "Pedidos",
        component: OrderLayout,
        icon: "fas fa-book",
        if_roles: ["dev", "admin", "user"],
        children: [
          // {
          //   path: "",
          //   name: "",
          //   component: Categories
          // }
        ]
      },
      // VISUALIZAÇÕES
      {
        path: "/visualizations",
        name: "Visualizações",
        component: VisualizationLayout,
        icon: "fas fa-box",
        if_roles: ["dev", "admin", "user"],
        children: [
          {
            path: "",
            name: "visualization.list",
            component: Visualizations
          }
        ]
      },

      // POST
      {
        path: "/posts",
        name: "Posts",
        icon: "fas fa-book",
        component: PostsLayout,
        if_roles: ["dev", "admin"],
        children: [
          {
            path: "",
            name: "post.list",
            component: Posts
          },
          {
            path: "create",
            name: "post.create",
            component: Post
          },
          {
            path: "details/:id",
            name: "Post",
            component: Post
          }
        ]
      },

      // CUPOM
      {
        path: "/cupons",
        name: "Cupons",
        icon: "fas fa-book",
        component: Cupom,
        if_roles: ["dev", 'admin', "seller"],
        children: []
      },

      // TAGS
      {
        path: "/tags",
        name: "Tags",
        component: TagsLayout,
        icon: "fas fa-tags",
        if_roles: ["dev", "admin"],
        children: [
          {
            path: "/",
            name: "",
            component: Tags
          }
        ]
      },

      // CATEGORIES
      {
        path: "/categories",
        name: "Categorias",
        component: CategoryLayout,
        icon: "fas fa-puzzle-piece",
        if_roles: ["dev", "admin", "user"],
        children: [
          {
            path: "",
            name: "",
            component: Categories
          }
        ]
      },

      // PARCEIROS
      {
        path: "/partners",
        name: "Parceiros",
        component: PartnerLayout,
        icon: "fas fa-box",
        if_roles: ["dev"],
        children: [
          {
            path: "",
            name: "partner.list",
            component: Partners
          },
          {
            path: "create",
            name: "partner.create",
            component: Product
          },
          {
            path: "details/:uuid",
            name: "partner.details",
            component: Product
          }
        ]
      },

      // USERS
      {
        path: "/users",
        name: "Usuários",
        component: UserLayout,
        icon: "fas fa-users",
        if_roles: ["dev", "admin", "user"],
        children: []
      },

      // USER DETAILS
      {
        path: "user/:uuid/details",
        name: "user.details",
        hideOnNav: true,
        component: UserDetails,
        if_roles: ["dev", "admin", "user"]
      },

      // COMISSÕES
      {
        path: "/comissions",
        name: "Minhas Vendas",
        component: SellerLayout,
        icon: "fas fa-calculator",
        hideOnNav: true,
        if_roles: ["dev", "admin", "user", "seller"],
        children: [
          // {
          //   path: "",
          //   name: "",
          //   component: Categories
          // }
        ]
      },

      // CONFIG
      {
        path: "/config",
        name: "Configurações",
        component: ConfigLayout,
        icon: "fas fa-cogs",
        if_roles: ["dev", "admin", "user"],
        children: [
          {
            path: "roles",
            name: "config.roles",
            component: ConfigRoles,
          },
          {
            path: "integrations",
            name: "config.integrations",
            component: ConfigIntegrations
          },
          {
            path: "store_rules",
            name: "config.store_rules",
            component: StoreRules
          },
          {
            path: "company",
            name: "config.company",
            component: Company
          },
          {
            path: "template",
            name: "config.template",
            component: Template
          },
          {
            path: "slider",
            name: "config.slider",
            component: Slider
          },
          {
            path: "paginas",
            name: "config.paginas",
            component: Pages
          },
          {
            path: "pessoal",
            name: "config.pessoal",
            component: Personal
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/preview",
    name: "preview.post",
    component: PreviewPost,
    if_roles: ["dev", "admin"]
  }
];

["push", "replace"].forEach(method => {
  const originalMethod = VueRouter.prototype[method];
  VueRouter.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== "NavigationDuplicated") {
        // capture exception
      }
    });
  };
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

export default router;
