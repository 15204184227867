<template>
  <div class="modal fade" id="OrderModal" tabindex="-1" aria-labelledby="OrderModalLabel" aria-hidden="true"
    v-if="!$masterHelper.isEmpty(order)">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="modal-title" id="OrderModalLabel">
              <StatusBadge :status="order.status" />
            </h4>
          </div>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body m-2">
          <div class="d-flex align-items-baseline">
            <h4 for=""><strong>Pedido</strong></h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="mt-3">
                <strong for="">Solicitado em:</strong>
                <p>{{ $dateTime(order.created_at) }}</p>
              </div>
              <div class="mt-3">
                <strong for="">Cod</strong>
                <p class="text-primary">{{ order.uuid }}</p>
              </div>
            </div>

            <div class="col">
              <div class="mt-3">
                <strong for="">Vendedor:</strong>
                <p class="text-muted">
                  <a @click.prevent="
                    openProfile(getSeller(order.seller_id).uuid)
                  " class="text-primary" v-if="getSeller(order.seller_id)">{{ getSeller(order.seller_id).name }}</a>
                  <span v-else>Nenhum</span>
                </p>
              </div>
              <div class="mt-3">
                <strong for="">Valor total:</strong>
                <p>R$ {{ $format($money(order.price)) || "-" }}</p>
              </div>
            </div>
          </div>
          <hr />
          <div v-role="['admin', 'dev']">
            <label for="" class="mt-3">Status geral</label>
            <div class="d-flex align-items-center">
              <StatusSelect v-model="order.status" @change="setTempOrder" :selectComponent="false" />
              <LoaderButton b_class="btn-primary" :wasClicked="isUpdating">
                <button @click="updateOrderStatus()" class="btn btn-primary">
                  Atualizar
                </button>
              </LoaderButton>
            </div>
          </div>
          <hr />
          <div class="d-flex align-items-baseline">
            <h4 for=""><strong>Cliente</strong></h4>
          </div>
          <hr />
          <strong class="mt-3">Nome:</strong>
          <p>
            {{ getUser(order.buyer_id).name || '-' }}
          </p>
          <strong class="mt-3">Email:</strong>
          <p>
            {{ getUser(order.buyer_id).email || '-' }}
          </p>
          <hr />
          <div class="d-flex align-items-baseline">
            <h4 for=""><strong>Pagamento</strong></h4>
          </div>
          <hr />
          <OrderPayment :order="order" />
          <hr />
          <div class="d-flex align-items-baseline">
            <h4>
              <strong>Frete</strong>
            </h4>
          </div>
          <hr />
          <OrderShipping :shippings="order.shipping" />
          <hr />
          <div class="d-flex align-items-baseline">
            <h4 for=""><strong>Entrega</strong></h4>
          </div>
          <hr />
          <div class="row mt-3">
            <div class="col">
              <div class="mt-3">
                <strong for="">Endereço:</strong>
                <p>
                  {{ order.delivery_address.street }} nº
                  {{ order.delivery_address.number || "-" }}
                </p>
              </div>
              <div class="mt-3">
                <strong for="">Complemento/Obs:</strong>
                <p>{{ order.delivery_address.complement || "-" }}</p>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="mt-3">
                    <strong for="">Cidade:</strong>
                    <p>{{ order.delivery_address.city || "-" }}</p>
                  </div>
                </div>
                <div class="col">
                  <div class="mt-3">
                    <strong for="">Estado:</strong>
                    <p>{{ order.delivery_address.state || "-" }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mt-3">
                    <strong for="">Bairro:</strong>
                    <p>{{ order.delivery_address.district || "-" }}</p>
                  </div>
                </div>
                <div class="col">
                  <div class="mt-3">
                    <strong for="">Cep:</strong>
                    <p>{{ order.delivery_address.zip || "-" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <h4 v-show="order && order.order_products">
            <strong>Produtos</strong>
          </h4>

          <OrderProductList :order="order" v-if="order && order.order_products" />
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserInfo from "@/components/users/UserInfo";
import StatusBadge from "@/components/status/Index";

import OrderPayment from "@/components/orders/OrderPayment";
import OrderProductList from "@/components/orders/OrderProductList";
import OrderShipping from "@/components/orders/OrderShipping";
import StatusSelect from "@/components/status/StatusSelect";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  data() {
    return {
      loadingIDs: [],
      tempOrder: {},
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters(["order", "users", "usersList", "products", "sellers"]),
  },
  components: {
    UserInfo,
    StatusBadge,
    OrderPayment,
    OrderProductList,
    OrderShipping,
    StatusSelect,
    LoaderButton,
  },
  mounted() {
    if (this.products && !this.products.length) this.LOAD_PRODUCTS();

    this.LOAD_USERS_SELLERS();
  },
  methods: {
    ...mapActions([
      "LOAD_PRODUCTS",
      "FETCH_USER",
      "UPDATE_ORDER_STATUS",
      "LOAD_USERS_SELLERS",
    ]),
    getClient(id) {
      return this.users.find((user) => user.id === id);
    },
    getSeller(id) {
      try {
        let hasSeller =
          this.sellers?.find((seller) => {
            return seller?.id === id;
          })?.user || undefined;


        if (hasSeller) return hasSeller;
      } catch (error) {
        // do anything
      }
    },
    openProfile(uuid) {
      if (this.$route.params.uuid !== uuid) {
        let routeData = this.$router.resolve({
          name: "user.details",
          params: { uuid: uuid },
        });
        window.open(routeData.href, "_blank");
      }
    },

    isLoadingUser(id) {
      return this.loadingIDs.some((loadingID) => loadingID == id);
    },
    getUser(id) {
      try {
        const usersRaw = this.users || this.usersList
        let user = usersRaw?.find((user) => user.id === id);

        console.log('Info::getUser', usersRaw, user)

        if (!user) {
          if (!this.isLoadingUser(id)) {
            this.loadingIDs.push(id);
            this.FETCH_USER(id).then((r) => {
              if (r?.status === 200) {
                return r.data.user;
              }
            });
          }

          return { name: "Carregando ..." };
        }

        return user;
      } catch (error) {
        console.log('Warn::getUser', error)
      }
    },
    setTempOrder(data) {
      this.tempOrder = {
        id: this.order.id,
        status: data,
      };
    },
    async updateOrderStatus() {
      this.isUpdating = true;
      let res = await this.UPDATE_ORDER_STATUS(this.tempOrder);
      if (res.status == 200) {
        if (res.data.order.error) {
          this.$toast.error(
            " ",
            error?.message || "Houve um erro, tente novamente mais tarde.",
            {
              position: "topRight",
              timeout: 3000,
            }
          );
        } else {
          this.$toast.success(" ", "Status do pedido foi atualizado.", {
            position: "topRight",
            timeout: 3000,
          });
        }
        this.isUpdating = false;
      } else {
        this.$toast.error(
          " ",
          error?.message || "Houve um erro, tente novamente mais tarde.",
          {
            position: "topRight",
            timeout: 3000,
          }
        );
        this.isUpdating = false;
      }
    },
  },
};
</script>
