<template>
  <div v-if="!$hasRole(loggedUser, ['seller'])">
    <BreadCrumb pageInfo="" page="Dashboard"> </BreadCrumb>
    <!-- v-can="['financial-report']" -->
    <div class="row">
      <div class="col-12 d-flex">
        <h2 class="text-dark mb-3 dash-session-title">
          Números Financeiros
        </h2>
        <hr class="w-100" />
      </div>

      <div class="row m-0 p-0 w-100" id="financial-report">
        <div class="w-100 w-sm-25 d-flex flex-wrap cards-row">
          <div class="col-12 card-row-container">
            <div class="card">
              <div class="card-body low-shadow">
                <h2 class="
                                text-dark
                                mb-1
                                w-100
                                text-truncate
                                font-weight-medium
                            ">
                  <sup class="set-doller">R$</sup><span class="count-me" v-if="orderReport">{{
                  !isNaN(orderReport.total_bruto)
                    ? $format(
                      $money(orderReport.total_bruto)
                    )
                    : "0,00"
                  }}</span>
                </h2>
                <strong class="">Total bruto em pedidos</strong>
              </div>
            </div>
          </div>

          <div class="col-12 card-row-container">
            <div class="card">
              <div class="card-body low-shadow">
                <h2 class="
                      text-dark
                      mb-1
                      w-100
                      text-truncate
                      font-weight-medium
                    ">
                  <sup class="set-doller">R$</sup>
                  <span class="count-me">
                    {{
                    !isNaN(sellerReport.total_comissions) ? $format($money(sellerReport.total_comissions)) : "0,00"
                    }}
                  </span>
                </h2>
                <strong class="">de Comissão</strong>
              </div>
            </div>
          </div>

          <div class="col-12 card-row-container">
            <div class="card">
              <div class="card-body low-shadow">
                <h2 class="
                      text-dark
                      mb-1
                      w-100
                      text-truncate
                      font-weight-medium
                    ">
                  <sup class="set-doller">R$</sup>
                  <span class="count-me">{{ !isNaN(financialReport.total_cost) ?
                    $format($money(financialReport.total_cost)) : "0,00"
                  }}</span>
                </h2>
                <strong class="">de Custo</strong>
              </div>
            </div>
          </div>
        </div>

        <!-- Last Orders -->
        <div class="w-100 w-sm-75 table-report" id="order-report">
          <div class="card low-shadow">
            <div class="card-header d-flex">
              <h3 class="text-dark mt-2">Últimos Pedidos Criados</h3>
              <hr />
            </div>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th colspan="2">Cliente</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in getLastOrders" :key="order.uuid">
                    <td>{{ order.id }}</td>
                    <td colspan="2">{{ order.user.name }}</td>
                    <td>
                      R$
                      {{
                      !isNaN(order.price) ? $format($money(order.price)) : "0,00"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- v-can="['product-report']" -->
    <div class="row" id="product-report">
      <div class="col-12 d-flex">
        <h2 class="text-dark mb-3 dash-session-title">
          Números de Produtos
        </h2>
        <hr class="w-100" />
      </div>

      <div class="col-12 col-sm-6 table-report">
        <div class="card low-shadow">
          <div class="card-header">
            <h3 class="text-dark mt-2">
              Últimos Produtos Negociados
            </h3>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th colspan="2">Produto</th>
                  <th>Qtde.</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="prod in getLastProductsSold" :key="prod.uuid">
                  <td>{{ prod.id }}</td>
                  <td colspan="2">{{ prod.product.name }}</td>
                  <td>{{ prod.quantity }}</td>
                  <td>
                    R$
                    {{
                    !isNaN(prod.total_price) ? $format($money(prod.total_price)) : "0,00"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 d-flex flex-wrap cards-row">
        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.total_products_count }}
              </h2>
              <strong class="">Produtos cadastrados</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.total_products_count_mounph }}
                <!-- 73 -->
              </h2>
              <strong class="">Produtos cadastrados este mês</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.count_highlight_products }}
              </h2>
              <strong class="">Produtos em destaque</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.count_distinct_sold_products }}
              </h2>
              <strong class="">Produtos diferentes negociados</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.total_products_sold }}
              </h2>
              <strong class="">Total de produtos negociados</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ productReport.different_products_visited }}
              </h2>
              <strong class="">Produtos diferentes visitados</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body low-shadow">
              <h2 class="text-dark mb-1 font-weight-medium">
                {{ visualizations.length }}
              </h2>
              <strong class="">Produtos Visualizados</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import _Counter from "../helpers/animated_value.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      today: this.$dayJs(),
      productsVisualization: 0,
    };
  },
  components: {
    BreadCrumb,
  },
  watch: {
    clients(val) {
      if (val.length) {
        _Counter();
        // console.log("this.today.getMonth(0)", this.getClientsOnMonth(8));
      }
    },
  },
  computed: {
    ...mapGetters([
      "users",
      "loggedUser",
      "orders",
      "visualizations",
      "orderReport",
      "financialReport",
      "productReport",
      "sellerReport",
      "clientReport"
    ]),
    newClients() {
      return this.getClientsOnMonth(dayjs().month()).length;
    },
    clients() {
      if (!this.users) return false;
      return this.users.filter((user) => {
        return user.roles.some((role) => {
          return role.name === "client";
        });
      });
    },
    getLastOrders() {
      if (!this.orderReport?.last_orders?.length) return [];

      return this.orderReport.last_orders;
    },
    getLastProductsSold() {
      //OK
      if (!this.productReport?.last_products_sold?.length) return [];

      return this.productReport.last_products_sold;
    },
  },
  mounted() {
    // do anything
    // throw new InternalError("Sentry test dialog Feedback")

    if (this.$hasRole(this.loggedUser, ["seller"])) {
      this.$router.push('comissions')
    }
  },
  methods: {
    ...mapActions([
      "ORDER_REPORT",
      "FINANCIAL_REPORT",
      "PRODUCT_REPORT",
      "SELLER_REPORT",
      "CLIENT_REPORT",
      "LOAD_ORDERS",
      "LOAD_VISUALIZATION",
    ]),

    getClientsOnMonth(month) {
      try {
        let users = this.users.filter((user) => {
          return user.roles.some((role) => {
            return role.name === "client";
          });
        });

        return users.filter((user) => {
          return dayjs(user.created_at).month() == month;
        });

      } catch (error) {
        // console.log(`getClientsOnMonth@error`, error)
      }
    },

    getOrderByStatus(status) {
      try {
        let orders = this.orders.filter((order) => {
          return (
            dayjs(order.created_at).month() == dayjs().month() &&
            order.status == status
          );
        });
        return orders;
      } catch (error) {
        //console.log('getOrderByStatus@error', error)
      }
    }
  },
};
</script>

<style>
.dash-session-title {
  white-space: nowrap;
  padding-right: 2vw;
}
</style>
