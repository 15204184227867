<template>
  <div class="">
    <BreadCrumb :crumbs="[
      { name: 'Usuários', routeName: 'users' },
      { name: 'Detalhes' },
    ]" :pageInfo="''" :page="user.name">
      <div slot="right-side" v-role="['admin', 'dev']">
        <button class="btn btn-warning" @click="remove(user)">
          Excluir
        </button>
        <!-- <button v-if="!isEditing" class="btn btn-primary ml-3" @click="edit()">
          Editar
        </button> -->
      </div>
    </BreadCrumb>
    <!-- <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <UserInfo v-model="tempUser" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h4 for=""><strong>Informações</strong></h4>
              <div>
                <button v-if="!isEditing" class="btn btn-primary ml-3" @click="edit()">
                  Editar
                </button>
                <button class="btn btn-default" v-if="isEditing" @click="cancel()">
                  Cancelar
                </button>
                <button class="btn btn-primary ml-3" v-if="isEditing" @click="update()">
                  Salvar
                </button>
              </div>
            </div>
            <UserInfo ref="user_infos" v-model="tempUser" :isEditing="isEditing" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="$hasRole(tempUser, ['seller']) && tempUser.seller">
      <div class="col-12">
        <div class="card"
          :style="`${tempUser.seller.active === 1 ? 'background-color: #f1fbf1 !important; color: #285e28 !important;' : 'background-color: #f6e5e9 !important; color:#bc537f !important;'}`">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h4 for="">
                <strong>
                  Dados de Vendedor
                  -
                  <span :style="`font-size: 0.9rem; ${tempUser.seller.active === 1 ? 'color: green' : 'color:red'}`">
                    {{ tempUser.seller.active === 1 ? 'Ativado' : 'Desativado' }}
                  </span>
                </strong>
              </h4>
              <div>
                <button class="btn btn-primary ml-3" @click="editSeller()">
                  Editar
                </button>
                <button class="btn btn-default" v-if="isEditingSeller" @click="cancelSeller()">
                  Cancelar
                </button>
                <button class="btn btn-primary ml-3" v-if="isEditingSeller" @click="updateSeller()">
                  Salvar
                </button>
              </div>
            </div>
            <UserSeller ref="seller" v-model="tempUser.seller" :isEditing="isEditingSeller" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else></div>
    <div class="row" v-if="!$masterHelper.isEmpty(tempUser) && $hasRole(tempUser, ['client'])">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h4><strong>Endereços</strong></h4>
              <i ref="add_addr_btn" class="fas fa-plus pointer" v-if="!isCreatingAddr" @click="addr('create')"></i>
            </div>
            <UserAddress ref="user_addr" v-model="tempUser" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h4><strong>Meios de pagamento</strong></h4>
            <UserPayments :user="user" />
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="
      tempUser.roles && $hasRole(tempUser, ['seller'])
    ">
      <div class="col-9">
        <OrderList :orders="seller_orders.data" :changePage="changePageName" />
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <SimpleFilter :page="changePageName" key="info" :isFixed="false" />
        </transition-group>
      </div>
    </div>

    <div class="row" v-if="
      tempUser.roles && $hasRole(tempUser, ['client'])
    ">

      <div class="col-9" id="responsive-page-table">
        <OrderList :orders="client_orders.data" :changePage="changePageName" />
      </div>
      <div class="col-3">
        <transition-group name="slide-in" mode="out-in">
          <SimpleFilter :page="changePageName" key="info" :isFixed="false" />
        </transition-group>
      </div>
    </div>


    <OrderModal />
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";
import OrderList from "@/components/orders/OrderList";
import OrderModal from "@/components/orders/OrderModal";
import UserInfo from "@/components/users/UserInfo";
import UserSeller from "@/components/users/UserSeller";
import UserAddress from "@/components/users/UserAddress";

import { mapActions, mapGetters } from "vuex";
import UserPayments from "@/components/users/UserPayments";
import SimpleFilter from "@/components/Filters/SimpleFilter";
export default {
  components: {
    BreadCrumb,
    OrderList,
    OrderModal,
    UserInfo,
    UserAddress,
    UserPayments,
    SimpleFilter,
    UserSeller,
  },
  data() {
    return {
      search: {
        client: null,
        code: null,
        status: null,
      },
      date: {
        starts: null,
        ends: null,
      },
      isEditing: false,
      isEditingSeller: false,
      isCreatingAddr: false,
      tempUser: {},
    };
  },
  computed: {
    ...mapGetters(["user", "orders", "order", "usersList", "seller_orders", 'client_orders']),

    changePageName() {
      //it depends on tempUserRoles
      if (this.tempUser.roles) {
        return this.tempUser.roles[0].name;
      }
      return false;
    },

    route() {
      return this.$route
    }
    // getOrders() {
    //   if (!this.user.roles || !this.user.roles.length) return false;
    //   let isSeller = this.user.roles.some(role => {
    //     return role.name.includes("vendedor") || role.name.includes("seller");
    //   });
    //   console.log("getOrders-----------", isSeller);

    //   if (isSeller) {
    //     return this.orders.filter(order => {
    //       return order.seller_id === this.user.id;
    //     });
    //   } else {
    //     return false;
    //   }
    // },
    // getClientOrders() {
    //   return this.orders.filter(order => {
    //     return order.buyer_id === this.user.id;
    //   });
    // }
  },
  watch: {
    tempUser: {
      deep: true,
      immediate: false,
      async handler(_newTempUser) {
        // console.log('Watch tempUser',this.seller_orders)
        if (this.seller_orders?.length <= 0) await this.LOAD_SELLER_ORDERS(this.tempUser?.seller?.id || 0);
      },
    },
    user: {
      deep: true,
      immediate: false,
      handler(val) {
        // console.log('Watch USER',val)
        this.tempUser = val
      }
    },
    '$route.params.uuid'(value) {
      this.getUser()
    }
  },
  mounted() {
    this.getUser();

    // this.LOAD_ORDERS();
    this.LOAD_USERS();
    this.LOAD_PRODUCTS();
    this.$event.$on("display_add_addr", (data) => {
      this.isCreatingAddr = data;
    });

    this.SET_PARAMS({});

    setTimeout(() => {
      if (!this.$masterHelper.isEmpty(this.tempUser) && this.tempUser.role) {
        let paramName;
        if (this.tempUser.roles && this.tempUser.roles[0].name == "seller")
          paramName = "seller_id";
        if (this.tempUser.roles && this.tempUser.roles[0].name == "client")
          paramName = "buyer_id";
        // console.log(
        //   `the param name is ${paramName} and the val ${this.tempUser.id}`
        // );
        this.ORDER_SIMPLE_FILTER({
          params: {
            [paramName]: [this.tempUser.id],
          },
        });
      }
    }, 1000);
  },
  methods: {
    ...mapActions([
      "GET_USER",
      "SELECT_ORDER",
      "LOAD_ORDERS",
      "LOAD_USERS",
      "DELETE_USER",
      "LOAD_PRODUCTS",
      "SET_PARAMS",
      "SEARCH_SELLER_ORDER",
      "ORDER_SIMPLE_FILTER",
      "LOAD_SELLER_ORDERS",
    ]),
    getUser() {
      try {
        this.GET_USER({ uuid: this.$route.params.uuid }).then(() => {
          this.tempUser = this.user?.data?.data || this.user?.data || this.user;
        });
      } catch (error) {
        console.log('Warn::getUser', error)
      }
    },
    edit() {
      this.isEditing = true;
    },
    editSeller() {
      this.isEditingSeller = true;
    },
    cancel() {
      this.isEditing = false;
    },
    cancelSeller() {
      this.isEditingSeller = false;
    },
    update() {
      this.$refs.user_infos.update();
    },
    async remove(user) {
      try {
        const response = await this.DELETE_USER(user)


        if (response.code === 200) {
          this.$toast.success(response?.message || 'Usuário excluido')

          window.history.back()
        }
      } catch (error) {
        this.$toast.warning(error?.message || 'Houve um error')
      }
    },
    updateSeller() {
      this.$refs.seller.update();
    },
    addr(action) {
      if (action == "create") {
        this.isCreatingAddr = true;
        this.$refs.user_addr.create();
      }
    },
  },
};
</script>

<style>
.low-shadow {
  -webkit-box-shadow: 0px 3px 15px -2px rgba(0, 0, 0, 0.171);
  box-shadow: 0px 3px 15px -2px rgba(0, 0, 0, 0.171);
}
</style>
