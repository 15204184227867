<template>
  <div>
    <div class="d-flex w-100 justify-content-end">
      <div v-if="selectedPartners.length > 1">
        <button class="btn btn-warning" @click="toggleStatus(true)">
          Ativar
        </button>
        <button class="btn btn-warning ml-2" @click="toggleStatus()">
          Desativar
        </button>
        <button class="btn btn-danger ml-2" @click="deleteAll()">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <table class="table mt-4"  v-if="data.length > 1">
      <thead>
        <tr>
          <th style="width:25px">
            <label>
              <input
                type="checkbox"
                :checked="selectAll"
                @click="selectAllItems()"
              />
            </label>
          </th>
          <th>Nome</th>
          <th>Criado em</th>
          <th style="width:40px">Status</th>
          <th style="width:40px">Highlight</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="item.uuid"
          class="pointer hover"
          :class="[{ 'soft-shadow ': item.id === partner.id }]"
        >
          <td style="width:25px">
            <label>
              <input
                @click="selecteItem(item)"
                type="checkbox"
                :checked="selectedItem(item)"
              />
            </label>
          </td>
          <td @click="getPartner(item, index)" class="wrap-text">
            {{ item.name }}
          </td>
          <td @click="getPartner(item, index)">
            {{ new Date(item.created_at).toLocaleDateString() }}
          </td>
          <td
            @click="getPartner(item, index)"
            style="width:40px"
            class="text-center"
          >
            <i
              class="fas fa-circle text-success"
              v-show="item.active === 1"
              title="Ativado"
            ></i>
            <i
              class="fas fa-circle text-warning"
              v-show="item.active === 2"
              title="Rascunho"
            ></i>
            <i
              class="far fa-dot-circle text-danger"
              v-show="!item.active"
              title="Desativado"
            ></i>
          </td>
          <td style="width:40px" class="text-center">
            <i
              class="fas fa-thumbtack mr-3"
              v-if="item.highlight === 1"
              title="Destaque"
            ></i>
            <i
              class="fas fa-thumbtack mr-3"
              v-if="item.highlight === 0"
              style="visibility: hidden;"
            ></i>
          </td>
          <td @click="deletePartner(item, index)">
            <i class="far fa-trash-alt" title="Excluir"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-9 mt-5" v-else>
      <h3>Nenhum parceiro disponível</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  data() {
    return { selectedPartners: [], selectAll: false };
  },
  components: {
    LoaderButton
  },
  props: {
    data: {
      required: true
    }
  },
  computed: {
    ...mapGetters(["partner", "partners"]),
    onlyOnePartnerSelected() {
      if (!this.selectedPartners) return false;
      return this.selectedPartners.length == 1;
    }
  },
  mounted() {
    this.GET_PARTNER({});
  },
  methods: {
    ...mapActions([
      "GET_PARTNER",
      "DELETE_PARTNERS",
      "BULK_DELETE_PARTNERS",
      "BULK_UPDATE_PARTNERS"
    ]),
    getPartner(part, index) {
      if (Object.entries(part).length > 0) {
        this.GET_PARTNER({});
        this.GET_PARTNER({ partner: part, index });
        // this.selecteItem(part);
      }
    },
    async deletePartner(part, index) {
      try {
        if (Object.entries(part).length > 0) {
          const response = await this.DELETE_PARTNERS({ partner: part, index });

          if (Object.entries(response)?.length > 1) {
            this.$toast.success(" ", part?.name + " Deletado com sucesso", {
              position: "topRight",
              timeout: 3000
            });
            this.GET_PARTNER({});
            this.$event.$emit("partner_update");
            this.$forceUpdate();
          }
        }
      } catch (error) {
        this.$toast.error(
          " ",
          error?.message || "Houve um erro! tente novamente",
          {
            position: "topRight",
            timeout: 3000
          }
        );
       // console.log(`DeletePartnerError: ${error.message}`);
      }
    },
    isSelected(uuid) {
      return this.partner.uuid === uuid;
    },
    selectAllItems() {
      if (!this.selectAll) {
        this.selectAll = true;
        this.selectedPartners = [...this.partners];
      } else {
        this.selectAll = false;
        this.selectedPartners = [];
      }
    },

    selecteItem(partner) {
      let isSelected = this.selectedPartners.findIndex(item => {
        return item.uuid === partner.uuid;
      });
      if (isSelected < 0) {
        this.selectedPartners.push(partner);
      } else {
        this.GET_PARTNER({});
        this.selectedPartners.splice(isSelected, 1);
      }
    },
    selectedItem(partner) {
      return this.selectedPartners.some(item => {
        return item.uuid === partner.uuid;
      });
    },
    deleteAll() {
      this.BULK_DELETE_PARTNERS(this.selectedPartners);
    },
    async toggleStatus(activate = false) {
      this.selectedPartners.forEach((item, index) => {
        this.selectedPartners[index].active = activate ? 1 : 0;
      });

      await this.BULK_UPDATE_PARTNERS(this.selectedPartners);

      this.selectedPartners = [];
    }
  }
};
</script>

<style>
.wrap-text {
  word-wrap: break-word;
  min-width: 160px;
  max-width: 160px;
}
</style>
