<template>
  <div>
    <button
      v-if="!hideAddBtn"
      id="tagModalBtn"
      data-toggle="modal"
      data-target="#tagModal"
      class="btn btn-primary ml-3"
    >
      <i class="fas fa-plus"></i>
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="tagModal"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="tagModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="tagModalLabel">Criar tag</h5>
            <button
              :disabled="isSaving"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">Nome</label>
              <input
                :disabled="isSaving"
                @keyup="_recheckValidation"
                id="name"
                type="text"
                class="form-control"
                v-model="tag.name"
              />
            </div>
            <div class="form-group">
              <label for="">Cor</label>
              <multiselect
                v-model="tag.settings"
                placeholder="Selecione uma cor"
                label="color"
                track-by="color"
                :options="tagColorsList"
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="d-flex align-items-center">
                    <div
                      class="option__image"
                      style="width: 50px; height: 50px"
                      :style="'background:' + props.option.backgroundColor"
                    ></div>

                    <span class="option__desc ml-3"
                      ><span class="option__title">{{
                        props.option.backgroundColor
                      }}</span></span
                    >
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="d-flex align-items-center">
                    <div
                      class="option__image"
                      style="width: 50px; height: 50px; border: 1px solid gray"
                      :style="'background:' + props.option.backgroundColor"
                    ></div>
                    <div class="option__desc ml-3">
                      <span class="option__title">{{
                        props.option.backgroundColor
                      }}</span>
                    </div>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="form-group">
              <label for="">Descrição</label>
              <textarea
                :disabled="isSaving"
                v-model="tag.description"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
            <div class="form-group">
              <toggle-button
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                v-model="tag.active"
                :sync="true"
                :labels="{ checked: 'Ativo', unchecked: '  Desativado' }"
              />
            </div>
            <div v-if="!$masterHelper.isEmpty(tag.settings)" class="w-100">
              <hr />
              <small>Prévia</small>
              <div
                class="
                  form-group
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <span
                  class="badge"
                  :style="tag.settings"
                  style="border: 1px solid gray"
                >
                  <span class="font-weight-bold m-2">{{
                    tag.name || "TAG"
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <LoaderButton b_class="btn-primary" :wasClicked="isSaving">
              <button
                @click="create()"
                type="button"
                class="btn btn-primary btn-request"
              >
                Criar
              </button>
            </LoaderButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      tag: {
        name: null,
        description: null,
        active: true,
        settings: {},
      },
      isSaving: false,
    };
  },
  components: {
    LoaderButton,
    Multiselect,
  },
  props: {
    hideAddBtn: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["tagColorsList"]),
  },
  mounted() {
    this.$event.$on("new_tag", () => {
      $("#tagModal").modal("show");
    });
  },
  methods: {
    ...mapActions(["CREATE_TAG"]),
    create() {
      if (this._validation()) {
        this.isSaving = true;
        this.tag.active = this.tag.active == 1 ? true : false;
        this.randomColorIfNotSelected();
        this.CREATE_TAG(this.tag).then((res) => {
          this.tag = {
            name: null,
            description: null,
            active: true,
          };
          this.$forceUpdate();
          this.isSaving = false;
          $("#tagModal").modal("hide");
          this.$emit("manager_save_tag", res.data);
        });
      }
    },
    randomColorIfNotSelected() {
      if (
        this.$masterHelper.isEmpty(this.tag.settings) &&
        this.tagColorsList &&
        this.tagColorsList.length
      ) {
        let randomIndex = Math.floor(Math.random() * this.tagColorsList.length);
        this.tag.settings = this.tagColorsList[randomIndex];
      }
    },

    _recheckValidation(e) {
      if (e.target.value.length) {
        if (e.target.classList.contains("border-danger")) {
          e.target.classList.remove("border-danger");
        }
      }
    },
    _validation() {
      let rules = {
        name: {
          required: true,
        },
      };

      let keys = Object.keys(this.tag);

     // console.log("keys", keys);

      let error = [];

      keys.forEach((key) => {
        if (rules[key] && rules[key].required) {
          if (this.tag[key] == null || !this.tag[key].length) {
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
