/* eslint-disable no-useless-catch */
import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    usersList: [],
    users: [],
    sellers: [],
    seller: {},
    user: {}
  },
  getters: {
    users: state => state.users.data,
    usersPaginate: state => state.users,
    user: state => state.user,
    sellers: state => state.sellers,
    usersList: state => state.usersList
  },
  mutations: {
    LOAD_QUICK_LIST(state, users) {
      state.usersList = users;
    },
    LOAD_USERS(state, users) {
      state.users = users;
    },
    LOAD_USERS_SELLERS(state, sellers) {
      state.sellers = sellers;
    },
    GET_USER(state, user) {
      state.user = user;
    },
    CREATE_USER(state, user) {
      let index = state.users.data.findIndex(u => u.id == user.id);
      if (index == -1) {
        state.users.data.unshift(user);
      }
    },
    UPDATE_USER(state, user) {
      let index = state.users?.data?.findIndex(u => u.id === user.id) || null;

      if (index) state.users[index] = user;
    },
    REMOVE_USER(state, user) {
      let users = state.users?.data?.filter(u => u.id !== user.id);

      if (users?.length > 0) state.users = users;
    },
    REMOVE_ADDRESS_FROM_USER(state, address) {
      let index = state.user.user_info.addresses.findIndex(
        addr => addr.id == address.id
      );

      state.user.user_info.addresses.splice(index, 1);
    },
    SET_USER_ADDRESS(state, addr) {
      let index = state.user.user_info.addresses.findIndex(
        address => address.id == addr.id
      );

      if (index < 0) {
        state.user.user_info.addresses.push(addr);
      } else {
        state.user.user_info.addresses[index] = addr;
      }
    }
  },
  actions: {
    PAGINATE_LOAD_USERS: async (context, payload) => {
      try {
        const response = await axios().post('users/search', payload);
        //console.log("users loaded", response);

        if (response.data.code !== 200) {
          context.commit("LOAD_USERS", []);
          return
        }

        context.commit("LOAD_USERS", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("PAGINATE_LOAD_USERS:error", error);
        return error;
      }
    },

    LOAD_USERS: async (context, payload) => {
      try {
        const response = await axios().get("/users");
        // console.log("users loaded", response);

        if (response.data.code !== 200) {
          context.commit("LOAD_USERS", []);
          context.commit("LOAD_QUICK_LIST", []);
          return
        }

        context.commit("LOAD_USERS", response.data.data);
        context.commit("LOAD_QUICK_LIST", response.data.data);
        return response.data;
      } catch (error) {
        // console.log("LOAD_USERS:error", error);
        return error;
      }
    },
    LOAD_USERS_SELLERS: async (context, _payload) => {
      try {
        const response = await axios().get("/seller");

        if (response.data.code !== 200) {
          context.commit("LOAD_USERS_SELLERS", []);
          return
        }

        context.commit("LOAD_USERS_SELLERS", response.data?.data);
        return response.data?.data;
      } catch (error) {
        return error;
      }
    },

    VIEW_USER: (context, payload) => {
      try {
        context.commit("GET_USER", payload);
      } catch (error) {
        //console.log("GET_USER:error", error);
        return error;
      }
    },

    GET_USER: async (context, payload) => {
      try {
        const response = await axios().get("/users/show/" + payload.uuid);
        //console.log("users loaded", response);

        if (response.data.code !== 200) {
          context.commit("GET_USER", []);
          return
        }

        context.commit("GET_USER", response.data?.data);
        return response.data?.data;
      } catch (error) {
        // console.log("LOAD_USER:error", error);
        return error;
      }
    },

    FETCH_USER: async (context, id) => {
      try {
        const response = await axios().get(`/users/get/${id}`);

        if (response.data.code !== 200) {
          context.commit("CREATE_USER", []);
          return
        }

        context.commit("CREATE_USER", response.data.data);
        return response;
      } catch (error) {
        //  console.log("LOAD_USER:error", error);
        return error;
      }
    },

    SEARCH_USER: async ({ commit, dispatch }, payload) => {
      try {
        // paylaod['no_paginate'] = true

        // if (payload?.no_paginate)
        payload['no_paginate'] = true

        console.log("SEARCH_USER", payload);
        // return
        const response = await axios().post("/users/search", payload);

        if (response.data.code !== 200) {
          commit("LOAD_USERS", []);
          return
        }

        dispatch("SET_PARAMS", payload);

        commit("LOAD_USERS", response.data.data);

        return response.data;
      } catch (error) {
        //console.log("LOAD_POSTS:error", error);
        return error;
      }
    },

    QUICK_LIST: async ({ commit, dispatch }, payload) => {
      try {
        let response = await axios().get("/users/fast_list");

        if (response.data.code !== 200) {
          commit("LOAD_QUICK_LIST", []);
          return
        }

        // const clients = response.data?.data.filter(client => client.roles.find(role => role.name === 'client'))

        commit("LOAD_QUICK_LIST", response.data?.data);

        return response.data?.data;
      } catch (error) {
        // console.log("LOAD_POSTS:error", error);
        return error;
      }
    },

    CREATE_USER: async (context, payload) => {
      try {
        let response = await axios().post("/users/store", payload);
        //console.log("CREATE_USER", response.data);

        if (response.data.code !== 201) {
          context.commit("CREATE_USER", {});
          return
        }

        context.commit("CREATE_USER", response.data.data);
        return response.data;
      } catch (error) {
        //console.log("CREATE_USER:error", error);
        return error;
      }
    },

    UPDATE_USER: async (context, payload) => {
      try {
        // console.log("UPDATE_USER", payload);

        let response = await axios().put(
          "/users/update/" + payload.id,
          payload
        );

        if (response.data.code !== 201) {
          // throw new Error(response?.messageRaw?.message || response?.message)
          return response.data;
        }

        context.commit("UPDATE_USER", [response.data?.data]);
        return response.data;
      } catch (error) {
        throw new Error(error?.message)
      }
    },

    DELETE_USER: async (context, payload) => {
      try {
        // console.log("UPDATE_USER", payload);

        let response = await axios().delete("/users/destroy/" + payload.uuid);

        if (response.data.code !== 200) {
          throw new Error(response?.data?.messageRaw?.message || response?.data?.message || 'Não foi possivel remover este usuário')
        }

        context.commit("REMOVE_USER", payload);
        return response.data;
      } catch (error) {
        throw error
      }
    },

    UPDATE_SELLER: async (context, payload) => {
      try {
        let response = await axios().put(
          "/seller/update/" + payload.id,
          payload
        );
        // console.log("UPDATE_USER", response.data);

        if (response.data.code !== 201) {
          return
        }

        return response.data;
      } catch (error) {
        throw new error
      }
    },

    ADD_ADDRESS: async ({ commit, dispatch, getters }, payload) => {
      try {
        let response = await axios().post(`/address/store`, payload);

        if (response.data?.code !== 201) {
          throw new Error(response.data?.messageRaw?.message || response.data?.message)
        }

        let user = getters.user;

        commit("SET_USER_ADDRESS", response.data?.data);

        return response.data;
      } catch (error) {
        throw error
      }
    },

    REMOVE_ADDRESS: async ({ commit, dispatch }, payload) => {
      try {
        commit("REMOVE_ADDRESS_FROM_USER", payload);

        let response = await axios().delete(`/address/delete/${payload.id}`);

        if (response.data?.code !== 201) {
          throw new Error(response.data?.messageRaw?.message || response.data?.message)
        }

        return response.data;
      } catch (error) {
        throw error
      }
    },

    UPDATE_ADDRESS: async ({ commit, dispatch, getters }, payload) => {
      try {
        let response = await axios().put(
          `/address/update/${payload.id}`,
          payload
        )

        if (response.data?.code !== 201) {
          throw new Error(response.data?.messageRaw?.message || response.data?.message)
        }

        commit("SET_USER_ADDRESS", response.data?.data);

        return response.data;
      } catch (error) {
        throw error
      }
    }
  }
};
