<template>
  <div>
    <div class="form-row" v-if="tempUser">
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Número da conta:</strong>
          <p v-if="!isEditing">{{ tempUser.bank.bank_number }}</p>
          <input id="bank_number" v-if="isEditing" type="text" v-model="tempUser.bank.bank_number" class="form-control"
            placeholder="Número da Conta" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Agência:</strong>
          <p v-if="!isEditing">{{ tempUser.bank.bank_agency }}</p>
          <input id="bank_agency" v-if="isEditing" type="text" v-model="tempUser.bank.bank_agency" class="form-control"
            placeholder="Agência" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Banco:</strong>
          <p v-if="!isEditing">{{ tempUser.bank.bank_code.split("/")[0] }}</p>
          <select v-if="isEditing" class="form-control" type="text" id="reg-bankCode" name="bank_code"
            v-model="tempUser.bank.bank_code">
            <option value="" default>Selecione o seu banco</option>
            <option v-for="(bank, index) in handlerGetBanks()" :key="index" :title="`(${bank.code}) - ${bank.name}`"
              :value="`(${bank.code})/${bank.name}`" :selected="`${tempUser.bank.bank_code.split('/')[0].replace('()', '') ==
              bank.code
              ? 'true'
              : 'false'
              }`">
              {{ bank.code }} - {{ bank.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">PIX:</strong>
          <p v-if="!isEditing">
            {{ tempUser.bank.bank_pix }}
          </p>
          <input id="bank_pix" v-if="isEditing" type="text" v-model="tempUser.bank.bank_pix" class="form-control"
            placeholder="Pix" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Tipo de conta:</strong>
          <p v-if="!isEditing">
            {{ tempUser.bank.bank_type.split("/")[1] }}
          </p>
          <select v-if="isEditing" class="form-control" type="text" id="reg-bankType" name="bank_type"
            v-model="tempUser.bank.bank_type">
            <option value="" default>Selecione o tipo de conta</option>
            <option v-for="(account, index) in handlerGetAccountBankTypes()" :key="index"
              :value="`${account.code}/${account.name}`" :selected="`${tempUser.bank.bank_type.split('/')[0] == account.code
              ? 'true'
              : 'false'
              }`">
              {{ account.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">
            Vendedor {{ tempUser.active ? "Ativo" : "Desativado" }}:
          </strong>
          <div class="row align-items-center gap-1 p-1" v-if="isEditing">
            <toggle-button v-model="tempUser.active" class="ml-3" :width="80" :switch-color="{
              checked: '#fff',
              unchecked: '#fff',
              disabled: '#CCCCCC',
            }" :sync="true" :labels="{ checked: 'Ativado', unchecked: '  Desativado' }" />
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Data de nascimento:</strong>
          <p v-if="!isEditing">
            {{ new Date(tempUser.birth).toLocaleDateString() }}
          </p>
          <input id="name" v-if="isEditing" type="date" v-model="tempUser.birth" class="form-control"
            placeholder="Nascimento" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">CPF/CNPJ:</strong>
          <p v-if="!isEditing">
            {{ tempUser.cpf_cnpj }}
          </p>
          <input id="cpf_cnpj" v-if="isEditing" type="text" v-model="tempUser.cpf_cnpj" class="form-control"
            placeholder="CPF/CNPJ" cpf-cnpj />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">RG:</strong>
          <p v-if="!isEditing">
            {{ tempUser.rg }}
          </p>
          <input id="rg" v-if="isEditing" type="text" v-model="tempUser.rg" class="form-control" placeholder="RG" rg />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">IE:</strong>
          <p v-if="!isEditing">
            {{ tempUser.ie }}
          </p>
          <input id="ie" v-if="isEditing" type="text" v-model="tempUser.ie" class="form-control" placeholder="IE" />
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <strong for="">Telefone:</strong>
          <p v-if="!isEditing">
            {{ tempUser.contacts.tel }}
          </p>
          <input id="tel" v-if="isEditing" type="text" v-model="tempUser.contacts.tel" class="form-control"
            placeholder="Telefone" />
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex flex-column" v-if="tempUser.clients">
      <strong>Clientes</strong>

      <div class="d-flex flex-row flex-wrap flex-align-center" style="gap: 1rem">
        <div class="d-flex flex-row flex-wrap mt-3"
          style="width: auto; height: auto; border-radius: 1.2rem; padding: 0.5rem; border:0.1rem solid #dddd; background-color:#f5f5f5; align-items:center; justify-content: center;"
          v-for="(client, index) in tempUser.clients" :key="index">
          <span style="width: auto; height: auto; font-size: 0.9rem" class="pointer" v-if="client.user"
            :title="`Ver cliente ${client.user.name}`"
            @click.prevent="toPage('user.details', { uuid: client.user.uuid })">
            <span style="font-weight: 500">
              {{ client.user.name }}
            </span>
          </span>
        </div>
      </div>
      <div v-if="tempUser.clients.length <= 0" class="d-flex flex-row flex-wrap flex-align-center" style="gap: 1rem">
        Nenhum cliente disponível
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";

// Utils
import { handlerGetBanks, handlerGetAccountBankTypes } from "@/utils/index.js";
import masks from "@/utils/masks.js";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      isSaving: false,
      model: this.value,
      numbers: "",
      tempUser: {
        roles: [],
        user_info: {
          cpf_cnpj: "",
        },
      },
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.model = val;
        if (val) {
          this.tempUser = this.value;
        }
      },
    },
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit("input", val);
        }
      },
    },
    isEditing: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == true) {
          masks();
          this.tempUser = this.value;
          if (!this.value.roles || !this.value.roles.length) {
            this.tempUser.roles = [];
          }
          if (this.value.user_info && !this.value.user_info.contacts) {
            this.tempUser.user_info.contacts = [];
          } else {
            this.numbers = this.value.user_info
              ? this.value.user_info.contacts.toString()
              : [];
          }
        }
      },
    },
    tempUser: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tempUser.active = val.active ? 1 : 0;
      },
    },
  },
  mounted() {
    masks();
  },
  props: {
    isEditing: {
      default: false,
    },
    value: {
      required: false,
    },
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  methods: {
    ...mapActions(["UPDATE_USER", "UPDATE_SELLER"]),
    traceNumbers() {
      let split = this.numbers.split(",");
      if (split.length <= 5) {
        this.tempUser.user_info.contacts = split;
      }

      // console.log(split);
    },
    update() {
      this.$toast.info(" ", "Atualizando", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });

      this.UPDATE_SELLER(this.tempUser)
        .then((_response) => {
          this.$toast.success(" ", "Atualizado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        })
        .catch((error) => {
          this.$toast.error(
            " ",
            "Não foi possivel atualizar, tente novamente!",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          );
        });
    },
    handlerGetBanks() {
      return handlerGetBanks();
    },
    handlerGetAccountBankTypes() {
      return handlerGetAccountBankTypes();
    },
    toPage(path = '', params = {}) {
      try {
        this.$router.push({
          name: path,
          params
        });
      } catch (error) {
        // 
      }
    }
  },
};
</script>

<style>

</style>
