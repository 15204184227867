<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-baseline justify-content-between">
          <hgroup>
            <h4>
              <strong>Adicionar Banners ao Slider (Loja)</strong>
            </h4>
            <h6 style="color: #458ee8">
              Recomendamos dimensões de <strong>1920x800</strong> para Desktop e de <strong>320x780</strong> para Smartphone.
            </h6>
            <h6 style="color: #458ee8">
              Selecione uma imagem com menos de <strong>2MB</strong>
            </h6>
          </hgroup>
          <button class="btn btn-primary" @click="save('slider_shop')" :disabled="isSaving" v-show="wantSlider && wantSlider.length > 0">
            {{
              isSaving ? 'Por Favor, aguarde!' : 'Salvar'
             }}
          </button>
        </div>
        <div class="row mt-5">
           <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <hgroup>
                <h4>Banners atuais</h4>
                <h6>Imagens para Desktop e Smartphone</h6>
              </hgroup>
              <div
              class="
                d-flex
                flex-row
                align-items-start
                justify-content-between
                flex-wrap
              "
              style="gap: 0.5rem"
              >
                <div
                  v-for="(slider, index) in store_sliders"
                  :key="index"
                  class="
                    d-flex
                    flex-column
                    p-2
                    flex-fill flex-glow-1
                    mt-5
                    border border-light
                  "
                  style="min-width: 49.5% !important; max-width: 100% !important; gap: 0.5rem"
                >
                  <div class='d-flex flex-row flex-wrap align-items-center justify-content-between' style="width: 100%; padding-bottom: 0.3rem; border-bottom: 0.1rem solid #d5d5d5">
                    <span>
                      Grupo {{ slider.groupID }}
                    </span>
                  </div>
                  <div
                    class="d-flex
                      flex-row
                      flex-nowrap
                      flex-align-center
                    "
                    style="min-width: 30% !important; gap: 1rem"
                  >
                    <div
                      class="
                        d-flex
                        flex-column
                      "
                      style="min-width: 30% !important; gap: 1rem"
                    >
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-between
                          flex-nowrap
                        "
                        style="width: 100%; height: auto; padding: 1rem; background-color: #f5f5f5; border-radius: 0.2rem; gap: 0.5rem"
                      >
                        {{ slider.is_mobile ? 'Smartphones' : 'Desktop' }}

                        <span class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-between
                          flex-nowrap"
                          style="gap:1rem"
                        >
                          <a
                            :href="slider.url"
                            target="__blank"
                            title="Clique para visualizar o banner"
                          >
                            Visualizar
                          </a>
                        <span title="Remover grupo" style="cursor: pointer; font-weight:bold;" @click="(event) => handlerRemoveGroupFromShop(slider.groupID,slider.is_mobile)">X</span>
                        </span>
                      </div>
                    <img loading="lazy" :src="`${$masterHelper.getURL(true)}${slider.src}.png`" :alt="slider.url" :title="`Acessar em ${slider.url}`"/>
                    </div>

                    <div
                      v-for="(nestedSlider,key) in slider.nestedGroup"
                      :key="key"
                      class="
                        d-flex
                        flex-column
                      "
                      style="min-width: 30% !important;  gap: 1rem"
                    >
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-between
                          flex-nowrap
                        "
                        style="width: 100%; height: auto; padding: 1rem; background-color: #f5f5f5; border-radius: 0.2rem; gap: 0.5rem"
                      >
                        {{ nestedSlider.is_mobile ? 'Smartphones' : 'Desktop' }}
                        <span class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-between
                          flex-nowrap"
                          style="gap:1rem"
                        >
                          <a
                            :href="nestedSlider.url"
                            target="__blank"
                            title="Clique para visualizar o banner"
                          >
                            Visualizar
                          </a>
                        <span title="Remover grupo" style="cursor: pointer; font-weight:bold;" @click="(event) => handlerRemoveGroupFromShop(slider.groupID,slider.is_mobile)">X</span>
                        </span>
                      </div>
                    <img loading="lazy" :src="`${$masterHelper.getURL(true)}${nestedSlider.src}.png`" :alt="nestedSlider.url" :title="`Acessar em ${nestedSlider.url}`"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <h4>Adicionar Banners ({{ countSlider }})</h4>
              <div
                class="d-flex flex-row flex-wrap mt-1 flex-fill"
                style="gap: 0.5rem"
              >
                <input type="number" class="form-control" v-model="countSlider" style="max-width: 130px"/>
              </div>
              <!-- Slider's  -->
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  gap-1
                  flex-wrap
                "
              >
                <div
                  class="
                    d-flex
                    flex-column
                    p-2
                    flex-fill flex-glow-1
                    mt-5
                    border border-light
                  "
                >
                  <div
                    class="d-flex flex-row flex-wrap flex-align-start flex-justify-content-start mt-1 flex-fill"
                    style="gap: 5rem; align-items: flex-start"
                    v-if="wantSlider && wantSlider.length > 0"
                  >
                    <div class="d-flex flex-row flex-wrap flex-align-center flex-justify-content-between p-3" style="border:1px solid #dddd; flex: 1; min-width: 40%; max-width: 50%; gap: 0.5rem"
                     v-for="(slider,index) in wantSlider" :key="index"
                     :id="`group_id_${index + 1}`">
                      <div class='d-flex flex-row flex-wrap align-items-center justify-content-between' style="width: 100%; padding-bottom: 0.3rem; border-bottom: 0.1rem solid #d5d5d5">
                        <span>
                          Grupo <strong>{{ index + 1 }}</strong>
                        </span>
                        <i>
                          {{
                            store_sliders_to_reference[`background_${index + 1}`] ||  store_sliders_to_reference[`background_mobile_${index + 1}`] ? 'Para atualizar o slider, adicione uma nova imagem' : ''
                          }}
                        </i>
                        <span title="Remover grupo" style="cursor: pointer; font-weight:bold;" @click="(event) => handlerRemoveGroupSlider(index + 1)">X</span>
                      </div>

                      <div class="d-flex flex-column" style="gap: 0.5rem; min-width: 49%;">
                        <label for="">Imagem para Desktop</label>
                        <Upload
                          position="header"
                          ref="up1"
                          :noFavorite="true"
                          :height="250"
                          btnText="Adicionar nova imagem"
                          @data_deleted="
                            (data) =>
                              handlerDeleteImage(
                                'sliders', `background_${index + 1}`, `image`,
                                data
                              )
                          "
                          @added_item="
                            (data) =>
                              handlerImage('sliders', `background_${index + 1}`, `image`, data)
                          "
                          :limit="1"
                        />
                        <span
                          class="d-flex flex-row flex-wrap flex-align-center flex-fill" style="gap: 0.8rem"
                          v-show="
                            Object.entries(store_template).length > 0
                            &&
                            store_template[`background_${index + 1}`]"
                        >
                          <div class="d-flex flex-column" style="gap: 0.5rem">
                            <label for="">Link de destino: </label>
                            <input
                              type="text"
                              class="form-control"
                              :id="`background_${index + 1}`"
                              @change="(value) => handlerSetURLOnSlider(`background_${index + 1}`,value)"
                              placeholder="Digite a URL do slider"
                            />
                          </div>
                        </span>
                      </div>

                      <div class="d-flex flex-column" style="gap: 0.5rem; min-width: 49%;">
                        <label for="">Imagem para <strong> Smartphone</strong></label>
                        <Upload
                          position="header"
                          ref="up1"
                          :noFavorite="true"
                          :height="250"
                          btnText="Adicionar nova imagem"
                          @data_deleted="
                            (data) =>
                              handlerDeleteImage(
                                'sliders', `background_${index + 1}`, `image`,
                                data
                              )
                          "
                          @added_item="
                            (data) =>
                              handlerImage('sliders', `background_mobile_${index + 1}`, `image`, data,{is_mobile: true})
                          "
                          :limit="1"
                        />
                        <span
                          class="d-flex flex-row flex-wrap flex-align-center flex-fill" style="gap: 0.8rem"
                          v-show="
                            Object.entries(store_template).length > 0
                            &&
                            store_template[`background_${index + 1}`]"
                        >
                          <div class="d-flex flex-column" style="gap: 0.5rem">
                            <label for="">Link de destino:  </label>
                            <input
                              type="text"
                              class="form-control"
                              :id="`background_${index + 1}`"
                              @change="(value) => handlerSetURLOnSlider(`background_mobile_${index + 1}`,value)"
                              placeholder="Digite a URL do slider"
                            />

                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { VMoney } from "v-money";

// Components
import Upload from "@/components/imageUpload/Upload";
import Multiselect from "vue-multiselect";

// Mixins
import Settings from "@/mixins/Settings.vue";

export default {
  directives: { money: VMoney },
  components: { Upload, Multiselect },
  mixins: [Settings],
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isEditing: false,
      countSlider: 0,
      wantSlider: [],
      store_sliders: [],
      store_sliders_temp: [],
      store_sliders_to_reference: [],
      store_sliders_remove: {},
      store_template: [],
      store_template_temp: [],
      fontTypeList: {
        value: null,
        options: [
          {
            name: "Lato",
            family: "Lato",
          },
        ],
      },
      hasUpload: false,
      formatString: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 1,
        masked: false,
      },
    };
  },
  watch: {
    settings: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          let actualGroupID = -1
          this.store_sliders = []
          val.forEach(setting => {
            if (setting?.key === "slider_shop") {
              const slidersGroups =  Object.entries(setting.content)
              this.store_sliders_to_reference = setting.content
              this.store_sliders_remove = setting.content
              // console.log('NA CASA DO CARALHO A PICANHA È CONTRA-FILÉ', slidersGroups)

              slidersGroups.forEach((value,index) => {
                const actualGroupIDRaw = value[0].split("_")

                if(actualGroupID < actualGroupIDRaw[actualGroupIDRaw.length - 1] && actualGroupID !== actualGroupIDRaw[actualGroupIDRaw.length - 1]) {

                  actualGroupID = actualGroupIDRaw[actualGroupIDRaw.length - 1]

                  const group = {
                    url: value[1]?.url || "",
                    src: value[1]?.background?.image || "",
                    is_mobile: value[1]?.is_mobile || false,
                    groupID: actualGroupID,
                    nestedGroup: []
                  }

                  slidersGroups.forEach((groupNested,indexNested) => {
                    if(indexNested !== index) {
                      const nestedGroupID = groupNested[0].split("_")
                      if(nestedGroupID[nestedGroupID.length - 1] === group.groupID) {

                        group.nestedGroup.push(
                          {
                            url: groupNested[1]?.url || "",
                            src: groupNested[1]?.background?.image || "",
                            is_mobile: groupNested[1]?.is_mobile || false
                          }
                        )

                      }
                    }
                  })

                  //console.log('TESTE',actualGroupID, actualGroupIDRaw[actualGroupIDRaw.length - 1], group)
                  this.store_sliders.push(group)
                }

              })

              this.countSlider = Object.entries(setting?.content || {}).length
            }
          })
        } catch (error) {
          // do anything
        }
      },
    },
    countSlider: {
      deep: true,
      immediate: true,
      handler(val,oldVal) {
        //console.log(val,oldVal, (oldVal - val))

        this.wantSlider = []

        for(let i=0; i < val; i++) {
          this.wantSlider.push(i)
        }
      }
    }
  },
  computed: {
    ...mapGetters(["settings", "tagColorsList"]),

    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "template";
        }) || false
      );
    },
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      // this.store_template = {
      //   ...this.store_template,
      //   ...(this.myGateways?.content || {}),
      // };
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS"]),
    save(key = "template", moreData = null) {
      let data = moreData || {...this.store_sliders_to_reference,...this.store_template};
      this.isSaving = true;
      this.$pace.restart();

      // TODO - THIS NEWS VERSION (V2) CREATE THE SETTINGS ALSO CAN UPDATE THE SETTINGS
      this.CREATE_SETTINGS({
        content: data,
        key: key,
      }).then((res) => {
        this.$pace.restart();
        this.UPDATE++;
        if (res.code === 201) {
          this.$toast.success(" ", "Concluido", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.loadSet();
          this.UPDATE_SETTINGS(res.data)
        } else {
          this.$toast.error(" ", "Houve uma falha.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
        this.isSaving = false;
      });
    },
    handlerImage(from = null, to = null, target = null, data = null, rest) {
      try {
        if (!from || !to || !target || !data) {
           this.store_template[to]['background'][target] = "";
          this.hasUpload = false;
          return;
        }

        const sizeImage = data?.dataRaw?.size || data?.sizeRaw

        if(!this.$masterHelper.getBytes(sizeImage,2, 2024**2)) {
          this.$toast.info(" ","Selecione uma imagem com menos de 2MB",{
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          })

          return
        }

        if (data?.src) {
          const preObject = {}
          preObject[to] = { background: {image: data?.src || ""}, url:"",...rest}

          this.store_template = {...this.store_template,...preObject}
          this.hasUpload = true;
        }

        return;
      } catch (error) {
        // do anything
        //console.log(error)
      }
    },
    handlerDeleteImage(target = "", data = null) {
      try {
        if (from && to && target && data) {
          this.store_template[to]['background'][target] = "";
          this.hasUpload = false;
          return;
        }
      } catch (error) {
        // do anything
      }
    },
    handlerSetURLOnSlider(target = "", event = "") {
      try {
        const value = event.target?.value?.trim() || ""

        if( value === '') return

        this.store_template[target]['url'] = value || ""
      } catch (error) {
        // do anything
        //console.log(error)
      }
    },
    handlerRemoveGroupSlider(groupID = -1){
      try {
        const groupElement = document.querySelector(`#group_id_${groupID}`)

        if(groupID <= -1) return

        if(groupElement) {
          if(this.store_template[`background_${groupID}`] || this.store_template[`background_mobile_${groupID}`]) {
            delete this.store_template[`background_${groupID}`]
            delete this.store_template[`background_mobile_${groupID}`]
          }

          groupElement.remove()
          // this.countSlider = this.countSlider - 1
        }
      } catch (error) {
        // do anything
      }
    },
    async handlerRemoveGroupFromShop(groupID = -1,isMobile = false){
      try {

        if(groupID <= -1) return

        if(this.store_sliders_remove[`background_${groupID}`] || this.store_sliders_remove[`background_mobile_${groupID}`]) {
          delete this.store_sliders_remove[`background_${groupID}`]

          if(isMobile) delete this.store_sliders_remove[`background_mobile_${groupID}`]

          await this.save("slider_shop",this.store_sliders_remove)
        }
      } catch (error) {
        // do anything
        //console.log('handlerRemoveGroupFromShop',error)
      }
    }
  },
};
</script>

<style></style>
