import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    clients: ""
  },
  getters: {
    clients: state => state.clients
  },
  mutations: {
    CARREGAR_CLIENTES(state, clientsArr) {
      state.clients = clientsArr;
    }
  },
  actions: {
    CARREGAR_CLIENTES: async (context, payload) => {
      try {
        let response = axios().post("/endpoint", payload);
        context.commit("CARREGAR_CLIENTES", response.data);
        return response.data;
      } catch (error) {
        //console.log("CARREGAR_CLIENTES:error", error);
        return error;
      }
    }
  }
};
