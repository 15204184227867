import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    last_search: {}
  },
  getters: {
    last_search: state => state.last_search
  },
  mutations: {
    SET_PARAMS(state, last_search) {
      state.last_search = last_search;
    }
  },
  actions: {
    SET_PARAMS: async (context, payload) => {
      try {
        context.commit("SET_PARAMS", payload);
      } catch (error) {
       // console.log("SET_PARAMS:error", error);
        return error;
      }
    }
  }
};
