<template>
  <div class="card">
    <div class="card-header">Preview variações</div>
    <div class="card-body">
      <div class="row">
        <div class="col-4" v-for="variation in _variations" :key="variation.id">
          <div class="card">
            <div class="card-header">
              Variação:
              {{ getVariation(variation.id).name }}
            </div>
            <div class="card-body">
              <strong>Opções ativas</strong>

              <ul class="list-group mt-4">
                <li
                  class="list-group-item"
                  v-for="option in variation.options"
                  :key="option.id"
                >
                  <div class="text-primary">
                    <strong> Nome: </strong>
                    {{ option.name }}
                  </div>
                  <div>
                    <strong>Quantidade</strong>:
                    {{ getDetails(option.id).quantity }}
                  </div>
                  <div>
                    <strong>Preço de venda</strong>:
                    {{ getDetails(option.id).price }}
                  </div>
                  <div>
                    <strong>Preço de custo</strong>:
                    {{ getDetails(option.id).cost_price }}
                  </div>
                  <div>
                    <strong>Preço promocional</strong>:
                    {{ getDetails(option.id).sale_price }}
                  </div>
                  <div>
                    <strong>Quantidade para Atacado</strong>:
                    {{ getDetails(option.id).wholesale_quantity }}
                  </div>
                  <div>
                    <strong>Preço de Atacado</strong>:
                    {{ getDetails(option.id).retail_price }}
                  </div>
                  <div>
                    <strong>SKU</strong>: {{ getDetails(option.id).sku }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.model = val;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["variations", "options"]),
    _variations() {
      var variations = [];
      this.model.forEach((mdl) => {
        var variate = this.getVariation(mdl.variation_id);
       // console.log("getVariation", variate);
        if (!this.$masterHelper.isEmpty(variate)) {
          let contains = variations.some((v) => {
            return v.id == variate.id;
          });
          if (!contains)
            variations.push({
              ...variate,
              options: this.hasOptions(mdl.variation_id),
            });
        }
      });

      //console.log("var ------- aaaa", variations);
      return variations;
    },
  },
  methods: {
    optionWasSelected(option_id) {
      return this.model.some((m) => {
        return m.option_id === option_id;
      });
    },
    getVariations(id) {
      if (!this.variations.length) return [];
      return this.variations.filter((variation) => {
        return variation.id === id;
      });
    },
    getVariation(id) {
      if (!this.variations.length) return {};
      return this.variations.find((variation) => {
        return variation.id === id;
      });
    },
    getOptions(id) {
      if (!this.options.length) return {};
      return this.options.find((option) => {
        return option.id === id;
      });
    },
    getDetails(id) {
      return this.model.find((detail) => {
        return detail.option_id == id;
      });
    },

    hasOptions(id) {
      let options = this.options.filter((op) => {
        return op.variation_id == id;
      });

      return options.filter((op) => {
        return this.optionWasSelected(op.id);
      });
    },
  },
};
</script>

<style></style>
