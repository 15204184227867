<template>
  <div>
    <button
      hidden
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      :data-target="'#' + modal_id"
      :id="modal_id + '_btn'"
    >

    </button>
    <div
      class="modal fade"
      :id="modal_id"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      :aria-labelledby="modal_id + 'Label'"
      aria-hidden="true"
    >
      <div class="modal-dialog" :class="modalSize">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" :id="modal_id + 'Label'">{{modalTitle}}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
              <slot name="body"></slot>
          </div>

          <div class="modal-footer">

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>

             <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    modal_id:{
      required:true
    },
    modalTitle:{
      required:true
    },
    modalSize:{
      required:false,
      default:'modal-xl'
    }
  },
  methods:{
    open(){
      document.getElementById(this.modal_id + '_btn').click()
    }
  }
};
</script>

<style></style>
