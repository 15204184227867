<template>
  <div
    v-if="isLoading"
    :style="{ backgroundColor: bgColor }"
    id="loadingScreenComponent"
    class="h-100 w-100 d-flex justify-content-center align-items-center "
    :class="[{ 'flex-column': dir === 'column' }]"
  >
    <div class="spinner-border text-primary mb-3" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <h4 :class="[{ 'ml-3': dir === 'row' }]">{{ message }}</h4>
  </div>
</template>

<script>
export default {
  data() {
    return { loading: true };
  },
  props: {
    message: {
      type: String,
      default: "Loading..."
    },
    eventName: {
      type: String,
      required: true
    },
    dir: {
      default: "row"
    },
    bgColor: {
      type: String,
      default: "black"
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.$props.options)) {
    }
    this.$event.$on(this.$props.eventName, data => {
      this.loading = data.loading;
    });
  }
};
</script>

<style>
#loadingScreenComponent {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.5s;
}
</style>
