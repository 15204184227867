<template>
    <div class="report-number-cards-container row flex-wrap col">
      <div class="col report-number-card-col" v-if="report">
          <div class="card report-number-card">
              <div class="card-body low-shadow">
                  <h2 class="text-dark mb-1 font-weight-medium">
                      {{ report.total_products_count || 0 }}
                  </h2>
                  <strong class="">Produtos cadastrados</strong>
              </div>
          </div>
      </div>

      <div class="col report-number-card-col" v-if="report">
        <div class="card report-number-card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              {{ report.count_highlight_products || 0 }}
            </h2>
            <strong class="">Produtos em destaque</strong>
          </div>
        </div>
      </div>

      <div class="col report-number-card-col" v-if="report">
        <div class="card report-number-card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
                {{ report.total_products_sold || 0 }}
            </h2>
            <strong class="">Produtos negociados</strong>
          </div>
        </div>
      </div>

      <div class="col report-number-card-col" v-if="report">
        <div class="card report-number-card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
              {{ report.count_distinct_sold_products || 0 }}
            </h2>
            <strong class="">Produtos diferentes negociados</strong>
          </div>
        </div>
      </div>

      <div class="col report-number-card-col" v-if="report">
        <div class="card report-number-card">
          <div class="card-body low-shadow">
            <h2 class="text-dark mb-1 font-weight-medium">
                {{ report.total_products_count_mounph || 0 }}
            </h2>
            <strong class="">Produtos cadastrados este mês</strong>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        params: {
            required: false,
            default: null,
        },
    },
    computed: {
        ...mapGetters(["productReport"]),

        report() {
            if (this.$masterHelper.isEmpty(this.productReport)) return {};
            return this.productReport.report;
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions(["PRODUCT_REPORT"]),
        load() {
            let action = (params) => this.PRODUCT_REPORT(params);

           // console.log("params", { params: this.params });

            if (this.params) {
                action({ params: this.params });
            } else {
                if (this.$masterHelper.isEmpty(this.productReport)) {
                    action(false);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.report-number-cards-container {
    flex: 0 0 50% !important;
    width: 50% !important;
    max-width: 50% !important;
}
.report-number-card-col {
    flex: 0 0 33% !important;
    width: 33% !important;
    max-width: 33% !important;
}
.card:hover {
    background-color: #f6f6f6 !important;

    /* background: linear-gradient(none) !important; */

    -webkit-transition: background-color 0.51s ease-out;
    -moz-transition: background-color 0.51s ease-out;
    -o-transition: background-color 0.51s ease-out;
    transition: background-color 0.51s ease-out;
}
.card-body {
    line-height: 1.1;
}

@media only screen and (max-width: 1366px) {
    .page-wrapper:not(.collapsed-wrapper) {
        .report-number-cards-container {
            flex: 0 0 100% !important;
            width: 100% !important;
            max-width: 100% !important;

            .report-number-card-col {
                flex: 0 0 20% !important;
                width: 20% !important;
                max-width: 20% !important;
                padding: 0.3rem;

                .card-body {
                    padding: 0.75rem;
                    line-height: 1.1;
                    /* text-align: center; */
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .report-number-cards-container {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
</style>
