import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    roles: [],
    permissions: []
  },
  getters: {
    roles: state => state.roles,
    permissions: state => state.permissions
  },
  mutations: {
    LOAD_ROLES(state, roles) {
      state.roles = roles;
    },
    LOAD_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    CREATE_ROLE(state, role) {
      state.roles.push(role);
    },
    UPDATE_ROLE(state, role) {
      let index = state.roles.findIndex(r => {
        return r.id === role.id;
      });
      state.roles[index] = role;
    },
    DELETE_ROLE(state, role) {
      let index = state.roles.findIndex(r => {
        return r.id === role.id;
      });
      state.roles.splice(index, 1);
    }
  },
  actions: {
    LOAD_ROLES: async (context, payload) => {
      try {
        let response = await axios().get("/roles");
        context.commit("LOAD_ROLES", response.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_ROLES:error", error);
        return error;
      }
    },
    CREATE_ROLE: async (context, payload) => {
      try {
        let response = await axios().post("/roles", payload);
        context.commit("CREATE_ROLE", response.data);
        return response;
      } catch (error) {
        //console.log("CREATE_ROLE:error", error);
        return error;
      }
    },
    UPDATE_ROLE: async (context, payload) => {
      try {
        let response = await axios().put("/roles/" + payload.id, payload);
        context.commit("UPDATE_ROLE", response.data);

        return response;
      } catch (error) {
       // console.log("UPDATE_ROLE:error", error);
        return error;
      }
    },
    LOAD_PERMISSIONS: async (context, payload) => {
      try {
        let response = await axios().get("/permissions");
        context.commit("LOAD_PERMISSIONS", response.data);
        return response.data;
      } catch (error) {
       // console.log("LOAD_ROLES:error", error);
        return error;
      }
    },
    DELETE_ROLE: async (context, payload) => {
      try {
        let response = await axios().delete("/roles/" + payload.id);
        context.commit("DELETE_ROLE", payload);
        return response;
      } catch (error) {
       // console.log("LOAD_ROLES:error", error);
        return error;
      }
    }
  }
};
