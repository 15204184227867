<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    //...mapGetters(["posts", "post"])
  },
  methods: {
    //...mapActions(["LOAD_POSTS", "VIEW_POST"]),
  }
};
</script>

<style>
.card-img-top {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
</style>
