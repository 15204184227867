<template>
  <div class="w-100 d-flex align-items-center pointer selected scale-in-center">
    <input
      v-model="model"
      type="text"
      class="form-control mr-4"
      placeholder="Nome"
      autofocus
      :disabled="disabled"
    />
    <div class="d-flex align-items-center ml-auto" v-if="!disabled">
      <i class="far fa-save" @click="save()" title="Criar"></i>
      <i title="Cancelar" class="fas fa-times  ml-4" @click="cancel()"></i>
    </div>
    <div v-else>
      <div
        class="spinner-border"
        role="status"
        style="color:rgb(236, 188, 117) !important"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      model: this.value,
      disabled: false
    };
  },
  mounted() {
    this.$event.$on("reset", () => {
      this.disabled = false;
    });
  },
  methods: {
    save() {
      this.disabled = true;
      this.$emit("input", this.model);
      this.$emit("save");
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style></style>
