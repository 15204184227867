<template>
  <div>
    <BreadCrumb pageInfo="Todos as tags" page="Tags">
      <div slot="right-side">
        <button class="btn btn-primary" @click="newTag()">
          Nova tag
        </button>
      </div>
    </BreadCrumb>
    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <TagList />
      </div>
      <div class="col-3" id="responsive-filter-container">
        <transition-group name="slide-in" mode="out-in">
          <div class="card" key="info" style="position:fixed;width:20%" v-if="$masterHelper.isEmpty(tag)">
            <div class="card-body">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label for="">Filtros</label>
                  <label @click="reset()" class="text-danger pointer">Limpar filtros</label>
                </div>
                <input @keyup.enter="filterTags()" class="form-control" type="text" placeholder="Pesquisar"
                  v-model="filter.search" :disabled="isSearching" />
                <small>Pesquise por nome ou descrição.</small>
              </div>
              <div class="form-group">
                <div class="form-group">
                  <label for="">Status</label>
                  <select class="form-control" name="" id="" v-model="filter.active">
                    <option value="1">Ativo</option>
                    <option value="0">Desativado</option>
                    <option value="all">Todos</option>
                  </select>
                </div>
              </div>
              <div>
                <button class="btn btn-primary btn-block" @click="filterTags()">
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
          <!-- <MasterFilter   key="info" style="position:fixed; width:20%" page="Tags"

          :rules='{
            searchable: ["name", "description"],
            relationships: ["posts", "products"]
          }'
          :hideInputs="['categories', 'tags', 'users','status']"
          v-if="$masterHelper.isEmpty(tag)"/> -->
          <div v-show="!$masterHelper.isEmpty(tag)" key="edition">
            <TagInfo />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TagList from "@/components/tags/TagList";
import TagManager from "@/components/tags/TagManager";
import TagInfo from "@/components/tags/TagInfo";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import MasterFilter from "@/components/Filters/MasterFilter";

export default {
  data() {
    return {
      selected: [],
      isSearching: false,
      filter: {
        active: "all",
        start: null,
        end: null,
        search: null
      }
    };
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
        }
      }
    }
  },
  components: {
    TagList,
    TagManager,
    TagInfo,
    BreadCrumb,
    MasterFilter
  },
  computed: {
    ...mapGetters(["tag", "tags", "tagBackup"]),
    filterCore() {
      if (!this.tags || (this.tags && this.tags.length)) return false;
      return this.tags.filter(tag => {
        return (
          tag.name
            .toLowerCase()
            .includes(toString(this.filter.search).toLowerCase()) ||
          tag.description
            .toLowerCase()
            .includes(toString(this.filter.search).toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions(["FILTER_TAGS", "CLEAN_FILTER_TAGS"]),

    reset() {
      this.filter = {
        active: "all",

        start: null,
        end: null,
        search: null
      };
      this.filterTags();
    },
    newTag() {
      this.$event.$emit("new_tag");
    },
    compare_dates(date1, date2) {
      if (new Date(date1 + " 00:00") > new Date(date2 + " 00:00"))
        return "Date1 > Date2";
      else if (new Date(date1 + " 00:00") < new Date(date2 + " 00:00"))
        return "Date2 > Date1";
      else return "Date1 = Date2";
    },

    filterTags() {
      this.isSearching = true;
      let starts = Date.parse(this.filter.start);
      let ends = Date.parse(this.filter.end);

      if (this.tagBackup.length) {
        this.CLEAN_FILTER_TAGS();
      }

      this.$event.$emit("loading_tags", true);
      let res = [];
      if (this.filter.search) {
        res = this.tags.filter(tag => {
          return (
            tag.name.toLowerCase().includes(this.filter.search.toLowerCase()) ||
            tag.description
              ?.toLowerCase()
              .includes(this.filter.search.toLowerCase())
          );
        });
      } else {
        res = this.tags;
      }

      if (this.filter.start) {
        res = res.filter(tag => {
          // console.log(
          //   `tag created_at: ${this.$date(tag.created_at)} = ${Date.parse(
          //     this.$date(tag.created_at)
          //   )} filter starts at ${this.$date(this.filter.start)} = ${starts}`
          // );

          // return Date.parse(this.$date(tag.created_at)) >= starts;
          return (
            new Date(this.$date(tag.created_at) + " 00:00") >=
            new Date(this.$date(this.filter.start) + " 00:00")
          );
        });
      }
      if (this.filter.end) {
        res = res.filter(t => {
          // console.log(
          //   `tag created_at:${new Date(this.$date(t.created_at) + " 00:00")}`
          // );
          // console.log(`tag below :${new Date(this.filter.end + " 00:00")}`);
          // return Date.parse(this.$date(t.created_at)) <= ends;
          return (
            new Date(this.$date(t.created_at) + " 00:00") <=
            new Date(this.$date(this.filter.end) + " 00:00")
          );
        });
      }

      if (this.filter.active !== "all") {
        res = res.filter(r => {
          return r.active == this.filter.active;
        });
      }

      // console.log("filter:res final", res);
      this.FILTER_TAGS(res).then(() => {
        this.isSearching = false;
      });
    }
  }
};
</script>
