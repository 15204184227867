<template>
  <table class="table mt-4">
    <thead>
      <tr>
        <th>Bandeira</th>
        <th>Numero</th>
        <th>Validade</th>
      </tr>
    </thead>
    <tbody v-if="user && user.user_info">
      <tr v-for="card in user.user_info.cards" :key="card.uuid">
        <td>
          {{ (card.brand && card.brand) || "-" }}
        </td>
        <td>********{{ card.card_number.substr(9, 12) }}</td>
        <td>São Paulo / SP</td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>
          Nenhum meio de pagamento registrado
        </td>
        <td>
          -
        </td>
        <td>-</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    user: {
      required: true
    }
  },
  computed: {}
};
</script>

<style></style>
