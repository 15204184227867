import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import Pace from "pace-js"
Vue.use(Vuex);

export default {
  state: {
    categories: [],
    category: {},
    categoryBackup: []
  },
  getters: {
    categories: state => state.categories,
    categoriesPaginate: state => state.categories,
    category: state => state.category,
    categoryBackup: state => state.categoryBackup
  },
  mutations: {
    LOAD_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    GET_CATEGORY(state, category) {
      state.category = category;
    },
    NEW_CATEGORY(state, category) {
      state.categories.unshift(category);
    },
    UPDATE_CATEGORY(state, category) {
      let index = state.categories.findIndex(t => {
        return t.id === category.id;
      });
      // console.log(
      //   `old name: ${state.categories[index].name}, new name: ${category.name}`
      // );
      state.categories[index] = category;
    },
    LOAD_CATEGORY(state, category) {
      if(Object.entries(state.category).length > 0) {
        state.category = {...state.category, ...category}
      }else{
        state.category = category
      }
    },
    REMOVE_CATEGORY(state, category) {
      let index = state.categories.findIndex(item => item.id === category.id);
      state.categories.splice(index, 1);
    },
    FILTER_CATEGORIES(state, categories) {
      if (!state.categoryBackup.length)
        state.categoryBackup = [...state.categories];
      state.categories = categories;
    },
    CLEAN_FILTER_CATEGORIES(state) {
      state.categories = state.categoryBackup;
      state.categoryBackup = [];
    }
  },
  actions: {
    CLEAN_FILTER_CATEGORIES: async context => {
      context.commit("CLEAN_FILTER_CATEGORIES");
    },
    FILTER_CATEGORIES: async (context, payload) => {
      context.commit("FILTER_CATEGORIES", payload);
    },
    PAGINATE_LOAD_CATEGORIES: async (context, payload) => {
      try {
        let response = await axios().get(payload);
       // console.log("PAGINATE_LOAD_CATEGORIES loaded", response);
        context.commit("LOAD_CATEGORIES", response.data.categories);
        return response;
      } catch (error) {
        //console.log("LOAD_CATEGORIES:error", error);
        return error;
      }
    },
    LOAD_CATEGORIES: async (context, payload) => {
      try {
        const response = await axios().get("/categories");

        if(response.data.code !== 200){
          context.commit("LOAD_CATEGORIES", []);
          return
        }

        //console.log("categories loaded", response);
        context.commit("LOAD_CATEGORIES", response.data.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_CATEGORIES:error", error);
        return error;
      }
    },
    VIEW_CATEGORY: (context, payload) => {
      try {
        context.commit("GET_CATEGORY", payload);
      } catch (error) {
       // console.log("GET_CATEGORY:error", error);
        return error;
      }
    },
    CREATE_CATEGORY: async (context, payload) => {
      try {
        const response = await axios().post("/categories/store", payload);

        if(response.data.code !== 201){
          context.commit("NEW_CATEGORY", {});
          return
        }

       // console.log("category created", response.data);
        context.commit("NEW_CATEGORY", response.data.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_CATEGORIES:error", error);
        return error;
      }
    },
    GET_CATEGORY: async (context, payload) => {
      try {
        let response = await axios().get("/categories/show/" + payload.uuid);
        //console.log("categories loaded", response);
        context.commit("GET_CATEGORY", response.data.category);
        return response.data;
      } catch (error) {
        //console.log("LOAD_CATEGORIES:error", error);
        return error;
      }
    },
    UPDATE_CATEGORY: async (context, payload) => {
      try {
       // console.log("UPDATE_CATEGORY", payload.id);

        const response = await axios().post(
          "/categories/update/" + payload.id,
          payload
        );

        Pace.restart()

        if(response.data.code !== 201){
          context.commit("UPDATE_CATEGORY", []);
          return
        }

        context.commit("UPDATE_CATEGORY", response.data.data);
        context.commit("LOAD_CATEGORY", response.data?.data || {});

        return response.data;
      } catch (error) {
        //console.log("UPDATE_CATEGORY:error", error);
        return error;
      }
    },
    BULK_UPDATE_CATEGORIES: async (context, payload) => {
      try {
        let response = await axios().post("/categories/bulk_update", payload);
        // context.commit("UPDATE_CATEGORY", response.data.category);
        return response.data;
      } catch (error) {
        //console.log("UPDATE_CATEGORY:error", error);
        return error;
      }
    },
    BULK_DELETE_CATEGORIES: async (context, payload) => {
      try {
        let ids = payload.map(item => item.id);

        let response = await axios().post("/categories/bulk_delete", ids);
        // context.commit("UPDATE_CATEGORY", response.data.category);

        payload.forEach(category => {
          context.commit("REMOVE_CATEGORY", category);
        });

        return response.data;
      } catch (error) {
        //console.log("UPDATE_CATEGORY:error", error);
        return error;
      }
    }
  }
};
