<template>
  <div :class="b_class" class="d-flex align-iems-center justify-content-center" >
    <span v-if="!wasClicked" style="width: 100%;">
      <slot></slot>
    </span>
      <div v-else class="d-flex align-items-center justify-content-center " >
         <div class="spinner-border m-2 d-flex align-iems-center justify-content-center" role="status" style="color:#fff; width:22px;height:22px;">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-if="message" class="ml-2">{{message}}</div>
      </div>
  </div>
</template>

<script>
export default {
props:{
  wasClicked:{
    required: true,
    default:false
  },
  b_class:{
    required: false
  },
  message:{
    default:null
  }
}
}
</script>

<style>

</style>
