var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-15",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%","height":"auto"}},[_c('div',{staticClass:"d-flex justify-content-center flex-column align-items-center"},[_c('Upload',{ref:"thumbnailRef",attrs:{"modelThumbnail":true,"newStyle":"","position":"header","noFavorite":true,"height":200,"btnText":"Imagem do parceiro","limit":1},on:{"data_deleted":_vm.unRegisterThumbnail,"added_item":_vm.registerThumbnail}}),_c('small',{staticClass:"mt-2"},[_vm._v("Dimensões: 300 x 300px")])],1)]),_c('div',{staticClass:"mt-4",staticStyle:{"width":"100%"}},[_c('strong',{attrs:{"for":""}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tempPartner.partner.name),expression:"tempPartner.partner.name"}],staticClass:"form-control",attrs:{"disabled":_vm.isSaving,"id":"name","name":"name","type":"text","maxlength":"21"},domProps:{"value":(_vm.tempPartner.partner.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tempPartner.partner, "name", $event.target.value)}}})]),_c('div',{staticClass:"mt-4"},[_c('strong',{attrs:{"for":""}},[_vm._v("Descrição")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tempPartner.partner.summary),expression:"tempPartner.partner.summary"}],staticClass:"form-control",attrs:{"disabled":_vm.isSaving,"id":"summary","name":"summary","maxlength":"90"},domProps:{"value":(_vm.tempPartner.partner.summary)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tempPartner.partner, "summary", $event.target.value)}}})]),_c('br'),_c('div',{staticStyle:{"display":"flex","flex-flow":"row nowrap","align-items":"center","gap":"5px"}},[_c('strong',{attrs:{"for":""}},[_vm._v("Highlight")]),_c('toggle-button',{ref:"toggleHighlight",staticClass:"ml-auto",attrs:{"disabled":_vm.isSaving,"width":90,"switch-color":{
            checked: '#fff',
            unchecked: '#fff',
            disabled: '#CCCCCC'
          },"labels":{ checked: 'Ativo', unchecked: '  Inativo' },"sync":true},model:{value:(_vm.tempPartner.partner.highlight),callback:function ($$v) {_vm.$set(_vm.tempPartner.partner, "highlight", $$v)},expression:"tempPartner.partner.highlight"}})],1),_c('br'),_c('div',{staticStyle:{"display":"flex","flex-flow":"row nowrap","align-items":"center","gap":"5px"}},[_c('strong',{attrs:{"for":""}},[_vm._v("Ativo")]),_c('toggle-button',{ref:"toggleActive",staticClass:"ml-auto",attrs:{"disabled":_vm.isSaving,"width":90,"switch-color":{
            checked: '#fff',
            unchecked: '#fff',
            disabled: '#CCCCCC'
          },"labels":{ checked: 'Ativo', unchecked: '  Inativo' },"sync":true},model:{value:(_vm.tempPartner.partner.active),callback:function ($$v) {_vm.$set(_vm.tempPartner.partner, "active", $$v)},expression:"tempPartner.partner.active"}})],1),_c('br'),_c('div',{staticStyle:{"display":"flex","flex-flow":"row nowrap","align-items":"center","gap":"5px"},attrs:{"slot":"right-side"},slot:"right-side"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":function($event){return _vm.savePartner()}}},[_vm._v(" Atualizar ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }