<template>
  <div>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive" v-if="!isLoading">
          <thead>
            <tr>
              <th>
                <label>
                  <input type="checkbox" @click="selecteAll" :checked="selectedAll" />
                </label>
              </th>
              <th>Id</th>
              <th>Codigo</th>
              <th>Imagem</th>
              <th>Marca</th>
              <th>Descrição</th>
              <th>Preço</th>
              <th>Situação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bling in filterCore" :key="bling.produto.id">
              <td>
                <label>
                  <input
                    type="checkbox"
                    :checked="isSelected(bling.produto)"
                    @click="selectProduct(bling.produto)"
                  />
                </label>
              </td>
              <td>{{ bling.produto.id }}</td>
              <td>{{ bling.produto.codigo }}</td>
              <td>{{ bling.produto.imageThumbnail || "-" }}</td>
              <td>{{ bling.produto.marca || " - " }}</td>
              <td>{{ bling.produto.descricao || " - " }}</td>
              <td style="width:150px">
                R$ {{ $format($money(bling.produto.preco)) || " - " }}
              </td>

              <td>{{ bling.produto.situacao }}</td>
            </tr>
          </tbody>
        </table>
        <div
          v-else
          class="d-flex align-items-center justify-content-center w-100 h-100"
        >
          <h4>Carregando produtos</h4>
        </div>
      </div>
    </div>
    <button
      type="button"
      hidden
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button>
    <div
      v-show="lastImport.message"
      class="modal fade"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Relatorio de importação
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="() => $event.$emit('back_to_products')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ lastImport.message }}.
            <ul class="list-group mt-3">
              <li
                class="list-group-item pointer"
                v-for="(prod, index) in lastImport.data"
                :key="index"
              >
                <a @click.prevent="goTopage(prod)" data-dismiss="modal" href="#">
                  {{ prod.name || prod.description }}
                </a>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="() => $event.$emit('back_to_products')"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MainModal from "@/components/modals/MainModal";
export default {
  components: { MainModal },
  data() {
    return {
      isLoading: false,
      model: this.value,
      selectedProducts: [],
      isImporting: false,
      lastImport: {},
      selectedAll: false
    };
  },
  props: {
    value: {
      require: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.model = val;
      }
    }
  },
  computed: {
    ...mapGetters(["blingProducts"]),
    filterCore() {

      let products = this.blingProducts.filter(blingProd => {

        return (
          blingProd.produto.id.includes(this.format(this.model.query)) ||
          blingProd.produto.codigo.includes(this.format(this.model.query)) ||
          blingProd.produto.descricao
            .trim()
            .includes(this.format(this.model.query))
        );

      });

      products = products.filter(blingProd => {
        return blingProd.produto.situacao.includes(this.model.situation);
      });

      return products;
    }
  },
  mounted() {

    this.$event.$on("loadBling", () => {
        this.loadProducts();
    });

    setTimeout(() => {
        this.loadProducts();
    }, 1500);

  },
  methods: {
    ...mapActions(["BLING_PRODUCTS", "IMPORT_BLING_PRODUCT", "VIEW_PRODUCT"]),
    format(query) {
      if (isNaN(query)) {
        return query.toLowerCase();
      }
      return query;
    },
    loadProducts() {
      try {
        this.isLoading = true;
        this.BLING_PRODUCTS().then(() => {
          this.isLoading = false;
        });
      } catch (error) {
        //console.log("Error: BlingProductList @loadProducts");
      }
    },
    selecteAll(e) {
     // console.log("input ---", e.target);
      if (e.target.checked) {
        let prods = this.filterCore;
        this.selectedAll = true;
        this.selectedProducts = prods.map(prod => prod.produto);
      } else {
        this.selectedAll = false;

        this.selectedProducts = [];
      }
    },
    selectProduct(prod) {
      //console.log("selectProduct", prod);
      let index = this.selectedProducts.findIndex(
        selected => selected.id == prod.id
      );
      if (index < 0) {
        this.selectedProducts.push(prod);
      } else {
        this.selectedProducts.splice(index, 1);
      }
    },
    isSelected(prod) {
      return this.selectedProducts.some(selected => {
        return selected.id == prod.id;
      });
    },
    startImport() {
      this.$toast.info(" ", "Important lista", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000
      });
      this.isImporting = true;
      this.IMPORT_BLING_PRODUCT(this.selectedProducts).then(res => {

        // this.$event.$emit("back_to_products");
        // this.lastImport = res.data
        $("#staticBackdrop").modal("show");

        //console.log("res", res);
        this.lastImport = res.data;
        if (res.status == 200) {
          this.$toast.success(" ", "Produtos importados", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          });

        }
        this.isImporting = false;
        this.selectedProducts = [];
        this.selectedAll = false
      });
    },
    goTopage(prod) {
      window.open(
        window.location.origin + "/products/details/" + prod.uuid,
        "_blank"
      );
    }
  }
};
</script>

<style></style>
