export default () => {
 // console.log('started animation')
  const animateValue = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const toCount = document.querySelectorAll("count-me");

  toCount.forEach((node, index )=> {
    //console.log("element found =============================", index + 1);
    animateValue(node, 100, 0, 5000);
  });
};

// animateValue(obj, 100, 0, 5000);
