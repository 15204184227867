var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_vm._m(0),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.isSaving ? 'Salvando' : 'Salvar')+" ")])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"form-group",staticStyle:{"max-width":"60% !important"}},[_vm._m(1),_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.formatString),expression:"formatString"},{name:"model",rawName:"v-model.lazy",value:(_vm.store_rules.comission),expression:"store_rules.comission",modifiers:{"lazy":true}}],staticClass:"form-control",class:[
              {
                'input-disabled': !_vm.store_rules.hasSeller,
              },
            ],staticStyle:{"max-width":"20% !important"},attrs:{"disabled":!_vm.store_rules.hasSeller,"type":"string","maxlength":"99","placeholder":"Ex. 5%"},domProps:{"value":(_vm.store_rules.comission)},on:{"change":function($event){return _vm.$set(_vm.store_rules, "comission", $event.target.value)}}})]),_c('div',{staticClass:"form-group",staticStyle:{"max-width":"60% !important"}},[_vm._m(2),_c('div',{staticClass:"row align-items-center",staticStyle:{"gap":"1rem"}},[_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.formatString),expression:"formatString"},{name:"model",rawName:"v-model.lazy",value:(_vm.store_rules.discount),expression:"store_rules.discount",modifiers:{"lazy":true}}],staticClass:"form-control",staticStyle:{"max-width":"20% !important"},attrs:{"type":"string","maxlength":"99","placeholder":"Ex. 5%"},domProps:{"value":(_vm.store_rules.discount)},on:{"change":function($event){return _vm.$set(_vm.store_rules, "discount", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.store_rules.discount_to_installment),expression:"store_rules.discount_to_installment",modifiers:{"lazy":true}}],staticClass:"form-control",staticStyle:{"max-width":"20% !important"},attrs:{"type":"number","maxlength":"99","placeholder":"Ex. 1"},domProps:{"value":(_vm.store_rules.discount_to_installment)},on:{"change":function($event){return _vm.$set(_vm.store_rules, "discount_to_installment", $event.target.value)}}})])]),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar Vendedor (Loja e Admin)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSeller,"width":80,"switch-color":{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasSeller),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasSeller", $$v)},expression:"store_rules.hasSeller"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar frete (Loja e Admin)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasDelivery),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasDelivery", $$v)},expression:"store_rules.hasDelivery"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar menu Posts (Admin)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasPosts),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasPosts", $$v)},expression:"store_rules.hasPosts"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev','admin']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar seção Categorias (Loja )")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasCategories),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasCategories", $$v)},expression:"store_rules.hasCategories"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev','admin','seller']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar cupons (Loja)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasCupom),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasCupom", $$v)},expression:"store_rules.hasCupom"}})],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Habilitar menu cupons (Admin)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasCupomAdmin),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasCupomAdmin", $$v)},expression:"store_rules.hasCupomAdmin"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar menu Blogs (Loja)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasBlogs),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasBlogs", $$v)},expression:"store_rules.hasBlogs"}})],1),(_vm.store_rules.hasBlogs)?_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Nome do Blog")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.store_rules.blogName),expression:"store_rules.blogName"}],staticClass:"ml-3",attrs:{"type":"text","placeholder":"Digite o nome do blog","disabled":!_vm.store_rules.hasBlogs,"width":80},domProps:{"value":(_vm.store_rules.blogName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.store_rules, "blogName", $event.target.value)}}})]):_vm._e()]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar menu Parceiros (Loja e Admin)")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasPatners),callback:function ($$v) {_vm.$set(_vm.store_rules, "hasPatners", $$v)},expression:"store_rules.hasPatners"}})],1)]):_vm._e(),(_vm.$masterHelper.hasRole(_vm.loggedUser,['dev']) === true)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Ativar botão Bling")]),_c('toggle-button',{staticClass:"ml-3",attrs:{"disabled":_vm.isSaving,"width":80,"switch-color":{
                checked: '#fff',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"sync":true,"labels":{ checked: 'Ativado', unchecked: '  Desativado' }},model:{value:(_vm.store_rules.hasBling.inSaleRight),callback:function ($$v) {_vm.$set(_vm.store_rules.hasBling, "inSaleRight", $$v)},expression:"store_rules.hasBling.inSaleRight"}})],1)]):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start wrapper-installments",staticStyle:{"gap":"1rem"}},[_c('strong',[_vm._v("Parcelas")]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start",staticStyle:{"gap":"0.5rem"}},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.handlerSetInstallments}},[_vm._v(" Adicionar ")])])])]),_c('strong',[_vm._v("Parcela(s) cadastrada(s)")]),(_vm.store_rules.installments.length >= 1)?_c('table',[_vm._m(5),_c('tbody',_vm._l((_vm.store_rules.installments),function(installment,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(installment.price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }))+" ")]),_c('td',[_vm._v(" "+_vm._s(installment.installmentRaw)+" ")]),_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handlerRemoveInstallments(index)}},[_vm._v(" X ")])])}),0)]):_c('span',[_vm._v(_vm._s(_vm.store_rules.installments.length))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('strong',[_vm._v("Regras da loja")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('hgroup',[_c('h4',[_vm._v(" De 0 a 100, qual a % (porcentagem) de comissão ")]),_c('h5',{staticClass:"seller-comission-alert"},[_vm._v(" Deixe o valor igual a 0, caso não tenha comissões ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('hgroup',[_c('h4',[_vm._v(" De 0 a 100, qual a % (porcentagem) de desconto ")]),_c('h5',{staticClass:"seller-comission-alert"},[_vm._v(" Deixe o valor igual a 0, caso não tenha descontos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('label',{attrs:{"for":""}},[_vm._v("De:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"","id":"installment_price"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('label',{attrs:{"for":""}},[_vm._v("Parcelas:")]),_c('input',{staticClass:"form-control",attrs:{"type":"number","name":"","id":"installment"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("A partir de")]),_c('th',[_vm._v("Parcela")]),_c('th',[_vm._v("Remover")])])])
}]

export { render, staticRenderFns }