<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-baseline justify-content-between">
        <h4>
          <strong>Regras da loja</strong>
        </h4>
        <button class="btn btn-primary" @click="save()" :disabled="isSaving">
          {{ isSaving ? 'Salvando' : 'Salvar' }}
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="col-8">

          <div class="form-group" style="max-width: 60% !important;">
            <hgroup>
              <h4>
                De 0 a 100, qual a % (porcentagem) de comissão
              </h4>
              <h5 class="seller-comission-alert">
                Deixe o valor igual a 0, caso não tenha comissões
              </h5>
            </hgroup>
            <input
              style="max-width: 20% !important;"
              :disabled="!store_rules.hasSeller"
              v-money="formatString"
              type="string"
              class="form-control"
              maxlength="99"
              :class="[
                {
                  'input-disabled': !store_rules.hasSeller,
                },
              ]"
              placeholder="Ex. 5%"
              v-model.lazy="store_rules.comission"
            />
          </div>

          <div class="form-group" style="max-width: 60% !important;">
            <hgroup>
              <h4>
                De 0 a 100, qual a % (porcentagem) de desconto
              </h4>
              <h5 class="seller-comission-alert">
                Deixe o valor igual a 0, caso não tenha descontos
              </h5>
            </hgroup>
            <div class="row align-items-center" style="gap: 1rem">
              <input
                style="max-width: 20% !important;"
                v-money="formatString"
                type="string"
                class="form-control"
                maxlength="99"
                placeholder="Ex. 5%"
                v-model.lazy="store_rules.discount"
              />
              <input
                style="max-width: 20% !important;"
                type="number"
                class="form-control"
                maxlength="99"
                placeholder="Ex. 1"
                v-model.lazy="store_rules.discount_to_installment"
              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
                <label for="">Ativar Vendedor (Loja e Admin)</label>
                <toggle-button
                  v-model="store_rules.hasSeller"
                  class="ml-3"
                  :disabled="isSeller"
                  :width="80"
                  :switch-color="{
                    checked: '#fff',
                    unchecked: '#fff',
                    disabled: '#CCCCCC',
                  }"
                  :sync="true"
                  :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
                />
            </div>
          </div>
          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar frete (Loja e Admin)</label>
              <toggle-button
                v-model="store_rules.hasDelivery"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar menu Posts (Admin)</label>
              <toggle-button
                v-model="store_rules.hasPosts"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev','admin']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar seção Categorias (Loja )</label>
              <toggle-button
                v-model="store_rules.hasCategories"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev','admin','seller']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar cupons (Loja)</label>
              <toggle-button
                v-model="store_rules.hasCupom"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
            <div class="d-flex align-items-center">
              <label for="">Habilitar menu cupons (Admin)</label>
              <toggle-button
                v-model="store_rules.hasCupomAdmin"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar menu Blogs (Loja)</label>
              <toggle-button
                v-model="store_rules.hasBlogs"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
            <div class="d-flex align-items-center" v-if="store_rules.hasBlogs">
              <label for="">Nome do Blog</label>
              <input
                type="text"
                placeholder="Digite o nome do blog"
                v-model="store_rules.blogName"
                class="ml-3"
                :disabled="!store_rules.hasBlogs"
                :width="80"

              />
            </div>
          </div>

          <div class="form-group" v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar menu Parceiros (Loja e Admin)</label>
              <toggle-button
                v-model="store_rules.hasPatners"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div class="form-group"   v-if="$masterHelper.hasRole(loggedUser,['dev']) === true">
            <div class="d-flex align-items-center">
              <label for="">Ativar botão Bling</label>
              <toggle-button
                v-model="store_rules.hasBling.inSaleRight"
                class="ml-3"
                :disabled="isSaving"
                :width="80"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC',
                }"
                :sync="true"
                :labels="{ checked: 'Ativado', unchecked: '  Desativado' }"
              />
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-start justify-content-start wrapper-installments"
            style="gap: 1rem"
          >
            <strong>Parcelas</strong>

            <div class="d-flex flex-row align-items-center justify-content-start" style="gap: 0.5rem">
              <div class="form-group">
                <div class="d-flex align-items-center" style="gap: 0.5rem">
                  <label for="">De:</label>
                  <input type="text" name="" id="installment_price" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex align-items-center" style="gap: 0.5rem">
                  <label for="">Parcelas:</label>
                  <input type="number" name="" id="installment" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex align-items-center" style="gap: 0.5rem">
                  <button type="button" class="btn btn-primary" @click="handlerSetInstallments">
                    Adicionar
                  </button>
                </div>
              </div>
            </div>

            <strong>Parcela(s) cadastrada(s)</strong>

            <table v-if="store_rules.installments.length >= 1">
              <thead>
                <tr >
                  <th>A partir de</th>
                  <th>Parcela</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(installment, index) in store_rules.installments" :key="index">
                  <td>
                    {{
                      installment.price.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })
                    }}
                  </td>
                  <td>
                    {{ installment.installmentRaw }}
                  </td>
                  <td @click="() => handlerRemoveInstallments(index)" style="cursor: pointer">
                    X
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-else>{{ store_rules.installments.length }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VMoney } from "v-money";
export default {
  directives: { money: VMoney },
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isSeller: false,
      isEditing: false,
      store_rules: {
        comission: null,
        discount: 0,
        discount_to_installment: 1,
        hasDelivery: false,
        hasCupom: false,
        hasCupomAdmin: false,
        hasSeller: false,
        hasPosts: false,
        hasBlogs: false,
        blogName: 'blog',
        hasCategories: false,
        hasPatners: false,
        hasBling: {
          inSaleRight: false,
        },
        installments: [
        ]
      },
      formatString: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 1,
        masked: false,
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      "settings",
      "loggedUser"
      ]),

    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "store_rules";
        }) || false
      );
    },
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      const cachedStoreTemplate = JSON.parse(JSON.stringify(this.store_rules))

      Object.entries(this.myGateways.content).forEach(objectTemplate => {
        if((Object.is(objectTemplate[1]) && Object.keys(objectTemplate[1] || {}).length > 0) ||  (Array.isArray(objectTemplate[1]) && objectTemplate[1].length > 0)) {
          if(Array.isArray(objectTemplate[1]) && objectTemplate[1].length > 0) {
            cachedStoreTemplate[objectTemplate[0]] = [
              ...objectTemplate[1]
            ]
          }else{
            // console.log(objectTemplate[0],objectTemplate[1],Object.keys(objectTemplate[1] || {}),Array.isArray(objectTemplate[1]))
            cachedStoreTemplate[objectTemplate[0]] = {
              ...cachedStoreTemplate[objectTemplate[0]],
              ...objectTemplate[1]
            }
          }
        }else{
          cachedStoreTemplate[objectTemplate[0]] = objectTemplate[1]
        }
      })

      this.store_rules = this.myGateways.content;
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS"]),
    comissionNumber(e) {
      //console.log("comissionNumber", e);
      this.store_rules.comission = e.target.value;
      // e.target.value = e.target.value;
      this.$forceUpdate();
    },
    save(key= "store_rules") {
      let data = this.store_rules;
      // if (this.store_rules.comission) {
      //   data.comission = parseFloat(this.store_rules.comission);
      // }

      if (data.hasSeller) {
        // if (Number(data.comission) <= 0) {
        //   this.$toast.info(" ", `Preencha o campo Comissão`, {
        //     icon: "icon-person",
        //     position: "topRight",
        //     timeout: 2000,
        //   });
        //   return;
        // }
      }

      this.isSaving = true;
      this.$pace.restart();

      this.CREATE_SETTINGS({
        content: data,
        key: key,
      }).then((res) => {
        this.$pace.restart();
        this.UPDATE++;
        if (res.code === 201) {
          this.$toast.success(" ", "Concluido", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.isSaving = false;
        }else {
          this.$toast.error(" ", "Houve uma falha.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.isSaving = false;
        }
      });
    },
    handlerSetInstallments(_installmentID = ""){
      try {
        const elementInstallment = document.querySelector(`#installment`)?.value || 0
        const elementInstallmentPrice = document.querySelector(`#installment_price`)?.value || 0.0

        if((!elementInstallment || elementInstallment <= 0) || (!elementInstallmentPrice || elementInstallmentPrice <= 0)) return

        let installmentsRaw = this.store_rules?.installments || []

        if(installmentsRaw.length >= 1) {
          installmentsRaw = installmentsRaw.filter(installment => installment.price !== parseFloat(elementInstallmentPrice))
        }

        installmentsRaw.push({
          price: parseFloat(elementInstallmentPrice),
          installmentRaw: elementInstallment
        })

        this.store_rules.installments = installmentsRaw
      } catch (error) {
        // do anything
      }
    },
    handlerRemoveInstallments(installmentIndex = -1){
      try {
        let installmentsRaw = this.store_rules?.installments || []

        if(installmentsRaw.length > 1) {
          installmentsRaw = installmentsRaw.filter((_installment,index) => index !== installmentIndex)
        }else{
          installmentsRaw = []
        }

        this.store_rules.installments = installmentsRaw
      } catch (error) {
        // do anything
      }
    }
  },
};
</script>

<style></style>
