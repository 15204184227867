<template>
  <portal to="BreadCrumb">
    <div class="page-breadcrumb slide-in-blurred-top" v-if="!hide() && loaded">
      <div class="row">
        <div class="col-7 align-self-center">
          <h1
            class="page-title text-truncate text-dark font-weight-medium mb-1"
          >
            {{ page }}
          </h1>
          <div class="d-flex align-items-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0 p-0">
                <li
                  class="breadcrumb-item"
                  v-for="(crumb, index) in crumbs"
                  :key="index"
                >
                  <a
                    @click.prevent="
                     $router.push({ name: crumb.name || '' })
                    "
                    href="#"
                    >
                    {{ crumb.name }}
                    </a>
                    <!-- {{crumb.routeName}} -->
                </li>
              </ol>
            </nav>
          </div>
          <div class="mt-2" v-if="pageInfo">
            <small>{{ pageInfo }}</small>
          </div>
        </div>
        <div class="col-5 align-self-center">
          <div class="customize-input float-right">
            <slot name="right-side"></slot>
            <!-- <select
            class="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius"
          >
            <option selected="">Aug 19</option>
            <option value="1">July 19</option>
            <option value="2">Jun 19</option>
          </select> -->
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      loaded: true
    };
  },
  props: {
    crumbs: {
      type: Array
    },
    page: {
      type: String
    },
    pageInfo: {
      type: String
    },
    hideOn: {
      type: Boolean,
      default: false
    },
    rightSide: {
      type: Object
    },
    onClick: {
      type: String
    }
  },
  watch: {
    // $route(to, from) {
    //   this.show = false;
    // }
  },
  computed: {
    currentPage() {
      return this.$route.name;
    }
  },
  mounted() {
    //console.log("props", this.$props);
    // this.$event.$on("new_product", data => {
    // });
  },
  methods: {
    hide() {
      switch (this.currentPage) {
        case "chat":
          return true;
          break;

        default:
          return false;
          break;
      }
    }
  }
};
</script>

<style>
.breadcrumb-item::before {
  color: #9ea3a9 !important;
}
</style>
