// Ideias from
// https://codepen.io/celsonf/pen/VwmRygV?editors=1010
const Mask = () => {
  const invalidCep = [
    '00000000',
    '00000000',
    '11111111',
    '22222222',
    '33333333',
    '44444444',
    '55555555',
    '66666666',
    '77777777',
    '88888888',
    '99999999',
  ]
  const invalidArr = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ]
  const getDigits = (s) => {
    return s.replace(/\D/g, '')
  }
  const selector = (s) => {
    if (getDigits(s).length < 12) {
      return cpf(s)
    }
    if (getDigits(s).length > 11 && getDigits(s).length <= 14) {
      return cnpj(s)
    }
  }
  const validate = (s) => {
    return invalidArr.some((invalid) => {
      return invalid.includes(getDigits(s))
    })
  }

  const cep = (v) => {
    // 14 digits
    v = v.replace(/\D/g, '')
    v = v.replace(/(\d{5})(\d)/, '$1-$2')
    // v = v.replace(/(\d{4})(\d)/, "$1-$2");
    return v
  }

  const rg = (v) => {
    // 9 digits
    v = v.replace(/\D/g, '')
    v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
    return v
  }

  // =================================================================================
  document.querySelectorAll('[rg]').forEach((e) => {
    e.addEventListener('keyup', (k) => {
      var formated = ''

      // console.log('value e', getDigits(e.value).length)
      if (getDigits(e.value).length > 3 && getDigits(e.value).length < 9) {
        e.classList.add('error')

        let elementExistis = document.getElementById('el_small_id_rg')

        if (!elementExistis) {
          e.parentNode.insertBefore(
            elSmall('el_small_id_rg', ' rg inválido'),
            e.nextSibling
          )
        } else {
          elSmall('el_small_id_rg', ' rg inválido')
        }
      } else {
        let el = document.getElementById('el_small_id_rg')
        e.classList.remove('error')
        el && el.remove()
      }
      if (getDigits(e.value).length <= 9) {
        formated = rg(getDigits(e.value).substr(0, 9))
        e.value = formated
      } else {
        e.value = rg(getDigits(e.value).substr(0, 9))
      }
      if (!getDigits(e.value).length) {
        e.classList.remove('error')
      }
    })
  })
  // valida cep jms
  document.querySelectorAll('[cep]').forEach((e) => {
    e.addEventListener('keyup', (k) => {
      var formated = ''
      // console.log('value e', getDigits(e.value).length)
      if (getDigits(e.value).length > 4 && getDigits(e.value).length < 8) {
        e.classList.add('error')
        let elementExistis = document.getElementById('el_small_id_cep')
        if (!elementExistis) {
          e.parentNode.insertBefore(
            elSmall('el_small_id_cep', 'cep inválido'),
            e.nextSibling
          )
        } else {
          elSmall('el_small_id_cep', 'cep inválido')
        }
      } else {
        let el = document.getElementById('el_small_id_cep')
        e.classList.remove('error')
        el && el.remove()
      }

      if (getDigits(e.value).length <= 8) {
        formated = cep(getDigits(e.value))
        e.value = formated
      } else {
        e.value = cep(getDigits(e.value).substr(0, 8))
      }
    })
  })

  //font: https://gist.github.com/fernandovaller/b10a3be0e7b3b46e5895b0f0e75aada5
  const cnpj = (v) => {
    // 14 digits
    v = v.replace(/\D/g, '') //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, '$1.$2') //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2') //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2') //Coloca um hífen depois do bloco de quatro dígitos
    return v
  }
  const cpf = (v) => {
    // 11 digits
    v = v.replace(/\D/g, '') //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2') //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2') //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2') //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
  }
  //Terminar
  const smallInfo = (el, type) => {
    const childs = el.parentElement.children
    const hasIt = childs.namedItem('_input-help-text_')
    const small = document.createElement('small')
    if (!hasIt) {
      small.name = '_input-help-text_'
      small.innerText = type
      el.parentElement.appendChild(small)
    }
  }
  const elSmall = (id, innerText) => {
    let foundElement = document.getElementById(id)
    let element
    if (!foundElement) {
      element = document.createElement('small')
      // element.style.border = "1px solid red";
      // console.log('your ele', element)
      element.id = id
      element.innerText = innerText
      return element
    } else {
      // element.style.border = "1px solid red";
      // console.log('your element', element)

      element = foundElement.innerText = innerText
    }
    // console.log('element-----below')
    // console.log(element)
    return element
  }
  // console.log('mask js loaded', document.querySelectorAll('[cpf-cnpj]'))

  document.querySelectorAll('[cpf-cnpj]').forEach((e) => {
    // console.log(e)
    var formated = ''
    e.addEventListener('keyup', (k) => {
      // console.log('does it contains the invalid?', validate(e.value))

      if (getDigits(e.value).length > 3 && getDigits(e.value).length < 11) {
        e.classList.add('error')
        let elementExistis = document.getElementById('el_small_id_cpf')
        if (!elementExistis) {
          e.parentNode.insertBefore(
            elSmall('el_small_id_cpf', 'cpf inválido'),
            e.nextSibling
          )
        } else {
          elSmall('el_small_id_cpf', 'cpf inválido')
        }
      } else {
        let el = document.getElementById('el_small_id_cpf')

        el && el.remove()
      }
      if (getDigits(e.value).length > 11 && getDigits(e.value).length < 14) {
        let elementExistis = document.getElementById('el_small_id_cnpj')
        if (!elementExistis) {
          e.parentNode.insertBefore(
            elSmall('el_small_id_cnpj', 'cnpj inválido'),
            e.nextSibling
          )
        } else {
          elSmall('el_small_id_cnpj', 'cnpj inválido')
        }
      } else {
        let ell = document.getElementById('el_small_id_cnpj')
        ell && ell.remove()
      }

      if (getDigits(e.value).length <= 14) {
        formated = selector(e.value)
        e.value = formated
      } else {
        e.value = formated
      }
      let submit = document.getElementsByClassName('block-btn')

      // console.log('submit btn', submit)

      if (getDigits(e.value).length > 11 && getDigits(e.value).length == 14) {
        e.classList.remove('error')
        submit ? (submit[0].disabled = false) : ''
      }
      if (getDigits(e.value).length > 11 && getDigits(e.value).length !== 14) {
        e.classList.add('error')
        submit ? (submit[0].disabled = true) : ''
      }

      if (getDigits(e.value).length > 8 && getDigits(e.value).length == 11) {
        e.classList.remove('error')
        submit ? (submit[0].disabled = false) : ''
      }
      if (getDigits(e.value).length < 14 && getDigits(e.value).length !== 11) {
        e.classList.add('error')
        submit ? (submit[0].disabled = true) : ''
      }

      if (!getDigits(e.value).length) {
        e.classList.remove('error')
        submit ? (submit[0].disabled = false) : ''
      }
    })
  })

  document.querySelectorAll('[number]').forEach((n) => {
    // console.log('element loaded', n)

    n.addEventListener('keyup', (k) => {
      if (k.target.value) {
        k.target.value = getDigits(k.target.value)
        // console.log('not a number')
      }

      // console.log('number inforcment', k)
    })
  })
}

export default Mask
