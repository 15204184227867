<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span class="pointer" @click="editTag()" v-if="!isEditing">
        <i class="far fa-edit"></i> Editar
      </span>
      <span
        class="pointer align-self-end text-danger"
        @click="VIEW_TAG({})"
        v-if="!isEditing"
      >
        fechar</span
      >
      <span
        class="text-danger pointer"
        v-if="isEditing"
        @click="isEditing = false"
        >Cancelar</span
      >
    </div>
    <div class="card-body" v-if="!isEditing">
      <div class="form-group">
        <strong for="">Nome</strong>
        <p class="mt-1">{{ tag.name }}</p>
      </div>
      <div class="form-group" v-if="!$masterHelper.isEmpty(tag.settings)">
        <strong for="">Prévia</strong>
        <div>
          <span
            class="badge"
            :style="tag.settings"
            style="border: 1px solid gray"
          >
            <span class="font-weight-bold m-2">{{ tag.name || "TAG" }}</span>
          </span>
        </div>
      </div>

      <div class="form-group">
        <strong for="">Descrição</strong>
        <p class="mt-1">{{ tag.description }}</p>
      </div>
    </div>
    <div class="card-body" v-if="isEditing">
      <div class="form-group">
        <strong for="">Nome</strong>
        <input class="form-control" type="text" v-model="tempTag.name" />
      </div>
      <div class="form-group">
        <label for="">Cor</label>
        <multiselect
          v-model="tempTag.settings"
          placeholder="Selecione uma cor"
          label="color"
          track-by="color"
          :options="tagColorsList"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="d-flex align-items-center">
              <div
                class="option__image"
                style="width: 50px; height: 50px"
                :style="'background:' + props.option.backgroundColor"
              ></div>

              <span class="option__desc ml-3"
                ><span class="option__title">{{
                  props.option.backgroundColor
                }}</span></span
              >
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="d-flex align-items-center">
              <div
                class="option__image"
                style="width: 50px; height: 50px; border: 1px solid gray"
                :style="'background:' + props.option.backgroundColor"
              ></div>
              <div class="option__desc ml-3">
                <span class="option__title">{{
                  props.option.backgroundColor
                }}</span>
              </div>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="form-group">
        <strong for="">Descrição</strong>
        <textarea class="form-control" v-model="tempTag.description" />
      </div>
      <div class="form-group">
        <toggle-button
          :width="80"
          :switch-color="{
            checked: '#fff',
            unchecked: '#fff',
            disabled: '#CCCCCC',
          }"
          v-model="tempTag.active"
          :sync="true"
          :labels="{ checked: 'Ativo', unchecked: '  Desativado' }"
        />
      </div>
      <button class="btn btn-primary btn-block" @click="updateTag()">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isEditing: false,
      tempTag: {},
      compareTag: {},
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters(["tag", "tagColorsList"]),
  },
  mounted() {
    this.$event.$on("tag_selected", () => {
      this.isEditing = false;
    });
  },
  methods: {
    ...mapActions(["VIEW_TAG", "UPDATE_TAG", "BULK_UPDATE_TAGS"]),
    editTag() {
      this.tempTag = {};
      this.tempTag = this.tag;
      // this.tempTag.active = parseInt(this.tempTag.active) == 1 ? true : false;

      Object.assign(this.compareTag, this.tempTag);

      this.isEditing = !this.isEditing;
      this.$event.$emit("edit_tag");
    },
    updateTag() {
      this.$pace.restart();
      this.$toast.info(" ", "Atualizando!", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      this.VIEW_TAG(this.tempTag);
      this.UPDATE_TAG(this.tempTag).then((e) => {
        if (e.code === 201) {
          this.$toast.success(" ", "Atualizado!", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        }
      });
      this.isEditing = false;
      this.$event.$emit("tag_updated");
    },
  },
};
</script>

<style></style>
