<template>
  <div>
    <div class="card">
      <LoadingScreen
        eventName="loading_categories"
        dir="column"
        bgColor="#fff"
        message="Pesquisando..."
      />
      <div class="card-body">
        <div
          class="w-100 d-flex align-items-center justify-content-between p-2"
        >
          <div>
            <h4>Total: {{ categoriesList.length }}</h4>
          </div>
          <div class="d-flex">
            <LoaderButton
              b_class="btn-warning ml-3 slide-in-blurred-top"
              :wasClicked="isGoingOff"
            >
              <button
                v-if="!isGoingOn"
                :disabled="isGoingOn || isDeleting"
                class="btn btn-warning "
                v-show="selectedItems.filter(i => i.active == 1 || i.active == 2).length"
                @click="updateCategory('isGoingOff')"
              >
                Desativar
              </button>
            </LoaderButton>

            <LoaderButton
              b_class="btn-warning ml-3 slide-in-blurred-top"
              :wasClicked="isGoingOn"
            >
              <button
               v-if="!isGoingOff"
                :disabled="isDeleting || isGoingOff"
                class="btn btn-warning"
                v-show="selectedItems.filter(i => i.active == 0 || i.active == 2).length"
                @click="updateCategory('isGoingOn')"
              >
                Ativar
              </button>
            </LoaderButton>

            <LoaderButton
              b_class="btn-danger ml-3 slide-in-blurred-top"
              :wasClicked="isDeleting"
            >
              <button
                @click="updateCategory('isDeleting')"
                class="btn btn-danger "
                v-if="selectedItems.length"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </LoaderButton>
            <!-- <CreateModal /> -->
          </div>
        </div>
        <table class="table  table-inverse w-100" v-if="categoriesList && categoriesList.length > 0">
          <thead class="thead-inverse">
            <tr>
              <th>
                <label>
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @click="selectAllItems()"
                  />
                </label>
              </th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Criado em</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="pointer hover slide-in-blurred-top"
              v-for="(category, index) in categoriesList"
              :key="index"
              :class="[{ selectedRow: isSelected(category.uuid) }]"
            >
              <th>
                <label>
                  <input
                    @click="selecteItem(category)"

                    type="checkbox"
                    :checked="selectedItem(category)"
                  />
                </label>
              </th>
              <td  @dblclick="editCategoryByDBClick(category)" @click="selectCategory(category)">{{ category.name }}</td>
              <td  @dblclick="editCategoryByDBClick(category)" @click="selectCategory(category)">
                {{ category.description || " --" }}
              </td>
              <td  @dblclick="editCategoryByDBClick(category)" @click="selectCategory(category)">
                {{ $date(category.created_at) }}
              </td>
              <td  @dblclick="editCategoryByDBClick(category)" @click="selectCategory(category)">
                <i
                  class="fas fa-circle text-success"
                  v-show="category.active"
                  title="Ativado"
                ></i>
                <i
                  class="far fa-dot-circle text-danger"
                  v-show="!category.active"
                  title="Desativado"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-9 mt-5" v-else>
          <h3>Nenhuma categoria disponível</h3>
        </div>
      </div>
      <!-- <div class="card-footer ">
         <Pagination
          v-model="categoriesPaginate"
          eventName="categories_loaded"
          currentPageName="categories"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import LoadingScreen from "@/components/navigation/LoadingScreen";
// import CreateModal from "@/components/categories/CreateModal";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import LoaderButton from "@/components/buttons/LoaderButton";
import { mapGetters, mapActions } from "vuex";
import Pagination from "@/components/navigation/Paginator";


export default {
  data() {
    return {
      rowStart: null,
      rowEnd: null,
      perPage: 10,
      currentPage: 1,
      isGoingOn: false,
      selectAll: false,
      selectedItems: [],
      userSelected: [],
      isDeleting: false,
      isGoingOff: false,
      UPDATED: 0
    };
  },
  components: {
    LoadingScreen,
    BreadCrumb,
    LoaderButton,
    Pagination
    // CreateModal
  },
  mounted() {
    // this.LOAD_CATEGORIES();
    this.$pace.restart();
    this.$event.$on("category_updated", () => {
      this.UPDATED++;
    });
    this.$event.$on("edit_category", () => {
      this.selectedItems = [];
      this.selectAll = false;
    });
    this.$event.$emit("loading_categories", false);
  },
  computed: {
    ...mapGetters(["categories", "category","categoriesPaginate"]),
    categoriesList() {
      this.UPDATED;
      return this.categories
    }
  },
  methods: {
    ...mapActions([
      "LOAD_CATEGORIES",
      "VIEW_CATEGORY",
      "BULK_UPDATE_CATEGORIES",
      "UPDATE_CATEGORY",
      "BULK_DELETE_CATEGORIES"
    ]),
    isSelected(uuid) {
      return this.category.uuid === uuid;
    },
    selectAllItems() {
      if (!this.selectAll) {
        this.selectedItems = [...this.categories];
      } else if (this.selectAll) {
        this.selectedItems = [];
      }
    },

    selecteItem(category) {
      let isSelected = this.selectedItems.findIndex(item => {
        return item.uuid === category.uuid;
      });
      if (isSelected < 0) {
        this.selectedItems.push(category);
      } else {
        this.selectedItems.splice(isSelected, 1);
      }
    },

    selectedItem(category) {
      return this.selectedItems.some(item => {
        return item.uuid === category.uuid;
      });
    },
    reset() {
      this.userSelected = [];
      this.selectedItems = [];
    },
    selectCategory(category) {
      this.$event.$emit("category_selected");
      this.VIEW_CATEGORY(category);
    },

    async updateCategory(action) {
      this[action] = true;
      switch (action) {
        case "isDeleting":
          await this.deleteSelectedCategories();
          break;
        case "isGoingOn":
          this.toggleVisability(action, 1);
          break;
        case "isGoingOff":
          this.toggleVisability(action, 0);
          break;
        default:
          break;
      }
    },

    editCategoryByDBClick(category){
      this.selectCategory(category)
      this.$event.$emit('edit_category')
    },

    toggleVisability(action, status) {

      if (this.selectedItems.length) {

        this.selectedItems.forEach((item, index) => {
          this.selectedItems[index].active = status;
        });

        this.$toast.info(" ", `Atualizando`, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        this.BULK_UPDATE_CATEGORIES(this.selectedItems).then(() => {

          this.$toast.success(" ", `Atualizado`, {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          });

          this[action] = false;
          this.reset();
        });
      }
    },

    deleteSelectedCategories() {
      this.$toast.info(" ", `Deletando`, {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000
      });

      this.BULK_DELETE_CATEGORIES(this.selectedItems).then(() => {
        this.$toast.success(" ", `Deletado!`, {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });
        this.isDeleting = false;
        this.reset();
      });
    }
  }
};
</script>

<style></style>
