<template>
  <div>
    <BreadCrumb :pageInfo="posts && posts.length + ' posts'" page="Posts">
      <div slot="right-side">
        <button class="btn btn-primary" @click="$router.push({ name: 'post.create' })">
          Novo
        </button>
      </div>
    </BreadCrumb>

    <div class="mb-2">
      <small class="alert alert-info">Dê dois cliques para abrir a postagem.</small>
    </div>

    <div class="row" id="responsive-page-container">
      <div class="col-9 table-report" id="responsive-page-table">
        <!-- <div class="col-4" v-for="post in posts" :key="post.uuid">
            <PostCard :item="post" />
          </div> -->
        <div class="card">
          <div class="card-body">
            <PostList :data="postFromPaginate" />
            <nav id="div-pagination" class="row align-items-center justify-content-center" style="margin: 0 auto">
              <button @click="() => getPage(1,true,1,Math.round(posts.length / 2) )" title="Ir para primeira">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                </svg>
              </button>

              <button v-for="(paginated, index) in navigationPaginate" :key="index" :id="`perPageID_${paginated.id}`"
                class="perPage row align-items-center justify-content-center" :perPage="`${paginated.perPage}`"
                :pageStart="`${paginated.pageStart}`" :pageEnd="`${paginated.pageEnd}`"
                @click="() => setPaginator(paginated.perPage, paginated.pageStart, paginated.pageEnd,paginated.id)">
                {{ paginated.page }}
              </button>

              <button @click="() => getPage(1,true,Math.round(posts.length / 2), posts.length + 1)"
                title="Ir para última">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-3" id="responsive-filter-container">
        <MasterFilter v-if="$masterHelper.isEmpty(post)" :hideInputs="['status', 'roles','users']" page="Posts" :rules="{
          searchable: ['title', 'sub_title', 'body'],
          relationships: ['tags', 'categories', 'media', 'user']
        }" />

        <!-- ======================================================================================================== -->
        <div v-else>
          <div class="card" style="position:fixed; width:20%">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span class="pointer" @click="editPost()" v-if="!isEditing">
                <i class="far fa-edit"></i> Editar
              </span>
              <span class="pointer align-self-end text-danger" @click="
                VIEW_POST({});
                $forceUpdate();
              " v-if="!isEditing">fechar</span>
              <span class="text-danger pointer" v-if="isEditing" @click="isEditing = false">Cancelar</span>
              <span class="text-primary pointer" v-if="isEditing" @click="updatePost()">Salvar</span>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label><strong>Status</strong></label>
                <p v-if="post.active && !isEditing" class="text-primary">
                  <strong>Ativo</strong>
                </p>
                <p v-if="!post.active && !isEditing">
                  Desativado
                </p>

                <div>
                  <toggle-button v-if="isEditing" :width="80" :switch-color="{
                    checked: '#fff',
                    unchecked: '#fff',
                    disabled: '#CCCCCC'
                  }" v-model="tempPost.active" :sync="true"
                    :labels="{ checked: 'Ativo', unchecked: '  Desativado' }" />
                </div>
              </div>
              <div class="form-group">
                <label><strong>Destaque</strong></label>
                <p v-if="post.active && !isEditing" class="text-primary">
                  <strong>Ativo</strong>
                </p>
                <p v-if="!post.active && !isEditing">
                  Desativado
                </p>

                <div>
                  <toggle-button v-if="isEditing" :width="80" :switch-color="{
                    checked: '#fff',
                    unchecked: '#fff',
                    disabled: '#CCCCCC'
                  }" v-model="tempPost.highlight" :sync="true" :labels="{ checked: 'Sim', unchecked: 'Não' }" />
                </div>
              </div>
              <div class="form-group">
                <label><strong>Título</strong></label>
                <p>{{ post.title }}</p>
              </div>

              <div class="form-group">
                <label><strong>Autor</strong></label>
                <p>{{ post.user ? post.user.name : "-" }}</p>
              </div>

              <div class="form-group">
                <strong for="">Categorias</strong>
                <div class="mt-2" v-if="post.categories && post.categories.length">
                  <span class="badge badge-dark mr-2" v-for="cat in post.categories" :key="cat.uuid"><i
                      class="fas fa-sitemap mr-1"></i> {{ cat.name }}
                  </span>
                </div>
                <div v-else><small>Sem categorias</small></div>
              </div>
              <div class="form-group">
                <strong for="">Tags</strong>
                <div class="mt-2" v-if="post.tags && post.tags.length">
                  <span class="badge badge-dark mr-2" v-for="tags in post.tags" :key="tags.uuid"><i
                      class="fas fa-tags mr-1"></i> {{ tags.name }}
                  </span>
                </div>
                <div v-else><small>Sem tags</small></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from "@/components/posts/PostCard";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import PostList from "@/components/posts/PostList";
import MasterFilter from "@/components/Filters/MasterFilter";

export default {
  components: {
    PostCard,
    Multiselect,
    BreadCrumb,
    PostList,
    MasterFilter
  },

  data() {
    return {
      selectedTags: [],
      selectedUsers: [],
      isEditing: false,
      tempPost: {},
      search: "",
      starts_at: null,
      ends_at: null,
      isSearching: false,
      postFromPaginate: [],
      navigationPaginate: [],
    };
  },

  mounted() {
    // this.LOAD_POSTS();
    // this.LOAD_TAGS();
    // this.LOAD_USERS();
    if (this.posts && !this.posts.length) {
      // this.$event.$emit("page_loaded", { isLoading: true });
      this.LOAD_POSTS().then(r => {
        // this.$event.$emit("page_loaded", { isLoading: false });
      });
    }
    this.VIEW_POST({});
  },

  watch: {
    posts: {
      deep: true,
      immediate: false,
      handler() {
        this.callbackStartPaginator()
      }
    }
  },

  computed: {
    ...mapGetters(["posts", "tags", "users", "post"])
  },

  methods: {
    ...mapActions([
      "LOAD_POSTS",
      "GET_POST",
      "LOAD_TAGS",
      "LOAD_USERS",
      "VIEW_POST",
      "UPDATE_POST"
    ]),

    open(post) {
      // console.log("post");
      post.video = { path: "" };
      this.GET_POST(post);
    },
    searchFilter() {
      let data = {
        search: this.search,
        tags: this.selectedTags.map(tag => tag.id) || null,
        authors: this.selectedUsers.map(user => user.id) || null,
        starts_at: this.starts_at,
        ends_at: this.ends_at
      };
    },
    editPost() {
      this.isEditing = true;
      this.tempPost = this.post;
      this.tempPost.active = parseInt(this.tempPost.active) == 1 ? true : false;
    },
    reset() {
      this.tempPost = {};
      this.isEditing = false;
    },
    updatePost() {
      let data = this.tempPost;
      data.active = data.active == true ? 1 : 0;
      this.$toast.info(" ", "Atualizando post!", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000
      });
      this.UPDATE_POST(data).then(response => {
        if (response.status == 200) {
          this.$toast.success(" ", "Post atualizado.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          });
          this.reset();
        }
      });
    },

    /**
     * Filter a list of data with bases on perPage and fromPage params
     * @param {number} perPage Receive the total of items that will showed per page
     * @param {*} fromPage Receive the position from where the data will filtered
     */
    setPaginator(perPage = 16, fromPage = 1, toPage = 2, pageID = -1) {
      try {
        const dataList = this.posts
        let itemsPerPage = []

        if (!dataList || dataList?.length <= 0) return

        if (perPage >= dataList?.length) {
          perPage = dataList?.length || 3
        }

        itemsPerPage = dataList.slice((fromPage - 1), (toPage - 1))

        this.postFromPaginate = itemsPerPage
        this.getPage(pageID)
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Mount the UI of to paginate with base on total of item per page
     *
     * @param {number} perPage Receive the total of item that will showed per page
     * @return {*} true or undefined
     */
    setPageNavigator(perPage = 3) {
      try {
        const dataList = this.posts
        this.navigationPaginate = []

        if (!dataList || dataList?.length <= 0) return

        let totalPages = perPage >= dataList.length || perPage <= 0 ? 1 : Math.round(dataList.length / perPage)

        if (perPage > 1 && (perPage + totalPages) === dataList.length) {
          perPage = perPage + 1
        }

        for (let j = 1; j <= totalPages; j++) {
          const paginatedID = j > 1 ? (j + perPage) : j
          const pageEndRaw = (j * perPage) + 1
          const pageEnd = (pageEndRaw - 1) > dataList.length ? dataList.length + 1 : pageEndRaw

          const pageStartRaw = ((pageEnd - 1) - perPage) + 1
          const pageStart = pageStartRaw > dataList.length ? dataList.length + 1 : pageStartRaw

          if (j <= dataList.length) {
            this.navigationPaginate.push({
              id: paginatedID,
              perPage: perPage,
              page: j,
              pageEnd,
              pageStart
            })
          }
        }

        console.log('TOMA NO CU', perPage)
        return true
      } catch (error) {
        console.log(`handlePaginator: ${error?.message}`, error)
      }
    },

    /**
     *  Get a list of data from a unique page
     * @param {*} pageID The pageID from page target
     * @param {*} fromArrows If was from arrows left/right
     */
    getPage(pageID = 1, fromArrows = false, arrowStart = 1, arrowEnd = 1) {
      try {
        const elementsPerPageRaw = document.querySelectorAll(".perPage")
        const elementsPerPageToFocus = document.querySelector(`#perPageID_${pageID}`)
        const elementsPerPageToFocused = document.querySelector(`.perPageInFocus`)

        if (pageID <= 0 || !elementsPerPageToFocus || !elementsPerPageRaw) return

        const elementsPerPageParsed = Array.from(elementsPerPageRaw)

        if (!elementsPerPageParsed || elementsPerPageParsed.length <= 0) return

        elementsPerPageParsed?.forEach(element => {
          if (element?.classList?.contains("perPageInFocus")) element?.classList?.remove("perPageInFocus")
        })

        elementsPerPageToFocus?.classList?.add("perPageInFocus")

        const perPage = parseInt(elementsPerPageToFocus?.getAttribute("perPage") || '16')
        const pageEnd = parseInt(elementsPerPageToFocus?.getAttribute("pageEnd") || '16')
        const pageStart = parseInt(elementsPerPageToFocus?.getAttribute("pageStart") || '16')

        if (fromArrows) {
          console.log('getPage', arrowStart, arrowEnd)
          this.setPaginator(perPage, arrowStart, arrowEnd)
        } else {
          this.setPaginator(perPage, pageStart, pageEnd)
        }
      } catch (error) {
        // do anything
      }
    },

    /**
     * Callback to use only on Watch
     */
    callbackStartPaginator() {
      try {
        const lengthDataList = this.posts.length <= 1 ? 1 : Math.round(this.posts.length / 2)

        if (this.setPageNavigator(lengthDataList)) {
          setTimeout(() => { this.getPage(1, false) }, 470)
        }
      } catch (error) {
        console.log('')
        throw error
      }
    },
  }
};
</script>

<style lang="scss">
.first-item {
  display: flex;

  .image {
    width: 120;
  }

  .description {
    margin-left: 1em;
  }
}
</style>
