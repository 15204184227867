<template>
  <div>
    <button
      class="edit-avatar"
      v-show="iconType === 'icon'"
      styles="border: none !important;"
      for="upload-image"
      @click="handlerGetImageFile(`image-card-upload-${typeFile}`)"
    >
    </button>
    <div class="image-upload" v-show="iconType === 'button'">
      <button
        for="upload-image"
        @click="handlerGetImageFile(`image-card-upload-${typeFile}`)"
      >
        <span v-if="imageUploadedRaw">
          <span :title="`Você vai enviar a imagem ${imageUploadedRaw.name}`">
            {{ imageUploadedRaw.name }}
          </span>
        </span>
        <span v-else> Carregar nova Imagem </span>
      </button>
      <input
        type="file"
        :id="`image-card-upload-${typeFile}`"
        @change="handlerParseImageFile"
        accept="image/png, image/jpg, image/jpeg"
      />
     <img loading="lazy"
        :src="imageUploaded"
        alt="Imagem carregada"
        v-if="imageUploaded.trim() !== ''"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    typeFile: {
      default: 'document',
      require: true,
    },
    iconType: {
      default: 'icon',
      require: false,
    }
  },
  data() {
    return {
      imageUploaded: '',
      imageUploadedRaw: undefined,
      fileReader: null,
    }
  },
  mounted() {
    this.fileReader = new FileReader()
  },
  watch: {
    imageUploaded: {
      deep: false,
      immediate: true,
      handler(val) {},
    },
    fileReader: {
      deep: true,
      immediate: true,
      handler(reader) {
        if (reader) {
          reader.addEventListener(
            'load',
            function () {
              this.$emit('imageUploaded', {
                data: reader.result,
                dataRaw: { ...this.imageUploadedRaw },
                type: this.typeFile,
              })
            }.bind(this),
            false
          )
        }
      },
    },
  },
  methods: {
    handlerGetImageFile(elementID) {
      try {
        const elementInputFile = document.querySelector(`#${elementID}`)

        if (!elementInputFile) return

        if (elementInputFile?.type !== 'file') return

        elementInputFile?.click()
      } catch (error) {
        ////console.log(error?.message)
        this.$toast.info('Tente novamente')
      }
    },
    handlerParseImageFile(fileRaw) {
      try {
        const imageRaw = fileRaw?.target?.files?.[0]

        this.imageUploadedRaw = {
          lastModified: imageRaw?.lastModified,
          lastModifiedDate: imageRaw?.lastModifiedDate,
          name: imageRaw?.name,
          size: imageRaw?.size,
          type: imageRaw?.type,
          webkitRelativePath: imageRaw?.webkitRelativePath,
        }

        this.fileReader.readAsDataURL(imageRaw)
      } catch (error) {
        this.$toast.info('Tente novamente')
      }
    },
    setImageUploadedState(value) {
      this.imageUploaded = value
    },
  },
}
</script>
