<template>
  <div class="card master-filter-container">
    <div class="card-body">
      <div class="form-group">
        <div class="d-flex justify-content-between" style="gap: 1rem">
          <label for="">Filtros</label>
          <label @click="reset()" class="text-danger pointer">Limpar filtros</label>
        </div>
        <input class="form-control" type="text" placeholder="Pesquisar" v-model="search" :disabled="isSearching" />
        <small v-if="!smallLabel">Pesquise por título ou por alguma frase.</small>
        <small v-else>{{ smallLabel }}</small>
      </div>
      <hr v-if="!shouldHide('categories')" />
      <div class="form-group d-flex flex-wrap" v-if="!shouldHide('categories')">
        <label for="selectTagsFilter">Categorias</label>
        <!-- <toggle-button
               class="ml-auto"
                :width="50"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC'
                }"
                :labels="{ checked: 'E', unchecked: '  OU' }"
              /> -->
        <!-- v-model="tempOperatorChangeExample.active" -->
        <multiselect class="w-100" id="selectTagsFilter" :disabled="isSearching" v-model="selectedCategories"
          :options="categoriesHydrate" :close-on-select="true" :clear-on-select="false" :hide-selected="true"
          :preserve-search="true" placeholder="Selecione suas categorias" label="name" track-by="id" :multiple="true">
        </multiselect>
      </div>
      <hr v-if="!shouldHide('tags')" />
      <div class="form-group d-flex flex-wrap" v-if="!shouldHide('tags')">
        <label for="selectTagsFilter">Tags</label>
        <!-- <toggle-button
               class="ml-auto"
                :width="50"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC'
                }"
                :labels="{ checked: 'E', unchecked: '  OU' }"
              /> -->
        <!-- v-model="tempOperatorChangeExample.active" -->
        <multiselect class="w-100" id="selectTagsFilter" :disabled="isSearching" v-model="selectedTags" :options="tags"
          :close-on-select="true" :clear-on-select="false" :hide-selected="true" :preserve-search="true"
          placeholder="Selecione suas tags" label="name" track-by="id" :multiple="true">
        </multiselect>
      </div>
      <hr v-if="!shouldHide('users')" />
      <div class="form-group d-flex flex-wrap" v-if="!shouldHide('users')">
        <label for="selectUsersFilter">Clientes</label>
        <!-- <toggle-button
               class="ml-auto"
                :width="50"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC'
                }"
                :labels="{ checked: 'E', unchecked: '  OU' }"
              /> -->
        <multiselect v-if="usersList" id="selectUsersFilter" :disabled="isSearching" v-model="selectedUsers"
          :options="usersList" :close-on-select="true" :clear-on-select="false" :hide-selected="true"
          :preserve-search="true" placeholder="Selecione os autores" label="name" track-by="id" :multiple="true">
        </multiselect>
      </div>
      <hr v-if="!shouldHide('categories')" />
      <div class="form-group d-flex flex-wrap" v-if="!shouldHide('roles')">
        <label for="selectTagsFilter">Roles</label>
        <!-- <toggle-button
               class="ml-auto"
                :width="50"
                :switch-color="{
                  checked: '#fff',
                  unchecked: '#fff',
                  disabled: '#CCCCCC'
                }"
                :labels="{ checked: 'E', unchecked: '  OU' }"
              /> -->
        <!-- v-model="tempOperatorChangeExample.active" -->
        <multiselect class="w-100" id="selectTagsFilter" :disabled="isSearching" v-model="selectedRoles"
          :options="roles.filter((t) => t.guard_name === 'api')" :close-on-select="true" :clear-on-select="false"
          :hide-selected="true" :preserve-search="true" placeholder="roles" label="name" track-by="id" :multiple="true">
        </multiselect>
      </div>
      <hr v-if="!shouldHide('dates')" />
      <div class="form-group" v-if="!shouldHide('dates')">
        <label for="">Data</label>
        <div class="form-hidden-input">
          <small>Inicio</small>
          <input class="form-control" type="date" :disabled="isSearching" v-model="starts" />
        </div>
        <div class="form-hidden-input">
          <small>Fim</small>
          <input class="form-control" type="date" :disabled="isSearching" v-model="ends" />
        </div>
        <div class="d-none form-date-group">
          <div>
            <small>Inicio</small>
            <input class="form-control" type="date" :disabled="isSearching" v-model="starts" />
          </div>
          <div>
            <small>Fim</small>
            <input class="form-control" type="date" :disabled="isSearching" v-model="ends" />
          </div>
        </div>
      </div>
      <div class="form-group" v-if="!shouldHide('active')">
        <label for="">Status</label>
        <select class="form-control" v-model="status">
          <option value="0">Inátivo</option>
          <option value="1">Publicado</option>
          <option value="2" v-if="$route.name !== 'product.list'">Rascunho </option>
          <option value="null" selected>Selecione</option>
        </select>
      </div>
      <div class="form-group" v-if="!shouldHide('status')">
        <label for="">Status</label>
        <StatusSelect v-model="status" @change="setStatus" :disabled="isSearching" />
        <!-- <select class="form-control" v-model="status" :disabled="isSearching">
          <option value="1">Aguardando Pagamento</option>
          <option value="2">Pedido Aceito</option>
          <option value="3">Pagamento não Aprovado</option>
          <option value="4">Pedido Entregue</option>
          <option value="6">Pedido Cancelado</option>
          <option value="7">Pedido em Trânsito</option>
          <option value="8">Pedido sendo Separado</option>

          <option :value="null" selected disabled>Selecione</option>
        </select> -->
      </div>
      <LoaderButton b_class="btn-primary" :wasClicked="isSearching" message="Pesquisando...">
        <button class="btn btn-primary btn-block" :disabled="isSearching" @click="filterQueries()">
          Pesquisar
        </button>
      </LoaderButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import LoaderButton from "@/components/buttons/LoaderButton";
import StatusSelect from "@/components/status/StatusSelect";
export default {
  components: { Multiselect, LoaderButton, StatusSelect },
  data() {
    return {
      isSearching: false,
      search: "",
      selectedRoles: [],
      selectedTags: [],
      selectedCategories: [],
      selectedUsers: [],
      starts: "",
      ends: "",
      status: null,
      categoriesHydrate: []
    };
  },
  watch: {
    categories: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          console.log('Watch categories', val);
          const categoriesHydrate = []
          val.forEach((category, _index) => {
            if (category.category_id === null && category.category_main === null) {
              if (category?.sub_categories.length > 0) {
                category?.sub_categories?.forEach(subCategory => {
                  const cachedSubCategory = { ...subCategory }
                  cachedSubCategory.name = `${category.name} > ${cachedSubCategory.name}`
                  categoriesHydrate.push(cachedSubCategory)
                })
              } else {
                if (category.active === 1) categoriesHydrate.push(category)
              }

            }
          })

          //console.log('Watch categories hydrated',categoriesHydrate)
          this.categoriesHydrate = categoriesHydrate
        } catch (error) {
          // do anything
        }
      }
    }
  },
  computed: {
    ...mapGetters(["tags", "categories", "usersList", "last_search", "roles"]),

    setFixed() {
      if (this.isFixed) {
        return "position:fixed; width:20%";
      } else {
        return "";
      }
    },

    filterActionsMap() {
      return {
        posts: {
          load: (cb) => cb("LOAD_POSTS"),
          search: (cb) => cb("SEARCH_POST"),
          model: "Post",
        },
        products: {
          load: (cb) => cb("LOAD_PRODUCTS"),
          search: (cb) => cb("SEARCH_PRODUCT"),
          model: "Product",
        },
        orders: {
          load: (cb) => cb("LOAD_ORDERS"),
          search: (cb) => cb("SEARCH_ORDER"),
          model: "Order",
        },
        users: {
          load: (cb) => cb("LOAD_USERS"),
          search: (cb) => cb("SEARCH_USER"),
          model: "User",
        },
      };
    },
  },
  props: {
    page: {
      required: false,
    },
    rules: {
      required: false,
    },
    hideInputs: {
      required: false,
      default: () => [],
    },
    smallLabel: {
      required: false,
      default: () => null,
    },
    isFixed: {
      required: false,
      default: true,
    },
  },
  mounted() {
    // this.QUICK_LIST();
  },
  methods: {
    ...mapActions([
      "SEARCH_POST",
      "SET_PARAMS",
      "SEARCH_PRODUCT",
      "LOAD_PRODUCTS",
      "SEARCH_ORDER",
      "LOAD_ORDERS",
      "LOAD_USERS",
      "SEARCH_USER",
      "QUICK_LIST",
    ]),

    setStatus(data) {
      this.status = data;
    },

    shouldHide(input) {
      return this.hideInputs.some((hide) => hide == input);
    },

    reset() {
      this.search = "";
      this.selectedTags = [];
      this.selectedCategories = [];
      this.selectedUsers = [];
      this.starts = "";
      this.ends = "";
      this.status = null;
      this.isSearching = false;
      // console.log(
      //   "!this.$masterHelper.isEmpty(this.last_search)",
      //   !this.$masterHelper.isEmpty(this.last_search)
      // );
      this.$pace.restart();
      this.$pace.start();

      console.log('Master Filter', this.last_search, this.page.toLowerCase())

      this.filterQueries()

      this.$emit('default-select', true)
    },

    filterQueries() {
      this.isSearching = true;

      let { model, search } = this.filterActionsMap[this.page.toLowerCase()];
      let obj = this._filterSearchBuilder(model);
      //console.log("MASTER FILTER PARAMS", obj);
      search((action) => {
        this[action](obj).then((res) => {
          this.isSearching = false;
          //console.log("MASTER FILTER", res);
          if (res.status === 200) {
            //console.log("ok works");
          }
        });
      });

      //the switch below is only for example and backup if logic above stops working

      // switch (this.page) {
      //   case "Posts":
      //     obj = this._filterSearchBuilder("Post");
      //     this.SEARCH_POST(obj).then(data => {
      //       this.isSearching = false;
      //       if (data.status == 200) {
      //         console.log("ok works");
      //       }
      //     });
      //     break;

      //   case "Products":
      //     obj = this._filterSearchBuilder("Product");
      //     this.SEARCH_PRODUCT(obj).then(data => {
      //       this.isSearching = false;
      //       if (data.status == 200) {
      //         console.log("ok works");
      //       }
      //     });
      //     break;

      //   case "Orders":
      //     obj = this._filterSearchBuilder("Order");
      //     this.SEARCH_ORDER(obj).then(data => {
      //       this.isSearching = false;

      //       if (data.status == 200) {
      //         console.log("ok works");
      //       }
      //     });

      //     break;
      //   default:
      //     break;
      // }
    },
    _filterSearchBuilder(model) {
      //Exemple of rules, must come by prop
      // let rules = {
      //   searchable: ["title", "sub_title", "body"],
      //   relationships: ["tags", "categories", "media", "user"]
      // };
      let search = this._searchParamBuilder(this.rules.searchable);
      let relations = this._searchFilterRelations(this.rules.relationships);

      // console.log("the final query is ...........", {
      //   search: search,
      //   relationships: relations,
      // });
      let data = {
        params: {
          search: search,
          relationships: relations,
        },
        model: model,
      };
      this.SET_PARAMS({});
      return data;
    },
    // _newParamObject(operator, type, val = null)
    //@param operator = where | orWhere | between
    //@param type = > | < | like | whereBetween
    //@param val = Any
    _newParamObject(operator, type, val = null, scope = null) {
      let obj = {
        type: type,
        operator: operator,
        value: !val ? this.search : val,
      };

      if (scope) {
        obj.scope = scope;
      }

      return obj;
    },
    _searchParamBuilder(keys) {
      //Builded params
      let params = {};
      if (this.status !== null) {
        let status = this._newParamObject("=", "where", this.status);

        if (!this.shouldHide("active")) {
          params.active = status;
        }

        if (!this.shouldHide("status")) {
          params.status = status;
        }
      }
      if (this.starts.length || this.ends.length) {
        let range = [];
        let date;

        if (this.starts.length) {
          range.push(this.starts + " 00:00:00");
        }

        if (this.ends.length) {
          range.push(this.ends + " 23:59:59");
        }

        if (range.length > 1) {
          date = this._newParamObject("between", "whereBetween", range);
          // params.push({ created_at: date });
          params.created_at = date;
        } else {
          date = this._newParamObject(">", "where", range);
          // params.push({ created_at: date });
          params.created_at = date;
        }
      }

      if (keys.length && this.search.length) {
        keys.forEach((item, index) => {
          if (index == 0) {
            let first = this._newParamObject("like", "where");
            // params.push({ [item]: first });
            params[item] = first;
          } else {
            let extra = this._newParamObject("like", "orWhere");
            // params.push({ [item]: extra });
            params[item] = extra;
          }
        });
      }
      //console.log("the builded query - - - - - - - - ", params);

      return params;
    },
    _searchFilterRelations(relationship_list) {
      let relParams = {};

      //Build query if any user is selected
      if (this.selectedUsers.length) {
        let userRel = this._newParamObject(
          "=",
          "whereIn",
          { "users.id": this.selectedUsers.map((u) => u.id) },
          "global"
        );
        relParams.user = userRel;
      }

      //Build query if any category is selected
      if (this.selectedCategories.length) {
        let catRel = this._newParamObject(
          "=",
          "whereIn",
          { "categories.id": this.selectedCategories.map((c) => c.id) },
          "global"
        );
        relParams.categories = catRel;
      }

      if (this.selectedRoles.length) {
        let roleRel = this._newParamObject(
          "=",
          "whereIn",
          { "roles.id": this.selectedRoles.map((t) => t.id) },
          "global"
        );
        relParams.roles = roleRel;
      }
      //Build query if any tag is selected
      if (this.selectedTags.length) {
        let tagRel = this._newParamObject(
          "=",
          "whereIn",
          { "tags.id": this.selectedTags.map((t) => t.id) },
          "global"
        );
        relParams.tags = tagRel;
      }

      let keys = Object.keys(relParams);

      //console.log("relParams relParams keys", keys);

      Object.assign(relParams, relationship_list);

      //console.log("relParams relParams relParams", relParams);

      return relParams;
    },
  },
};
</script>

<style>

</style>
