<template>
  <div
    class="modal fade"
    id="ViewUserModal"
    tabindex="-1"
    aria-labelledby="ViewUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ViewUserModalLabel">Novo usuario</h5>
          <button
            :disabled="isSaving"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="">Nome</label>
            <input
              id="name"
              type="text"
              class="form-control"
              v-model="user.name"
            />
            <small v-if="shortNameErr" class="text-danger"
              >Nome esta curto, o minimo é 6 digitos.</small
            >
          </div>
          <div class="form-group">
            <label for="">Email</label>
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="user.email"
            />
          </div>
          <div class="form-group">
            <label for="">Senha</label>
            <input
              id="password"
              type="password"
              class="form-control"
              v-model="user.password"
            />
          </div>
          <div class="form-group">
            <label for="">Tipo da conta</label>
            <select class="form-control" v-model="user.roles" id="roles">
              <option v-for="(role,index) in roles" :key="`${index}`">
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            :disabled="isSaving"
            type="button"
            class="btn btn-secondary slide-in-blurred-top"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <LoaderButton
            b_class="btn-primary slide-in-blurred-top ml-3"
            :wasClicked="isSaving"
          >
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="createUser()"
            >
              Salvar
            </button>
          </LoaderButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  components: { LoaderButton },
  data() {
    return {
      isSaving: false,
      shortNameErr: false,
      user: {
        roles: null,
        name: null,
        email: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["CREATE_USER"]),
    async createUser() {
      if (this._validation()) {
        try {
          this.isSaving = true;
          this.$toast.info(" ", `Criando ...`, {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          await this.CREATE_USER(this.user).then((response) => {
            if (response.code === 201) {
              this.$toast.info(" ", `Usuário criado`, {
                icon: "icon-person",
                position: "topRight",
                timeout: 2000,
              });
            }
          });
          this.$forceUpdate();
          $("#ViewUserModal").modal("hide");
          this.reset();
        } catch (error) {
          //console.log("error createUser", error);
        }
      }
    },
    reset() {
      this.user = {
        roles: null,
        name: null,
        email: null,
      };
      this.isSaving = false;
    },

    _validation() {
      let rules = {
        name: {
          required: true,
        },
        email: {
          required: true,
        },
        password: {
          required: true,
        },
        roles: {
          required: true,
        },
      };

      let keys = Object.keys(this.user);

      //console.log("keys", keys);

      let error = [];

      keys.forEach((key) => {
        if (rules[key] && rules[key].required) {
          if (this.user[key] == null || !this.user[key].length) {
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
          if (this.user.name && this.user.name.length < 6) {
            this.shortNameErr = true;
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          } else {
            this.shortNameErr = false;
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
