<template>
  <h5>
    <span class="badge" :class="getColor(status)">{{
      getStatusName(status)
    }}</span>
  </h5>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      colors: [
        { id: 1, color: "badge-warning" },
        { id: 2, color: "badge-danger" },
        { id: 3, color: "badge-danger" },
        { id: 4, color: "badge-primary" },
        { id: 5, color: "badge-primary" },
        { id: 6, color: "badge-warning" },
        { id: 7, color: "badge-success" }
      ]
    };
  },
  props: ["status"],
  computed: {
    ...mapGetters(["status_list"])
  },
  mounted() {
    this.LOAD_STATUS();

    this.$on('default-select', payload => document.querySelector('#status_selector').options[0].selected = true)
  },
  methods: {
    ...mapActions(["LOAD_STATUS"]),
    getColor(status) {
      return this.colors.find(c => c.id == status).color || "-";
    },
    getStatusName(status) {
      if (!status) return "-";
      let sta = this.status_list.find(c => c.id == status);
      return sta.name || "-";
    }
  }
};
</script>

<style></style>
