import axios from "axios";
import Pace from "pace-js";
export default () => {
  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "bearer ".concat(USER_TOKEN);
  const BASE_URL = process.env.VUE_APP_BACK_BASE_URL + "api/";


  const api = axios.create({
    baseURL: process.env.VUE_APP_BACK_BASE_URL + "api/",
    headers: {
      "cache-control": "no-cache, public, must-revalidate, max-age=2592000",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: AuthStr
    }
  });

  api.interceptors.request.use(config => {
    Pace.start();
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    return config;
  });

  api.interceptors.response.use(
    response => {
      if (response.status === 200 || response.status === 201) {
        Pace.stop();
        // console.log('KROOMA AXIO RESPONSE INTERCEPTORS', response.data)
        return Promise.resolve(response);
      } else {
        Pace.stop();
        return Promise.reject(response);
      }
    },

    error => {
      if (error?.response?.status) {
        //TODO do something :)
        const URI = error?.request?.responseURL?.split("api/")
        switch (error?.response?.status) {
          case 400:
            break;

          case 401:
            if (URI[URI.length - 1] !== 'get/authenticated' && URI[URI.length - 1] !== 'check_user') {
              window.location.href = "/login"
            }

            break;
          case 403:
            // router.replace({
            //   path: "/login",
            //   query: { redirect: router.currentRoute.fullPath }
            // });
            break;
          case 404:
            // alert("page not exist");
            break;
          case 502:
            // setTimeout(() => {
            //   router.replace({
            //     path: "/login",
            //     query: {
            //       redirect: router.currentRoute.fullPath
            //     }
            //   });
            // }, 1000);
            break;
        }
        Pace.restart();
        return Promise.reject(error.response);
      }
    }
  );

  return api;
};
