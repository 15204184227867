import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    cupons: [],
    cupom: null
  },
  getters: {
    CUPONS: state => state.cupons,
    CUPOM: state => state.cupom,
  },
  mutations: {
    SET_CUPONS(state, cupons) {
      state.cupons = cupons;
    },
    SET_CUPOM(state, cupom) {
      state.cupom = cupom;
    }
  },
  actions: {
    CREATE_CUPOM: async (context, payload) => {
      try {
        const payloadFormatted = {
          name: payload?.name || '',
          description: payload?.description || '',
          discount: payload?.discount || 0.0,
          condition: {
            amountCart: payload?.amountCart || {},
            amountProduct: payload?.amountProduct || {},
            quantityCart: payload?.quantityCart || {},
            quantityProduct: payload?.quantityProduct || {},
            categoryProduct: payload?.categoryProduct || {},
            product: payload?.product || {},
            installments: payload?.installments || {},
          },
          quantity: payload?.quantity || 0,
          active: Number(payload?.active) || 0,
          isPercent: Number(payload?.isPercent) || 0,
          started: payload.date?.start || '',
          ended: payload.date?.end || '',
          cupom_type: payload.type || '',
          code: payload.code || '',
        }

        let response = await axios().post("/coupons/store", payloadFormatted);

        if (response.data?.code !== 201) {
          context.commit("SET_CUPOM", null);
          return response;
        }

        context.commit("SET_CUPOM", response.data?.data);
        context.dispatch("SET_CUPONS", response.data?.data);
        return response;
      } catch (error) {
        console.log('')
        throw error;
      }
    },

    UPDATE_CUPOM: async (context, payload) => {
      try {
        const payloadFormatted = {
          name: payload?.name || '',
          description: payload?.description || '',
          discount: payload?.discount || 0.0,
          condition: {
            amountCart: payload?.amountCart || {},
            amountProduct: payload?.amountProduct || {},
            quantityCart: payload?.quantityCart || {},
            quantityProduct: payload?.quantityProduct || {},
            categoryProduct: payload?.categoryProduct || {},
            product: payload?.product || {},
            installments: payload?.installments || {},
          },
          quantity: payload?.quantity || 0,
          active: Number(payload?.active) || 0,
          isPercent: Number(payload?.isPercent) || 0,
          started: payload.date?.start || '',
          ended: payload.date?.end || '',
          cupom_type: payload.type || '',
          code: payload.code || '',
        }

        let response = await axios().post(`/coupons/update/${payload?.id || -1}`, payloadFormatted);

        if (response.data?.code !== 201) {
          return response;
        }

        context.commit("SET_CUPOM", response.data?.data);
        return response;
      } catch (error) {
        console.log('')
        throw error;
      }
    },

    DESTROY_CUPOM: async ({ state, commit }, payload) => {
      try {

        let response = await axios().delete(`/coupons/delete/${payload}`);

        if (response.data?.code !== 201) {
          commit("SET_CUPOM", null);
          return response;
        }

        // console.log(state.cupons, state.cupons.filter(cupom => cupom.id !== payload), payload)

        let cuponsFiltered = []

        if (state.cupons.length > 1) {
          cuponsFiltered = state.cupons.filter(cupom => cupom.id !== payload)
        } else {
          if (state.cupons[0].id === payload) cuponsFiltered = []
        }

        commit('SET_CUPONS', cuponsFiltered)

        return response;
      } catch (error) {
        console.log('')
        throw error;
      }
    },

    SHOW_CUPOM: async (context, payload) => {
      try {
        let response = await axios().get(`/coupons/show/${payload}`);

        if (response.data?.code !== 200) {
          context.commit("SET_CUPOM", null);
          return response;
        }

        context.commit("SET_CUPOM", response.data?.data);
        return response;
      } catch (error) {
        console.log('')
        throw error;
      }
    },

    INDEX_CUPOM: async (context) => {
      try {
        let response = await axios().get("/coupons/all");

        if (response.data?.code !== 200) {
          context.commit("SET_CUPONS", []);
          return response
        }

        context.commit("SET_CUPONS", response.data?.data);
        return response
      } catch (error) {
        console.log('')
        throw error;
      }
    },

    SET_CUPONS: async ({ commit, state }, payload) => {
      try {
        if (!payload) return

        let cuponsFiltered = []

        if (state.cupons.length > 1) {
          cuponsFiltered = state.cupons.filter(cupom => cupom.code !== payload?.code) || []
        } else if (state.cupons.length === 1) {
          cuponsFiltered = state.cupons[0].code !== payload?.code ? [] : state.cupons
        }
        cuponsFiltered.push(payload)

        console.warn('SET_CUPONS', state.cupons[0]?.code !== payload?.code)

        commit('SET_CUPONS', cuponsFiltered)
      } catch (error) {
        console.log('')
        throw error
      }
    },

    PARSE_CUPOM: (_context, paylaod) => {
      try {
        if (!paylaod) return {}

        return {
          name: payload?.name || '',
          description: payload?.description || '',
          discount: payload?.discount || 0.0,
          condition: {
            amountCart: payload?.amountCart || {},
            amountProduct: payload?.amountProduct || {},
            quantityCart: payload?.quantityCart || {},
            quantityProduct: payload?.quantityProduct || {},
            categoryProduct: payload?.categoryProduct || {},
            product: payload?.product || {},
            installments: payload?.installments || {},
          },
          quantity: payload?.quantity || 0,
          active: payload?.active || 0,
          started: payload.date?.start || '',
          ended: payload.date?.end || '',
        }
      } catch (error) {
        console.log('')
        throw error
      }
    }

  }
};
