<template>
  <transition name="slide-in" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {

}
</script>

<style>

</style>
