import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    tags: [],
    tag: {},
    tagBackup: [],
    tagColorsList: [
      { backgroundColor: "#89C45A", color: "#062A12" },
      { backgroundColor: "#04240E", color: "#FFF" },
      { backgroundColor: "#062A12", color: "#FFF" },
      { backgroundColor: "#2B3234", color: "#FFF" },
      { backgroundColor: "#EE9F2E", color: "#FFF" },
      { backgroundColor: "#C6D634", color: "#1D9A44" },
      { backgroundColor: "#1D9A44", color: "#FFFFFF" },

      { backgroundColor: "#E46D35", color: "#FFFFFF" },

      { backgroundColor: "#EE9F2E", color: "#0B431D" },
      { backgroundColor: "#0B431D", color: "#C6D634" },
      { backgroundColor: "#FFFFFF", color: "#0B431D" }
    ]
  },
  getters: {
    tags: state => state.tags,
    tagsPaginate: state => state.tags,
    tag: state => state.tag,
    tagBackup: state => state.tagBackup,
    tagColorsList: state => state.tagColorsList
  },
  mutations: {
    LOAD_TAGS(state, tags) {
      state.tags = tags;
    },
    GET_TAG(state, tag) {
      state.tag = tag;
    },
    NEW_TAG(state, tag) {
      state.tags.unshift(tag);
    },
    UPDATE_TAG(state, tag) {
      let index = state.tags.findIndex(t => {
        return t.id === tag.id;
      });
      //console.log(`old name: ${state.tags[index].name}, new name: ${tag.name}`);
      state.tags[index] = tag;
    },
    REMOVE_TAG(state, tag) {
      let index = state.tags.findIndex(item => item.id === tag.id);
      state.tags.splice(index, 1);
    },
    FILTER_TAGS(state, tags) {
      if (!state.tagBackup.length) state.tagBackup = [...state.tags];
      state.tags = tags;
    },
    CLEAN_FILTER_TAGS(state) {
      state.tags = state.tagBackup;
      state.tagBackup = [];
    }
  },
  actions: {
    CLEAN_FILTER_TAGS: async context => {
      context.commit("CLEAN_FILTER_TAGS");
    },
    FILTER_TAGS: async (context, payload) => {
      context.commit("FILTER_TAGS", payload);
    },
    PAGINATE_LOAD_TAGS: async (context, payload) => {
      try {
        const response = await axios().get(payload);
        //console.log("tags loaded", response);

        if (response.data.code !== 200) {
          context.commit("LOAD_TAGS", []);
          return
        }

        context.commit("LOAD_TAGS", response.data.data);

        return response.data;
      } catch (error) {
       // console.log("LOAD_TAGS:error", error);
        return error;
      }
    },
    LOAD_TAGS: async (context, payload) => {
      try {
        const response = await axios().get("/tags");
        //console.log("tags loaded", response);

        if (response.data.code !== 200) {
          context.commit("LOAD_TAGS", []);
          return
        }

        context.commit("LOAD_TAGS", response.data.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_TAGS:error", error);
        return error;
      }
    },
    VIEW_TAG: (context, payload) => {
      try {
        context.commit("GET_TAG", payload);
      } catch (error) {
        //console.log("GET_TAG:error", error);
        return error;
      }
    },
    CREATE_TAG: async (context, payload) => {
      try {
        const response = await axios().post("/tags/store", payload);
       // console.log("tag created", response.data);

        if (response.data.code !== 201) {
          context.commit("NEW_TAG", {});
          return
        }

        context.commit("NEW_TAG", response.data.data);
        return response.data;
      } catch (error) {
        //console.log("LOAD_TAGS:error", error);
        return error;
      }
    },
    GET_TAG: async (context, payload) => {
      try {
        const response = await axios().get("/tags/show/" + payload.uuid);
       // console.log("tags loaded", response);
        if (response.data.code !== 200) {
          context.commit("GET_TAG", []);
          return
        }

        context.commit("GET_TAG", response.data.data);
        return response.data;
      } catch (error) {
       // console.log("LOAD_TAGS:error", error);
        return error;
      }
    },
    DELETE_TAG: async (context, payload) => {
      try {
        const response = await axios().post(
          "/tags/delete/" + payload.id,
          payload
        );

        if (response.data.code !== 201) {
          context.commit("REMOVE_TAG", {});
          return
        }

        context.commit("REMOVE_TAG", response.data.data);

        return response.data;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    },
    UPDATE_TAG: async (context, payload) => {
      try {
        let response = await axios().post(
          "/tags/update/" + payload.id,
          payload
        );

        if (response.data.code !== 201) {
          context.commit("UPDATE_TAG", {});
          return
        }

        context.commit("UPDATE_TAG", response.data.data);
        return response.data;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    },
    BULK_UPDATE_TAGS: async (context, payload) => {
      try {
        let response = await axios().post("/tags/bulk_update", payload);
        // context.commit("UPDATE_TAG", response.data.tag);
        return response.data;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    },
    BULK_DELETE_TAGS: async (context, payload) => {
      try {
        let ids = payload.map(item => item.id);

        let response = await axios().post("/tags/bulk_delete", ids);

        if (response.data.code !== 201) {
          context.commit("REMOVE_TAG", {});
          return
        }

        payload.forEach(tag => {
          context.commit("REMOVE_TAG", tag);
        });

        return response.data;
      } catch (error) {
       // console.log("UPDATE_TAG:error", error);
        return error;
      }
    }
  }
};
