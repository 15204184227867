<template>
  <div
    class="card hover"
    style="max-height:416px;"
    @click="viewInfo(item)"
    @dblclick="open()"
    :class="[{ 'soft-shadow': item.id == post.id }]"
  >
    <div style="width:100%;height:230px;">
     <img loading="lazy"
        style="max-height:100%; max-width:100%;"
        class="card-img-top"
        :src="_filterMedia(item, 'image')"
        alt="Card image cap"
      />
    </div>
    <div class="card-body ">
      <h4 class="card-title" :title="item.title">
        {{ item.title.substr(0, 30) }}
      </h4>
      <div class="hover-atention" v-if="item.summary">
        <p class="card-text" :title="item.summary.substr(0, 50)">
          {{ item.summary.substr(0, 30) }}...
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <small class="text-muted">Last updated 3 mins ago</small>
        <small class="text-info" v-if="item.active">Publicado</small>
        <small class="text-danger" v-else>Desativado</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["posts", "post"])
  },
  methods: {
    ...mapActions(["LOAD_POSTS", "VIEW_POST"]),
    viewInfo(post) {
      this.VIEW_POST(post);
    },
    open() {
      //console.log("post");
      this.$props.item.video = { path: "" };
      this.VIEW_POST(this.$props.item);
      this.$router.push({ path: `/posts/details/${this.$props.item.uuid}` });
    },
    _filterMedia(data, type) {
      let medias = data.medias || [];

      medias = medias.filter(media => media.type === type);

      if (medias.length) {
        let maped = medias.map(m => m.path);
        return this.$api + maped[0];
      } else {
        return "https://www.annesara.nl/wp-content/themes/claue/assets/images/placeholder.png";
      }
    }
  }
};
</script>

<style>
.card-img-top {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
</style>
