import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    visualizations: [],
    visualization: {}
  },
  getters: {
    visualizations: state => state.visualizations,
    visualization: state => state.visualization,
  },
  mutations: {
    LOAD_VISUALIZATION(state, visutalizationArr) {
      state.visualizations = visutalizationArr;
    }
  },
  actions: {
    LOAD_VISUALIZATION: async (context, payload) => {
      try {
        let response = await axios().get("/visualization/all", payload);

        if(response.data?.code !== 200) throw new Error(response.data?.message?.trim())

        if(response.data?.data?.length <= 0) throw new Error("The visualizations is empty")

        context.commit("LOAD_VISUALIZATION", response.data?.data || []);
        return response;
      } catch (error) {
        //console.log("LOAD_VISUALIZATION:error", error?.message);
        return error;
      }
    },
  }
};
