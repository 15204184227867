import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { masterHelper } from "./helpers/Master.js";
import PortalVue from "portal-vue";
import ToggleButton from "vue-js-toggle-button";
import VueIziToast from "vue-izitoast";
import config from "../src/APP_CONFIG.json";
import bus from "../src/helpers/eventBus";

import Pace from "pace-js";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);

dayjs.extend(timezone);

const _dayjs = dayjs;

import "popper.js";
import "bootstrap";
import "./assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js";

import "./assets/dist/animations.css";
import "./assets/dist/css/icons/font-awesome/css/fontawesome-all.css";

import "./assets/dist/css/style.css";
import "./assets/dist/css/master.scss";

import "vue-multiselect/dist/vue-multiselect.min.css";
import "izitoast/dist/css/iziToast.min.css";

//Styles
// src\assets\styles\animations.scss
import "./assets/styles/animations.scss";

import "./directives";

import "./assets/styles/center-simple.css";
import "./assets/styles/index.css";
import "./assets/styles/dashboard.scss";
import "./assets/styles/mobile/index.scss";

Vue.use(PortalVue);

Vue.use(ToggleButton);

Vue.use(VueIziToast);

console.log('Sentry DSN', process.env.VUE_APP_SENTRY_ENVRIONMENT)

// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN || "",
//   trackComponents: true,
//   maxBreadcrumbs: 50,
//   debug: true,
//   environment: process.env.VUE_APP_SENTRY_ENVRIONMENT || "development",
//   autoSessionTracking: true,
//   hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
//   beforeSend(event, _hint) {
//     if (event.exception) {
//       if (event.level === 'error') {
//         Sentry.showReportDialog(
//           {
//             eventId: event.event_id,
//             lang: "pt-br",
//             title: "Parece que ocorreu um erro :(",
//             subtitle: "Nosso time já foi notificado!",
//             subtitle2: "Abaixo você pode nos contar oque aconteceu!",
//             successMessage: "Muito obrigado pelo Feedback, vamos solucionar seu problema o mais rápido possível",
//             errorGeneric: "Infelizmente não foi possivel enviar seu Feedback, Por Favor Tente Novamente!",
//             errorFormEntry: "Alguns campos não foram preenchidos corretamente",
//             labelSubmit: "Enviar"
//           }
//         );
//       }
//     }
//     return event;
//   },
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [/^\//],
//     }),
//   ],
//   tracesSampleRate: 0.7,
// });

// Sentry.setTag("platform", "admin")

//=================
Vue.prototype.$config = config;

Vue.prototype.$event = bus;
Vue.prototype.$money = val => !isNaN(val) ? (!isNaN(parseFloat(val).toFixed(2)) ? parseFloat(val).toFixed(2) : 0.00) : 0.00;
Vue.prototype.$format = val => {
  // val = val.replace(".", ",");
  // split = val.split(",");

  val = Number(val).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });

  return val.replace("R$", "").trim();
};
Vue.prototype.$masterHelper = masterHelper;
Vue.prototype.$findImg = (m, p, urlOnly) => masterHelper.findImg(m, p, urlOnly);
Vue.prototype.$filterMedia = (data, type, position = "gallery") =>
  masterHelper.filterMedia(data, type, position);

Pace.options = {
  restartOnRequestAfter: true,
  document: false
};

Vue.prototype.$pace = Pace;

Vue.prototype.$api = process.env.VUE_APP_BACK_BASE_URL + "storage/";

Vue.prototype.$front = process.env.VUE_APP_FRONT_URL || "http://localhost:8081";

_dayjs().tz("America/Sao_Paulo");

Vue.prototype.$dateTime = d => _dayjs(d).format("DD/MM/YYYY [às] HH:MM");
Vue.prototype.$date = d => _dayjs(d).format("DD/MM/YYYY");
Vue.prototype.$dayJs = d => _dayjs((d = null));

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

//Not implemente yet
Vue.prototype.$DEBUG = true;

Vue.config.productionTip = false;
//is on
Vue.prototype.$USE_VARIATIONS = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
