<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-baseline justify-content-between">
          <hgroup>
            <h4>
              <strong>Dados pessoais</strong>
            </h4>
          </hgroup>
          <button class="btn btn-primary" @click="save('pages_laws')" :disabled="isSaving">
            {{
              isSaving ? 'Por Favor, aguarde!' : 'Salvar'
             }}
          </button>
        </div>

        <div class="col mt-5">
          <div class="col-12">
            <div
              class="
                d-flex
                flex-column
                p-2
                flex-fill flex-glow-1
                mt-5
                border border-light
              "
            >
              <h4>Dados de acesso</h4>
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-start
                  gap-1
                  flex-wrap
                  mt-5
                "
                style="gap: 1rem"
                id="personal_form"
              >
                <div class="form-group">
                  <label for="">
                    <strong>Senha</strong>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    id=""
                    v-model.lazy="personal_data.password"
                  />
                </div>
                <div class="form-group">
                  <label for="">
                    <strong>Senha de Confirmação</strong>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    name="password_confirmation"
                    id=""
                    v-model.lazy="personal_data.password_confirmation"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { VMoney } from "v-money";

// Components
import Upload from "@/components/imageUpload/Upload";
import Multiselect from "vue-multiselect";
import { VueEditor } from "vue2-editor";

// Mixins
import Settings from "@/mixins/Settings.vue";

// Utils
import { profileSchema } from "@/utils/validations/profile/schema.js";
import { handlerValidation } from "@/utils/validations/";

import { handlerOrganizeForm, handlerClearForm } from "@/utils/functions/";


export default {
  directives: { money: VMoney },
  components: { Upload, Multiselect ,VueEditor},
  mixins: [Settings],
  data() {
    return {
      UPDATE: 0,
      isSaving: false,
      isEditing: false,
      personal_data: {
        password: "",
        password_confirmation: ""
      }
    };
  },
  watch: {
    settings: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          val.forEach(setting => {
            if (setting?.key === "personal_data") {
              this.personal_data = setting?.content || {}
            }
          })
        } catch (error) {
          // do anything
        }
      },
    }
  },
  computed: {
    ...mapGetters(["settings", "tagColorsList","loggedUser"]),

    myGateways() {
      this.UPDATE;
      return (
        this.settings.find((set) => {
          return set.key === "personal_data";
        }) || false
      );
    },
  },
  mounted() {
    if (!this.$masterHelper.isEmpty(this.myGateways)) {
      this.pages_default = {
        ...this.myGateways?.content || {}
      };
    }
  },
  methods: {
    ...mapActions(["CREATE_SETTINGS", "UPDATE_SETTINGS","UPDATE_USER","LOGOUT"]),
    async save() {
      try {
        let data = this.personal_data;

        const validationsResponse =  await this.handlerSecurityToForm()

        if(!validationsResponse) return

        this.loadSet();
        this.isSaving = true;

        const response = await this.UPDATE_USER({...data, ...this.loggedUser})

        if(response?.code !== 201) {
          this.$toast.error(
            response?.data?.message || response?.data?.messageRaw || "Não foi possivel atualizar seus dados",
            "Administrador",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            }
          )
          this.isSaving = false;
          return
        }

        this.$toast.success(response?.data?.message || "Dados atualizados com sucesso", "Administrador",{
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        })

        window.addEventListener("click", (event) => {
          event.preventDefault()
          return
        })

        setTimeout(() => {
          this.LOGOUT()
        },1200)

      } catch (error) {
        this.isSaving = false;
      }
    },
    async handlerSecurityToForm(){
      try {
        const formDataParsed = handlerOrganizeForm('personal_form')


        if (Object.keys(formDataParsed || {}).length <= 0) {
          this.$toast.warning(`Preencha todos os campos`)
          return false
        }

        let res = await handlerValidation(profileSchema, formDataParsed).catch(
          (err) => {
            // console.log('err------------', err)
          }
        )

        if (res) {
          let resKeys = Object.keys(res)

          if (resKeys.length) {
            resKeys.forEach((r) => {
              this.$toast.error(res[r], 'Administrador',{
                icon: "icon-person",
                position: "topRight",
                timeout: 2000,
              })
            })

            return false
          }
        }

        return true

      } catch (error) {
        //console.log(error)
        throw new Error(error?.message)
      }
    }
  },
};
</script>

<style></style>
