<template>
  <div class="card w-100">
    <div class="card-body w-100">
      <h3>0 Comentarios</h3>
      <!-- <div class="text-center">
        <div>Nenhum comentario registrado</div>
      </div> -->
      <div class="w-100 d-flex">
        <div class="p-3 w-100">
          <div class="d-flex w-100">
           <img loading="lazy"
              src="https://dummyimage.com/50x50/000/fff"
              alt=""
              height="50"
              width="50"
            />
            <div class="d-flex flex-column  ml-4 w-100">
              <div class="comment-container-header">
                <div class="">User name</div>
                <small class="">Mar 13, 2021</small>
              </div>

              <div class="comment w-100 slide-in-blurred-top">
                <div>
                  {{ comment.comment.substr(0, 310) }}
                  <span v-if="!isOpen(comment)">...</span>
                  <span v-if="isOpen(comment)" class="slide-in-blurred-top">
                    {{ comment.comment.substr(310, comment.length) }}</span
                  >
                </div>
                <div class="text-primary text-center pointer mt-2">
                  <span @click="toggleMessage(comment)">
                    <span v-if="!isOpen(comment)"
                      ><i class="fas fa-eye"></i> Ver mais</span
                    >
                    <span v-else
                      ><i class="far fa-eye-slash"></i> Minimizar</span
                    >
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2 ">
                <!-- <textarea class="form-control" id="" rows="1"></textarea> -->
                <small class="text-primary pointer ml-4">Responder</small>
                <!-- <small class="text-primary pointer ml-4">Concluir</small> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Status from "@/components/status/Index";
export default {
  components: {
    Status
  },
  data() {
    return {
      openComments: [],
      comment: {
        uuid: 1,
        comment:
          "Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent Leite de capivaris, leite de mula manquis sem cabeça. Praesent"
      }
    };
  },
  methods: {
    toggleMessage(comment) {
      let exists = this.openComments.findIndex(comm => {
        return comm.uuid === comment.uuid;
      });
      if (exists < 0) this.openComments.push({ uuid: comment.uuid });
      else {
        this.openComments.splice(exists, 1);
      }
    },
    isOpen(comment) {
      return this.openComments.some(comm => {
        return comm.uuid === comment.uuid;
      });
    }
  }
};
</script>

<style>
.comment {
  width: 100%;
  background: #0000000a;
  padding: 0.8em;
  min-width: 100% !important;
}
.comment-container-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
</style>
