<template>
  <div>
    <BreadCrumb pageInfo="* itens importantes" :crumbs="[
      { name: 'Produtos', routeName: 'product.list' },
      { name: 'Detalhes' },
    ]" :page="
  $route.name === 'product.details' ? 'Editar Produto' : 'Novo Produto'
">
      <div slot="right-side">
        <LoaderButton b_class="btn-primary" :wasClicked="isSaving">
          <button class="btn btn-primary" @click="createProduct()">
            Publicar
          </button>
        </LoaderButton>
      </div>
    </BreadCrumb>
    <div class="row">
      <div class="col-9 ml-3 mb-3 align-items-center justify-content-start w-50"
        style="min-height: 2rem; height: auto; border:0.1rem solid #26292c; background-color:#515354">
        <span style="color:#fff" class="p-0 m-0">
          Link de vídeo(s) retirado(s) do youtube devem estar configurados para serem exibidos por sites terceiros.
          <br />
          para mais informações <a href="https://support.google.com/youtube/answer/171780?hl=pt-BR"
            target="__blank">Clique Aqui</a>
        </span>
      </div>

      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="
                    d-flex
                    w-100
                    h-100
                    align-items-center
                    justify-content-center
                  ">
                  <div>
                    <div>
                      <div class="image_preview_thumb" v-if="mediaHeaderProductFlag !== -1">
                        <img loading="lazy" v-show="tempProduct.media.length >= 1"
                          :src="tempProduct.media[mediaHeaderProductFlag].src || ''" />
                      </div>
                      <div v-else class="image_preview_thumb">
                        <img loading="lazy" :src="`${$masterHelper.getURL(true)}${tempProduct.thumbnail || ''}.png`" />
                      </div>

                      <ImageCardUpload :iconType="`button`" :typeFile="`header`"
                        @imageUploaded="handlerGetmageUploadEvent" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <div class="row align-items-center mb-5" style="gap: 0.5rem">

                  <div class="toggle-ecommerce" :class="{
                    'toggle-active': tempProduct.active && true
                  }" type="button" :disabled="isSaving"
                    @click.prevent="() => tempProduct.active = !tempProduct.active">
                    <span>{{ tempProduct.active ? 'Ativo' : 'Inativo' }}</span>
                  </div>

                  <div class="toggle-ecommerce" :class="{
                    'toggle-active': tempProduct.show_dimensions && true
                  }" type="button" :disabled="isSaving"
                    @click.prevent="() => tempProduct.show_dimensions = !tempProduct.show_dimensions">
                    <span>{{ tempProduct.show_dimensions ? 'Mostrar dimensões' : 'Não Mostrar dimensões' }}</span>
                  </div>

                  <div class="toggle-ecommerce" :class="{
                    'toggle-active': tempProduct.show_weight && true
                  }" type="button" :disabled="isSaving"
                    @click.prevent="() => tempProduct.show_weight = !tempProduct.show_weight">
                    <span>{{ tempProduct.show_weight ? 'Mostrar peso' : 'Não Mostrar peso' }}</span>
                  </div>

                  <div class="toggle-ecommerce" :class="{
                    'toggle-active': tempProduct.highlight && true
                  }" type="button" :disabled="isSaving"
                    @click.prevent="() => tempProduct.highlight = !tempProduct.highlight">
                    <span>{{ tempProduct.highlight ? 'Em Destaque' : 'Sem Destaque' }}</span>
                  </div>

                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="d-flex justify-content-between">
                      <label for="">Nome*</label>

                    </div>

                    <input :disabled="isSaving" name="" id="product_name" v-model="tempProduct.name" type="text"
                      class="form-control" placeholder="Nome do produto" />
                  </div>
                </div>

                <div class="form-row mt-3">
                  <div class="col">
                    <label for="">Quantidade em Estoque*</label>
                    <input number :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " id="product_quantity" v-model="tempProduct.quantity" type="number" min="0" class="form-control"
                      placeholder="100" />
                  </div>
                  <div class="col">
                    <label for="">Preço de Custo</label>
                    <input :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " id="product_cost_price" v-model="tempProduct.cost_price" v-money="money" type="text"
                      class="form-control" placeholder="25,00" />
                  </div>
                </div>

                <div class="form-row mt-3">
                  <div class="col">
                    <label for="">Preço de Venda </label>
                    <input v-money="money" :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " id="product_price" v-model="tempProduct.price" type="text" class="form-control"
                      placeholder="100" />
                  </div>
                  <div class="col">
                    <label for="">Preço promocional </label>

                    <input v-money="money" :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " v-model="tempProduct.sale_price" type="text" class="form-control" placeholder="25,00" />
                  </div>
                </div>

                <div class="form-row mt-3">
                  <div class="col">
                    <label for="">Quantidade para Atacado </label>
                    <input @keyup="_format('wholesale_quantity')" number :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " v-model="tempProduct.wholesale_quantity" type="number" class="form-control" placeholder="100" />
                  </div>
                  <div class="col">
                    <label for="">Preço de Atacado </label>

                    <input v-money="money" :disabled="
                      isSaving || (hasVariationSetted > 0 && $USE_VARIATIONS)
                    " v-model="tempProduct.retail_price" type="text" class="form-control" placeholder="25,00" />
                  </div>
                </div>

                <div class="form-group mt-3">
                  <label for="">Resumo do Produto</label>
                  <textarea id="product_summary" :disabled="isSaving" class="form-control" type="text"
                    placeholder="Nome do produto" v-model="tempProduct.summary" @keyup="_recheckValidation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- right-side -->
      <div class="col-3 col-max-width">
        <div class="card mb-3" v-if="
          tempProduct.annotations &&
          !$masterHelper.isEmpty(tempProduct.annotations)
        ">
          <div class="card-header" :class="`bg-${tempProduct.annotations.type}`">
            <i :class="tempProduct.annotations.icon" class="mr-2"></i>
            {{ tempProduct.annotations.title }}
          </div>
          <div class="card-body">
            {{ tempProduct.annotations.content }}
          </div>
        </div>
        <!-- SEO card -->
        <simple-seo v-model="tempProduct" />
        <!-- SEO card -->
      </div>

    </div>
    <!-- Variaçoes -->
    <div class="row" v-if="$USE_VARIATIONS">
      <div class="col-9 col-max-width">
        <VariationSection v-model="tempProduct.product_variations" id="product_variations" />
      </div>
    </div>
    <!-- Variaçoes -->
    <!-- Preview Variaçoes -->
    <div class="row" v-if="$USE_VARIATIONS">
      <div class="col-9 col-max-width">
        <PreviewVariation v-model="tempProduct.product_variations" id="product_variations" />
      </div>
    </div>
    <!-- Preview Variaçoes -->
    <!-- Imagens -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header d-flex justify-content-between w-100">
            <div>
              <div>
                <div class="d-flex align-items-baseline">
                  <h4>Mídias</h4>
                  <small class="ml-3 text-primary pointer" @click="toggleInstructions()">Instruções</small>
                </div>
                <small>Escolha a imagem principal, clicando na estrela dentro da que
                  deseja.</small>
              </div>
            </div>
            <button :disabled="isSaving" v-if="tempProduct.media && tempProduct.media.length" class="btn"
              @click="() => $refs.up2.loadPreview()">
              + adicionar imagens
            </button>
          </div>
          <div class="card-body">
            <div v-if="showInstructions" class="alert alert-warning alert-dismissible fade show" role="alert">
              Utilize imagens em PNG, com o fundo transparente para dar mais
              destaque ao produto.
              <button @click.prevent="toggleInstructions()" type="button" class="close" data-dismiss="alert"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Upload type="container" ref="up2" @favorite_selected="(data) => handlerGetmageUploadEvent(data)"
              @data_update="_whileUploadComponentUpdates" @data_deleted="_whileUploadComponentDeletes" :limit="10"
              :height="200" />
            <div class="form-group mt-2">
              <label for="">Vídeo URL</label>
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fab fa-youtube"></i></span>
                <input :disabled="isSaving" v-model="tempProduct.video.path" type="text" class="form-control"
                  placeholder="Exemplo: https://www.youtube.com/watch?v=Qy0iiRSKws8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Imagens -->
    <!-- Descrição -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header">
            <h4>Descrição Curta*</h4>
          </div>
          <div class="card-body">
            <div class="form-group mt-2" id="product_short_description">
              <vue-editor :editorToolbar="customToolbar" @text-change="_shortDescription()" v-model="short_description"
                :disabled="isSaving" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Descrição Completa* -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header">
            <h4>Descrição Completa * </h4>
          </div>

          <div class="card-body">
            <div class="form-group mt-2" id="product_description">
              <vue-editor :editorToolbar="customToolbar" v-model="tempProduct.description" :disabled="isSaving" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Descrição -->

    <!-- Organização -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header">
            <h4>Organização</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <label for="">Categorias</label>

                <CategorySelector :filter="searchCategory" @change="_productAddCategories" ref="category_selector" />
              </div>
              <div class="col">
                <label for="">Tags</label>
                <transition name="scale-in" mode="out-in">
                  <TagManager :disabled="isSaving" class="mt-3" v-if="createNewTag" :possibleName="searchInput"
                    @manager_save_tag="newTagSave" />
                  <div v-else>
                    <multiselect ref="selectedTags" :disabled="isSaving" @search-change="search" v-model="selectedTags"
                      @input="_setTagsToProduct" :options="tags.filter((t) => t.active == 1)" :close-on-select="true"
                      :clear-on-select="false" :hide-selected="true" :preserve-search="true"
                      placeholder="Selecione suas tags" label="name" track-by="id" :multiple="true">
                      <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="tags.length"
                          @mousedown.prevent.stop="clearAll(props.search)"></div>
                      </template>
                      <span slot="noResult" class="d-flex flex-column">
                        <p>
                          Tag
                          <strong>"{{ searchInput }}"</strong>
                          não encontrada, Deseja criar?
                        </p>

                        <div class="d-flex justify-content-center mt-2">
                          <button class="btn btn-primary ml-2" @click="createNewTag = true">
                            Sim
                          </button>
                          <button class="btn btn-warning ml-2" @click="closeMultiselect()">
                            Não
                          </button>
                        </div>
                      </span>
                    </multiselect>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Organização -->
    <!-- Códigos e Estoque -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h4>Códigos e Identificação</h4>
            <div v-if="tempProduct.origin">
              Origem do Cadastro:
              <img loading="lazy" v-if="tempProduct.origin.logo" class="pointer ml-2" :src="tempProduct.origin.logo"
                width="50" :title="tempProduct.origin.name" />
              <span v-else>{{ tempProduct.origin.name }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="col">
                <label for=""> Código SKU * </label>
                <input v-model="tempProduct.sku" type="text" class="form-control" placeholder="" />
                <span class="text-primary pointer" @click="makeSKU()">Gerar</span>
              </div>
              <div class="col">
                <label for=""> GTIN </label>
                <input v-model="tempProduct.gtin" type="text" class="form-control" placeholder="" />
              </div>
              <div class="col">
                <label for=""> MPN </label>
                <input v-model="tempProduct.mpn" type="text" class="form-control" placeholder="" />
              </div>
              <div class="col">
                <label for=""> NCM </label>
                <input v-model="tempProduct.mcm" type="text" class="form-control" placeholder="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Códigos e Estoque -->
    <!-- Peso e dimensões -->
    <div class="row">
      <div class="col-9 col-max-width">
        <div class="card">
          <div class="card-header">
            <h4>Peso e Dimensões</h4>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="col">
                <label for=""> Tipo de unidade </label>
                <input :disabled="isSaving" id="product_depth" v-model="tempProduct.unit" type="text"
                  class="form-control" />
                <small>Unidade, Quilo, Palete, Caixas, Litros Etc</small>
              </div>
              <div class="col-2">
                <label for="">Peso*</label>
                <div class="input-group-prepend">
                  <input v-money="weight" :disabled="isSaving" id="product_weight" v-model="tempProduct.weight"
                    type="text" class="form-control" />
                  <select name="weight_unit" id="weight_unit" v-model="tempProduct.weight_unit"
                    title="O padrão é em Quilogramas(KG)">
                    <option value="KG" title="Quilogramas" selected>KG</option>
                    <option value="G" title="Gramas">G</option>
                    <option value="MG" title="Milegrama">MG</option>
                    <option value="CM" title="Centímetros">CM</option>
                    <option value="MM" title="Milimetros">MM</option>
                    <option value="M" title="Metro">M</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <label for=""> Altura* </label>
                <div class="input-group-prepend">
                  <input v-money="height" :disabled="isSaving" id="product_height" v-model="tempProduct.height"
                    type="text" class="form-control" />
                  <select name="height_unit" id="height_unit" v-model="tempProduct.height_unit"
                    title="O padrão é em Centímetros(C)">
                    <option value="KG" title="Quilogramas">KG</option>
                    <option value="G" title="Gramas">G</option>
                    <option value="MG" title="Milegrama">MG</option>
                    <option value="CM" title="Centímetros" selected>CM</option>
                    <option value="MM" title="Milimetros">MM</option>
                    <option value="M" title="Metro">M</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <label for=""> Largura* </label>
                <div class="input-group-prepend">
                  <input v-money="width" :disabled="isSaving" id="product_width" v-model="tempProduct.width" type="text"
                    class="form-control" />
                  <select name="width_unit" id="width_unit" v-model="tempProduct.width_unit"
                    title="O padrão é em Quilogramas(KG)">
                    <option value="KG" title="Quilogramas">KG</option>
                    <option value="G" title="Gramas">G</option>
                    <option value="MG" title="Milegrama">MG</option>
                    <option value="CM" title="Centímetros" selected>CM</option>
                    <option value="MM" title="Milimetros">MM</option>
                    <option value="M" title="Metro">M</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <label for=""> Profundidade* </label>
                <div class="input-group-prepend">
                  <input v-money="depth" :disabled="isSaving" id="product_depth" v-model="tempProduct.depth" type="text"
                    class="form-control" />
                  <select name="depth_unit" id="depth_unit" v-model="tempProduct.depth_unit"
                    title="O padrão é em Quilogramas(KG)">
                    <option value="KG" title="Quilogramas">KG</option>
                    <option value="G" title="Gramas">G</option>
                    <option value="MG" title="Milegrama">MG</option>
                    <option value="CM" title="Centímetros" selected>CM</option>
                    <option value="MM" title="Milimetros">MM</option>
                    <option value="M" title="Metro">M</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="alert alert-warning mt-5" role="alert">
              Algo muito louco escrito aqui
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Peso e dimensões -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import BreadCrumb from "@/components/navigation/BreadCrumb";

import Multiselect from "vue-multiselect";

import CategoryManager from "@/components/categories/CategoryManager";
import CategoryModal from "@/components/categories/CategoryModal";

import TagManager from "@/components/tags/TagManager";

import Upload from "@/components/imageUpload/Upload";
import ImageCardUpload from "@/components/imageUpload/ImageCardUpload";

import CategorySelector from "@/components/categories/CategorySelectorNested";
import LoaderButton from "@/components/buttons/LoaderButton";
import SimpleSeo from "@/components/seo/SimpleSeo";
import INPUT_VALIDATIONS from "@/helpers/InputValidation.js";
import "pace-js";

import VariationSection from "@/components/variations/Section";
import PreviewVariation from "@/components/variations/PreviewVariations";


import { VMoney } from "v-money";

export default {
  directives: { money: VMoney },
  components: {
    BreadCrumb,
    Multiselect,
    CategoryManager,
    TagManager,
    Upload,
    CategoryModal,
    CategorySelector,
    VueEditor,
    LoaderButton,
    SimpleSeo,
    VariationSection,
    PreviewVariation,
    ImageCardUpload
  },
  data() {
    return {
      showInstructions: false,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
      weight: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 1,
      },
      height: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 1,
      },
      width: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 1,
      },
      depth: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 1,
      },
      short_description: "",
      isSaving: false,
      searchInput: "",
      searchCategory: "",
      createNewCategory: false,
      createNewTag: false,
      tempImages: [],

      tempProduct: {
        video: {},
        mainImg: null,
        name: null,
        description: "",
        price: null,
        cost_price: null,
        sale_price: null,
        weight: null,
        height: null,
        width: null,
        depth: null,
        weight_unit: 'KG',
        height_unit: 'CM',
        width_unit: 'CM',
        depth_unit: 'CM',
        show_weight: 1,
        highlight: 1,
        show_dimensions: 1,
        sku: null,
        unit: null,
        gtin: null,
        mpn: null,
        mcm: null,
        media: [],
        quantity: null,
        quantity_min: null,
        meta: [],
        wholesale_quantity: null,
        retail_price: null,
        active: 1,
        summary: null,
        short_description: null,
        product_variations: [],
      },

      customToolbar: [
        [{ font: [] }],

        [{ header: [false, 1, 2, 3, 4, 5, 6] }],

        [{ size: ["small", false, "large", "huge"] }],

        ["bold", "italic", "underline", "strike"],

        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],

        [{ header: 1 }, { header: 2 }],

        ["blockquote"],

        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ script: "sub" }, { script: "super" }],

        [{ indent: "-1" }, { indent: "+1" }],

        [{ color: [] }, { background: [] }],

        ["link", "formula"],

        [{ direction: "rtl" }],

        ["clean"],
      ],

      mediaHeaderProductFlag: -1,

      selectedTags: [],
      selectedCategories: [],
    };
  },
  // funções que o tempo inteiro escuta e recomputa
  computed: {
    // This.$store.getters.model
    ...mapGetters([
      "posts",
      "tags",
      "users",
      "categories",
      "product",
      "loggedUser",
    ]),
    limitLongDesc() {
      if (!this.tempProduct.description) return 0;
      return this.tempProduct.description.replace(/<[^>]+>/g, "").length;
    },
    hasVariationSetted() {
      return this.tempProduct?.product_variations?.length || 0;
    },
  },
  // roda depois que o DOM está montado
  mounted() {
    // this.$off('image-was-uploaded')
    // this.LOAD_CATEGORIES();
    if (this.tags && !this.tags.length) {
      this.LOAD_TAGS();
    }

    if (this.categories && !this.categories.length) {
      this.LOAD_CATEGORIES();
    }

    this.checkPage();
  },
  // Observa as var
  watch: {

    tempProduct: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val?.active !== null) {
          if (val?.active === 0) {
            this.tempProduct.active = false;
          }
          if (val?.active === 1) {
            this.tempProduct.active = true;
          }
        }

        if (val && val?.show_weight !== null) {
          if (val?.show_weight === 0) {
            this.tempProduct.show_weight = false;
          }
          if (val?.show_weight === 1) {
            this.tempProduct.show_weight = true;
          }
        }

        if (val && val?.show_dimensions !== null) {
          if (val?.show_dimensions === 0) {
            this.tempProduct.show_dimensions = false;
          }
          if (val?.show_dimensions === 1) {
            this.tempProduct.show_dimensions = true;
          }
        }

        if (val && val?.highlight !== null) {
          if (val?.highlight === 0) {
            this.tempProduct.highlight = false;
          }
          if (val?.highlight === 1) {
            this.tempProduct.highlight = true;
          }
        }

        if (this.hasVariationSetted) {
          this.tempProduct.quantity = 1;
        }
      },
    },
  },
  // métodos disponíveis
  methods: {
    // Ações do STATE. Funções que já estão disponíveis no state
    ...mapActions([
      "LOAD_POSTS",
      "GET_POST",
      "LOAD_TAGS",
      "LOAD_USERS",
      "LOAD_CATEGORIES",
      "CREATE_CATEGORY",
      "CREATE_PRODUCT",
      "GET_PRODUCT",
      "UPDATE_PRODUCT",
      "VIEW_PRODUCT",
      "LOAD_PRODUCTS",
      "GET_MEDIA",
      "LOAD_VARIATION",
      "LOAD_OPTIONS",
    ]),
    async loadMedia() {
      let media = await this.GET_MEDIA({
        model_id: this.tempProduct.id,
        model_type: "product",
      });

      if (media.status == 200) {
        this.tempProduct.media = media.data.data;
      }
    },
    async checkPage() {
      let detailPage = this.$route.name === "product.details";

      if (detailPage) {
        await this.GET_PRODUCT(this.$route.params.uuid);

        let product = this.product;

        product.video = {
          path: "",
        };

        this.tempProduct = product;

        await this.loadMedia();

        this.short_description = this.tempProduct.short_description;

        this._setComponentsDataWhenEditing();

        //Load INPUT_VALIDATIONS
        INPUT_VALIDATIONS();
        this.LOAD_VARIATION();
        this.LOAD_OPTIONS();
      }
      // INPUT_VALIDATIONS();
      //console.log("checked page -----------------------------");
    },
    // com _ são funções auxiliares internas
    _format(item) {
      // verifica se não são campos de quantidade e sim de valor moeda
      if (item !== "quantity" && item !== "wholesale_quantity") {
        this.tempProduct[item] = this.$format(
          this.$money(this.tempProduct[item].replace(/\D/g, ""))
        );
      } else {
        this.tempProduct[item] = this.tempProduct[item].replace(/\D/g, "");
      }
    },

    _whileUploadComponentUpdates(data) {
      data.map((d) => {
        let contains = this.tempProduct.media.some((tp) => {
          if (d.src) {
            return d.src === tp.src;
          } else {
            return d.path === tp.path;
          }
        });
        if (!contains && d.type !== "video") this.tempProduct.media.push(d);
      });
    },
    _whileUploadComponentDeletes(data) {
      // console.log("on update removal", data);
      let index = this.tempProduct.media.findIndex((m) => {
        if (m.src) {
          return m.src === data.src;
        } else {
          return m.path === data.path;
        }
      });
      if (index > -1) this.tempProduct.media.splice(index, 1);
      this.$refs.up1?.removeByEvent(data);
    },
    //Inject data on components
    _setComponentsDataWhenEditing() {
      // 1 Set <Upload/>
      if (this.tempProduct.media.length) {
        let imageTypeOnly =
          this.tempProduct.media.filter((m) => {
            return m.type === "image";
          }) || [];

        let formatedWithExtention = [];

        imageTypeOnly.forEach((img) => {
          img.path = img.path + ".webp";
          formatedWithExtention.push(img);
        });
        this.$refs.up2.eventSetData(formatedWithExtention);

        //set an empty video object to avoid error
        this.tempProduct.video = this.tempProduct.media.find((m) => {
          return m.type === "video";
        }) || { path: "" };
      }
      // 2 Set <CategorySelector/>
      this.tempProduct.categories.map((cat) =>
        this.$refs.category_selector.selectedCategories.push(cat)
      );
      // 3 Set selectedTags
      // selectedTags is the variable that tag multiselect uses for v-model
      this.selectedTags = this.tempProduct.tags;
      this._productAddCategories(this.tempProduct.categories);
      this._setTagsToProduct(this.tempProduct.tags);
    },
    toggleInstructions() {
      this.showInstructions = !this.showInstructions;
    },
    search(e) {
      this.searchInput = e;
      this.$forceUpdate();
      //console.log("logging this.searchInput", this.searchInput);
    },
    createMissingCategory() {
      this.CREATE_CATEGORY(this.searchInput);
    },
    newCategorySave(category) {
      this.createNewCategory = false;
      this.selectedCategories.push(category);
      this.searchInput = null;
    },
    newTagSave(tag) {
      this.selectedTags.push(tag);
      this.resetCreations();
    },
    resetCreations() {
      this.searchInput = null;
      this.createNewTag = false;
      this.createNewCategory = false;
    },
    _shortDescription() {
      this.tempProduct.short_description = this.short_description
      this.$forceUpdate();
    },
    clearMoney() {
      let items = ["price", "cost_price", "sale_price", "retail_price"];
      const clear = (data) => {
        return data
          .replaceAll(",", "")
          .replaceAll("R$", "")
          .replaceAll(" ", "");
      };
      items.forEach((item) => {
        // this.tempProduct[item].replace(/\D/g, "")
        this.tempProduct[item] = clear(this.tempProduct[item]);

        // console.log(`item ${item} formated`, this.tempProduct[item]);
      });
    },
    async createProduct() {
      if (this._validation()) {
        // if (this.tempProduct.short_description) {
        //   this.tempProduct.short_description =
        //     this.tempProduct.short_description.substr(0, 254);
        // }

        // if (this.tempProduct.description) {
        //   this.tempProduct.description = this.tempProduct.description.substr(
        //     0,
        //     254
        //   );
        // }

        this.tempProduct.user_id = this.loggedUser.id;
        // console.log("saving this status before", this.tempProduct.active);
        this.tempProduct.active = this.tempProduct.active ? 1 : 0;
        // console.log("saving this status", this.tempProduct.active);
        this.isSaving = true;
        this.$pace.restart();

        try {
          let res = null;

          this.clearMoney();

          switch (this.$route.name) {
            case "product.details":
              // this.$event.$emit("page_loaded", {
              //   isLoading: true,
              //   message: "Atualizando ..."
              // });
              this.tempProduct.cost_price = this.tempProduct.cost_price.replace(
                ",",
                ""
              );
              this.tempProduct.price = this.tempProduct.price.replace(",", "");
              this.tempProduct.retail_price =
                this.tempProduct.retail_price.replace(",", "");
              this.tempProduct.sale_price = this.tempProduct.sale_price.replace(
                ",",
                ""
              );
              var saveThis = JSON.parse(JSON.stringify(this.tempProduct));

              saveThis.product_variations = this.clearVariations();

              res = await this.UPDATE_PRODUCT(saveThis)

              this.$forceUpdate();

              this.$router.push({ name: "product.list" });
              this.$event.$emit("page_loaded", {
                isLoading: false,
              });

              // TODO tratamento do retorno do res

              //console.log("the res", res);

              if (res && res.code === 201) {
                this.$toast.success(" ", "Produto atualizado!", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 3000,
                });
              } else {
                this.$toast.info(" ", "Não foi possivel atualizar o produto", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 3000,
                });
              }

              this.isSaving = false;

              break;

            case "product.create":
              var saveThis = JSON.parse(JSON.stringify(this.tempProduct));
              saveThis.product_variations = this.clearVariations();

              saveThis.origin = {
                logo: null,
                name: "Interno",
              };

              this.$event.$emit("page_loaded", {
                isLoading: true,
                message: "Criando ...",
              });

              res = await this.CREATE_PRODUCT(saveThis);

              // console.log("CREATE_PRODUCT RESPONSE", res);

              // TODO:IF ESTÁ SEM TRATAMENTO ELSE
              if (res.code === 201) {
                // console.log("CREATE_PRODUCT RESPONSE SUCCESS");

                this.$toast.success(" ", "Produto criado!", {
                  icon: "icon-person",
                  position: "topRight",
                  timeout: 3000,
                });

                this.$forceUpdate();
                this.$router.push({ name: "product.list" });

                this.$event.$emit("page_loaded", {
                  isLoading: false,
                });
              }

              this.isSaving = false;
              //console.log("CREATE_PRODUCT RESPONSE ERROR", res);

              break;

            default:
              break;
          }
        } catch (err) {
          // console.log("error on catch", err);
          this.$toast.error("Verifique as campos obrigatórios", "Alerta", {
            position: "topRight",
          });
        }
        this.VIEW_PRODUCT({});
      }
    },
    _productAddCategories(categories) {
      this.tempProduct.categories = categories.map((cat) => cat.id);
    },
    _setTagsToProduct(tags) {
      this.tempProduct.tags = tags.map((cat) => cat.id);
    },
    _recheckValidation(e) {
      if (e.target.value.length) {
        if (
          this.tempProduct.description &&
          !this.tempProduct.description.length
        ) {
          document
            .getElementById("quill-container")
            .classList.add("border-danger");
        }
        if (e.target.classList.contains("border-danger")) {
          e.target.classList.remove("border-danger");
        }
      }
    },
    clearVariations() {
      const clear = (data) => {
        if (!data) return 0;
        return data
          .replaceAll(",", "")
          .replaceAll("R$", "")
          .replaceAll(" ", "");
      };
      let variations = JSON.parse(
        JSON.stringify(this.tempProduct.product_variations)
      );
      if (variations && variations.length) {
        variations.forEach((variation, index) => {
          variations[index].price = clear(variations[index].price);
          variations[index].cost_price = clear(variations[index].cost_price);
          variations[index].sale_price = clear(variations[index].sale_price);
          variations[index].retail_price = clear(
            variations[index].retail_price
          );

          //console.log("variations", variations[index].price);
          variations[index].qty = parseInt(variations[index].qty);
          variations[index].wholesale_quantity = parseInt(
            variations[index].wholesale_quantity
          );
        });
      }

      return variations;
    },
    hasVariationInUse() {
      return this.tempProduct?.product_variations?.length > 0 ? true : false;
    },
    _validation() {
      let rules = {
        name: {
          required: true,
        },
        quantity: {
          required: this.hasVariationInUse() ? false : true,
        },
        price: {
          required: this.hasVariationInUse() ? false : true,
        },
        summary: {
          required: false,
        },
        cost_price: {
          required: this.hasVariationInUse() ? false : true,
        },
        weight: {
          required: true,
        },
        height: {
          required: true,
        },
        width: {
          required: true,
        },
        depth: {
          required: true,
        },
        description: {
          required: true,
        },
        short_description: {
          required: true,
        },
        product_variations: {
          required: false,
        },
      };
      //   console.log('Rules', rules)

      let keys = Object.keys(this.tempProduct);

      let error = [];

      let isZero = this.tempProduct.price === "R$ 0.00";

      let clearError = (id) => {
        let el = document.getElementById(`product_${id}`);

        if (el.classList.contains("border-danger")) {
          el.classList.remove("border-danger");
        }
      };

      keys.forEach((key) => {
        let type = typeof this.tempProduct[key];

        if (key == "price") {
          if (rules[key].required && isZero) error.push(key);
        }

        if (rules[key] && rules[key].required) {
          clearError(key);
          if (
            this.tempProduct[key] == null ||
            (!this.tempProduct[key].length && type.includes("string"))
          ) {
            //console.log("this input is empty:" + key);
            error.push(key);
          }
        }
      });

      error.forEach((id) => {
        // console.log("empty inputs elements", document.getElementById(id));
        document.getElementById(`product_${id}`).classList.add("border-danger");
      });
      // console.log(
      //   "error ------------------------- ------------------------------ ------------------------------",
      //   error
      // );
      if (error.length) {
        // alert(error);
        this.$toast.error(" ", "Campos em destaque faltam ser preenchidos!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });
        this.$forceUpdate();
        return false;
      }

      return true;
    },
    makeSKU() {
      this.tempProduct.sku =
        "VDO-" + new Date().getTime().toString().slice(0, -4);
    },
    closeMultiselect() {
      this.$refs.selectedTags.deactivate();
    },
    handlerGetmageUploadEvent(dataImage) {
      console.log('handlerGetmageUploadEvent', dataImage)
      // return
      // eslint-disable-next-line no-useless-catch
      try {

        const sizeImage = dataImage?.dataRaw?.size || dataImage?.sizeRaw

        if (!this.$masterHelper.getBytes(sizeImage, 2, 2024 ** 2)) {
          this.$toast.info(" ", "Selecione uma imagem com menos de 2MB", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          })

          return
        }

        const payload = {
          name: dataImage?.dataRaw?.name || dataImage?.name || "",
          src: dataImage?.data || dataImage?.src || "",
          position: "header"
        }

        if (this.mediaHeaderProductFlag <= -1) {
          const hasMediaHeader = this.tempProduct.media.findIndex(media => media.position === 'header')

          if (hasMediaHeader >= 0) {
            this.mediaHeaderProductFlag = hasMediaHeader
            this.tempProduct.media[hasMediaHeader] = payload
          } else {
            this.tempProduct.media.push(payload)
            this.mediaHeaderProductFlag = this.tempProduct.media.length - 1
          }

        } else {
          this.tempProduct.media[this.mediaHeaderProductFlag] = payload
        }
      } catch (error) {
        if (this.mediaHeaderProductFlag >= 0) this.tempProduct.media[this.mediaHeaderProductFlag]?.pop()
        throw error
      }
    }
  },
  // antes da rota atual sair
  beforeRouteLeave(to, from, next) {
    this.VIEW_PRODUCT({});
    // this.LOAD_PRODUCTS()

    next();
  },
};
</script>

<style lang="scss">
.criation-container {
  width: 100%;
  display: flex;

  .img-container {
    img {
      width: 100%;
    }
  }

  .content-creation {
    /* display: flex; */
    padding: 1.5em;
    width: 100%;
  }
}

.thumbnails {
  margin-top: 0.8em;
  overflow: hidden;
  flex: column;

  .thumb {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .img_info {
      padding: 0.5em;
    }

    .img {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        margin-right: 0.8em;
      }
    }
  }
}

.bg-warning {
  color: black;
}
</style>
