<template>
  <div>
    <h3 v-if="perPeriod !== undefined">
      De {{ new Date(perPeriod.dates[0]).toLocaleDateString() }} até
      {{ new Date(perPeriod.dates[1]).toLocaleDateString() }}
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Nome</th>
          <th>Visualizações</th>
          <th>Por</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in visualizationsFilter || tempVisualizations" :key="index" class="pointer hover"
          @click.prevent="handlerToPage('product.details', { uuid: item.uuid })">
          <td>
            <img loading="lazy" :src="`${$masterHelper.getURL(true)}${item.thumbnail}.png`" :alt="item.name"
              :title="item.name" width="140" />
          </td>
          <td>
            <!-- <a :href="`../products/details/${item.uuid}`" target="_blank"> -->
            {{ item.name }}
            <!-- </a> -->
          </td>
          <td>
            {{ item.visualization }}
          </td>
          <td>
            {{ item.user_name || "Visitante" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  components: {
    LoaderButton,
  },
  data() {
    return {
      tempVisualizations: [
        {
          id: 0,
          visualization: 0,
          name: "",
          model_type: "",
        },
      ],
      visualizationsFilter: undefined,
      perPeriod: undefined,
    };
  },
  watch: {
    visualizations: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log(val);
        if (val.length > 0) {
          this.tempVisualizations = JSON.parse(
            JSON.stringify(this.$store.getters.visualizations)
          );
        }
      },
    },
  },
  props: {},
  mounted() {
    this.$event.$on("visualizations-filter-period", (payload) => {
      this.perPeriod = payload;
    });

    this.$event.$on("visualizations-filter-not-found", (payload) => {
      this.visualizationsFilter = payload;
      this.visualizations = payload;
    });

    this.$event.$on("visualizations-filter", (payload) => {
      this.visualizationsFilter = payload;
    });

    this.$event.$on("visualizations-filter-reset", (_payload) => {
      this.visualizationsFilter = undefined;
    });
  },
  computed: {
    ...mapGetters(["visualizations"]),
  },
  methods: {
    handlerToPage(path = "product.details", params = {}) {
      try {
        // console.log(path,params)
        this.$router.push({ name: path, params });
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>

<style>

</style>
