<template>
  <div>
    <div>
      <portal-target name="loading-screen" />
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <top-nav></top-nav>
        <left-nav></left-nav>
        <div class="page-wrapper" id="mainWrapper">
          <portal-target name="BreadCrumb"></portal-target>

          <div class="container-fluid">
            <portal-target name="modals"></portal-target>
            <transition name="slide-in" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="loading"
      class="w-100 d-flex justify-content-center align-items-center flex-column"
      style="min-height: 100vh"
      id="spinner-loading"
    >
      <div class="spinner-border mb-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h4 class="mt-1">{{ message || "Carregando..." }}</h4>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import * as Sentry from "@sentry/browser";

import BreadCrumb from "@/components/navigation/BreadCrumb";

import LeftNav from "@/components/navigation/LeftNav";
import TopNav from "@/components/navigation/TopNav";
import Auth from "@/mixins/Auth.vue";
import Settings from "@/mixins/Settings.vue";

// Helpers
import dynamicStyle from "@/helpers/DynamicStyle.js";

export default {
  mixins: [Auth, Settings],
  components: {
    LeftNav,
    TopNav,
    BreadCrumb,
  },
  data() {
    return {
      loading: true,
      message: null,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    //
  },
  mounted() {
    // evento
    this.$event.$on("page_loaded", (data) => {
     // console.log("firing event");
      this.loading = data.isLoading;
      this.message = data.message || null;
    });

    this.load();

    if(this.loggedUser) {
      Sentry.setUser({
        email: this.loggedUser?.email || null,
        name: this.loggedUser?.name || null,
        id: this.loggedUser?.id || -1,
      })
    }
  },
  methods: {
    ...mapActions([
      "LOAD_ROLES",
      "LOAD_POSTS",
      "LOAD_TAGS",
      "LOAD_CATEGORIES",
      "LOAD_PRODUCTS",
      "LOAD_ORDERS",
      "LOAD_USERS",
      "CHECK_USER",
      "LOAD_PERMISSIONS",
      "LOAD_SETTINGS",
      "LOAD_STATUS",
      "LOAD_VISUALIZATION",
      "ORDER_REPORT",
      "FINANCIAL_REPORT",
      "SELLER_REPORT",
      "PRODUCT_REPORT",
      "CLIENT_REPORT",
      "QUICK_LIST"
    ]),

    async callApis() {
      await Promise.all([
        this.LOAD_STATUS(),
        this.LOAD_ROLES(),
        this.LOAD_ORDERS(),
        this.LOAD_SETTINGS(),
        this.LOAD_PERMISSIONS(),
        this.LOAD_POSTS(),
        this.LOAD_TAGS(),
        this.LOAD_CATEGORIES(),
        this.LOAD_PRODUCTS(),
        this.LOAD_USERS(),
        this.loaded(),
        this.LOAD_VISUALIZATION(),
        this.ORDER_REPORT(),
        this.FINANCIAL_REPORT(),
        this.SELLER_REPORT(),
        this.PRODUCT_REPORT(),
        this.CLIENT_REPORT(),
        this.QUICK_LIST(),
      ])
      .then(_resolve => this.loaded())
      .catch(reject =>  this.loaded(reject));
    },
    loaded(reject = null) {
      try {
       // console.log('load is finished')

        if(reject) console.log('load is finished with reject promise', reject)
        this.loading = false;
      } catch (error) {
        //console.log('loaded@error', error)
      }
    },
    load() {
      try {
       // console.log("Layout default started the load of all data to application");
        this.callApis();
        this.$masterHelper.butonsBlockOnRequest();
      }catch(error) {
       // console.log('load@error', error)
      }
    },
  },
};
</script>

<style lang="scss">
@import url("//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css");
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
#spinner-loading {
  color: #333;
}
.el-aside {
  color: #333;
}
.collapsed-wrapper {
  margin-left: unset !important;
}
</style>
