<template>
  <div class="container-fluid h-100 now-go" id="login-container-geral">
    <div
      class="row row-center justify-content-center align-items-center"
      style="height: 100vh"
    >
    <!-- {{ admin_template }} -->
      <div
        v-if="admin_template && admin_template.content"
        class="d-none d-sm-flex col-8 col-md-9 h-100"
        :style="`background-image: url('${$masterHelper.getURL(true)}${admin_template.content.general.background.image || ''}.png') !important; background-size: cover !important; background-color: ${admin_template.content.general.background.color || '#fff'} !important;`"
      >
      </div >
      <div
        class="col-12 col-sm-4 col-md-3 fundo-verde d-flex flex-column flex-center align-items-center justify-content-center"
        :style="`background-color: ${admin_template && admin_template.content ? admin_template.content.general.background.color2 : '#f5f5f5'} !important;`"
        style="height: 100vh"
      >
        <div class="form-signin d-flex flex-column align-items-center justify-content-center" style="height: auto; gap: 1rem">

         <img loading="lazy"
            v-if="company && company.content"
            class="mb-5 img-fluid"
            :src="`${$masterHelper.getURL(true)}${company.content.logo.background.image || ''}.png`"
            alt=""
            style="max-width: 50%; !important"
          />

          <h1
            class="h3 mt-3 mb-3 font-weight-normal"
            :style="`color: ${admin_template && admin_template.content ? admin_template.content.general.text.colorTitle : '#fff'} !important;`"
            style="align-self:flex-start !important"
          >
            Digite seu dados de acesso:
          </h1>

          <input
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="Email "
            v-model="user.email"
          />

          <input
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Senha"
            v-model="user.password"
            @keypress.enter="login()"
          />

          <button
            class="btn btn-lg btn-primary btn-block mt-3"
            @click="login()"
            :style="`background-color: ${admin_template && admin_template.content ? admin_template.content.general.button.background : '#fff'} !important; color: ${admin_template && admin_template.content ? admin_template.content.general.button.text : '#fff'} !important;`"
          >
            Entrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
      return {
        user: {
          email: "",
          password: "",
        },
      };
    },
    computed: {
      ...mapGetters(["token","admin_template","company"]),
    },
    mounted() {
      if (this.token.includes("::401")) {
        this.$toast.error(" ", "Sessão expirada!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      }
      this.LOAD_SETTINGS()
    },
    methods: {
      ...mapActions(["LOGIN","LOAD_SETTINGS"]),
      login() {
        if (this.user.email.length && this.user.password.length) {
          this.LOGIN(this.user).then((res) => {
           // console.log("dfdfdf", Object.keys(res));
           // console.log("Login", res);

            if (res.status === 401) {
              this.$toast.error(" ", "Credenciais inválidas", {
                icon: "icon-person",
                position: "topRight",
                timeout: 3000,
              });

              this.reset();
              localStorage.removeItem("token");
              localStorage.removeItem("T-AUTH");
            }

            this.$router.push('/')
          });
        } else {
          this.$toast.error(" ", "Um dos campos está vazio.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        }
      },
      reset() {
          this.user = {
              email: "",
              password: "",
          };
      },
    },
};
</script>

<style>
.row-center {
    min-height: 100vh !important;
}
#login-container-geral {
    padding-left: 0px;
    padding-right: 33px;
}
</style>
