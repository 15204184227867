<template>
  <div class="card">
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Email</th>
            <th>Adicionado</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr class="pointer" v-for="user in userFromPaginate" :key="user.uuid" @dblclick="openUser(user, true)"
            @click.prevent="openUser(user, true)">
            <td scope="row">{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ $date(user.created_at) }}</td>

            <td>
              <span class="badge badge-info"
                v-if="user.roles && user.roles.length && user.roles[0].name === 'client'">{{
                  user.roles[0].name
                }}</span>
              <span class="badge badge-success"
                v-if="user.roles && user.roles.length && user.roles[0].name !== 'client'">{{
                  user.roles[0].name
                }}</span>
              <span class="badge badge-info" v-if="!user.roles.length">--</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer ">
      <div class="row w-100 mt-5">
        <nav id="div-pagination" class="row align-items-center justify-content-center mb-5" style="margin: 0 auto">
          <button @click="() => getPage(1, true, 1, Math.round(users.length / 2) + 1)" title="Ir para primeira">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
            </svg>
          </button>

          <button v-for="(paginated, index) in navigationPaginate" :key="index" :id="`perPageID_${paginated.id}`"
            class="perPage row align-items-center justify-content-center" :perPage="`${paginated.perPage}`"
            :pageStart="`${paginated.pageStart}`" :pageEnd="`${paginated.pageEnd}`"
            @click="() => setPaginator(paginated.perPage, paginated.pageStart, paginated.pageEnd, paginated.id)">
            {{ paginated.page }}
          </button>

          <button @click="() => getPage(1, true, Math.round(users.length / 2) + 1, users.length + 1)"
            title="Ir para última">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-right-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Pagination from "@/components/navigation/Paginator/Paginator";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      userFromPaginate: [],
      navigationPaginate: [],
    };
  },
  props: {
    users: {
      required: true
    }
  },
  mounted() {
    this.callbackStartPaginator()
  },
  computed: {
    ...mapGetters(['usersPaginate'])
  },
  watch: {
    users: {
      deep: true,
      immediate: false,
      handler() {
        try {
          this.callbackStartPaginator()
        } catch (error) {
          console.log('Watch to callbackStartPaginator@error', error)
        }
      }
    }
  },
  methods: {
    ...mapActions(["VIEW_USER"]),
    async openUser(user, openDestails = false) {
      try {
        await this.VIEW_USER(user);
        if (openDestails) {
          this.$router.push({
            name: "user.details",
            params: { uuid: user.uuid }
          });
        }
      } catch (error) {
        console.log("openUser::error", error);
      }
    },

    /**
     * Filter a list of data with bases on perPage and fromPage params
     * @param {number} perPage Receive the total of items that will showed per page
     * @param {*} fromPage Receive the position from where the data will filtered
     */
    setPaginator(perPage = 16, fromPage = 1, toPage = 2, pageID = -1) {
      try {
        const dataList = this.users
        let itemsPerPage = []

        if (!dataList || dataList?.length <= 0) return

        if (perPage >= dataList?.length) {
          perPage = dataList?.length || 3
        }

        itemsPerPage = dataList.slice((fromPage - 1), (toPage - 1))

        this.userFromPaginate = itemsPerPage
        this.getPage(pageID)
      } catch (error) {
        console.log('')
        throw error
      }
    },

    /**
     * Mount the UI of to paginate with base on total of item per page
     *
     * @param {number} perPage Receive the total of item that will showed per page
     * @return {*} true or undefined
     */
    setPageNavigator(perPage = 3) {
      try {
        const dataList = this.users
        this.navigationPaginate = []

        if (!dataList || dataList?.length <= 0) return

        let totalPages = perPage >= dataList.length || perPage <= 0 ? 1 : Math.round(dataList.length / perPage)

        if (perPage > 1 && (perPage + totalPages) === dataList.length) {
          perPage = perPage + 1
        }

        for (let j = 1; j <= totalPages; j++) {
          const paginatedID = j > 1 ? (j + perPage) : j
          const pageEndRaw = (j * perPage) + 1
          const pageEnd = (pageEndRaw - 1) > dataList.length ? dataList.length + 1 : pageEndRaw

          const pageStartRaw = ((pageEnd - 1) - perPage) + 1
          const pageStart = pageStartRaw > dataList.length ? dataList.length + 1 : pageStartRaw

          if (j <= dataList.length) {
            this.navigationPaginate.push({
              id: paginatedID,
              perPage: perPage,
              page: j,
              pageEnd,
              pageStart
            })
          }
        }

        return true
      } catch (error) {
        console.log(`handlePaginator: ${error?.message}`, error)
      }
    },

    /**
     *  Get a list of data from a unique page
     * @param {*} pageID The pageID from page target
     * @param {*} fromArrows If was from arrows left/right
     */
    getPage(pageID = 1, fromArrows = false, arrowStart = 1, arrowEnd = 1) {
      try {
        const elementsPerPageRaw = document.querySelectorAll(".perPage")
        const elementsPerPageToFocus = document.querySelector(`#perPageID_${pageID}`)
        const elementsPerPageToFocused = document.querySelector(`.perPageInFocus`)

        if (pageID <= 0 || !elementsPerPageToFocus || !elementsPerPageRaw) return

        const elementsPerPageParsed = Array.from(elementsPerPageRaw)

        if (!elementsPerPageParsed || elementsPerPageParsed.length <= 0) return

        elementsPerPageParsed?.forEach(element => {
          if (element?.classList?.contains("perPageInFocus")) element?.classList?.remove("perPageInFocus")
        })

        elementsPerPageToFocus?.classList?.add("perPageInFocus")

        const perPage = parseInt(elementsPerPageToFocus?.getAttribute("perPage") || '16')
        const pageEnd = parseInt(elementsPerPageToFocus?.getAttribute("pageEnd") || '16')
        const pageStart = parseInt(elementsPerPageToFocus?.getAttribute("pageStart") || '16')

        if (fromArrows) {
          console.log('getPage', arrowStart, arrowEnd)
          this.setPaginator(perPage, arrowStart, arrowEnd)
        } else {
          this.setPaginator(perPage, pageStart, pageEnd)
        }
      } catch (error) {
        // do anything
      }
    },

    /**
    * Callback to use only on Watch
    */
    callbackStartPaginator() {
      try {
        const lengthDataList = this.users.length === 1 ? 1 : Math.round(this.users.length / 2)

        if (this.setPageNavigator(lengthDataList)) {
          setTimeout(() => { this.getPage(1, false) }, 470)
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    },
  }
};
</script>

<style lang="scss">
.table>tbody>tr>td {
  vertical-align: middle;
}
</style>
