import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import event from "../../helpers/eventBus";

Vue.use(Vuex);

export default {
  state: {
    orders: [],
    order: {},
    seller_orders: [],
    client_orders: [],
    seller_clients: [],
    seller_reports: {
      retained_earnings: 0,
      total_earnings: 0,
      earnings: 0
    }
  },
  getters: {
    orders: state => state.orders.data,
    ordersPaginate: state => state.orders,
    order: state => state.order,
    seller_orders: state => state.seller_orders,
    client_orders: state => state.client_orders,
    seller_clients: state => state.seller_clients,
    seller_reports: state => state.seller_reports
  },
  mutations: {
    LOAD_ORDERS(state, ordersArr) {
      // console.log("LOAD_ORDERS - PRE-MUTATIONS", ordersArr)
      if ((ordersArr?.data?.length > 0 || ordersArr.length > 0) && (state.orders.length <= 0 || !state.orders)) {
        // console.log("LOAD_ORDERS - MUTATIONS", ordersArr)
        state.orders = ordersArr;
      }
    },
    CLEAR_ORDERS(state, _payload) {
      // console.log("CLEAR_ORDERS - MUTATIONS")
      state.orders = [];
    },
    LOAD_SELLER_ORDERS(state, orders) {
      state.seller_orders = orders;
    },
    LOAD_CLIENT_ORDERS(state, orders) {
      state.client_orders = orders;
    },
    LOAD_PAGINATOR_SELLER_ORDERS(state, orders) {
      if (orders?.data?.length > 0 || orders.length > 0) {
        //console.log("LOAD_PAGINATOR_SELLER_ORDERS - MUTATIONS", orders)
        state.ordersPaginate = orders;
      }
    },
    LOAD_SELLER_REPORTS(state, reports) {
      let {
        retained_earnings,
        total_earnings,
        earnings,
        monthly_sales
      } = reports;

      state.seller_reports = {
        retained_earnings,
        total_earnings,
        earnings,
        monthly_sales
      };
    },
    LOAD_SELLER_CLIENTS(state, clients) {
      state.seller_clients = clients;
    },
    SELECT_ORDER(state, order) {
      state.order = order;
    },
    UPDATE_ORDER(state, order) {
      let index = state.orders.data.findIndex(o => o.id === order.id);
      // console.log("before update", state.orders[index]);
      state.orders.data[index] = order;
      state.order.status = order.status;
      event.$emit("order_updated");
      // console.log("after update", state.orders.data[index]);
    }
  },
  actions: {
    LOAD_SELLER_CLIENTS: async (context, id) => {
      try {
        let response = await axios().post("orders/search", {
          "params": {
            "search": {
              "buyer_id": {
                "type": "where",
                "operator": "=",
                "value": id
              },
              "seller_id": {
                "type": "where",
                "operator": "!=",
                "value": null
              }
            },
            "relationships": {
              0: "user",
              1: "client",
              2: "seller"
            }
          },
          "model": "Order"
        });

        if (response?.data?.data?.length <= 0) {
          context.commit("LOAD_SELLER_CLIENTS", []);
          return
        }

        context.commit("LOAD_SELLER_CLIENTS", response.data?.data?.data);
        return;
      } catch (error) {
        return error;
      }
    },
    SEARCH_SELLER_ORDER: async ({ commit, dispatch }, payload) => {
      try {
        console.log('Info::SEARCH_SELLER_ORDER', payload)

        let response = await axios().post("orders/search", payload);

        if (response?.data?.code !== 200) {
          commit("LOAD_SELLER_ORDERS", []);
          return
        }

        dispatch("SET_PARAMS", payload);
        commit("LOAD_SELLER_ORDERS", response.data);
        return;
      } catch (error) {
        return error;
      }
    },
    LOAD_SELLER_ORDERS: async ({ commit }, payload) => {
      try {
        //console.log("LOAD_SELLER_ORDERS:init", payload);

        let response = await axios().get(`/orders/seller/${payload}`);

        if (response.data?.code !== 200) {
          commit("LOAD_SELLER_ORDERS", []);
          return
        }

        commit("LOAD_SELLER_ORDERS", response.data?.data);
        commit("LOAD_SELLER_REPORTS", response.data);

        return response.data?.data;
      } catch (error) {
        //console.log("LOAD_SELLER_ORDERS:error", error);
        return error;
      }
    },
    LOAD_PAGINATOR_SELLER_ORDERS: async ({ commit, getters }, payload) => {
      try {

        if (!payload) return

        let cachedSellerOrders = getters.seller_orders;

        const hasSellersOrders = getters.seller_orders?.data?.filter(
          (orderSeller) => orderSeller?.seller_id === payload
        );

        /// console.log(`LOAD_PAGINATOR_SELLER_ORDERS FROM ${payload} - CACHED: `, cachedSellerOrders)

        if (hasSellersOrders?.length <= 0) {
          commit("LOAD_PAGINATOR_SELLER_ORDERS", []);
          return
        }

        console.log(`LOAD_PAGINATOR_SELLER_ORDERS FROM ${payload}: `, hasSellersOrders)

        cachedSellerOrders.data = hasSellersOrders;
        cachedSellerOrders.to = hasSellersOrders?.length / 2;
        cachedSellerOrders.per_page = hasSellersOrders?.length / 2;
        cachedSellerOrders.total = hasSellersOrders?.length;
        cachedSellerOrders.last_page = hasSellersOrders?.length + 1;

        //console.log(`LOAD_PAGINATOR_SELLER_ORDERS FROM ${payload} - PARSED: `, cachedSellerOrders)

        commit("LOAD_PAGINATOR_SELLER_ORDERS", cachedSellerOrders);

        return cachedSellerOrders;
      } catch (error) {
        // console.log("LOAD_PAGINATOR_SELLER_ORDERS:error", error);
        return error;
      }
    },
    LOAD_ORDERS: async (context, _payload) => {
      try {
        let response = await axios().get("/orders");

        // console.log("LOAD_ORDERS - ACTIONS")

        if (response.data?.code !== 200) {
          context.commit("LOAD_ORDERS", []);
          return
        }

        context.commit("LOAD_ORDERS", response.data?.data?.orders);
        return response.data?.data;
      } catch (error) {
        // console.log("LOAD_ORDERS:error", error);
        return error;
      }
    },
    CLEAR_ORDERS: async (context, _payload) => {
      try {
        //console.log('CLEAR_ORDERS ACTIONS')
        context.commit("CLEAR_ORDERS", []);
        return true;
      } catch (error) {
        //console.log("CLEAR_ORDERS", error)
      }
    },
    ORDER_SIMPLE_FILTER: async ({ commit, dispatch }, payload) => {
      try {
        console.log('ORDER_SIMPLE_FILTER@initialized', payload)

        let response = await axios().post("orders/search", payload);

        // console.log('ORDER_SIMPLE_FILTER@response', response)

        if (response?.data?.data?.length <= 0) {
          commit("LOAD_ORDERS", []);
          commit("LOAD_SELLER_ORDERS", []);
          commit("LOAD_CLIENT_ORDERS", []);
          return
        }

        // console.log('ORDER_SIMPLE_FILTER@end', response)

        dispatch("SET_PARAMS", payload);
        commit("LOAD_ORDERS", response.data);
        commit("LOAD_SELLER_REPORTS", response.data);

        // console.log('ORDER_SIMPLE_FILTER@seller', payload, payload['params'],)

        if (payload['params']['search']['seller_id'] || payload['params']['seller_id']) {
          commit("LOAD_SELLER_ORDERS", response.data);
        }

        if (payload['params']['search']['buyer_id'] || payload['params']['buyer_id']) {
          commit("LOAD_CLIENT_ORDERS", response.data);
        }

        return response.data?.data;
      } catch (error) {
        // console.log('ORDER_SIMPLE_FILTER', error)
        return error;
      }
    },
    SEARCH_ORDER: async ({ commit, dispatch, getters }, payload) => {
      try {
        //console.log("trying ----", payload);
        let cachedOrderList = getters.orders || []

        // console.log('ORDERS LIST PRE-CACHED', cachedOrderList)

        if (cachedOrderList || getters.orders?.length > 0) dispatch("CLEAR_ORDERS", [])

        dispatch("SET_PARAMS", payload)

        let response = await axios().post("/orders/search", payload);

        if (!response.data) {
          commit("LOAD_ORDERS", cachedOrderList || [])
          return
        }

        //console.log('SEARCH_ORDER ACTIONS');

        commit("LOAD_ORDERS", response.data)

        //console.log('ORDERS LIST CACHED', cachedOrderList || [])

        return response;
      } catch (error) {
        // console.log("SEARCH_ORDER:error", error);
        return error;
      }
    },
    PAGINATE_LOAD_ORDERS: async ({ dispatch, commit }, payload) => {
      try {
        dispatch("CLEAR_ORDERS", [])
        let response = await axios().get(payload);

        if (response.data?.code !== 200) {
          return
        }

        commit("LOAD_ORDERS", response.data?.data)

        return response.data?.data;
      } catch (error) {
        //console.log("LOAD_ORDERS:error", error);
        return error;
      }
    },
    SELECT_ORDER: (context, payload) => {
      context.commit("SELECT_ORDER", payload);
    },
    UPDATE_ORDER: async ({ commit, getters }, payload) => {
      payload.products.forEach((prod, index) => {
        payload.products[index]["product"] = getters.products.data.find(p => {
          return p.id === prod.product_id;
        });
      });
      try {
        let response = await axios().put(
          "/orders/update/" + payload.id,
          payload
        );
        commit("UPDATE_ORDER", response.data.order);
        return response.data.order;
      } catch (error) {
        //console.log("UPDATE_ORDER:error", error);
        return error;
      }
    },
    UPDATE_ORDER_STATUS: async ({ commit, getters }, payload) => {
      try {
        let response = await axios().put(
          "/orders/update-status/" + payload.id,
          payload
        );
        commit("UPDATE_ORDER", response.data.order);
        return response;
      } catch (error) {
        //console.log("UPDATE_ORDER:error", error);
        return error;
      }
    }
  }
};
