import Vue from "vue";
import router from "../router/index";

import store from "../store/index";
Vue.prototype.$hasRole = (user, roles) => {
  return user?.roles?.some(role => {
    return roles?.includes(role?.name);
  });
};
Vue.prototype.$userByroles = (users, names) => {
  return users?.filter(user => {
    return user?.roles.some(r => {
      return names?.includes(r?.name);
    });
  });
};
Vue.directive("can", {
  inserted(el, binding) {
    const user = store.getters.loggedUser;
    const arg = binding.arg;
    const modifiers = binding.modifiers;
    let value = binding.value;

    //console.log("v-can binding: ", binding);

    const findPermissions = perm => {
      let found = 0;

      //Foi comentado pq nao deve procurar permissions na role e sim no user, caso mudar essa regra descomente aqui e comente o q esta em uso
      // user.roles.forEach(role => {
      //   console.log(" user.roles.forEach", role);

      //   // if (role.permissions) {
      //   //   role.permissions.forEach(permission => {
      //   //     console.log("permission name ========= ", permission.name);

      //   //     console.log("perm name =============", perm);

      //   //     console.log(
      //   //       "perm name includs =============",
      //   //       perm.includes(permission.name)
      //   //     );

      //   //     let hasIt = perm.includes(permission.name);

      //   //     if (hasIt) found++;
      //   //   });
      //   // } else if (user.permissions) {
      //   //   user.permissions.forEach(permission => {
      //   //     let hasIt = perm.includes(permission.name);
      //   //     if (hasIt) found++;
      //   //   });
      //   // }

      // });

      // ===================== EM USO ======================================
      if (user?.permissions) {
        user?.permissions?.forEach(permission => {
          let hasIt = perm.includes(permission?.name);
          if (hasIt) found++;
        });
      }

      // console.log("qty permissions", found);

      if (found > 0) return true;

      return false;
    };

    if (typeof value == "string") {
      alert("v-can usage error");
    } else {
      let res = findPermissions(value);

      //console.log("res ========== role", res);

      if (!res) {
        el.remove();
      }
    }
  }
});

Vue.directive("role", {
  inserted(el, binding) {
    let found = 0;

    const user = store.getters.loggedUser;
    const arg = binding.arg;
    const modifiers = binding.modifiers;
    let value = binding.value;

    user?.roles?.forEach(permission => {
      let hasIt = value.includes(permission?.name);
      if (hasIt) found++;
    });

    if (found == 0) {
      el.remove();
    }
  }
});
