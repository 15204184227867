<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-baseline">
        <h4>
          <strong>Cargos</strong>
        </h4>
        <small class="ml-2 text-primary ml-3 pointer" @click="newRole()"
          >Adicionar</small
        >
      </div>
      <div class="mt-3 row">
        <div class="col-3">
          Web
          <ul class="list-group">
            <li
              v-show="role.name.length && role.guard_name === 'web'"
              class="list-group-item pointer"
              :class="[
                {
                  active: selectedRole
                    ? role.name == selectedRole.name &&
                      role.guard_name === selectedRole.guard_name
                    : false,
                },
              ]"
              v-for="(role, index) in roles"
              :key="index"
              @click="selectRole(role)"
            >
              {{ role.name }}
            </li>
          </ul>
          <br />
          <br />
          API
          <ul class="list-group">
            <li
              v-show="role.name.length && role.guard_name === 'api'"
              class="list-group-item pointer"
              :class="[
                {
                  active: selectedRole
                    ? role.name == selectedRole.name &&
                      role.guard_name === selectedRole.guard_name
                      ? true
                      : false
                    : false,
                },
              ]"
              v-for="(role, index) in roles"
              :key="index"
              @click="selectRole(role)"
            >
              {{ role.name }}
            </li>
          </ul>
        </div>
        <div class="col">
          <div>
            <div
              v-if="!isCreating"
              class="d-flex align-items-baseline justify-content-between"
            >
              <div class="d-flex align-items-baseline">
                <h3 v-if="!isEditing">
                  {{ selectedRole ? selectedRole.name.capitalize() : "" }}
                </h3>

                <span v-else>
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectedRole.name"
                  />
                </span>

                <small
                  v-if="selectedRole && !isEditing && !isCreating"
                  class="text-primary ml-4 pointer"
                  @click="toggleEdition()"
                >
                  Editar
                </small>
                <div
                  v-if="selectedRole && !isEditing && !isCreating"
                  class="d-flex align-items-baseline"
                >
                  <small
                    class="pointer text-danger ml-4"
                    @click="confirm = true"
                    >Deletar</small
                  >
                  <div
                    class="alert alert-warning ml-3"
                    role="alert"
                    v-if="confirm"
                  >
                    Tem certeza?
                    <button
                      class="btn btn-danger btn-sm ml-2"
                      @click="confirm = false"
                    >
                      Não
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm ml-2"
                      @click="deleteRole()"
                    >
                      Sim
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="isEditing" class="slide-in-blurred-top">
                <a
                  v-if="!isSaving"
                  class="text-danger pointer mr-2"
                  @click.prevent="reset()"
                  href="#"
                  ><span>Cancelar</span></a
                >
                <button
                  v-if="!isSaving"
                  class="ml-3 btn btn-outline-primary"
                  @click="updateRole()"
                >
                  Salvar
                </button>
                <div v-if="isSaving">
                  <strong>Salvando ...</strong>
                </div>
              </div>
            </div>
            <div v-if="isCreating">
              <div class="d-flex justify-content-between">
                <div class="form-group">
                  <label for="">Nome do cargo</label>
                  <input
                    ref="role_name"
                    v-if="!isSaving"
                    type="text"
                    class="form-control"
                    v-model="tempRole.name"
                  />
                </div>
                <div class="slide-in-blurred-top mt-4">
                  <a
                    v-if="!isSaving"
                    class="text-danger pointer mr-2"
                    @click.prevent="reset()"
                    href="#"
                    ><span>Cancelar</span></a
                  >
                  <button
                    v-if="!isSaving && tempRole.name && tempRole.name.length"
                    class="ml-3 btn btn-outline-primary"
                    @click="saveRole()"
                  >
                    Salvar
                  </button>
                  <strong v-if="isSaving">Salvando ...</strong>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div
                class="p-2 w-100 mt-3"
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  grid-template-rows: auto;
                  column-gap: 10px;
                  row-gap: 15px;
                "
              >
                <div
                  class="form-check form-check-inline mb-2"
                  v-for="perm in permissionsList"
                  :key="guardNameFixKey(perm)"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :disabled="!isManaging || isSaving"
                    :checked="isPermissionSelected(perm)"
                    :id="perm.name"
                    @change="togglePermission(perm)"
                  />
                  <label class="form-check-label" :for="perm.name"
                    >{{ perm.name.capitalize() }}
                    {{ $DEBUG ? "(" + perm.guard_name + ")" : "" }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/navigation/BreadCrumb";
import { mapActions, mapGetters } from "vuex";
import Pace from "pace-js";
export default {
  data() {
    return {
      isSaving: false,
      selectedRole: null,
      isManaging: false,
      isCreating: false,
      isEditing: false,
      tempRole: {
        name: null,
        permissions: [],
      },
      confirm: false,
    };
  },
  components: {
    BreadCrumb,
  },
  mounted() {
    this.$pace.restart();
    // this.LOAD_PERMISSIONS();
  },
  computed: {
    ...mapGetters(["roles", "permissions"]),
    permissionsApiList() {
      return this.permissions.filter((per) => {
        return per.guard_name == "api";
      });
    },
    permissionsWebList() {
      return this.permissions.filter((per) => {
        return per.guard_name == "web";
      });
    },
    permissionsList() {
      const hasPermissions = this.permissions.filter((per) => {
        if (!this.selectedRole) return false;
        return per.guard_name === this.selectedRole.guard_name || false;
      });

      return hasPermissions;
    },
  },
  methods: {
    ...mapActions([
      "LOAD_ROLES",
      "LOAD_PERMISSIONS",
      "CREATE_ROLE",
      "UPDATE_ROLE",
      "DELETE_ROLE",
    ]),
    guardNameFixKey(data) {
      return `${data.name}_${data.guard_name}_${data.id}`;
    },

    selectRole(role) {
      this.reset();
      this.selectedRole = role;
    },
    isPermissionSelected(perm) {
      if (this.selectedRole)
        return this.selectedRole.permissions.some((p) => {
          return p.id === perm.id && p.name === perm.name;
        });
    },
    togglePermission(perm) {
      //console.log("togglePermission", perm);
      let where = !this.isCreating ? "selectedRole" : "tempRole";
      let index = this[where].permissions.findIndex((p) => {
        return p.name == perm.name && p.id == perm.id;
      });

      if (index < 0) {
        this[where].permissions.push(perm);

        //select other

        let web = this.permissionsWebList.find((webPer) => {
          return webPer.name == perm.name;
        });
        //console.log("togglePermission web", web);

        this[where].permissions.push(web);
      } else {
        this[where].permissions.splice(index, 1);

        let webIndex = this[where].permissions.findIndex((p) => {
          return p.name == perm.name && p.guard_name == "web";
        });

        this[where].permissions.splice(webIndex, 1);
      }
      this.$forceUpdate();
    },
    toggleEdition() {
      this.isManaging = !this.isManaging;
      this.isEditing = !this.isEditing;
    },
    newRole() {
      this.isManaging = true;
      this.selectedRole = null;
      this.isCreating = true;
      this.isEditing = false;
      setTimeout(() => {
        this.$refs.role_name.focus();
      }, 1000);
    },
    updateRole() {
      if (this._validation()) {
        this.$pace.restart();
        this.$pace.start();
        this.isSaving = true;
        this.isEditing = false;

        this.isManaging = false;

        let data = { ...this.selectedRole };

        let perm = data.permissions.map((p) => p.id);

        data.permissions = perm;

        this.UPDATE_ROLE(data).then((res) => {
          if (res.status === 200) {
            this.$toast.success(" ", "Atualizado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          } else {
            this.$toast.error(" ", "Erro ao atualizar", {
              icon: "icon-person",
              position: "topRight",
              timeout: 2000,
            });
          }
          this.$forceUpdate();
          this.isEditing = false;
          this.isManaging = false;
          this.isSaving = false;
        });
      }
    },
    reset() {
      this.$pace.restart();
      this.isManaging = false;
      this.isCreating = false;
      this.isEditing = false;
      this.selectedRole = null;
      this.confirm = false;
      this.tempRole = {
        name: null,
        permissions: [],
      };
    },
    deleteRole() {
      this.$toast.success(" ", "Removendo", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      this.confirm = false;
      this.DELETE_ROLE(this.selectedRole).then((res) => {
        //console.log("promisse res", res);
        if (res.status === 200) {
          this.$toast.success(" ", "Role deletado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.reset();
        } else {
          this.$toast.error(" ", "Erro ao deletar", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
        this.selectedRole = null;
      });
    },
    saveRole() {
      this.$pace.restart();
      this.$toast.info(" ", "Salvando", {
        icon: "icon-person",
        position: "topRight",
        timeout: 2000,
      });
      this.isSaving = true;

      this.tempRole.permissions = this.tempRole.permissions.map((p) => p.id);

      this.CREATE_ROLE(this.tempRole).then((res) => {
        if (res.status === 200) {
          this.$toast.success(" ", "Role criada", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
          this.isManaging = false;
          this.isCreating = false;
          this.isEditing = false;
          this.isSaving = false;
          this.$forceUpdate();
          this.selectedRole = res.data;
        } else {
          this.$toast.error(" ", "Erro ao atualizar", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000,
          });
        }
      });
    },
    _validation() {
      let rules = {
        name: {
          required: true,
        },
      };

      let keys = Object.keys(this.selectedRole);

      //console.log("keys", keys);

      let error = [];

      keys.forEach((key) => {
        if (rules[key] && rules[key].required) {
          if (
            this.selectedRole[key] == null ||
            !this.selectedRole[key].length
          ) {
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style>
.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
</style>
