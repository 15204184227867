<template>
    <div>
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="sidebar-item">
                    <router-link v-role="['user', 'admin', 'dev']" exact aria-expanded="false"
                        class="sidebar-link sidebar-link" :to="{ name: 'index' }"><i class="mr-3 fas fa-home"></i><span
                            class="hide-menu">Dashboard</span></router-link>
                </li>
                <li class="list-divider" v-role="['user', 'admin', 'dev']"></li>
                <li class="nav-small-cap">
                    <span class="hide-menu">Menu</span>
                </li>

                <!-- {{ permissionsNav }} -->
                <!-- {{ routes }} -->
                <li class="sidebar-item" v-for="route in routes" :key="route.name">
                    <!-- {{ userRole }}
                    {{ userRole.some(role => ['dev', 'seller', 'admin'].includes(role.name)) }}
                    {{ route.name !== 'index' }} -->
                    <router-link aria-expanded="false" class="sidebar-link" :to="{ path: route.path }" v-if="
                    userRole.some(role => ['dev', 'admin'].includes(role.name))
                    &&
                    route.name !== 'index'">
                        <i class="mr-3" :class="route.icon"></i>
                        <span class="hide-menu">{{ route.name }}</span>
                    </router-link>
                </li>

                <li class="sidebar-item" v-for="(route, index) in $router.options.routes[0].children"
                    :key="route.name + '_' + index">
                    <router-link
                        v-if="route.name == 'Minhas Vendas' && userRole.some(role => ['seller'].includes(role.name))"
                        aria-expanded="false" class="sidebar-link" :to="{ path: route.path }">
                        <i class="mr-3" :class="route.icon"></i><span class="hide-menu">
                            {{ route.name }}
                        </span></router-link>
                </li>

            </ul>
        </nav>

        <div id="side-footer">
            <a href="https://011brasil.com.br/" target="_blank"
                class="list-group-item list-group-item-action link-logo">Desenvolvido por:
                <div class="logo">
                    <img loading="lazy" class="img-fluid" src="https://smb.011brasil.com.br/imagens/011-escuro.png" />
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            permissionsNav: {},
        };
    },
    watch: {
        settings: {
            deep: true,
            immediate: false,
            handler(setting) {
                try {
                    this.permissionsNav =
                        setting.find((s) => s.key === "store_rules")?.content ||
                        {};
                } catch (error) {
                    // do anything
                }
            },
        },
    },
    computed: {
        ...mapGetters(["loggedUser", "settings", "store_rules"]),
        userRole() {
            return this.loggedUser?.roles;
        },
        routes() {
            let routes = this.$router.options.routes[0].children;
            routes = routes.filter((route) => {
                if (route.hideOnNav) {
                    return false;
                } else {
                    return true;
                }
            });
            return routes;
        },
    },
    mounted() {
        try {
            // console.log('Info::mounted',
            //     this.loggedUser,
            //     this.userRole,
            //     this.userRole.some(role => ['dev', 'seller', 'admin'].includes(role.name))
            // )
        } catch (error) {
            console.log('Warn::mounted', error)
        }
    }
};
</script>

<style lang="scss">
#side-footer {
    position: fixed;
    bottom: 0;
    width: inherit;

    & .link-logo {
        display: flex;
        align-items: center;
        align-content: center;
        border: 0;
    }

    & .logo {
        width: 45px;
        margin-left: 5px;
    }
}

@media only screen and (max-width: 1366px) and (max-height: 900px) {

    .list-divider {
        margin: .41rem 0px !important;
    }

    li.nav-small-cap {
        display: none !important;
    }

    .sidebar-link {
        padding: 9px 25px !important;
    }

}
</style>
