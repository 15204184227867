<template>
  <div>
    <BreadCrumb :crumbs="[
      { name: 'Posts', routeName: 'post.list' },
      { name: 'Detalhes' }
    ]" pageInfo="" :page="page === 'details' ? 'Post' : 'Novo post'">
      <div slot="right-side" class="d-flex">
        <LoaderButton b_class="btn-primary ml-3" :wasClicked="isSendingPreview">
          <button class="btn btn-primary" :disabled="isSavingDraft || isSaving" @click="preview()">
            Preview
          </button>
        </LoaderButton>

        <LoaderButton b_class="btn-primary ml-3" :wasClicked="isSavingDraft">
          <button class="btn btn-primary btn-request" :disabled="isSavingDraft || isSaving"
            @click="save('isSavingDraft')">
            Salvar rascunho
          </button>
        </LoaderButton>
        <LoaderButton b_class="btn-primary ml-3" :wasClicked="isSaving">
          <button class="btn btn-primary btn-request" :disabled="isSavingDraft || isSaving" @click="save('isSaving')">
            Publicar
          </button>
        </LoaderButton>
      </div>
    </BreadCrumb>
    <div class="col-9 mb-3 align-items-center justify-content-start"
      style="height: 2rem; border-radius: 0.2rem; border:0.1rem solid #4197d6; background-color:#55aff0">
      <span style="color:#fff">
        A imagem principal deve ter uma resolução de 1920x1080
      </span>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                  <div>
                    <small>Imagem principal</small>
                    <div style="max-width:500px;max-height:100%px;">
                      <div style="width: 100%; object-fit: contain;">
                        <Upload position="header" ref="up1" :noFavorite="true" :height="300" btnText="Imagem principal"
                          @data_deleted="data => $refs.up2.unFavByEvent(data)"
                          @added_item="data => $refs.up2.addByEvent(data)" :limit="1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label for="">Título</label>
                    <div class="d-flex">
                      <div class="form-check mr-3">
                        <label class="form-check-label pointer">
                          <input type="checkbox" class="form-check-input" v-model="tempPost.highlight" />
                          <small class="text-primary mr-3">Destaque</small>
                        </label>
                      </div>
                      <div @click="visability(1)" v-if="tempPost.active == 0">
                        <small class="text-danger pointer">Restrito</small>
                        <i class="far fa-eye-slash ml-3 pointer" title="Ativar"></i>
                      </div>
                      <div @click="visability(0)" v-if="tempPost.active == 1">
                        <small class="text-primary mr-3 pointer">Público</small>
                        <i class="fas fa-eye pointer text-primary" title="Desativar"></i>
                      </div>
                    </div>
                  </div>
                  <input id="title" :disabled="isSaving" class="form-control" type="text" placeholder="Título"
                    v-model="tempPost.title" @keyup="_recheckValidation" />
                </div>
                <div class="form-group">
                  <label for="">Subtítulo</label>
                  <input :disabled="isSaving" class="form-control" type="text" placeholder="Subtítulo"
                    v-model="tempPost.sub_title" @keyup="_recheckValidation" />
                </div>
                <div class="form-group">
                  <label for="">Resumo</label>
                  <textarea id="summary" :disabled="isSaving" class="form-control" type="text" placeholder="Resumo"
                    v-model="tempPost.summary" @keyup="_recheckValidation" />
                </div>
                <div class="form-row ">
                  <div class="col">
                    <label for="">Slug</label>
                    <input type="text" class="form-control" v-model="tempPost.slug" />
                    <small>Deixe vazio para gerar um slug automático.</small>
                  </div>
                  <!-- <div class="col">
                    <label for="">Status</label>
                    <select class="form-control " v-model="tempPost.active">
                      <option :value="1">Publicado</option>
                      <option :value="2">Rascunho</option>
                      <option :value="0">Desativado</option>
                    </select>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <simple-seo v-model="tempPost" />
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="mt-2 mb-2">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div>
                <div class="d-flex align-items-baseline">
                  <h4>Mídias</h4>
                  <small class="ml-3 text-primary pointer" @click="toggleInstructions()">Instruções</small>
                </div>
                <small>Escolha a imagem principal, clicando na estrela dentro da que
                  deseja.</small>
              </div>
              <button v-if="tempPost.media && tempPost.media.length" class="btn"
                @click="() => this.$refs.up2.loadPreview()">
                + adicionar imagens
              </button>
            </div>
            <div class="card-body">
              <div v-if="showInstructions" class="alert alert-warning alert-dismissible fade show" role="alert">
                Use imagens retangulares para manter uma proporção.
                <button @click.prevent="toggleInstructions()" type="button" class="close" data-dismiss="alert"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Upload type="container" ref="up2" @favorite_selected="data => this.$refs.up1.setByEvent(data)"
                @data_update="_whileUploadComponentUpdates" @data_deleted="_whileUploadComponentDeletes" :limit="10" />
              <!-- <div class="form-group mt-2">
                <label for="">Vídeo url</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fab fa-youtube"></i
                  ></span>
                  <input
                    :disabled="isSaving"
                    v-model="tempPost.video.path"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body" style="min-height:80vh">
            <!-- <div class="styled-text" v-if="page === 'details'">
              <div class="mb-4">
                <h1>{{ post.title }}</h1>
                <h5>{{ post.sub_title }}</h5>
                <small>{{ post.summary }}</small>
              </div>

              <span v-html="post.body"></span>
            </div> -->
            <div id="body">
              <vue-editor @keyup="_recheckValidation" :disabled="isSaving" class="post-editor"
                v-model="tempPost.body" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="mt-2">
          <div class="card">
            <div class="card-header">
              <h4>Organização</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label for="">Categorias</label>

                  <CategorySelector :filter="searchCategory" @change="_AddCategories" ref="category_selector" />
                </div>
                <div class="col">
                  <label for="">Tags</label>
                  <transition name="scale-in" mode="out-in">
                    <TagManager class="mt-3" v-if="createNewTag" :possibleName="searchInput"
                      @manager_save_tag="newTagSave" />
                    <div v-else>
                      <multiselect v-if="tags.length" @search-change="search" v-model="selectedTags" @input="_setTags"
                        :options="tags" :close-on-select="true" :clear-on-select="false" :hide-selected="true"
                        :preserve-search="true" placeholder="Selecione suas tags" label="name" track-by="id"
                        :multiple="true">
                        <template slot="clear" slot-scope="props">
                          <div class="multiselect__clear" v-if="tags.length"
                            @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template>
                        <span slot="noResult" class="d-flex flex-column">
                          <p>
                            Categoria <strong>"{{ searchInput }}"</strong> não
                            encontrada, Deseja criar?
                          </p>

                          <div class="d-flex justify-content-center mt-2">
                            <button class="btn btn-primary ml-2" @click="createNewTag = true">
                              Sim
                            </button>
                            <button class="btn btn-warning ml-2">Não</button>
                          </div>
                        </span>
                      </multiselect>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pace from "pace-js";

import PostCard from "@/components/posts/PostCard";
import PostComments from "@/components/comments/PostComments";
import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import SimpleSeo from "@/components/seo/SimpleSeo";
import Multiselect from "vue-multiselect";
import Upload from "@/components/imageUpload/Upload";

import TagManager from "@/components/tags/TagManager";

import CategorySelector from "@/components/categories/CategorySelectorNested";

import LoaderButton from "@/components/buttons/LoaderButton";

export default {
  data() {
    return {
      isNotPublic: false,
      isSendingPreview: false,
      isSavingDraft: false,
      paceManage: null,
      page: "details",
      isSaving: false,
      showInstructions: false,
      tempPost: {
        active: 1,
        title: null,
        body: null,
        summary: null,
        sub_title: null,
        video: {
          path: ""
        },
        media: []
      },
      searchCategory: "",
      selectedTags: [],
      createNewTag: false,
      searchInput: "",
      createNewCategory: false,
      search: "",
      previewHtml: null
    };
  },
  watch: {
    tempPost: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.highlight == true) {
          this.tempPost.highlight = 1;
        } else {
          this.tempPost.highlight = 0;
        }
      }
    }
  },
  components: {
    PostCard,
    PostComments,
    VueEditor,
    BreadCrumb,
    SimpleSeo,
    Multiselect,
    TagManager,
    CategorySelector,
    Upload,
    LoaderButton
  },
  created() {
    // console.log("checking created");
  },
  mounted() {
    setTimeout(() => {
      this.paceManage = Pace;
      // if (this.$masterHelper.isEmpty(this.post)) {
      //   this.$event.$emit("page_loaded",{isLoading: true});
      //   console.log("loading");
      //   this.checkPage();
      // }
    }, 100);
    this.checkPage();
    // console.log(this.$masterHelper.isEmpty(this.post));
    setTimeout(() => {
      // console.log('MainModal component', this.$refs.main_modal.open())
    }, 2000);
  },
  computed: {
    ...mapGetters([
      "post",
      "posts",
      "tags",
      "users",
      "categories",
      "product",
      "loggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "LOAD_POSTS",
      "GET_POST",
      "LOAD_TAGS",
      "LOAD_USERS",
      "LOAD_CATEGORIES",
      "CREATE_CATEGORY",
      "CREATE_PRODUCT",
      "GET_PRODUCT",
      "UPDATE_PRODUCT",
      "CREATE_POST",
      "UPDATE_POST",
      "PREVIEW_POST",
      "GET_MEDIA"
    ]),
    toggleInstructions() {
      this.showInstructions = !this.showInstructions;
    },
    async preview() {
      let url = this.$front;

      this.isSendingPreview = true;

      this.tempPost.user = this.loggedUser;

      this.PREVIEW_POST(this.tempPost).then(response => {
        this.isSendingPreview = false;
        window.open(url + "preview", "_blank");
      });
    },

    async getPost() {
      this.$event.$emit("page_loaded", {
        isLoading: true,
        message: "carregando ..."
      });
      await this.GET_POST(this.$route.params.id);
      this.$event.$emit("page_loaded", { isLoading: false });
    },
    async checkPage() {
      //  console.log("checking");
      let createPage = this.$route.name === "post.create";
      if (!createPage) {
        if (!this.$masterHelper.isEmpty(this.post)) {
          this.tempPost = this.post;
          let media = await this.GET_MEDIA({
            model_id: this.post.id,
            model_type: "post"
          });

          if (media.status == 200) {
            this.tempPost.media = media.data.data;
          }

          if (!this.tempPost.seo) this.tempPost.seo = {};
        } else {
          await this.GET_POST(this.$route.params.id);
          let post = this.post;
          post.video = {
            path: ""
          };
          this.tempPost = post;
        }
        this._setComponentsDataWhenEditing();
      } else {
        this.page = "create";
      }
      this.LOAD_TAGS();
      this.LOAD_CATEGORIES();
    },
    _setComponentsDataWhenEditing() {
      // 1 Set <Upload/>
      if (this.tempPost.media && this.tempPost.media.length) {
        let imageTypeOnly =
          this.tempPost.media.filter(m => {
            return m.type === "image";
          }) || [];

        let formatedWithExtention = [];

        imageTypeOnly.forEach(img => {
          img.path = img.path + ".webp";
          formatedWithExtention.push(img);
        });
        this.$refs.up2.eventSetData(formatedWithExtention);
        //set an empty video object to avoid error

        this.tempPost.video = this.tempPost.media.find(m => {
          return m.type === "video";
        }) || { path: "" };
      }
      // 2 Set <CategorySelector/>
      this.tempPost.categories.map(cat =>
        this.$refs.category_selector.selectedCategories.push(cat)
      );
      // 3 Set selectedTags
      // selectedTags is the variable that tag multiselect uses for v-model
      this.selectedTags = this.tempPost.tags;
      this._AddCategories(this.tempPost.categories);
      this._setTags(this.tempPost.tags);
    },

    async save(action) {
      //  ==================== Publish post rules ===================
      let rules = {
        title: {
          required: true
        },
        summary: {
          required: action == "isSaving" ? true : false
        },
        body: {
          required: true
        }
      };
      //  ==================== Publish post rules ===================
      if (this._validation(rules)) {
        this[action] = true;
        this.tempPost.user_id = this.loggedUser.id;
        this.$pace.restart();

        if (action == "isSavingDraft") {
          this.tempPost.active = 2;
        }

        if (action == "isSaving" && this.tempPost.active == 2) {
          this.tempPost.active = 1;
        }

        if (this.page === "create") {
          this.$toast.info(" ", "Salvando post!", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          });

          this.CREATE_POST(this.tempPost).then(d =>
            setTimeout(() => {
              this.$toast.success(" ", "Post criado!", {
                icon: "icon-person",
                position: "topRight",
                timeout: 3000
              });

              this[action] = false;

              this.$forceUpdate();

              setTimeout(() => {
                this.$router.push({ name: "post.list" });
              }, 1000);
            }, 1000)
          );
        } else {
          this.$toast.info(" ", "Atualizando post!", {
            icon: "icon-person",
            position: "topRight",
            timeout: 2000
          });
          let _tempData = JSON.parse(JSON.stringify(this.tempPost));

          if (_tempData.media && _tempData.media.length) {
            _tempData.media.forEach((m, index) => {
              if (m.path) {
                _tempData.media[
                  index
                ].path = this.$masterHelper.checkForExtentions(
                  _tempData.media[index].path
                );
              }
            });
          }
          this.UPDATE_POST(_tempData).then(d =>
            setTimeout(() => {
              this.$toast.success(" ", "Post atualizado!", {
                icon: "icon-person",
                position: "topRight",
                timeout: 3000
              });

              this[action] = false;
              this.$forceUpdate();
              this.$router.push({ name: "post.list" });
              this.$forceUpdate();
            }, 1000)
          );
        }
      } else {
      }
    },
    _recheckValidation(e) {
      if (e.target.value.length) {
        if (this.tempPost.body && !this.tempPost.body.length) {
          document
            .getElementById("quill-container")
            .classList.add("border-danger");
        }
        if (e.target.classList.contains("border-danger")) {
          e.target.classList.remove("border-danger");
        }
      }
    },

    _validation(rules) {
      let keys = Object.keys(this.tempPost);

      // console.log("keys", keys);

      let error = [];

      keys.forEach(key => {
        if (rules[key] && rules[key].required) {
          if (this.tempPost[key] == null || !this.tempPost[key].length) {
            if (key == "body") {
              document
                .getElementById("quill-container")
                .classList.add("border-danger");
            }
            document.getElementById(key).classList.add("border-danger");

            error.push(key);
          }
        }
      });

      if (error.length) {
        this.$toast.error(" ", "Campos importantes!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 2000
        });

        return false;
      }

      return true;
    },
    newTagSave(tag) {
      this.selectedTags.push(tag);
      this.resetCreations();
    },
    resetCreations() {
      this.searchInput = null;
      this.createNewTag = false;
      this.createNewCategory = false;
    },
    _AddCategories(categories) {
      // console.log("dfferfwerwerwerwerwer", categories);
      this.tempPost.categories = categories.map(cat => cat.id);
    },
    _setTags(tags) {
      this.tempPost.tags = tags.map(cat => cat.id);
    },
    _whileUploadComponentDeletes(data) {
      //console.log("on update removal", data);
      let index = this.tempPost.media.findIndex(m => {
        if (m.src) {
          return m.src === data.src;
        } else {
          return m.path === data.path + ".webp";
        }
      });
      if (index > -1) this.tempPost.media.splice(index, 1);
      this.$refs.up1.removeByEvent(data);
    },
    _whileUploadComponentUpdates(data) {
      data.map(d => {
        let contains = this.tempPost.media.some(tp => {
          if (d.src) {
            return d.src === tp.src;
          } else {
            return d.path === tp.path;
          }
        });
        if (!contains) this.tempPost.media.push(d);
      });
    },
    visability(s) {
      if (s == 0) {
        this.isNotPublic = true;
      }
      this.tempPost.active = s;
    }
  }
};
</script>

<style lang="scss">
.styled-text {
  line-height: 2.5em;
  padding: 1em 5em 1em 5em;
}

.post-editor {
  #quill-container {
    min-height: 666px;
  }
}
</style>
