<template>
  <div class="product-container" v-show="order && ordersProducts.length > 0">
    <div class="row align-items-center mt-4" v-for="(product, index) in ordersProducts"
      :key="getID(product) + '_' + index">
      <div class="col-3">
        <img loading="lazy" style="width: 150px" :src="$filterMedia(getProduct(getID(product)), 'image', 'header')"
          alt="" />
      </div>
      <div class="col">
        {{ getProduct(getID(product)).name || '' }}
      </div>
      <div class="col">
        {{ getQuantity(product) }}
      </div>
      <div class="col">R$ {{ $format($money(getPrice(product))) || "-" }}</div>
      <div class="col">
        R$ {{ $format($money(getTotalPrice(product))) || "-" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return {
      loadingIDs: [],
      ordersProducts: []
    };
  },
  mounted() {
    try {
      this.ordersProducts = []
      this.ordersProducts = this.order?.products || this.order?.order_products || []
      // this.ordersProducts = this.order.products
    } catch (error) {
      console.log('Warn::mounted', error)
      //console.log("ORDER DEBUG", this.order);
    }
  },
  computed: {
    ...mapGetters(["products"]),
  },

  watch: {
    order: {
      deep: true,
      immediate: false,
      handler(newOrder) {
        try {
          this.ordersProducts = []
          this.ordersProducts = newOrder?.products || newOrder?.order_products || []
        } catch (error) {
          //
        }
      }
    }
  },
  methods: {
    ...mapActions(["GET_PRODUCT_BY_ID"]),
    isLoadingProduct(id) {
      return this.loadingIDs.some((loadingID) => loadingID == id);
    },

    getProduct(id) {
      try {
        console.log('Actual Order', this.order)
        console.log('Actual Order Products', this.order?.order_products)

        if (this.order?.order_products?.length <= 0) return {}

        const product = this.order?.order_products.find(order_product => order_product?.product.id === id)?.product || {}

        return product;
      } catch (error) {
        // do abything
      }
    },

    getPrice(productRaw) {
      console.log('getPrice', productRaw?.product?.pivot?.price || productRaw?.product?.price, productRaw)
      return productRaw?.product?.pivot?.price || productRaw?.price
    },

    getTotalPrice(productRaw) {
      let total_price = 0.0

      if (productRaw?.product?.pivot) {
        total_price = productRaw?.product?.pivot?.price * productRaw?.product?.pivot?.qty
      } else {
        total_price = productRaw?.total_price
      }

      console.log('getTotalPrice', total_price, productRaw, productRaw?.product?.pivot?.price, productRaw?.total_price)

      return total_price
    },

    getID(productRaw) {
      console.log('getID', productRaw?.productRaw?.id || productRaw?.product_id, productRaw)
      return productRaw?.product?.id || productRaw?.product_id;
    },

    getQuantity(product) {
      console.log('getQuantity', product?.qty_selected || product?.quantity, product)
      return product?.qty_selected || product?.quantity
    }
  },
};
</script>

<style>

</style>
