import * as Yup from 'yup'

export const profileSchema = Yup.object().shape({
  name: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string()
    .email('Insira um e-mail válido'),
  contact: Yup.string(),
  password: Yup.string().min(6, "O campo senha deve ter no minimo 6 caracteres"),
  password_confirmation: Yup.string()
    .min(6, "O campo confirme a senha deve ter no minimo 6 caracteres")
    .oneOf([Yup.ref('password'), null], 'O campo confirme a senha não é igual a sua senha')
})
