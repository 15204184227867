<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['preview']),
  },
  mounted() {

    // console.log(localStorage.getItem('preview'))

    setTimeout(() => {
      document.body.innerHTML = localStorage.getItem('preview')
    }, 1000)
  }
}

</script>

<style>

</style>
