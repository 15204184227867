<template>
  <div class="row mt-3" v-if="shippings">
    <div v-for="(shipping, index) in shippings" :key="index">
      <div class="row ml-1 mt-3">
        <div class="col" v-if="shipping.delivery_address">
          <strong>Endereço de entrega:</strong>

          <div class="mt-3">
            <strong> Rua: </strong>
            <p> {{ shipping.delivery_address.street }} </p>

            <strong> Bairro: </strong>
            <p> {{ shipping.delivery_address.district }} </p>

            <strong> Número: </strong>
            <p> {{ shipping.delivery_address.number }} </p>

            <strong> CEP: </strong>
            <p> {{ shipping.delivery_address.zip }} </p>

            <strong> Estado:</strong>
            <p> {{ shipping.delivery_address.state }} </p>

            <strong> Cidade: </strong>
            <p> {{ shipping.delivery_address.city }} </p>
          </div>
        </div>

        <div class="col" v-if="shipping.shipping_data">
          <div class="col">
            <strong>Detalhes do frete:</strong>
            <div class="mt-3">
              <strong> Prazo: </strong>
              <p> {{ shipping.shipping_data.time }} dia(s), após a postagem</p>

              <strong> Valor: </strong>
              <p>
                {{
                  Number(shipping.shipping_data.price || 0.0).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )
                }}
              </p>
            </div>

            <div class="mt-3">
              <strong> Tipo: </strong>
              <p> {{ shipping.shipping_data.from }} </p>

              <strong> Entrega nos finais de semana: </strong>
              <p> {{ shipping.shipping_data.codigo === 1 ? "Sim" : "Não" }} </p>

              <strong> Número de rastreio: </strong>
              <p> {{ shipping.tracking_code || "Ainda não postado" }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col text-center align-center">
      <p>--- <strong>Frete Gratis</strong> ---</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // do anything
    };
  },
  components: {},
  props: ["shippings"],
  methods: {},
};
</script>

<style>

</style>
